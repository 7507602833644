<template>
  <div class="conf-sessions__ask-a-question-button">
    <button class="button btn btn-primary" @click="expand">
      <i class="fa-solid fa-hand"></i> {{ __("conference_sessions.ask_a_question.buttons.ask") }}
    </button>
  </div>

  <AskAQuestionModal ref="modal" :conferenceSession="conferenceSession"></AskAQuestionModal>

</template>

<script>

import AskAQuestionModal from './AskAQuestionModal.vue';

export default {
  components: { AskAQuestionModal },
  methods: {
    expand() {
      this.$refs.modal.modalOpen();
    },
  },
};
</script>
