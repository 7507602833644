<template>
  <div :class="classes">
    <Transition :name="resizing ? null : 't-reveal-left'">
      <div class="layout-3-column__left" v-show="smallLaptopOrLarger || activeColumn == 1">
        <slot name="left"></slot>
      </div>
    </Transition>
    <Transition :name="resizing ? null : 't-reveal-right'">
      <div
        class="layout-3-column__center"
        v-show="smallLaptopOrLarger || activeColumn != 1"
        >
        <slot></slot>
      </div>
    </Transition>
    <Transition :name="resizing ? null : 't-reveal-right'">
      <div class="layout-3-column__right" v-show="laptopOrLarger">
        <slot name="right"></slot>
      </div>
    </Transition>
  </div>
</template>

<script>
import { useBreakpoints } from '~/Frontend/useBreakpoints';
import { useWindowResizing } from '~/Frontend/useWindowResizing';

export default {
  props: {
    activeColumn: {
      type: Number,
      default: 2,
      validator: (num) => num >= 1 && num <= 3,
    },
  },
  data() {
    return {
      currentTransition: [],
    };
  },
  computed: {
    classes() {
      return [{
        'layout-3-column': true,
        'layout-3-column--left': this.activeColumn === 1,
        'layout-3-column--center': this.activeColumn === 2,
      }];
    },
  },
  setup() {
    const { laptopOrLarger, smallLaptopOrLarger } = useBreakpoints();
    const { resizing } = useWindowResizing();
    return {
      laptopOrLarger, smallLaptopOrLarger, resizing,
    };
  },
  watch: {
    activeColumn(newVal, oldVal) {
      this.currentTransition = [oldVal, newVal];
    },
  },
};
</script>
