<template>
  <fieldset class="filter-group">
    <slot name="default"></slot>
  </fieldset>
</template>

<script>

export default {
  name: 'FilterRadioGroup',
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ['update:modelValue'],
};
</script>
