<template>
  <ApexChart
    type="area"
    height="200"
    :options="options"
    :series="series"
  />
</template>

<script>
import ApexChart from 'vue3-apexcharts';
import moment from 'moment';

export default {
  props: {
    profileViewsPerDay: {
      type: Array,
      required: true,
    },
  },
  components: { ApexChart },
  computed: {
    series() {
      return [{
        data: this.seriesData,
      }];
    },
    seriesData() {
      const data = [];
      for (let day = this.seriesStart.clone(); day <= moment(); day.add(1, 'days')) {
        const date = day.format('YYYY-MM-DD');
        data.push(
          [date, this.dayMap.has(date) ? this.dayMap.get(date) : 0],
        );
      }
      return data;
    },
    dayMap() {
      return new Map(
        this.profileViewsPerDay.map(({ value, day }) => [day, value]),
      );
    },
    // Get all the days we have data for
    days() {
      return this.profileViewsPerDay.map(({ day }) => moment(day));
    },
    // Start the graph at the ealiest day - 1
    seriesStart() {
      return this.days.reduce((min, day) => moment.min(min, day), moment())
        .clone()
        .subtract(1, 'days');
    },
  },
  data() {
    return {
      options: {
        chart: {
          id: 'profile-views-per-day',
          background: '#fff',
          offsetX: 0,
          offsetY: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          type: 'datetime',
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM \'yy',
            day: 'dd MMM',
          },
          labels: {
            style: {
              colors: 'var(--gray-4)',
            },
          },
        },
        yaxis: {
          show: true,
          min: 0,
          labels: {
            show: true,
            formatter(val) {
              return val.toFixed(0);
            },
          },
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
      },
    };
  },
};
</script>
