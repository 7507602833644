<template>
  <ul class="conf-session__attendees">
    <TransitionGroup>
      <li
      v-for="attendee in attendees"
      :key="attendee.id"
      class="conf-session__attendee"
      >
        <ContactAvatar :contact="attendee" size="xsmall" />

        <span class="conf-session__attendee-name">
          {{ attendee.display_name }}
          -
          {{ attendee.company.display_name }}
        </span>
      </li>
    </TransitionGroup>
  </ul>
</template>
<script>
import _ from 'lodash';
import axios from 'axios';
import ContactAvatar from '../CRM/Components/ContactAvatar.vue';

export default {
  props: {
    initialAttendees: {
      type: Array,
      default: () => [],
    },
    updateUrl: {
      type: String,
    },
  },
  components: { ContactAvatar },
  data() {
    return {
      attendees: this.initialAttendees,
    };
  },
  methods: {
    update() {
      axios.get(this.updateUrl)
        .then((response) => {
          this.attendees = response.data.attendees;
        }).catch(() => {
          // eslint-disable-next-line no-alert
          alert('Please refresh your browser');
        }).then(() => {
          this.updateSoon();
        });
    },
    updateSoon() {
      const wait = _.random(3000, 7000);
      setTimeout(this.update, wait);
    },
  },
  mounted() {
    this.updateSoon();
  },
};
</script>

<style scoped>
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }
  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
