<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="67" viewBox="0 0 24 67">
    <g transform="translate(-1413 -755)">
      <g transform="translate(1413 755)" fill="#fff" stroke="#727a82" stroke-width="1">
        <rect width="24" height="42" rx="10" stroke="none"/>
        <rect x="0.5"
          y="0.5"
          width="23"
          height="41"
          rx="9.5"
          fill="none"
          />
      </g>
      <line y2="10"
        transform="translate(1425 761.5)"
        fill="none"
        stroke="#707070"
        stroke-linecap="round"
        stroke-width="2"
      />
      <text
        transform="translate(1425 820)"
        fill="#727a82"
        font-size="16"
        font-family="FontAwesome6Pro-Solid, 'Font Awesome \36  Pro'"
      >
        <tspan x="-5" y="0"></tspan>
      </text>
    </g>
  </svg>
</template>
