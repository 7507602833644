<template>
  <Teleport to="body">
    <BaseModal
      ref="modal"
      title="Edit images"
      titleIcon="far fa-edit"
      @opened="$emit('opened')"
      @closed="$emit('closed')"
      v-show="!childModalOpen"
      height="tall"
      >
      <p>
        {{ type.name }}: <b> {{ entryName }} </b>
      </p>
      <div class="profile-table">
        <draggable
          tag="div"
          :component-data="{
            tag: 'div',
            type: 'transition-group',
            name: drag ? 'flip-list' : null,
          }"
          v-model="list"
          item-key="id"
          class="profile-table__body"
          handle=".handle"
          v-if="list.length > 0"
          @start="drag = true"
          @end="drag = false"
          @sort="handleSort"
        >
          <template #item="{  element: image, index }">
            <ImageGalleryTableRow
              :image="image"
              :endpoint="`${endpoint}/${image.id}`"
              :tag="index === 0 ? 'Main' : null"
              @modalOpened="childModalOpen = true"
              @modalClosed="childModalOpen = false"
              @deleted="list.splice(index, 1)"
              @updated="(image) => { handleUpdate(image, index) }"
              recommended-size="1920 X 1080"
              small
              />
          </template>
        </draggable>
        <div v-if="list.length == 0" style="min-height: 100px;">
          <p>{{ __('ui.noItemsToDisplay') }}</p>
          <a href="#" @click.prevent="$refs.createModal.open()">
            <span v-html="__('ui.add')"></span>
          </a>
        </div>

      </div>
      <template #footer>
        <div class="button-group">
          <button class="button  button--secondary" type="button" @click="close">
            {{ __('buttons.cancel') }}
          </button>
          <button class="button" type="button" @click="$refs.createModal.open()">
            <span v-html="__('ui.add')"></span>
          </button>
        </div>
      </template>
    </BaseModal>

    <ImageForm
      ref="createModal"
      :action="endpoint"
      @closed="childModalOpen = false"
      @opened="childModalOpen = true"
      @success="handleCreate"
      recommended-size="1920 X 1080"
    />
  </Teleport>
</template>

<script>
import draggable from 'vuedraggable';
import axios from 'axios';
import _ from 'lodash';

export default {
  components: {
    draggable,
  },
  props: {
    gallery: {
      type: Array,
      required: true,
    },
    entryId: {
      type: Number,
      required: true,
    },
    entryName: {
      type: String,
      required: true,
    },
    type: {
      type: Object,
      required: true,
    },
  },
  expose: ['open', 'close'],
  emits: ['opened', 'closed', 'update:gallery'],
  data() {
    return {
      childModalOpen: false,
      drag: false,
    };
  },
  computed: {
    list: {
      get() {
        return this.gallery;
      },
      set(list) {
        this.$emit('update:gallery', list);
      },
    },
    endpoint() {
      return `/company-profile/catalogue-entries/${this.entryId}/gallery`;
    },
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    handleCreate(response) {
      const newImage = response.image;
      newImage.fresh = true;
      this.list.push(newImage);
    },
    handleUpdate(image, index) {
      this.list[index] = image;
    },
    handleSort() {
      axios.patch(`${this.endpoint}/sort`, {
        keys: _.map(this.list, 'id'),
      }).catch((response) => { this.errors = response?.data?.errors; });
    },
  },
};
</script>
