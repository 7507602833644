<template>
  <label class="form-input" :class="{'form-input--invalid': invalid}">
    <FormLabel v-if="label" v-bind="labelAttrs" />
    <input
      v-model="modelValue"
      class="form-input__input"
      v-bind="inputAttrs"
      @input="feForm.clearErrors(name)"
      ref="inputElement"
    />
    <FormError :errors="errors" />
  </label>
</template>

<script>

import { computed } from 'vue';

export default {
  props: {
    default: {
      required: false,
      default: '',
    },
    name: {
      required: true,
      type: String,
    },
    label: {
      required: false,
      type: String,
    },
    icon: {
      type: String,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
    autofocus: Boolean,
  },
  data() {
    return {
      modelValue: this.default || '',
    };
  },
  inject: ['feForm'],
  computed: {
    id() {
      return `${this.feForm.name}-${this.name}`;
    },
    errors() {
      return this.feForm.error(this.name).value || this.feForm.flatError(this.name).value || [];
    },
    invalid() {
      return this.errors.length > 0;
    },
    labelAttrs() {
      const attrs = {
        icon: this.icon,
        label: this.label,
        size: this.modelValue.length,
        limit: this.max,
      };

      if ('required' in this.$attrs) {
        attrs.required = this.$attrs.required;
      }

      return attrs;
    },
    inputAttrs() {
      const attrs = { ...this.$attrs };
      // Removing required from html as backend validation is nicer
      if ('required' in attrs) {
        delete attrs.required;
      }

      if (this.max) {
        attrs.maxlength = this.max;
      }

      attrs.id = this.id;
      attrs.name = this.name;
      return attrs;
    },
  },
  mounted() {
    this.feForm.register(this.name, computed(() => this.modelValue));
    if (this.autofocus) {
      this.$refs.inputElement.focus();
    }
  },
};

</script>
