<template>
  <MeetingChatRow
    type="InviteForwardedOnToChat"
    :preMessage="preMessage"
    :message="message"
    :when="chat.when"
    :sender="sender"
    :sentByYou="sentByYou"
    :read="chat.read"
  >
    <template #chip>
      <div class="chip chip--small chip--alert">
        <i class="far fa-info-circle"></i>
        {{ __('conversations.chips.forwarded') }}
      </div>
    </template>
  </MeetingChatRow>
</template>
<script>

import MeetingChatRow from './MeetingChatRow.vue';

export default {
  components: { MeetingChatRow },
  props: {
    chat: {
      type: Object,
      required: true,
    },
    sender: {
      type: Object,
      required: true,
    },
    invite: {
      type: Object,
      required: false,
    },
    forwarded_to_contact: {
      type: Object,
      required: true,
    },
    policy: {
      type: Object,
      required: false,
    },
    sentByYou: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    message() {
      return 'null';
    },
    preMessage() {
      // eslint-disable-next-line no-underscore-dangle
      return this.__('conversations.forwarded_to', { recipient: this.forwarded_to_contact.display_name });
    },
  },
};

</script>
