import { createApp } from 'vue';
import eventEditionManager from '~/Frontend/User/EventEditionManager';
import MeetingInviteService from '~/Frontend/Services/Networking/MeetingInviteService';
import MeetingService from '~/Frontend/Services/Networking/MeetingService';
import mitt from 'mitt';
import storeManager from '~/Frontend/store';
import VPopper from 'vue3-popper';
import VueTelInput from 'vue-tel-input';
import { createPinia } from 'pinia';
import { sentryInit } from '~/dependencies';
import { translations } from '~/Mixins/translations';

const emitter = mitt();

const pinia = createPinia();

const app = createApp({
  provide: {
    meetingInviteService: MeetingInviteService(),
    meetingService: MeetingService(),
    eventEditionManager,
    csrf: document.head.querySelector('meta[name="csrf-token"]').content,
  },
})
  .mixin(translations);

sentryInit(app);

app.use(VueTelInput);
app.use(pinia);

app.config.globalProperties.emitter = emitter;

app.config.globalProperties.storeGet = storeManager.get;

app.component('VPopper', VPopper);

window.storeManager = storeManager;

window.emitter = emitter;

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
const frontendComponents = import.meta.globEager('./Components/Frontend/**/*.vue');

const commonComponents = import.meta.globEager('./Components/Common/**/*.vue');

const components = Object.entries({ ...commonComponents, ...frontendComponents });

components.forEach(([path, component]) => {
  app.component(
    path.split('/').pop().split('.')[0],
    component.default,
  );
});

/**
 * this disables {{ interpolation }} in blade
 * which saves us from back office xss attacks
 */
app.config.compilerOptions.delimiters = ['', ''];

app.mount('#app');

window.eventEditionManager = eventEditionManager;
