<template>
  <img
    v-if="profileImageUrl"
    :class="['contact__avatar', size, color]"
    :src="profileImageUrl"
    :alt="contact.initials"
    />
  <div v-else :class="['contact__avatar default', size, color]">
    <span class="contact__default-profile-image-text" :class="size">
      {{ contact.initials }}
    </span>
  </div>
</template>

<script>

const colors = ['palette-1', 'palette-2', 'palette-3', 'palette-4'];

/**
 * Just a very simple hash function to give a bit of randomness to distribution
 * of colors
 */
function hash(n) {
  let x = n;
  x = ((x >> 16) ^ x) * 0x45d9f3b;
  x = ((x >> 16) ^ x) * 0x45d9f3b;
  x ^= (x >> 16);
  return x;
}

export default {
  props: {
    contact: {
      type: Object,
      default: () => {},
    },
    size: {
      type: String,
      validator: (v) => ['xsmall', 'small', 'medium', 'large', 'xlarge'].includes(v),
      default: 'medium',
    },
  },
  data() {
    return {
      color: colors[hash(this.contact.id) % colors.length],
    };
  },
  computed: {
    profileImageUrl() {
      if (!this.contact.avatar && !this.contact.avatar_url) {
        return false;
      }
      // new version for use in the meetings section
      if (this.contact.avatar_url) {
        return this.contact.avatar_url;
      }
      if (this.contact.avatar.avatar && this.contact.avatar.avatar.url) {
        return this.contact.avatar.avatar.url;
      }
      if (this.contact.avatar.thumbnail && this.contact.avatar.thumbnail.url) {
        return this.contact.avatar.thumbnail.url;
      }
      return false;
    },
  },
};
</script>
