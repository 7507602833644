<template>
  <ContactAvatar :contact="contact" size="small" @click.stop="openProfileModal">
  </ContactAvatar>
  <div class="inbox-row__contact-container">
    <div class="inbox-row__contact-info">
      <div class="inbox-row__contact-name" @click.stop="openProfileModal">
        <div class="inbox-row__relationship">
          {{ relationshipLabel }}
        </div>
        <div class="inbox-row__ellipsis">
          {{ contact.full_name ?? contact.display_name }},
        </div>
      </div>
      <div class="inbox-row__contact-job-title">
        <div class="inbox-row__ellipsis">
          {{ contact.job_title }}
        </div>
      </div>
    </div>
    <div class="inbox-row__company-info">
      <div class="inbox-row__company-name" @click.stop="openCompanyModal">
        <div class="inbox-row__ellipsis">
          {{ contact.company.display_name }}
        </div>
      </div>
      <div v-if="contact.badgeLabel" class="badge-type">
        {{ contact.badgeLabel }}
      </div>
    </div>
  </div>
</template>

<script>
import ContactAvatar from '../../CRM/Components/ContactAvatar.vue';

export default {
  components: {
    ContactAvatar,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    relationshipLabel: {
      type: String,
      required: true,
    },
  },
  methods: {
    openProfileModal() {
      window.location.href = `#/contacts/${this.contact.id}/profile`;
    },
    openCompanyModal() {
      window.location.href = `#/companies/${this.contact.company_id}`;
    },
  },
};
</script>
