<template>
  <div class="registration-footer">
      <div class="registration-footer__buttons">
        <slot></slot>
      </div>
      <p
        v-if="this.registrationStore.getPlacement?.url"
        class="registration-footer__text-info"
      >
        Sponsored by
        <a
          :href="this.registrationStore.getPlacement.url"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ this.registrationStore.getPlacement.name }}
        </a>
      </p>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import useRegistrationStore from '~/Store/Registration';

export default {
  name: 'RegistrationFooter',
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
  },
};
</script>
