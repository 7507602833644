<template>
  <div :class="{'contact-row': true, 'contact-row__card': isCard}">
    <template v-if="contact">
      <ContactAvatar :contact="contact" :size="avatarSize" />
      <div>
        <div class="contact-row__items">
          <a v-if="linkable" class="contact-row__name" :href="`#/contacts/${contact.id}/profile`">
            {{ contact.full_name || contact.display_name }}
          </a>
          <span v-else class="contact-row__name">
            {{ contact.full_name || contact.display_name }}
          </span>
          <span v-if="!hideJobTitle" class="contact-row__job_title">
            {{ contact.job_title }}
          </span>
          <span v-if="!hideCompany" class="contact-row__company-name">
            <a v-if="linkable && contact.company_id"
class="contact-row__company-name-link"
              :href="`#/companies/${contact.company_id}`"
            >
              {{ contact.company.display_name}}
            </a>
            <span v-else>
               {{ contact.company.display_name}}
             </span>
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ContactAvatar from './Components/ContactAvatar.vue';
import { store } from '../../../Frontend/store';

export default {
  name: 'ContactRow',
  components: {
    ContactAvatar,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    isCard: {
      type: Boolean,
    },
    avatarSize: {
      type: String,
      default: 'medium',
    },
    linkable: {
      type: Boolean,
      default: false,
    },
    hideJobTitle: {
      type: Boolean,
      default: false,
    },
    hideCompany: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
  },
};

</script>
