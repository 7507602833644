import { useWindowSize } from '@vueuse/core';
import { ref, watch } from 'vue';

export default {};

export function useWindowResizing() {
  const resizing = ref(false);

  let timeout = null;

  const { width, height } = useWindowSize();

  watch([width, height], () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    resizing.value = true;
    timeout = setTimeout(() => { resizing.value = false; }, 300);
  });

  return {
    resizing,
  };
}
