<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.company.legalName' ) }}
    </div>
    <div class="profile-row__value">
      {{ legalName }} &nbsp; <b> <i class="fa-solid fa-lock"></i> {{ __('ui.locked') }} </b>
    </div>
    <a class="button button--link" href="#/concierge-contact">
      <i class="fa fa-user-headset"></i> {{ __('buttons.contact') }}
    </a>
  </div>
</template>

<script>

export default {
  props: {
    legalName: {
      type: String,
      required: true,
    },
  },
};

</script>
