<template>
  <div
    class="modal"
    v-show="isOpen"
    v-if="template"
    :style="{ visibility: hidden ? 'hidden' : visible }"
    >
    <div class="modal__body" :class="{'modal__body--full-page': props?.fullPage, 'modal__body--small': props?.size === 'small' }">
      <slot>
      </slot>
      <button v-if="props.closable" @click="modalClose" class="modal__close-button">
        <i class="far fa-times"></i>
      </button>
      <component :is="template" v-bind="props" :key="key" v-on="eventHandlers"></component>
    </div>
  </div>
</template>

<script setup>

import {
  onMounted, ref, shallowRef, onBeforeUnmount,
} from 'vue';
import { modalSubscribe, modalClose } from '../../modal';

// Data
const isOpen = ref(false);
const template = shallowRef(null);
const props = ref({});
const eventHandlers = ref({});
const key = ref(1);

// Methods
function open() {
  isOpen.value = true;
}

function close() {
  isOpen.value = false;
}

function push({ component, props: componentProps = {}, handlers = {} }) {
  template.value = component;
  componentProps.closable = componentProps.closable ?? true;
  props.value = componentProps;
  eventHandlers.value = handlers;
  key.value += 1;
}

function handleKeydown(e) {
  switch (e.keyCode) {
    case 27:
      modalClose();
      break;
    default:
      break;
  }
}

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown);
});

// Hooks
onMounted(() => {
  modalSubscribe('open', open);
  modalSubscribe('close', close);
  modalSubscribe('push', push);
  window.addEventListener('keydown', handleKeydown, null);
});

</script>
