<template>
  <label class="form-input" :class="{'form-check--invalid': invalid}">
    <div class="form-input__checkfield-option" v-for="option in options" :key="option.value">
      <FrontendSwitch
        :value="option.value"
        v-model="modelValue"
        >
        <span class="form-input__check-label"> {{ option.label }} </span>
      </FrontendSwitch>
    </div>
    <FormError :errors="errors"/>
  </label>
</template>

<script>

import { computed } from 'vue';

export default {
  props: {
    /**
     * @type Object option
     * @type Boolean option.checked
     * @type String option.value
     * @type String option.label
     *
     * @prop option[] options
     */
    options: {
      type: Array,
      required: true,
    },
    name: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      modelValue: this.options.filter((option) => option.checked).map((option) => option.value),
    };
  },
  inject: ['feForm'],
  computed: {
    id() {
      return `${this.feForm.name}-${this.name}`;
    },
    errors() {
      return this.feForm.error(this.name).value || [];
    },
    invalid() {
      return this.errors.length > 0;
    },
  },
  mounted() {
    this.feForm.register(this.name, computed(() => this.modelValue));
  },
};

</script>
