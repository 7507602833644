<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ eventEdition.name }}
    </div>
    <div
      class="profile-row__value tag-list tag-list--vertical"
    >
      <div class="tag tag__part">
        <template v-if="data.findable">
          <span class="text-success">
            <i class="fas fa-check"></i>
          </span>
        {{ __('profile.optouts.type_state_findable_true') }}
        </template>
        <template v-else>
          <span class="text-warning">
            <i class="fas fa-xmark"></i>
          </span>
          {{ __('profile.optouts.type_state_findable_false') }}
        </template>
      </div>
      <div class="tag tag__part">
        <template v-if="data.meetable">
          <span class="text-success">
            <i class="fas fa-check"></i>
          </span>
          {{ __('profile.optouts.type_state_meetable_true') }}
        </template>
        <template v-else>
          <span class="text-warning">
            <i class="fas fa-xmark"></i>
          </span>
          {{ __('profile.optouts.type_state_meetable_false') }}
        </template>
      </div>
    </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal">
        <i class="fa fa-edit"></i>
        {{ __('buttons.edit') }}
      </button>
    </div>
  </div>
  <ModalForm
    :form="{
      action: '/profile/optouts',
      method: 'patch',
      name: 'update-preferences',
    }"
    :modal="{
      title: __('ui.edit'),
      titleIcon: 'far fa-edit',
    }"
    ref="modal"
    @success="update"
  >
    <FormHidden name="event_edition_id" :value="eventEdition.id" />

    <FormCheckbox
      name="findable"
      :labelTrue="__('profile.optouts.type_state_findable_true')"
      :labelFalse="__('profile.optouts.type_state_findable_false')"
      :checked="data.findable"
    />

    <FormCheckbox
      name="meetable"
      :labelTrue="__('profile.optouts.type_state_meetable_true')"
      :labelFalse="__('profile.optouts.type_state_meetable_false')"
      :checked="data.meetable"
    />

    <template #footer>
      <div class="button-group">
        <button type="button" class="button button--secondary" @click="closeModal">
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit> {{ __('buttons.save') }}</FormSubmit>
      </div>
    </template>
  </ModalForm>
</template>

<script>

export default {
  props: {
    eventEdition: {
      type: Object,
      default: () => ({}),
    },
    findable: {
      type: Boolean,
    },
    meetable: {
      type: Boolean,
    },
  },
  data() {
    return {
      data: {
        findable: this.findable,
        meetable: this.meetable,
      },
    };
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    update(data) {
      this.data = data;
      this.closeModal();
    },
  },
};

</script>
