<template>
  <div class="event-edition-card">
    <div class="event-edition-card__image-holder">
       <img
         :src="logo"
         class="event-edition-card__image"
         alt="Event Edition Logo"
         />
    </div>
    <div class="event-edition-card__description">
        {{ eventEdition.short_description }}
    </div>
    <div class="event-edition-card__actions">
      <!-- is attending and open -->
      <a
        v-if="isAttending && eventEdition.event_open"
        class="button button--full button--secondary"
        :href="'\\' + eventEdition.slug"
      >
        {{ __('event_editions.card.launch') }} <i class="fa-regular fa-right-long"></i>
      </a>

      <!-- not attending and reg open -->
      <a
        v-else-if="!isAttending && (eventEdition.registration_open && eventEdition.registration_url)"
        class="button button--full button--secondary"
        :href="eventEdition.registration_url"
        target="_blank"
        rel="noopener noreferrer"
        >
          {{ __('event_editions.card.register') }}
      </a>

      <template v-else>
        <!-- Event closed and in past -->
        <span v-if="eventEdition.event_finished" class="button--base">
          {{ __('event_editions.card.event_closed') }}
        </span>
        <span v-else class="button--base">
          {{ __('event_editions.card.reg_soon') }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventEditionCard',
  props: {
    eventEdition: {
      type: Object,
      required: true,
    },
    isAttending: {
      type: Boolean,
      required: true,
    },
    logo: {
      type: String,
    },
  },
};
</script>
