<template>
  <div
    class="notification-bar"
    :class="modifier"
    >
    <i :class="icon" v-if="icon"></i>
    <slot></slot>
  </div>
</template>

<script>

const modifiers = {
  error: 'notification-bar--error',
  success: 'notification-bar--success',
  gray: 'notification-bar--gray',
  dark: 'notification-bar--dark',
  info: 'notification-bar--info',
};

export default {
  props: {
    type: {
      type: String,
    },
    icon: {
      type: String,
      default: 'fas fa-circle-info',
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modifier() {
      return (this.type in modifiers ? modifiers[this.type] : '')
        + (this.center ? ' notification-bar--center' : '');
    },
  },
};
</script>
