<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.contact.timezone') }}
      <FeBadge v-if="!timezone" alert small />
    </div>
    <div class="profile-row__value" v-if="timezone">
      {{ timezoneLabel }}
    </div>
    <div class="profile-row__value profile-row__value--invalid" v-else>
      {{ __('ui.notAdded') }}
    </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal">
        <i class="fa fa-edit"></i>
        {{ __('buttons.edit') }}
      </button>
    </div>
  </div>
  <ModalForm
    :form="{
      action: '/profile/timezone',
      method: 'patch',
      name: 'timezone',
    }"

    :modal="{
      title: __('ui.edit'),
      titleIcon: 'far fa-edit',
    }"
    ref="modal"
    @success="update"
  >
    <FormSelect
      name="timezone"
      :label="__('crm.contact.timezone')"
      :default="timezone || Intl.DateTimeFormat().resolvedOptions().timeZone"
      :options="timezoneOptions"
      />
    <template #footer>
      <div class="button-group">
        <button type="button" class="button button--secondary" @click="closeModal">
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit>
          {{ __('buttons.save') }}
        </FormSubmit>
      </div>
    </template>
  </ModalForm>
</template>

<script>

import timezones from '~/timezones';
import { user } from '~/Frontend/store';

export default {
  computed: {
    timezone: () => user.activeContact.timezone,
    timezoneOptions() {
      return timezones.timezones().map((tz) => ({
        value: tz.code,
        text: tz.label,
      }));
    },
    timezoneLabel() {
      const zone = timezones.find(this.timezone);
      return zone ? zone.label : this.timezone;
    },
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    update({ timezone }) {
      user.activeContact.timezone = timezone;
      this.closeModal();
    },
  },
};

</script>
