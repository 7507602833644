<template>
  <span v-html="__(k, {...$attrs, ...params})"></span>
</template>

<script>

export default {
  props: {
    k: {
      type: String,
      required: false,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
