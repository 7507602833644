<template>
  <Popper
    :content="__('buttons.delete')"
    hover
    arrow
  >
    <button class="button button--link button--warning" @click="$emit('click')">
      <i  class="far fa-trash-can"></i>
    </button>
  </Popper>
</template>

<script>

import Popper from 'vue3-popper';

export default {
  name: 'DeleteIconButton',
  components: { Popper },
  emits: ['click'],
};
</script>
