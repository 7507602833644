<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.company.displayName') }}
      <FeBadge v-if="!data.display_name" alert small />
    </div>
    <div class="profile-row__value" v-if="data.display_name">
      {{ data.display_name }} &nbsp; <b> <i class="fa-solid fa-lock"></i> {{ __('ui.locked') }} </b>
    </div>
    <!-- <div class="profile-row__value profile-row__value--invalid" v-else> -->
    <!--   {{ __('ui.displayName') }} -->
    <!-- </div> -->
    <!-- <div class="profile-row__action"> -->
    <!--   <button class="button button--link" @click="openModal"> -->
    <!--     <i class="fa fa-edit"></i> -->
    <!--     {{ __('buttons.edit') }} -->
    <!--   </button> -->
    <!-- </div> -->
    <a class="button button--link" href="#/concierge-contact">
      <i class="fa fa-user-headset"></i> {{ __('buttons.contact') }}
    </a>
  </div>
  <ModalForm
    :form="{
      action: '/profile/company-display-name',
      method: 'patch',
      name: 'display_name',
    }"
    ref="modal"
    :modal="{
      title: __('ui.edit'),
      titleIcon: 'far fa-edit',
    }"
    @success="update"
  >
    <FormText
      :label="__('crm.company.displayName')"
      name="display_name"
      :default="data.display_name"
      required
    />
    <template #footer>
      <div class="button-group">
        <button type="button" class="button button--secondary" @click="closeModal">
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit>
          {{ __('buttons.save') }}
        </FormSubmit>
      </div>
    </template>
  </ModalForm>
</template>

<script>

export default {
  props: {
    displayName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: {
        display_name: this.displayName,
      },
    };
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    update(data) {
      this.data = data;
      this.closeModal();
    },
  },
};

</script>;
