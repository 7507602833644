import { useBreakpoints as vueuseBreakpoints } from '@vueuse/core';

// Minimum screen size for a breakpoint - if change also needs to change
// in scss frontend/abstracts/_responsive.scss
const breakpoints = {
  tablet: 768,
  smallLaptop: 1025,
  laptop: 1280,
};

const sizes = [
  'mobile', 'tablet', 'smallLaptop', 'laptop',
];

function sizeUp(size) {
  return sizes[1 + sizes.indexOf(size)];
}

function useBreakpoints(options = {}) {
  const composable = vueuseBreakpoints(breakpoints, options);

  return {
    ...composable,
    mobile: composable.smaller(sizeUp('mobile')),
    tabletOrSmaller: composable.smaller(sizeUp('tablet')),
    smallLaptopOrSmaller: composable.smaller(sizeUp('smallLaptop')),

    tabletOrLarger: composable.greaterOrEqual('table'),
    smallLaptopOrLarger: composable.greaterOrEqual('smallLaptop'),
    laptopOrLarger: composable.greaterOrEqual('laptop'),
  };
}

export {
  breakpoints,
  useBreakpoints,
};
