<template>
  <div class="directory__filter ">
    <div class="filter__label" v-if="!hideLabel">
      {{ filter.label }}
    </div>
    <div class="filter__az">
      <label v-for="option in filter.options"
        :key="option.value"
        class="filter__az-option"
        :class="{
          'filter__az-option--active': selected === option.value,
          'filter__az-option--disabled': !option.available
        }"
      >
        {{ option.label }}
        <input
          type="radio"
          :value="option.value"
          v-model="selected"
          :disabled="!option.available"
          hidden
        />
      </label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DirectoryAZFilter',
  props: {
    filter: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: [String, Array],
      required: true,
    },
    hideLabel: Boolean,
  },
  emits: ['update:modelValue'],
  computed: {
    selected: {
      get() { return this.modelValue; },
      set(value) { this.$emit('update:modelValue', value); },
    },
  },
};

</script>
