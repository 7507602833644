<template>
  <div class="directory__filter">
    <div class="filter__label" v-if="!hideLabel">
      {{ filter.label }}
    </div>
    <div class="primary-filter__option-holder">
      <label v-for="option in filter.options"
       :key="option.value"
       class="bubble-filter"
       :class="{
        'bubble-filter--selected': selected.includes(option.value),
       }"
      >
       {{ option.label }}
       <input
        v-if="filter.enable_multi_select"
        type="checkbox"
        :value="option.value"
        v-model="selected"
        hidden
      />
      <input
        v-else
        type="radio"
        :value="[option.value]"
        v-model="selected"
        hidden
      />
      </label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DirectoryBubbleSelectFilter',
  props: {
    filter: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    hideLabel: Boolean,
  },
  emits: ['update:modelValue'],
  computed: {
    selected: {
      get() { return this.modelValue; },
      set(value) { this.$emit('update:modelValue', value); },
    },
  },
};

</script>
