<template>
  <div class="directory__filter">
    <div class="filter__label" v-if="!hideLabel">
      {{ filter.label }}
    </div>
    <select
      class="frontend-text-input"
      v-model="selected[0]"
      >
      <option
        v-for="option in filter.options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>

export default {
  name: 'DirectorySelectFilter',
  props: {
    filter: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    hideLabel: Boolean,
  },
  emits: ['update:modelValue'],
  computed: {
    selected: {
      get() { return this.modelValue; },
      set(value) { this.$emit('update:modelValue', value); },
    },
  },
};

</script>
