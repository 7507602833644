<template>
  <label v-for="i in 5" :key="i">
    <i class="frontend-star-rating-input fa-star"
      :class="[
        value >= i ? 'fa-solid' : 'fa-thin',
      ]"
    ></i>
    <input
      :name="`rating`"
      style="opacity: 0; width: 1px;"
      :value="i"
      v-model="value"
      type="radio"
    />
  </label>
</template>

<script>

export default {
  name: 'FrontendStarRatingInput',
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
    },
    required: {
      type: Boolean,
    },
  },
  emits: [
    'update:modelValue',
  ],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
