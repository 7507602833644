<template>
  <div class="form-input" :class="{'form-input--invalid': invalid}">
    <FormLabel v-bind="{label, required}" />

    <label
      ref="input"
      @drop="handleDrop"
      class="form-input__input form-input--image"
      :class="{ active: dragActive }"
      @drag.stop.prevent
      @dragend.stop.prevent="dragActive = false"
      @dragenter.stop.prevent="dragActive = true"
      @dragleave.stop.prevent="dragActive = false"
      @dragover.stop.prevent="dragActive = true"
      @dragstart.stop.prevent
      @drop.stop.prevent="handleDrop"
    >
      <i v-if="invalid" class="form-input__icon form-input__icon--warning far fa-triangle-exclamation"></i>
      <img v-else-if="preview" class="form-input__preview" :src="preview" />
      <i v-else class="form-input__icon fal fa-image-polaroid-user"></i>

      <input
        type="file"
        style="display: none"
        ref="fileInput"
        @change="handleChange"
        accept="image/*"
      />

      <p>
        <FormError :errors="errors" />
      </p>
      <p @click.prevent="openDialog" style="margin-bottom: 0">
        <span v-if="hasValue" v-html=" __('form.image.changeTip')"></span>
        <span v-else v-html=" __('form.image.tip')"></span>
        <small> {{ __('form.image.maxSize') }}</small>
      </p>
      <p v-if="recommendedSize"> <small>{{ __('form.image.recommended_size', {
          recommended_size: recommendedSize
        }) }}</small></p>
    </label>

  </div>
</template>

<script>

import { computed } from 'vue';

export default {
  name: 'FormImage',
  props: {
    default: String,
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    recommendedSize: {
      type: String,
      default: null,
    },
  },
  inject: ['feForm'],
  data() {
    return {
      preview: this.default,
      dragActive: false,
      modelValue: null,
    };
  },
  computed: {
    dragAndDropCapable() {
      const div = document.createElement('div');
      return (('draggable' in div)
              || ('ondragstart' in div && 'ondrop' in div))
          && 'FormData' in window
          && 'FileReader' in window;
    },
    id() {
      return `${this.feForm.name}-${this.name}`;
    },
    errors() {
      const formErrors = this.feForm.error(this.name).value || [];
      return [...this.localErrors, ...formErrors];
    },
    localErrors() {
      const errors = [{
        test: this.fileTypeValid,
        message: this.__('form.image.unsupportedFileType'),
      }];

      return errors.filter((error) => !error.test).map((error) => error.message);
    },
    invalid() {
      return this.errors.length > 0;
    },
    fileTypeValid() {
      return (!this.modelValue) || (/\.(jpe?g|png|gif|webp)$/i.test(this.modelValue.name));
    },
    hasValue() {
      return this.default || this.modelValue;
    },
  },
  methods: {
    openDialog() {
      this.$refs.input.click();
    },
    handleChange(event) {
      // eslint-disable-next-line prefer-destructuring
      this.modelValue = event.target.files[0];
      this.feForm.clearErrors(this.name);
    },
    handleDrop(event) {
      this.errorText = null;
      this.dragActive = false;
      // eslint-disable-next-line prefer-destructuring
      this.modelValue = event.dataTransfer.files[0];
    },
    previewImage() {
      if (!this.modelValue) {
        return;
      }
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.preview = reader.result;
      }, false);
      reader.readAsDataURL(this.modelValue);
    },
    removeFile() {
      this.modelValue = null;
    },
  },
  mounted() {
    this.feForm.register(this.name, computed(() => this.modelValue));
  },
  watch: {
    modelValue() {
      this.previewImage();
    },
  },
};
</script>
