<template>
  <div class="company__gallery">
    <img
      class="company__gallery-image"
      v-for="(image, index) in imageCollection"
      :key="index"
      :src="image.gallery.url"
      alt=""
      @click="expand(index)"
      />
  </div>
</template>

<script>
import { modalOpen, modalPush } from '../../../modal';
import FullpageGallery from '../FullpageGallery.vue';

const limit = 5;

export default {
  props: {
    images: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageCollection() {
      return this.images.slice(0, limit);
    },
  },
  methods: {
    expand(index) {
      const url = new URL('#gallery', window.location);
      window.history.pushState({}, '', url);
      modalPush(
        FullpageGallery,
        {
          fullPage: true,
          images: this.images,
          initial: index,
        },
      );
      modalOpen();
    },
  },
};

</script>
