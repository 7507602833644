<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.company.websites') }}
      <FeBadge v-if="attentionNeeded" alert small />
    </div>
    <div class="profile-row__value tag-list tag-list--vertical" v-if="data.websites.length > 0">
      <div v-for="website in data.websites" :key="website.id" class="tag">
        <div class="tag__part">
          <i class="far fa-globe"></i>
          <span class="tag__truncate">{{ website.url }}</span>
        </div>
        <div class="tag__part tag__part--action">
          <EditIconButton @click="openModal('update-' + website.id)" />
        </div>
        <div class="tag__part tag__part--action">
          <DeleteIconButton @click="openModal('remove-' + website.id)" />
        </div>
        <Teleport to="body">
          <ModalForm
            :form="{
              action: `/profile/websites/${website.id}`,
              method: 'patch',
              name: 'update-website',
            }"
            :modal="{
              title: __('profile.rows.websites.updateModalTitle'),
              titleIcon: 'far fa-edit',
            }"
            @success="update($event); closeModal('update-' + website.id)"
            :ref="'update-' + website.id"
          >
            <FormText label='Url:' name="url" :default="website.url" placeholder="https://www.example.com" />
            <template #footer>
              <div class="button-group">
                <button
                  type="button"
                  class="button button--secondary"
                  @click="closeModal('update-' + website.id)"
                  >
                  {{ __('buttons.cancel') }}
                </button>
                <FormSubmit>
                 {{ __('buttons.save') }}
                </FormSubmit>
              </div>
            </template>
          </ModalForm>

          <ModalForm
            :form="{
              action: `/profile/websites/${website.id}`,
              method: 'delete',
              name: 'update-website',
            }"

            :modal="{
              title: __('ui.delete'),
              titleIcon: 'far fa-trash-can',
            }"
            :ref="'remove-' + website.id"
            @success="update($event); closeModal('remove-' + website.id)"
          >
            <p> {{ __('profile.rows.companyWebsites.removeWarning') }} </p>
            <p>
              <a :href="website.url"> {{ website.url }} </a>
            </p>
            <template #footer>
              <div class="button-group">
                <button
                  type="button"
                  class="button button--secondary"
                  @click="closeModal('remove-' + website.id)"
                  >
                  {{ __('buttons.cancel') }}
                </button>
                <button type="submit" class="button button--warning">
                  {{ __('buttons.delete') }}
                </button>
              </div>
            </template>
          </ModalForm>
        </Teleport>
      </div>
    </div>
    <div class="profile-row__value profile-row__value--invalid" v-else>
      {{ __('ui.notAdded') }}
    </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal('create')">
        <i class="far fa-plus-circle"></i>
        {{ __('buttons.add') }}
      </button>
    </div>
  </div>
  <ModalForm
    :form="{
      action: '/profile/websites',
      method: 'post',
      name: 'create-website',
      }"
    :modal="{
      title: __('profile.rows.websites.addModalTitle'),
      titleIcon: 'far fa-plus-circle',
    }"
    ref="create"
    @success="update($event); closeModal('create')"
  >
    <FormText :label="__('crm.link.url')" name="url" placeholder="https://www.example.com" />
    <FormHidden name="type" value="external" />
    <template #footer>
      <div class="button-group">
        <button type="button" class="button button--secondary" @click="closeModal('create')">
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit> {{ __('buttons.save') }} </FormSubmit>
      </div>
    </template>
  </ModalForm>
</template>

<script>
import useMyAccountStore from '~/Store/MyAccount';
import { mapActions } from 'pinia';
import FeBadge from '~/Components/Frontend/Core/Misc/FeBadge.vue';
import ModalForm from '~/Components/Frontend/Form/ModalForm.vue';
import FormText from '~/Components/Frontend/Form/Inputs/FormText.vue';
import DeleteIconButton from '~/Components/Frontend/Form/Inputs/DeleteIconButton.vue';
import EditIconButton from '~/Components/Frontend/Form/Inputs/EditIconButton.vue';
import FormSubmit from '~/Components/Frontend/Form/FormSubmit.vue';

export default {
  name: 'CompanyWebsitesProfileRow',
  components: {
    FeBadge,
    ModalForm,
    FormText,
    FormSubmit,
    DeleteIconButton,
    EditIconButton,
  },
  props: {
    websites: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: {
        websites: this.websites,
      },
    };
  },
  computed: {
    attentionNeeded() {
      return this.data.websites.length === 0;
    },
  },
  methods: {
    ...mapActions(useMyAccountStore, ['loadSectionStatus']),
    getModal(ref) {
      let modal = this.$refs[ref];
      if (Array.isArray(modal)) {
        // eslint-disable-next-line prefer-destructuring
        modal = modal[0];
      }
      return modal;
    },
    openModal(ref) {
      this.getModal(ref).open();
    },
    closeModal(ref) {
      this.getModal(ref).close();
    },
    update(data) {
      this.data = data;
    },
  },
  watch: {
    attentionNeeded() {
      this.loadSectionStatus();
    },
  },
};

</script>;
