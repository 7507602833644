<template>
  <div class="dialog__row">
    <ContactAvatar
      v-if="!sentByYou"
      :contact="sender"
      size="xsmall"
    />

    <div
      class="dialog__body"
      :data-dialog-type="type"
      :class="{
        'dialog__body-from-you': sentByYou,
        'dialog__body-important': important,
        'dialog__body-unread': !read,
      }"
    >
      <slot name="chip"></slot>
      <div>
        <div class="dialog__sender-details">
          <span class="dialog__sender-name">
            {{ sender.display_name }}
          </span>
          <span class="dialog__sender-when">
            {{ when }}
          </span>
        </div>
        <div>
          <div v-if="preMessage" class="dialog__premessage" v-html="preMessage"></div>
          <div v-if="message" class="dialog__message">
            <PreWrap :text="message" />
          </div>
        </div>
      </div>
      <slot name="actions"></slot>
    </div>

    <ContactAvatar
      v-if="sentByYou"
      :contact="sender"
      size="xsmall"
    />
  </div>
</template>

<script>
import PreWrap from '~/Components/Common/PreWrap.vue';
import ContactAvatar from '~/Components/Frontend/CRM/Components/ContactAvatar.vue';

export default {
  name: 'MeetingChatRow',
  components: {
    ContactAvatar,
    PreWrap,
  },
  props: {
    type: {
      type: String,
      required: false,
    },
    when: {
      type: String,
      required: false,
    },
    sender: {
      type: Object,
      required: true,
    },
    preMessage: {
      type: String,
    },
    message: {
      type: String,
      required: true,
    },
    sentByYou: {
      type: Boolean,
      required: true,
    },
    important: {
      type: Boolean,
    },
    read: {
      type: Boolean,
    },
  },
};

</script>
