<template>
  <BaseModal
    ref="modal"
    height="tall"
    :startOpen="true"
    @closed="$emit('closed')"
  >
    <template #header>
      <h3 v-if="title" class="base-modal__title">
        <i v-if="icon" class="fa" :class="icon"></i>
        <span v-html="title"></span>
        <button
          type="button"
          class="base-modal__title-link"
          @click.stop="reset"
        >
          {{ __('directory.filters.reset') }}
        </button>
      </h3>
    </template>

    <template v-if="sorts.options?.length > 0">
      <div class="directory__filter">
        <div class="filter__label">
            {{ sorts.label }}
        </div>
        <MultilevelDropdown
          v-model="sort"
          :options="sorts.options"
          :isMulti="false"
        />
      </div>
    </template>

    <component
      v-for="filter in filters"
      :is="filter.input_type"
      v-model="selected[filter.name]"
      :filter="filter"
      :key="filter.name"
      class="directory__filter"
      :hideLabel="hideLabels"
    />

    <template #footer>
      <div class="button-group">
        <button
          type="button"
          class="button button--primary"
          :disabled="selected.length === 0"
          @click="updated"
        >
          {{ __('directory.filters.apply') }}
        </button>
        <button
          type="button"
          class="button button--secondary"
          @click="cancel"
        >
          {{ __('filter_modal.cancel') }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import _ from 'lodash';
import BaseModal from '~/Components/Frontend/BaseModal.vue';
import DirectoryAZFilter from '~/Components/Frontend/Directories/DirectoryAZFilter.vue';
import MultilevelDropdown from '~/Components/Common/MultilevelDropdown.vue';

export default {
  name: 'FiltersModal',
  components: { BaseModal, DirectoryAZFilter, MultilevelDropdown },
  props: {
    title: String,
    icon: String,
    filters: {
      type: Array,
      required: true,
    },
    initialSelected: {
      type: Object,
      required: true,
    },
    sorts: {
      type: Object,
      default() {
        return {
          label: '',
          options: [],
        };
      },
    },
    initialSort: {
      type: Array,
      required: true,
    },
    visible: Boolean,
    hideLabels: Boolean,
  },
  emits: ['updated', 'closed'],
  data() {
    return {
      selected: _.cloneDeep(this.initialSelected),
      sort: [...this.initialSort],
    };
  },
  methods: {
    updated() {
      this.$emit('updated', this.selected, this.sort);
    },
    cancel() {
      this.$refs.modal.close();
    },
    reset() {
      this.filters.forEach((filter) => {
        let newValue = [];
        if (filter.input_type === 'DirectoryTextSearchFilter') {
          newValue = null;
        } else if (filter.input_type === 'DirectoryAZFilter') {
          newValue = null;
        }
        this.selected[filter.name] = newValue;
      });
    },
  },
};
</script>
