<template>
  <div v-if="page === 1">
    <h2 class="purchase-upgrade__popup-title">{{ purchaseItem.popup_title }}</h2>
    <p
      class="purchase-upgrade__popup-subtitle"
      v-html="purchaseItem.popup_subtitle_html"
    >
    </p>

    <div class="purchase-upgrade__item-holder">
      <div>
        <p class="purchase-upgrade__item-name">
          1 x {{ purchaseItem.name }}
        </p>
        <p class="purchase-upgrade__item-description">
          {{ purchaseItem.description }}
        </p>
      </div>
      <div class="purchase-upgrade__item-price">
        {{ purchaseItem.currency_symbol }}
        {{ (purchaseItem.price_in_cents/100).toFixed(2) }}
      </div>

      <div class="purchase-ugrade__tax-label">
        {{ purchaseItem.tax_type }}
        ({{ purchaseItem.tax_percentage}}%)
      </div>
      <div class="purchase-ugrade__tax-amount">
        {{ purchaseItem.currency_symbol }}
        {{ (purchaseItem.tax_in_cents/100).toFixed(2) }}
      </div>
      <div class="purchase-ugrade__total-label">
        Total
      </div>
      <div class="purchase-ugrade__total-amount">
        {{ purchaseItem.currency_symbol }}
        {{ (purchaseItem.total_in_cents/100).toFixed(2) }}
      </div>

      <div class="purchase-ugrade__currency-description">
        Currency {{ purchaseItem.currency_symbol }} {{ purchaseItem.currency }}
      </div>
    </div>

    <div class="purchase-upgrade__item-agree-terms-holder">
      <input type="checkbox"
        v-model="agreeTerms"
        name="agreeTerms"
        id="agreeTerms"
        value="agree"
      />
      <label class="purchase-upgrade__item-agree-terms" for="agreeTerms">
        {{ __('purchase_upgrade.agree_terms_pre') }}
        <a href="#" @click.prevent.stop="openTandCs">{{ __('purchase_upgrade.agree_terms_link') }}</a>
        {{ __('purchase_upgrade.agree_terms_post') }}
      </label>
    </div>

    <div class="button-group button-group--right" style="margin-top: 2rem;">
      <button
        type="button"
        class="button button--secondary"
        @click="$emit('cancel')"
        style="max-width: fit-content"
      >
        {{ __("purchase_upgrade.buttons.cancel") }}
      </button>
      <button
        type="button"
        class="button button--primary"
        @click="$emit('buyNow')"
        style="max-width: fit-content"
        :disabled="!agreeTerms"
      >
        {{ __("purchase_upgrade.buttons.buy_now") }}
        <i class="far fa-arrow-right"></i>
      </button>
    </div>
  </div>

  <div v-else>
    <h2 class="purchase-upgrade__popup-title">{{ __('purchase_upgrade.terms.title') }}</h2>
    <!--<p class="purchase-upgrade__popup-subtitle">{{ __('purchase_upgrade.terms.subtitle') }}</p>-->
    <p>By completing payment, you agree to these terms and conditions of sale. Do not purchase tickets, sponsorship, upgrades, enhancements or services if you do not agree to these Terms.</p>
    <p>Sector Global Limited provides a marketplace for the event organiser to advertise and sell goods and services, including, but not limited to, event tickets, sponsorship opportunities, event badge upgrades, enhanced experiences, and premium support services. Sector Global Limited and the event organiser reserve the right to withdraw, substitute, restrict the sale of, or amend the advertised products and services, including item description, price and availability.</p>
    <p>Sector Global Limited use the Stripe (stripe.com) payment processing platform to process purchase payments on behalf of the event organiser.</p>
    <p>Payment for goods and services is made online only using a valid debit or credit card. Cards bearing the Visa, MasterCard, or American Express logo are accepted. Other credit cards and forms of payment may also be available to complete purchase payments.</p>
    <p>Your bank or card issuer may charge additional fees for foreign currency payments and for purchasing our goods and services.</p>
    <p>The purchaser must consider the suitability of the goods and services before completing payment.</p>
    <p>Neither Sector Global Limited nor the event organiser are responsible for the performance, availability, result or consequence of using the purchased goods or services. </p>
    <p>Purchasing upgrades, enhanced services or premium networking passes does not guarantee performance, availability, compatibility access or admittance.</p>
    <p>Refunds are not available for digital services. </p>
    <div class="button-group button-group--right" style="margin-top: 2rem;">
      <button
        type="button"
        class="button button--secondary"
        @click="page = 1"
        style="max-width: fit-content"
      >
        {{ __("purchase_upgrade.buttons.back") }}
      </button>
      <button
        type="button"
        class="button button--primary"
        @click="agreeTermsAndClose"
        style="max-width: fit-content"
      >
        {{ __("purchase_upgrade.buttons.agree") }}
        <i class="far fa-check"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    purchaseItem: Object,
  },
  data() {
    return {
      agreeTerms: false,
      page: 1,
    };
  },
  emits: ['cancel', 'buyNow'],
  methods: {
    openTandCs() {
      this.page = 2;
    },
    agreeTermsAndClose() {
      this.agreeTerms = true;
      this.page = 1;
    },
  },
};
</script>
