<template>
  <Teleport :to="to" v-if="isMounted" :disabled="disabled"><slot></slot></Teleport>
</template>

<script>

/**
 * Teleport creates a race condition. The target must be rendered before
 * the body. By defering load until after vue app is mounted we can
 * bypass this.
 * */
export default {
  name: 'MountedTeleport',
  props: {
    to: String,
    disabled: Boolean,
  },
  data() {
    return { isMounted: false };
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>
