<template>
  <div class="form-input" :class="{'form-input--invalid': errorText !== null}">
    <label
      ref="mainElement"
      @drag.stop.prevent
      @dragend.stop.prevent="setDropZoneInactive"
      @dragenter.stop.prevent="setDropZoneActive"
      @dragleave.stop.prevent="setDropZoneInactive"
      @dragover.stop.prevent="setDropZoneActive"
      @dragstart.stop.prevent
      @drop.stop.prevent="handleFileDrop($event)"
      class="form-input__input form-input--image"
      :class="{ active: dropZoneActive }"
    >

      <i v-if="errorText !== null" class="form-input__icon form-input__icon--warning far fa-triangle-exclamation"></i>
      <i v-else-if="file" class="form-input__icon far fa-check-circle"></i>
      <i v-else class="form-input__icon fal fa-file"></i>
      <input
        type="file"
        style="display:none;"
        :name="name"
        ref="fileInput"
        @change="handleFileInput( $event )"
        accept="application/pdf"
      />

      <p v-if="file">{{ file.name }}</p>

      <p @click.prevent="openDialog">
        <span v-html=" __('form.image.tip')"></span>
        <small> {{ __('form.image.maxSize') }}</small>
      </p>
      <p v-if="errorText !== null" class="form-input__error">{{ errorText }}</p>
    </label>
  </div>
</template>

<script>
export default {
  name: 'FrontendFileUpload',
  props: {
    icon: {
      type: String,
      default: 'far fa-file-pdf',
    },
    fileData: {
      type: Object,
      required: false,
    },
  },
  emits: ['changed'],
  data() {
    return {
      errorText: null,
      file: null,
      dropZoneActive: false,
      inActiveTimeout: null,
      uploadedFileUrl: null,
    };
  },
  computed: {
    dragAndDropCapable() {
      const div = document.createElement('div');
      return (('draggable' in div)
                || ('ondragstart' in div && 'ondrop' in div))
            && 'FormData' in window
            && 'FileReader' in window;
    },
  },
  mounted() {
    if (this.dragAndDropCapable) {
      this.bindEvents();
    }
  },
  methods: {
    openDialog() {
      this.$refs.fileInput.click();
    },
    bindEvents() {
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
        this.$refs.mainElement.addEventListener(evt, (e) => {
          e.preventDefault();
          e.stopImmediatePropagation();
        }, false);
      });
    },
    checkFileTypeValid(fileName) {
      return (/\.(pdf)$/i.test(fileName));
    },
    handleFileInput(event) {
      this.errorText = null;
      this.file = null;
      if (this.checkFileTypeValid(event.target.files[0].name)) {
        // eslint-disable-next-line prefer-destructuring
        this.file = event.target.files[0];
        this.$emit('changed', this.file);
        return;
      }
      this.errorText = this.__('form.image.unsupportedFileTypePdf');
    },
    handleFileDrop(event) {
      this.errorText = null;
      this.file = null;
      if (this.checkFileTypeValid(event.dataTransfer.files[0].name)) {
        // eslint-disable-next-line prefer-destructuring
        this.file = event.dataTransfer.files[0];
        this.$emit('changed', this.file);
        return;
      }
      this.errorText = this.__('form.image.unsupportedFileTypePdf');
    },
    removeFile() {
      this.file = null;
    },
    setDropZoneActive() {
      this.dropZoneActive = true;
      clearTimeout(this.inActiveTimeout);
    },
    setDropZoneInactive() {
      this.inActiveTimeout = setTimeout(() => {
        this.dropZoneActive = false;
      }, 50);
    },
  },
};
</script>
