<template>
  <a
    style="position: fixed; bottom: 0; right: 0; margin: 8px; "
    class="button button--secondary button--compact button--gray"
    :href="href"
    target="_blank"
  >

    <i class="fas fa-bullhorn"> </i> Report a bug
  </a>
</template>

<script>
export default {
  computed: {
    href() {
      return `https://forms.monday.com/forms/9b05337aeb5f649f3e71479360ddaa6e?r=use1&link=${window?.location.href}`;
    },
  },
};
</script>
