<template>
  <button class="primary-filter" @click="$emit('click')">
    <i class="far" :class="icon"></i> {{ label }}

    <small v-if="count > 0" style="padding-right: var(--sizing-1)">
      +{{ count }}
    </small>

    <i class="fas fa-caret-down"></i>
  </button>
</template>

<script>
export default {
  name: 'DirectoryFilter',
  emits: ['click'],
  props: {
    count: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};

</script>
