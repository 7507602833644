<template>
  <div class="profile2-welcome">
    <ContactAvatar :contact="activeContact" size="large" />
    <h3 style="margin-bottom: 0">
      {{ __('profile.pages.myAccount.welcome', { firstName: activeContact.first_name }) }}
    </h3>
  </div>
  <h2> {{ __('profile.pages.myAccount.eventAndBadges') }} </h2>
  <div class="event-edition-row" v-for="event in events" :key="event.id">
    <img
      class="event-edition-row__logo"
      v-if="event.logo?.card?.url"
      :src="event.logo.card.url"
      />
      <div class="event-edition-row__details">
        <h3 class="event-edition-row__name"> {{ event.name }} </h3>
        <p> {{ event.timespan }} | {{ event.location }} </p>
        <div class="chip-list">
          <span
            v-for="badgeType in eventBadges[event.id]"
            :key="badgeType.id"
            class="chip chip--primary"
            >
            {{ badgeType.name }}
          </span>
        </div>
      </div>
      <div class="event-edition-row__actions" v-if="event.event_open">
        <a class="button" :href="`/${event.slug}`">
          {{ __('profile.pages.myAccount.action') }}
          <i class="far fa-long-arrow-right"></i></a>
      </div>
  </div>
</template>

<script>

import axios from 'axios';
import { groupBy } from 'lodash';
import { user } from '~/Frontend/store';

export default {
  data() {
    return {
      events: [],
      badgeTypes: [],
    };
  },
  computed: {
    activeContact: () => user.activeContact,
    eventBadges() {
      return groupBy(this.badgeTypes, 'event_edition_id');
    },
  },
  mounted() {
    axios.get('/profile/my-account-home')
      .then(({ data }) => {
        this.events = data.events;
        this.badgeTypes = data.badge_types;
      });
  },
};
</script>
