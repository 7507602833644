<template>
  <div class="accordion">
    <h3 v-if="header" class="accordion__header">
      {{ header }}
    </h3>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FeAccordion',
  props: {
    header: {
      type: String,
    },
  },
};
</script>
