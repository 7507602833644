<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.company.socials') }}
      <FeBadge v-if="attentionNeeded" alert small />
    </div>
    <div class="profile-row__value tag-list tag-list--vertical" v-if="data.socials.length > 0">
      <span v-for="social in data.socials" :key="social.id" class="tag tag__part">
        <i :class="findSocialType(social.type).icon"></i> {{ social.url }}
      </span>
    </div>
    <div class="profile-row__value profile-row__value--invalid" v-else> {{ __('ui.notAdded') }}
    </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal">
        <i class="fa fa-edit"></i>
        {{ __('buttons.edit') }}
      </button>
    </div>
  </div>
  <ModalForm
    :form="{
      action: '/profile/socials',
      method: 'patch',
      name: 'update-socials'
    }"
    :modal="{
      title: __('profile.rows.socials.updateModalTitle'),
      titleIcon: 'far fa-edit',
    }"
    @success="update"
    ref="modal"
  >
    <template v-for="(social, i) in allSocials" :key="`social_${i}`">
      <FormText
      :icon="findSocialType(social.type).icon"
      :label='findSocialType(social.type).value'
      :name="'socials[' + i + '][url]'"
      :default="social.url"
      />
      <FormHidden :name="'socials[' + i + '][type]'" :value="social.type" />
    </template>
    <template #footer>
      <div class="button-group">
        <button type="button" class="button button--secondary" @click="closeModal">
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit>
        {{ __('buttons.update') }}
        </FormSubmit>
      </div>
    </template>
  </ModalForm>
</template>

<script>
import useMyAccountStore from '~/Store/MyAccount';
import { mapActions } from 'pinia';

export default {
  props: {
    socials: {
      type: Array,
      default: () => [],
    },
    socialTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: {
        socials: this.socials,
      },
    };
  },
  computed: {
    allSocials() {
      const unusedSocialTypes = this.socialTypes.filter((socialType) => !this.data.socials.some((social) => social.type === socialType.type));
      const socials = [
        ...this.data.socials,
        ...unusedSocialTypes.map((socialType) => ({
          url: '',
          type: socialType.type,
        })),
      ].sort((a, b) => {
        if (a.type < b.type) {
          return -1;
        }
        if (a.type > b.type) {
          return 1;
        }
        return (a.id ?? 0) - (b.id ?? 0);
      });
      return socials;
    },
    attentionNeeded() {
      return this.data.socials.length === 0;
    },
  },
  methods: {
    ...mapActions(useMyAccountStore, ['loadSectionStatus']),
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    update(data) {
      this.data = data;
      this.closeModal();
    },
    findSocialType(type) {
      return this.socialTypes.find((socialType) => socialType.type === type);
    },
  },
  watch: {
    attentionNeeded() {
      this.loadSectionStatus();
    },
  },
};

</script>;
