<template>
  <MeetingChatRow
    type="AdminChatMessage"
    :message="chat.chatBody"
    :when="chat.when"
    :sender="sender"
    :sentByYou="sentByYou"
    :read="chat.read"
  />
</template>

<script>

export default {
  props: {
    chat: {
      type: Object,
      required: false,
    },
    sender: {
      type: Object,
      required: false,
    },
    sentByYou: {
      type: Boolean,
      required: true,
    },
  },
};

</script>
