<template>
  <FrontendTextInput
    id="{{ registrationStore.getCurrentQuestion.id }}"
    :required="registrationStore.getCurrentQuestion.is_required"
    type="email"
    :max="255"
    :placeholder="registrationStore.getCurrentQuestion.placeholder"
    v-model="registrationStore.getCurrentQuestion.answer"
    v-model:errors="registrationStore.getCurrentQuestion.error"
  />
</template>

<script>
import { mapStores, storeToRefs } from 'pinia';
import { watch } from 'vue';
import FrontendTextInput from '~/Components/Frontend/Inputs/FrontendTextInput.vue';
import useRegistrationStore from '~/Store/Registration';

export default {
  type: 'lazy',
  name: 'RegistrationEmailComponent',
  components: {
    FrontendTextInput,
  },
  setup() {
    const store = useRegistrationStore();
    const { getCurrentQuestion } = storeToRefs(store);
    watch(
      getCurrentQuestion,
      (state) => {
        store.updatePersonalization({
          ...store.getPersonalization,
          '*|EMAIL|*': state.answer,
        });
      },
      { deep: true },
    );
  },
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
  },
};
</script>
