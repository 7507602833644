<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.contact.country') }}
      <FeBadge v-if="!country" alert small />
    </div>
    <div class="profile-row__value" v-if="country"> {{ country.name }} </div>
    <div class="profile-row__value profile-row__value--invalid" v-else>
      {{ __('ui.notAdded') }}
    </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal">
        <i class="fa fa-edit"></i>
        {{ __('buttons.edit') }}
      </button>
    </div>
  </div>
  <ModalForm
    :form="{
      action: '/profile/country',
      method: 'patch',
      name: 'country',
    }"
    :modal="{
      title: __('ui.edit'),
      titleIcon: 'far fa-edit',
    }"
    @success="update"
    ref="modal"
  >
    <FormSelect
      name="country_code"
      :label="__('crm.contact.country')"
      :default="country.code"
      :options="countryOptions"
      :placeholder="__('profile.rows.country.selectCountryPlaceholder')"
      required
      />
      <template #footer>
        <div class="button-group">
          <button type="button" class="button button--secondary" @click="closeModal">
            {{ __('buttons.cancel') }}
          </button>
          <FormSubmit>
            {{ __('buttons.save') }}
          </FormSubmit>
        </div>
      </template>
  </ModalForm>
</template>

<script>

import { user } from '~/Frontend/store';

export default {
  props: {
    countries: {
      type: Array,
      required: true,
    },
  },
  computed: {
    country: () => user.activeContact.country,
    countryOptions() {
      return this.countries.map(({ code: value, name: text }) => ({ value, text }));
    },
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    update(data) {
      user.activeContact.country_code = data.country_code;
      user.activeContact.country = data.country;

      this.closeModal();
    },
  },
};

</script>;
