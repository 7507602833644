<template>
  <div class="registration-name-component"
    :class="{'registration-name-component--inline' :
          registrationStore.getCurrentQuestion.layout === 'inline',
          'registration-name-component--stacked' :
          registrationStore.getCurrentQuestion.layout === 'stacked'}"
        >
    <FrontendTextInput
      id="{{ registrationStore.getCurrentQuestion.id }}"
      type="text"
      :max="80"
      :placeholder="__('registrations.form.questions.component.name.first_name_placeholder')"
      v-model="registrationStore.getCurrentQuestion.answer.first_name"
      v-model:errors="firstNameError"
      :required="true"
    />
    <FrontendTextInput
      id="{{ registrationStore.getCurrentQuestion.id }}"
      type="text"
      :max="80"
      :placeholder="__('registrations.form.questions.component.name.last_name_placeholder')"
      v-model="registrationStore.getCurrentQuestion.answer.last_name"
      v-model:errors="lastNameError"
      :required="true"
    />
  </div>
</template>

<script>
import { mapStores, storeToRefs } from 'pinia';
import { watch } from 'vue';
import FrontendTextInput from '~/Components/Frontend/Inputs/FrontendTextInput.vue';
import useRegistrationStore from '~/Store/Registration';

export default {
  type: 'lazy',
  name: 'RegistrationNameComponent',
  components: {
    FrontendTextInput,
  },
  setup() {
    const store = useRegistrationStore();
    const { getCurrentQuestion } = storeToRefs(store);
    watch(
      getCurrentQuestion,
      (state) => {
        store.updatePersonalization({
          ...store.getPersonalization,
          '*|FNAME|*': state.answer.first_name,
          '*|LNAME|*': state.answer.last_name,
          '*|FULLNAME|*': `${state.answer.first_name} ${state.answer.last_name}`,
        });
      },
      { deep: true },
    );
  },
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
    firstNameError() {
      if (this.registrationStore.getCurrentQuestion?.error?.length > 0) {
        const error = JSON.parse(this.registrationStore.getCurrentQuestion?.error?.[0]);
        if (error?.first_name) {
          return [error?.first_name];
        }
      }
      return [];
    },
    lastNameError() {
      if (this.registrationStore.getCurrentQuestion?.error?.length > 0) {
        const error = JSON.parse(this.registrationStore.getCurrentQuestion?.error?.[0]);
        if (error?.last_name) {
          return [error?.last_name];
        }
      }
      return [];
    },
  },
};
</script>
