<template>
  <div
    class="frontend-text-input__container"
    :class="{
      'frontend-text-input__container--small': size === 'small',
      'has-error': errors && errors.length > 0,
    }">
    <div class="frontend-text-input__label-container" v-if="label || (max && !hideCounter)">
      <label v-if="label" :for="id" class="frontend-text-input__label">
        {{ label }}
      </label>
      <div v-if="max && !hideCounter" :class="counterClasses">
        <i class="fas fa-warning" v-show="max === length"></i>
        {{ (max - length) }}
      </div>
    </div>

    <div class="frontend-text-input__row">
      <span v-if="placeholderIcon" class="frontend-text-input__placeholder-icon">
        <i :class="placeholderIcon"></i>
      </span>
      <div class="frontend-text-input__holder">
        <input
          v-if="type !=='textarea'"
          :name="name"
          v-model="input"
          :type="type"
          :id="id"
          class="frontend-text-input"
          :class="{
            'frontend-text-input--with-icon': placeholderIcon,
            'has-action-button': actionButton,
          }"
          :maxLength="max"
          :placeholder="placeholder"
          :required="required"
          :autofocus="autofocus"
          :aria-describedby="description ? id + '-described-by' : false"
          :disabled="disabled"
        />
        <textarea
          v-if="type ==='textarea'"
          :name="name"
          v-model="input"
          :id="id"
          ref="textarea"
          :rows="rows"
          class="frontend-text-input"
          :class="{
            'frontend-text-input--with-icon': placeholderIcon,
            'frontend-text-input--unresizeable': preventResize,
            'frontend-text-input--autosizeable': autosize,
          }"
          :maxLength="max"
          :placeholder="placeholder"
          :required="required"
          :autofocus="autofocus"
          :aria-describedby="description ? id + '-described-by' : false"
          :disabled="disabled"
        />
      </div>

      <button
        v-if="actionButton"
        class="button frontend-text-input__action-button"
        @click="$emit('actionButtonClicked')"
        :disabled="length == 0"
        >
          <i :class="actionButtonIcon"></i>
      </button>
    </div>
    <ul class="frontend-text-input__error" v-if="allErrors && allErrors.length > 0">
      <li v-for="error in allErrors" :key="error">
        <i class="far fa-triangle-exclamation" style="margin-right:var(--sizing-1)"></i>
        <span v-if="enableHtmlErrors" v-html="error"></span>
        <span v-else>{{ error }}</span>
      </li>
    </ul>
    <slot name="description">
      <p class="frontend-text-input__description" v-if="description" :id="id + '-described-by'">
        {{ description }}
      </p>
    </slot>
    <GenerateAiButton
      :generate-ai-url="generateAiUrl"
      @update:text="updateText"
    />
  </div>
</template>

<script>
import GenerateAiButton from '~/Components/Frontend/Core/Buttons/GenerateAiButton.vue';
import { ref } from 'vue';
import { useVModel, useTextareaAutosize } from '@vueuse/core';

export default {
  name: 'FrontendTextInput',
  emits: ['update:modelValue', 'actionButtonClicked'],
  components: {
    GenerateAiButton,
  },
  setup(props, { emit }) {
    const input = useVModel(props, 'modelValue', emit, { passive: true });
    const textarea = ref(null);

    if (props.autosize) {
      useTextareaAutosize({ input, element: textarea });
    }

    return {
      input,
      textarea,
    };
  },
  props: {
    preventResize: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
    placeholderIcon: {
      type: String,
      default: null,
    },
    max: Number,
    hideCounter: Boolean,
    label: String,
    name: String,
    placeholder: String,
    modelValue: {
      type: [Number, String],
      default: null,
    },
    type: {
      type: String,
      validator: (value) => ['email', 'text', 'textarea', 'number', 'password'].includes(value),
      default: 'text',
    },
    required: Boolean,
    rows: Number,
    error: String,
    errors: Array,
    description: {
      type: String,
      required: false,
    },
    actionButton: {
      type: Boolean,
      default: false,
    },
    actionButtonIcon: {
      type: String,
    },
    customClass: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      validator: (str) => ['small', 'medium'].includes(str),
      default: 'medium',
    },
    disabled: Boolean,
    generateAiUrl: {
      type: String,
      default: '',
    },
    autosize: {
      type: Boolean,
      default: false,
    },
    enableHtmlErrors: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    length() {
      return this.input?.length ?? 0;
    },
    counterClasses() {
      return [
        'frontend-text-input__counter',
        {
          'frontend-text-input__counter--warning': this.length >= this.max,
          'frontend-text-input__counter--alert': this.length > this.max * 0.75,
        },
      ];
    },
    allErrors() {
      return this.error ? [this.error] : this.errors;
    },
  },
  methods: {
    updateText(text) {
      this.input = text;
      if (text.length % 5 === 0) this.scrollToBottom();
    },
    scrollToBottom() {
      this.textarea?.value.scrollTo({
        top: this.textarea.value.scrollHeight,
        behavior: 'smooth',
      });
    },
  },
};
</script>
