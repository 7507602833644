const regexpEscape = (string) => string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');

// eslint-disable-next-line consistent-return
const StringReplace = (string, search) => {
  if (Object.keys(search).length > 0) {
    const regexp = new RegExp(
      Object.keys(search)
        .map((item) => regexpEscape(item))
        .join('|'),
      'g',
    );
    return string.replace(regexp, (match) => search[match]);
  } if (Object.keys(search).length === 0) {
    return string;
  }
};

export default StringReplace;
