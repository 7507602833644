<template>
  <section class="contact-card" @click="goToProfile">
    <div
      :style="{ 'background-image': `url(${backgroundImage})` }"
      class="contact-card__top-banner">
    </div>

    <div class="contact-card__body" :class="{'contact-card__body--faded': isHidden}" >
      <div
        class="contact-card__contact-type"
        :style="{ 'visibility': model.label ? 'visible' : 'hidden' }"
      >
        {{ model.label ?? ' ' }}
        <div class="contact-card__sub-menu">
          <SubOptions>
            <template v-slot:button="{toggle}">
              <IconButton @click.stop="toggle" small tinted icon="fa-ellipsis" />
            </template>
            <SubOptionFavourite
              :favouritableId="model.id"
              favouritableType="Contact"
              v-model:isFavourited="isFavourited"
            />
            <SubOption v-if="button.type !== 'profile'" :link="`#/contacts/${model.id}/profile`" icon="fas fa-user fa-fw" :label="__('expanded_contact.show.view_profile')" />
            <SubOption :icon="button.icon" :link="button.href" :label="button.label" />
            <SubOptionHide
              :hideableId="model.id"
              hideableType="Contact"
              v-model:isHidden="isHidden"
            />
          </SubOptions>
        </div>
      </div>

      <div class="contact-card__avatar-holder">
        <ContactAvatar :contact="model" size="xlarge" />
        <div class="contact-card__flourish-holder">
          <span
            v-if="model.flourish_icon || model.flourish_text"
            class="contact-card__flourish flourish"
          >
            <i v-if="model.flourish_icon" :class="model.flourish_icon"></i>
            &nbsp;{{ model.flourish_text }}
          </span>
        </div>
      </div>
      <div class="contact-card__full-name" :title="nameToShow">
        <i class="fa-2xs fa fa-star" v-if="isFavourited"></i>
        {{ nameToShow }}
      </div>
      <div
        :title="model.job_title"
        class="contact-card__company-position"
      >
        {{ model.job_title }}
      </div>

      <div class="contact-card__company-name">
        <a
          @click.stop
          :href="`#/companies/${model.company.id}`"
          :title="model.company.display_name"
          class="contact-card__company-name-link"
        >
          {{ model.company.display_name }}
        </a>
      </div>

      <div class="contact-card__actions">
        <a class="button button--secondary" :href="button.href" @click.stop="button.action">
          {{ button.label }}
        </a>
      </div>

    </div>

  </section>
</template>

<script>
import axios from 'axios';
import SubOption from '~/Components/Frontend/SubOption.vue';
import SubOptions from '~/Components/Frontend/SubOptions.vue';
import SubOptionFavourite from '~/Components/Frontend/Profile/Actions/SubOptionFavourite.vue';
import SubOptionHide from '~/Components/Frontend/Profile/Actions/SubOptionHide.vue';
import { store, contactInviteCredits } from '../../../Frontend/store';
import ContactAvatar from './Components/ContactAvatar.vue';

const defaultBackgroundImage = 'https://s3.eu-west-1.amazonaws.com/local.gmpv4.eventnetworking.com/demo/connectexpo_contact-card_background.png';

export default {
  components: {
    ContactAvatar,
    SubOptions,
    SubOption,
    SubOptionFavourite,
    SubOptionHide,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    autoMatchmaking: Boolean,
  },
  data() {
    return {
      contactInviteCredits,
      iAmMatched: this.model.iAmMatched,
      inviteSent: false,
      pendingSentInviteFromActiveContactExists: false,
      pendingIncomingInviteFromThisContactExists: false,
      meetingId: null,
      inviteId: null,
      isFavourited: this.model.isFavourited,
      isHidden: this.model.isHidden,
    };
  },
  computed: {
    backgroundImage() {
      return this.model.backgroundImage || defaultBackgroundImage;
    },
    nameToShow() {
      return this.model.full_name ? this.model.full_name : this.model.display_name;
    },
    /**
     * Extract button context into computed
     */
    button() {
      if (this.autoMatchmaking) {
        return {
          action: () => this.toggleMatch(),
          // eslint-disable-next-line no-underscore-dangle
          label: this.iAmMatched ? this.__('expanded_contact.buttons.unmatch') : this.__('expanded_contact.buttons.match'),
          type: 'autoMatchmaking',
          icon: 'fas fa-solid fa-handshake',
        };
      }
      if (!this.eventEdition?.enable_meetings) {
        return {
          href: `#/contacts/${this.model.id}/profile`,
          // eslint-disable-next-line no-underscore-dangle
          label: this.__('expanded_contact.show.view_profile'),
          type: 'profile',
          icon: 'fas fa-user',
        };
      }
      if (this.meetingId) {
        return {
          href: `#/meetings/${this.meetingId}/details`,
          // eslint-disable-next-line no-underscore-dangle
          label: this.__('expanded_contact.show.view_meeting'),
          type: 'view-meeting',
          icon: 'fas fa-eye',
        };
      }
      if (this.pendingIncomingInviteFromThisContactExists) {
        return {
          href: `#/invites/${this.model.pendingInvitesReceived[0]}/show`,
          // eslint-disable-next-line no-underscore-dangle
          label: this.__('expanded_contact.show.respond'),
          type: 'pending-incoming-received',
          icon: 'fas fa-eye',
        };
      }
      if (this.pendingSentInviteFromActiveContactExists) {
        return {
          href: `#/contacts/${this.model.id}/conversation`,
          // eslint-disable-next-line no-underscore-dangle
          label: this.__('expanded_contact.show.view_invite'),
          type: 'pending-sent-invite',
          icon: 'fas fa-eye',
        };
      }
      if (this.model.policy.can_meet) {
        return {
          href: `#/contacts/${this.model.id}/send-invite`,
          // eslint-disable-next-line no-underscore-dangle
          label: this.__('expanded_contact.show.send_invite'),
          type: 'send-invite',
          icon: 'fas fa-envelope-open-text',
        };
      }
      if (this.model.policy.can_upgrade_to_meet) {
        return {
          href: `#/purchase-upgrade/${this.model.policy.purchase_item_id}`,
          // eslint-disable-next-line no-underscore-dangle
          label: this.__('expanded_contact.show.send_invite'),
          type: 'send-invite',
          icon: 'fas fa-envelope-open-text',
        };
      }
      return {
        href: `#/contacts/${this.model.id}/profile`,
        // eslint-disable-next-line no-underscore-dangle
        label: this.__('expanded_contact.show.view_profile'),
        type: 'profile',
        icon: 'fas fa-user',
      };
    },
    eventEdition() {
      return store.eventEdition;
    },
  },
  mounted() {
    this.pendingSentInviteFromActiveContactExists = this.model.pendingInvitesSent.length > 0;
    this.pendingIncomingInviteFromThisContactExists = this.model.pendingInvitesReceived.length > 0;

    // old view model
    if (this.model?.meeting) {
      this.meetingId = this.model.meeting.id;
    }
    // new resource
    if (this.model?.meeting_id) {
      this.meetingId = this.model.meeting_id;
    }

    this.emitter.on('invites.sent', (data) => {
      if (data.contactId === this.model.id) {
        this.pendingSentInviteFromActiveContactExists = true;
      }
    });
    this.emitter.on('invites.accepted', (data) => {
      if (data.attendeeContactIds.includes(this.model.id)) {
        this.meetingId = data.meetingId;
      }
    });
    this.emitter.on('invites.declined', (data) => {
      if (data.inviteSenderId === this.model.id) {
        this.pendingIncomingInviteFromThisContactExists = false;
      }
    });
    this.emitter.on('invites.withdrawn', (data) => {
      if (data.inviteRecipientId === this.model.id) {
        this.pendingSentInviteFromActiveContactExists = false;
      }
    });
    this.emitter.on('invites.forwarded', (data) => {
      if (data.inviteSenderId === this.model.id) {
        this.pendingIncomingInviteFromThisContactExists = false;
      }
    });
    this.emitter.on('meetings.cancelled', (data) => {
      if (data.attendeeContactIds.includes(this.model.id)) {
        this.pendingIncomingInviteFromThisContactExists = false;
        this.meetingId = null;
      }
    });
  },
  methods: {
    toggleMatch() {
      axios.post(`/automatic-matchmaking/${this.model.id}/toggle`)
        .then(() => {
          this.iAmMatched = !this.iAmMatched;
        }).catch((response) => {
          this.errors = response.data?.errors ?? {};
        });
    },
    goToProfile() {
      window.location.href = `#/contacts/${this.model.id}/profile`;
    },
  },
};
</script>
