<template>
  <div :style="[complete ? 'opacity: 0.5' : '']">
    <div
      v-html="details.information"
      class="notification-bar notification-bar--gray"
    >
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  emits: ['updatePayload'],
  props: {
    complete: Boolean,
    details: Object,
    payload: Object,
    errors: Object,
  },
  mounted() {
    this.$emit('updatePayload', 'isValid', true);
  },
};
</script>
