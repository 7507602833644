<template>
  <div class="saved-search-card">
    <div class="saved-search-card__header">
      <h3>{{ search.name }}</h3>
      <SubOptions>
        <template v-slot:button="{toggle}">
          <IconButton @click.stop="toggle" small tinted icon="fa-ellipsis" />
        </template>
        <SubOption :label="__('buttons.run_search')" icon="far fa-search" :link="search.run_url" />
        <SubOption :label="__('buttons.notifications')" icon="far fa-bell" @click="$emit('update', {searchId: search.id, searchName: search.name, directoryId: search.directory_id})" />
        <SubOption :label="__('buttons.delete')" icon="far fa-trash" @click="destroy" warning />
      </SubOptions>
    </div>
    <div v-if="errors.length > 0">{{ __('directories.saved_search_card_error') }}</div>
    <div class="tag-list" v-else>
      <div v-for="(values, key) in search.params" :key="key">
        <span class="tag tag__part" v-if="values.length >= 1">
          <template v-if="key === 'initial'">
            {{ __('directory.initial_search', {value : values}) }}
          </template>
          <template v-else-if="key === 'search'">
            {{ __('directory.text_search',{value: values}) }}
          </template>
          <template v-else>
            {{ values[0] }}
            <span v-if="values.length > 1" style="font-size:var(--sizing-3)">
              {{ '+' + (values.length - 1)}}
            </span>
          </template>
        </span>
      </div>
    </div>
    <div style="margin-top:var(--sizing-4); text-align:left;">
      <span class="chip chip--small chip--success">
        <i class="far fa-bell"></i>
        {{ search.notification_frequency }}
      </span>
      <span style="margin-left:var(--sizing-4);" class="chip chip--small chip--neutral">
         {{ __('directory.search_last_run', {date: search.last_run_at}) }}
      </span>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import SubOption from '~/Components/Frontend/SubOption.vue';
import SubOptions from '~/Components/Frontend/SubOptions.vue';

export default {
  name: 'SavedSearchCard',
  components: {
    SubOption,
    SubOptions,
  },
  props: {
    search: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      frequencyLabel: null,
    };
  },
  emits: ['deleted', 'update'],
  methods: {
    destroy() {
      axios.delete(this.search.destroy_url)
        .then(() => this.$emit('deleted'))
        .catch(({ response }) => {
          this.errors = response?.data?.errors;
        });
    },
  },
};
</script>
