<template>
  <ul class="navigation-panel">
    <NavigationItem
      v-for="tab in tabs"
      :key="tab.code"
      v-bind="tab"
      @tabClick="$emit('update:modelValue', $event)"
      :activeTab="modelValue"
      />
  </ul>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: false,
    },
    tabs: {
      type: Array,
      required: true,
    },
  },
};
</script>
