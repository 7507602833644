<template>
  <div class="profile-group">
    <h3 class="profile-group__heading" v-if="heading"> {{ heading }} </h3>
    <p class="profile-group__subheading" v-if="subheading" v-html="subheading"></p>
    <slot name="description"></slot>
    <slot></slot>
  </div>
</template>

<script>

export default {
  props: {
    heading: String,
    subheading: String,
  },
};

</script>
