<template>
  <BaseModal
    height="tall"
    startOpen
    ref="modal"
    :notifications="notifications"
  >
    <template #header>
      <ContactRow
        v-if="contact.id"
        :key="contact.id"
        :contact="contact"
        avatarSize="small"
        hideCompany
      />
    </template>

    <template v-if="contact.id">
      <MeetingInviteNotificationBar
        :status="status"
        :contact="contact.id"
        v-if="status.message"
      >
      </MeetingInviteNotificationBar>
      <NotificationBar
        type="error"
        v-if="contact.policy.cant_invite_message"
      >
        <div v-html="contact.policy.cant_invite_message"></div>
      </NotificationBar>

      <h2> {{ __('expanded_contact.show.about.contact.title') }} </h2>

      <p v-if="contact.bio" class="contact__bio">
        <PreWrap :text="contact.bio" />
      </p>

      <h5 class="contact__demographic">
        {{ __('expanded_contact.show.about.contact.country') }}
      </h5>

      <ul class="contact__demographic-values">
        <li class="contact__demographic-value">
          {{ contact.country_name }}
        </li>
      </ul>

      <template v-if="contact.looking_for">
        <h5>
          {{ __('expanded_contact.show.about.contact.looking_for') }}
        </h5>
        <p class="contact__looking_for"> {{ contact.looking_for }} </p>
      </template>

      <template
        v-for="demographic in contactDemographics"
        :key="demographic.id"
        >
        <h5 class="contact__demographic">{{ demographic.name }}</h5>
        <ul class="contact__demographic-values">
          <li
            v-for="value in demographic.values"
            :key="value.id"
            class="contact__demographic-value"
            >
            {{ value.full_name }}
          </li>
        </ul>
      </template>

      <h5> {{ __('expanded_contact.show.about.company.title') }} </h5>

      <p v-if="contact.company.display_name"> {{ contact.company.display_name }} </p>

      <img class="contact__company-logo"
          v-if="contact.company.logo_url"
          :src="contact.company.logo_url"
          :alt="contact.company.display_name + ' logo'"
          />

      <p v-if="contact.company.bio" class="contact__company-bio">
        {{ contact.company.bio }}
      </p>

      <template
        v-for="demographic in companyDemographics"
        :key="demographic.id"
        >
        <h5 class="contact__demographic">{{ demographic.name }}</h5>
        <ul class="contact__demographic-values">
          <li
            v-for="value in demographic.values"
            class="contact__demographic-value"
            :key="value.id"
            >
            {{ value.full_name }}
          </li>
        </ul>
      </template>

      <template v-if="contact.colleagues.length > 0">
        <h5>{{ __('expanded_contact.show.colleagues') }}</h5>
        <p>{{ __('expanded_contact.show.other_contacts', {
          company_display_name: contact.company.display_name
        }) }}</p>
        <ul class="contact__colleagues">
          <li
            class="contact__colleague"
            v-for="colleague in contact.colleagues"
            :key="colleague.id"
            >
            <ContactAvatar :contact="colleague" />
            <div class="contact__colleague-role">
              {{ colleague.job_title }}
            </div>
          </li>
        </ul>
      </template>
    </template>

    <template v-if="!contact.id && !errored">
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>

    <template #footer>
      <template v-if="contact.id">
        <a
          v-if="canSendNewInvite"
          class="button button--primary"
          :href="`#/contacts/${this.contact.id}/send-invite`"
        >
          {{ __(`expanded_contact.buttons.book_meeting`) }}
        </a>

        <a
          v-else-if="contact.meeting_id"
          class="button button--primary"
          :href="`#/meetings/${contact.meeting_id}/details`"
        >
          {{ __('expanded_contact.show.view_meeting') }}
        </a>

        <a
          v-else-if="contact.pendingInvitesReceived.length"
          class="button button--primary"
          :href="`#/invites/${contact.pendingInvitesReceived[0]}/show`"
        >
          {{ __('expanded_contact.show.respond') }}
        </a>

        <a
          v-else-if="contact.pendingInvitesSent.length"
          class="button button--primary"
          :href="`#/contacts/${contact.id}/conversation`"
        >
          {{ __('expanded_contact.show.view_invite') }}
        </a>
      </template>

    </template>

  </BaseModal>
</template>

<script>
import axios from 'axios';
import NotificationBar from '~/Components/Frontend/NotificationBar.vue';
import { store } from '../../../../Frontend/store';

export default {
  name: 'ContactProfileModal',
  components: { NotificationBar },
  props: {
    contactId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contact: {},
      contactDemographics: null,
      companyDemographics: null,
      errored: false,
      status: {},
      notifications: [],
    };
  },
  mounted() {
    axios.get(`/${this.eventEdition?.slug}/contacts/${this.contactId}`)
      .then((response) => {
        this.contact = response.data.contact;
        this.status = response.data.contact.meeting_invite_status.status;
        this.contactDemographics = response.data.contact_demographics;
        this.companyDemographics = response.data.company_demographics;
      })
      .catch((response) => {
        this.errored = true;
        this.errorMessage = response?.data?.message;
        this.notifications.push({
          time: Date.now(),
          type: 'error',
          // eslint-disable-next-line no-underscore-dangle
          message: this.__('expanded_contact.show.errors.cannot_load'),
          icon: 'fa-circle-info',
        });
      });
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
    canSendNewInvite() {
      return this.contact?.policy?.meetings_enabled
        && this.contact.policy.can_meet
        && !this.contact.meeting_id
        && this.contact.pendingInvitesSent.length === 0
        && this.contact.pendingInvitesReceived.length === 0;
    },
  },
};

</script>
