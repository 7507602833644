<template>
  <BaseModal
    ref="modal"
    height="short"
    :title="title"
    titleIcon="far fa-search"
    @closed="$emit('update:visible', false)"
    @open="$emit('update:visible', true)"
  >
    <template #default v-if="success">
      <h2 style="text-align:center; padding:var(--sizing-4)">
        <i class="fas fa-circle-check info-icon--success"></i>
        {{ __('directory.saved_search_success') }}
      </h2>
    </template>
    <template #default v-else>
      <div>
        <FrontendTextInput
          id="search_name"
          name="search_name"
          :default="search_name"
          v-model="search_name"
          :label="__('directory.search_name')"
          :placeholder="__('directory.default_search_name', {date: new Date().toDateString()})"
          :errors="errors.name"
          :max="40"
          required
        />
        <FrontendSelectInput
          placeholder="Please select"
          :options="notificationFrequencies"
          id="notification_frequency"
          v-model="notificationFrequency"
          name="notification_frequency"
          :label="__('directory.notification_frequency')"
        />
      </div>
    </template>
    <template #footer>
      <div class="button-group">
        <button
          type="button"
          class="button button--secondary"
          @click="cancel"
        >
          {{ __('filter_modal.cancel') }}
        </button>
        <button
          type="submit"
          class="button button--primary"
          :disabled="success ? true : false"
          @click="update"
        >
          {{ __('buttons.update') }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '~/Components/Frontend/BaseModal.vue';
import axios from 'axios';
import FrontendTextInput from '../Inputs/FrontendTextInput.vue';
import FrontendSelectInput from '../Inputs/FrontendSelectInput.vue';
import { store } from '../../../Frontend/store';

export default {
  name: 'UpdateSearchModal',
  components: {
    BaseModal,
    FrontendSelectInput,
    FrontendTextInput,
  },
  props: {
    title: String,
    icon: String,
    visible: Boolean,
    notificationFrequencies: Object,
    searchId: Number,
    directoryId: Number,
    searchName: String,
  },
  emits: ['update:visible', 'updated', 'closed', 'close'],
  data() {
    return {
      search_name: '',
      search_id: null,
      notificationFrequency: 'never',
      errors: {},
      success: false,
    };
  },
  watch: {
    visible(value) {
      this.errors = {};
      this.success = false;

      if (value) {
        this.search_name = this.searchName;
        this.search_id = this.searchId;
        this.$refs.modal.open();
      } else {
        this.$refs.modal.close();
      }
    },
  },
  methods: {
    update() {
      const url = `/${store.eventEdition.slug}/directories/${this.directoryId}/saved-searches/${this.searchId}`;

      axios.patch(url, {
        name: this.search_name,
        notification_frequency: this.notificationFrequency,
      }).then(() => {
        this.success = true;
        this.$emit('updated');
        window.setTimeout(() => {
          this.$refs.modal.close();
        }, 3000);
      })
        .catch(({ response }) => {
          this.errors = response?.data?.errors ?? {};
        });
    },
    cancel() {
      this.$refs.modal.close();
    },
  },
};
</script>
