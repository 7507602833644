<template>
  <input type="hidden" v-bind="{ id, name, value }" />
  <div class="form-input__error" v-if="errors.length > 0">
    <i class="far fa-exclamation-circle"></i> {{ errors[0] }}
  </div>
</template>

<script>

import { computed } from 'vue';

export default {
  props: {
    default: {
      required: false,
      default: '',
    },
    name: {
      required: true,
      type: String,
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      modelValue: this.default,
    };
  },
  inject: ['feForm'],
  computed: {
    id() {
      return `${this.feForm.name}-${this.name}`;
    },
    errors() {
      return this.feForm.error(this.name).value || [];
    },
    invalid() {
      return this.errors.length > 0;
    },
  },
  mounted() {
    this.feForm.register(this.name, computed(() => this.value));
  },
};

</script>
