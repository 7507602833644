const timezones = [
  { label: 'Afghanistan, Kabul', code: 'Asia/Kabul' },
  { label: 'Albania, Tirane', code: 'Europe/Tirane' },
  { label: 'Algeria, Algiers', code: 'Africa/Algiers' },
  { label: 'American Samoa, Pago Pago', code: 'Pacific/Pago_Pago' },
  { label: 'Andorra, Andorra', code: 'Europe/Andorra' },
  { label: 'Angola, Luanda', code: 'Africa/Luanda' },
  { label: 'Anguilla, Anguilla', code: 'America/Anguilla' },
  { label: 'Antarctica, Casey', code: 'Antarctica/Casey' },
  { label: 'Antarctica, Davis', code: 'Antarctica/Davis' },
  { label: 'Antarctica, DumontDUrville', code: 'Antarctica/DumontDUrville' },
  { label: 'Antarctica, Mawson', code: 'Antarctica/Mawson' },
  { label: 'Antarctica, McMurdo', code: 'Antarctica/McMurdo' },
  { label: 'Antarctica, Palmer', code: 'Antarctica/Palmer' },
  { label: 'Antarctica, Rothera', code: 'Antarctica/Rothera' },
  { label: 'Antarctica, Syowa', code: 'Antarctica/Syowa' },
  { label: 'Antarctica, Troll', code: 'Antarctica/Troll' },
  { label: 'Antarctica, Vostok', code: 'Antarctica/Vostok' },
  { label: 'Antigua and Barbuda, Antigua', code: 'America/Antigua' },
  { label: 'Argentina, Buenos Aires', code: 'America/Argentina/Buenos_Aires' },
  { label: 'Argentina, Catamarca', code: 'America/Argentina/Catamarca' },
  { label: 'Argentina, Cordoba', code: 'America/Argentina/Cordoba' },
  { label: 'Argentina, Jujuy', code: 'America/Argentina/Jujuy' },
  { label: 'Argentina, La Rioja', code: 'America/Argentina/La_Rioja' },
  { label: 'Argentina, Mendoza', code: 'America/Argentina/Mendoza' },
  { label: 'Argentina, Rio Gallegos', code: 'America/Argentina/Rio_Gallegos' },
  { label: 'Argentina, Salta', code: 'America/Argentina/Salta' },
  { label: 'Argentina, San Juan', code: 'America/Argentina/San_Juan' },
  { label: 'Argentina, San Luis', code: 'America/Argentina/San_Luis' },
  { label: 'Argentina, Tucuman', code: 'America/Argentina/Tucuman' },
  { label: 'Argentina, Ushuaia', code: 'America/Argentina/Ushuaia' },
  { label: 'Armenia, Yerevan', code: 'Asia/Yerevan' },
  { label: 'Aruba, Aruba', code: 'America/Aruba' },
  { label: 'Australia, Macquarie', code: 'Antarctica/Macquarie' },
  { label: 'Australia, Adelaide', code: 'Australia/Adelaide' },
  { label: 'Australia, Brisbane', code: 'Australia/Brisbane' },
  { label: 'Australia, Broken Hill', code: 'Australia/Broken_Hill' },
  { label: 'Australia, Darwin', code: 'Australia/Darwin' },
  { label: 'Australia, Eucla', code: 'Australia/Eucla' },
  { label: 'Australia, Hobart', code: 'Australia/Hobart' },
  { label: 'Australia, Lindeman', code: 'Australia/Lindeman' },
  { label: 'Australia, Lord Howe', code: 'Australia/Lord_Howe' },
  { label: 'Australia, Melbourne', code: 'Australia/Melbourne' },
  { label: 'Australia, Perth', code: 'Australia/Perth' },
  { label: 'Australia, Sydney', code: 'Australia/Sydney' },
  { label: 'Austria, Vienna', code: 'Europe/Vienna' },
  { label: 'Azerbaijan, Baku', code: 'Asia/Baku' },
  { label: 'Bahamas, Nassau', code: 'America/Nassau' },
  { label: 'Bahrain, Bahrain', code: 'Asia/Bahrain' },
  { label: 'Bangladesh, Dhaka', code: 'Asia/Dhaka' },
  { label: 'Barbados, Barbados', code: 'America/Barbados' },
  { label: 'Belarus, Minsk', code: 'Europe/Minsk' },
  { label: 'Belgium, Brussels', code: 'Europe/Brussels' },
  { label: 'Belize, Belize', code: 'America/Belize' },
  { label: 'Benin, Porto-Novo', code: 'Africa/Porto-Novo' },
  { label: 'Bermuda, Bermuda', code: 'Atlantic/Bermuda' },
  { label: 'Bhutan, Thimphu', code: 'Asia/Thimphu' },
  { label: 'Bolivia, Plurinational State of, La Paz', code: 'America/La_Paz' },
  { label: 'Bonaire, Sint Eustatius and Saba, Kralendijk', code: 'America/Kralendijk' },
  { label: 'Bosnia and Herzegovina, Sarajevo', code: 'Europe/Sarajevo' },
  { label: 'Botswana, Gaborone', code: 'Africa/Gaborone' },
  { label: 'Brazil, Araguaina', code: 'America/Araguaina' },
  { label: 'Brazil, Bahia', code: 'America/Bahia' },
  { label: 'Brazil, Belem', code: 'America/Belem' },
  { label: 'Brazil, Boa Vista', code: 'America/Boa_Vista' },
  { label: 'Brazil, Campo Grande', code: 'America/Campo_Grande' },
  { label: 'Brazil, Cuiaba', code: 'America/Cuiaba' },
  { label: 'Brazil, Eirunepe', code: 'America/Eirunepe' },
  { label: 'Brazil, Fortaleza', code: 'America/Fortaleza' },
  { label: 'Brazil, Maceio', code: 'America/Maceio' },
  { label: 'Brazil, Manaus', code: 'America/Manaus' },
  { label: 'Brazil, Noronha', code: 'America/Noronha' },
  { label: 'Brazil, Porto Velho', code: 'America/Porto_Velho' },
  { label: 'Brazil, Recife', code: 'America/Recife' },
  { label: 'Brazil, Rio Branco', code: 'America/Rio_Branco' },
  { label: 'Brazil, Santarem', code: 'America/Santarem' },
  { label: 'Brazil, Sao Paulo', code: 'America/Sao_Paulo' },
  { label: 'British Indian Ocean Territory, Chagos', code: 'Indian/Chagos' },
  { label: 'Brunei Darussalam, Brunei', code: 'Asia/Brunei' },
  { label: 'Bulgaria, Sofia', code: 'Europe/Sofia' },
  { label: 'Burkina Faso, Ouagadougou', code: 'Africa/Ouagadougou' },
  { label: 'Burundi, Bujumbura', code: 'Africa/Bujumbura' },
  { label: 'Cambodia, Phnom Penh', code: 'Asia/Phnom_Penh' },
  { label: 'Cameroon, Douala', code: 'Africa/Douala' },
  { label: 'Canada, Atikokan', code: 'America/Atikokan' },
  { label: 'Canada, Blanc-Sablon', code: 'America/Blanc-Sablon' },
  { label: 'Canada, Cambridge Bay', code: 'America/Cambridge_Bay' },
  { label: 'Canada, Creston', code: 'America/Creston' },
  { label: 'Canada, Dawson', code: 'America/Dawson' },
  { label: 'Canada, Dawson Creek', code: 'America/Dawson_Creek' },
  { label: 'Canada, Edmonton', code: 'America/Edmonton' },
  { label: 'Canada, Fort Nelson', code: 'America/Fort_Nelson' },
  { label: 'Canada, Glace Bay', code: 'America/Glace_Bay' },
  { label: 'Canada, Goose Bay', code: 'America/Goose_Bay' },
  { label: 'Canada, Halifax', code: 'America/Halifax' },
  { label: 'Canada, Inuvik', code: 'America/Inuvik' },
  { label: 'Canada, Iqaluit', code: 'America/Iqaluit' },
  { label: 'Canada, Moncton', code: 'America/Moncton' },
  { label: 'Canada, Rankin Inlet', code: 'America/Rankin_Inlet' },
  { label: 'Canada, Regina', code: 'America/Regina' },
  { label: 'Canada, Resolute', code: 'America/Resolute' },
  { label: 'Canada, St Johns', code: 'America/St_Johns' },
  { label: 'Canada, Swift Current', code: 'America/Swift_Current' },
  { label: 'Canada, Toronto', code: 'America/Toronto' },
  { label: 'Canada, Vancouver', code: 'America/Vancouver' },
  { label: 'Canada, Whitehorse', code: 'America/Whitehorse' },
  { label: 'Canada, Winnipeg', code: 'America/Winnipeg' },
  { label: 'Canada, Yellowknife', code: 'America/Yellowknife' },
  { label: 'Cape Verde, Cape Verde', code: 'Atlantic/Cape_Verde' },
  { label: 'Cayman Islands, Cayman', code: 'America/Cayman' },
  { label: 'Central African Republic, Bangui', code: 'Africa/Bangui' },
  { label: 'Chad, Ndjamena', code: 'Africa/Ndjamena' },
  { label: 'Chile, Punta Arenas', code: 'America/Punta_Arenas' },
  { label: 'Chile, Santiago', code: 'America/Santiago' },
  { label: 'Chile, Easter', code: 'Pacific/Easter' },
  { label: 'China, Shanghai', code: 'Asia/Shanghai' },
  { label: 'China, Urumqi', code: 'Asia/Urumqi' },
  { label: 'Christmas Island, Christmas', code: 'Indian/Christmas' },
  { label: 'Cocos (Keeling) Islands, Cocos', code: 'Indian/Cocos' },
  { label: 'Colombia, Bogota', code: 'America/Bogota' },
  { label: 'Comoros, Comoro', code: 'Indian/Comoro' },
  { label: 'Congo, Brazzaville', code: 'Africa/Brazzaville' },
  { label: 'Congo, the Democratic Republic of the, Kinshasa', code: 'Africa/Kinshasa' },
  { label: 'Congo, the Democratic Republic of the, Lubumbashi', code: 'Africa/Lubumbashi' },
  { label: 'Cook Islands, Rarotonga', code: 'Pacific/Rarotonga' },
  { label: 'Costa Rica, Costa Rica', code: 'America/Costa_Rica' },
  { label: 'Croatia, Zagreb', code: 'Europe/Zagreb' },
  { label: 'Cuba, Havana', code: 'America/Havana' },
  { label: 'Curaçao, Curacao', code: 'America/Curacao' },
  { label: 'Cyprus, Famagusta', code: 'Asia/Famagusta' },
  { label: 'Cyprus, Nicosia', code: 'Asia/Nicosia' },
  { label: 'Czech Republic, Prague', code: 'Europe/Prague' },
  { label: "Côte d, Abidjan'Ivoire", code: 'Africa/Abidjan' },
  { label: 'Denmark, Copenhagen', code: 'Europe/Copenhagen' },
  { label: 'Djibouti, Djibouti', code: 'Africa/Djibouti' },
  { label: 'Dominica, Dominica', code: 'America/Dominica' },
  { label: 'Dominican Republic, Santo Domingo', code: 'America/Santo_Domingo' },
  { label: 'Ecuador, Guayaquil', code: 'America/Guayaquil' },
  { label: 'Ecuador, Galapagos', code: 'Pacific/Galapagos' },
  { label: 'Egypt, Cairo', code: 'Africa/Cairo' },
  { label: 'El Salvador, El Salvador', code: 'America/El_Salvador' },
  { label: 'Equatorial Guinea, Malabo', code: 'Africa/Malabo' },
  { label: 'Eritrea, Asmara', code: 'Africa/Asmara' },
  { label: 'Estonia, Tallinn', code: 'Europe/Tallinn' },
  { label: 'Ethiopia, Addis Ababa', code: 'Africa/Addis_Ababa' },
  { label: 'Falkland Islands (Malvinas), Stanley', code: 'Atlantic/Stanley' },
  { label: 'Faroe Islands, Faroe', code: 'Atlantic/Faroe' },
  { label: 'Fiji, Fiji', code: 'Pacific/Fiji' },
  { label: 'Finland, Helsinki', code: 'Europe/Helsinki' },
  { label: 'France, Paris', code: 'Europe/Paris' },
  { label: 'French Guiana, Cayenne', code: 'America/Cayenne' },
  { label: 'French Polynesia, Gambier', code: 'Pacific/Gambier' },
  { label: 'French Polynesia, Marquesas', code: 'Pacific/Marquesas' },
  { label: 'French Polynesia, Tahiti', code: 'Pacific/Tahiti' },
  { label: 'French Southern Territories, Kerguelen', code: 'Indian/Kerguelen' },
  { label: 'Gabon, Libreville', code: 'Africa/Libreville' },
  { label: 'Gambia, Banjul', code: 'Africa/Banjul' },
  { label: 'Georgia, Tbilisi', code: 'Asia/Tbilisi' },
  { label: 'Germany, Berlin', code: 'Europe/Berlin' },
  { label: 'Germany, Busingen', code: 'Europe/Busingen' },
  { label: 'Ghana, Accra', code: 'Africa/Accra' },
  { label: 'Gibraltar, Gibraltar', code: 'Europe/Gibraltar' },
  { label: 'Greece, Athens', code: 'Europe/Athens' },
  { label: 'Greenland, Danmarkshavn', code: 'America/Danmarkshavn' },
  { label: 'Greenland, Nuuk', code: 'America/Nuuk' },
  { label: 'Greenland, Scoresbysund', code: 'America/Scoresbysund' },
  { label: 'Greenland, Thule', code: 'America/Thule' },
  { label: 'Grenada, Grenada', code: 'America/Grenada' },
  { label: 'Guadeloupe, Guadeloupe', code: 'America/Guadeloupe' },
  { label: 'Guam, Guam', code: 'Pacific/Guam' },
  { label: 'Guatemala, Guatemala', code: 'America/Guatemala' },
  { label: 'Guernsey, Guernsey', code: 'Europe/Guernsey' },
  { label: 'Guinea, Conakry', code: 'Africa/Conakry' },
  { label: 'Guinea-Bissau, Bissau', code: 'Africa/Bissau' },
  { label: 'Guyana, Guyana', code: 'America/Guyana' },
  { label: 'Haiti, Port-au-Prince', code: 'America/Port-au-Prince' },
  { label: 'Holy See (Vatican City State), Vatican', code: 'Europe/Vatican' },
  { label: 'Honduras, Tegucigalpa', code: 'America/Tegucigalpa' },
  { label: 'Hong Kong, Hong Kong', code: 'Asia/Hong_Kong' },
  { label: 'Hungary, Budapest', code: 'Europe/Budapest' },
  { label: 'Iceland, Reykjavik', code: 'Atlantic/Reykjavik' },
  { label: 'India, Kolkata', code: 'Asia/Kolkata' },
  { label: 'Indonesia, Jakarta', code: 'Asia/Jakarta' },
  { label: 'Indonesia, Jayapura', code: 'Asia/Jayapura' },
  { label: 'Indonesia, Makassar', code: 'Asia/Makassar' },
  { label: 'Indonesia, Pontianak', code: 'Asia/Pontianak' },
  { label: 'Iran, Islamic Republic of, Tehran', code: 'Asia/Tehran' },
  { label: 'Iraq, Baghdad', code: 'Asia/Baghdad' },
  { label: 'Ireland, Dublin', code: 'Europe/Dublin' },
  { label: 'Isle of Man, Isle of Man', code: 'Europe/Isle_of_Man' },
  { label: 'Israel, Jerusalem', code: 'Asia/Jerusalem' },
  { label: 'Italy, Rome', code: 'Europe/Rome' },
  { label: 'Jamaica, Jamaica', code: 'America/Jamaica' },
  { label: 'Japan, Tokyo', code: 'Asia/Tokyo' },
  { label: 'Jersey, Jersey', code: 'Europe/Jersey' },
  { label: 'Jordan, Amman', code: 'Asia/Amman' },
  { label: 'Kazakhstan, Almaty', code: 'Asia/Almaty' },
  { label: 'Kazakhstan, Aqtau', code: 'Asia/Aqtau' },
  { label: 'Kazakhstan, Aqtobe', code: 'Asia/Aqtobe' },
  { label: 'Kazakhstan, Atyrau', code: 'Asia/Atyrau' },
  { label: 'Kazakhstan, Oral', code: 'Asia/Oral' },
  { label: 'Kazakhstan, Qostanay', code: 'Asia/Qostanay' },
  { label: 'Kazakhstan, Qyzylorda', code: 'Asia/Qyzylorda' },
  { label: 'Kenya, Nairobi', code: 'Africa/Nairobi' },
  { label: 'Kiribati, Kanton', code: 'Pacific/Kanton' },
  { label: 'Kiribati, Kiritimati', code: 'Pacific/Kiritimati' },
  { label: 'Kiribati, Tarawa', code: 'Pacific/Tarawa' },
  { label: "Korea, Democratic People, Pyongyang's Republic of", code: 'Asia/Pyongyang' },
  { label: 'Korea, Republic of, Seoul', code: 'Asia/Seoul' },
  { label: 'Kuwait, Kuwait', code: 'Asia/Kuwait' },
  { label: 'Kyrgyzstan, Bishkek', code: 'Asia/Bishkek' },
  { label: "Lao People, e3e3e's Democratic Republic", code: 'Asia/Vientian[2;2R[3;1R[>0;95;0c]10;rgb:6565/7b7b/8383]11;rgb:fdfd/f6f6/e3e3e' },
  { label: 'Latvia, Riga', code: 'Europe/Riga' },
  { label: 'Lebanon, Beirut', code: 'Asia/Beirut' },
  { label: 'Lesotho, Maseru', code: 'Africa/Maseru' },
  { label: 'Liberia, Monrovia', code: 'Africa/Monrovia' },
  { label: 'Libya, Tripoli', code: 'Africa/Tripoli' },
  { label: 'Liechtenstein, Vaduz', code: 'Europe/Vaduz' },
  { label: 'Lithuania, Vilnius', code: 'Europe/Vilnius' },
  { label: 'Luxembourg, Luxembourg', code: 'Europe/Luxembourg' },
  { label: 'Macao, Macau', code: 'Asia/Macau' },
  { label: 'Macedonia, the Former Yugoslav Republic of, Skopje', code: 'Europe/Skopje' },
  { label: 'Madagascar, Antananarivo', code: 'Indian/Antananarivo' },
  { label: 'Malawi, Blantyre', code: 'Africa/Blantyre' },
  { label: 'Malaysia, Kuala Lumpur', code: 'Asia/Kuala_Lumpur' },
  { label: 'Malaysia, Kuching', code: 'Asia/Kuching' },
  { label: 'Maldives, Maldives', code: 'Indian/Maldives' },
  { label: 'Mali, Bamako', code: 'Africa/Bamako' },
  { label: 'Malta, Malta', code: 'Europe/Malta' },
  { label: 'Marshall Islands, Kwajalein', code: 'Pacific/Kwajalein' },
  { label: 'Marshall Islands, Majuro', code: 'Pacific/Majuro' },
  { label: 'Martinique, Martinique', code: 'America/Martinique' },
  { label: 'Mauritania, Nouakchott', code: 'Africa/Nouakchott' },
  { label: 'Mauritius, Mauritius', code: 'Indian/Mauritius' },
  { label: 'Mayotte, Mayotte', code: 'Indian/Mayotte' },
  { label: 'Mexico, Bahia Banderas', code: 'America/Bahia_Banderas' },
  { label: 'Mexico, Cancun', code: 'America/Cancun' },
  { label: 'Mexico, Chihuahua', code: 'America/Chihuahua' },
  { label: 'Mexico, Ciudad Juarez', code: 'America/Ciudad_Juarez' },
  { label: 'Mexico, Hermosillo', code: 'America/Hermosillo' },
  { label: 'Mexico, Matamoros', code: 'America/Matamoros' },
  { label: 'Mexico, Mazatlan', code: 'America/Mazatlan' },
  { label: 'Mexico, Merida', code: 'America/Merida' },
  { label: 'Mexico, Mexico City', code: 'America/Mexico_City' },
  { label: 'Mexico, Monterrey', code: 'America/Monterrey' },
  { label: 'Mexico, Ojinaga', code: 'America/Ojinaga' },
  { label: 'Mexico, Tijuana', code: 'America/Tijuana' },
  { label: 'Micronesia, Federated States of, Chuuk', code: 'Pacific/Chuuk' },
  { label: 'Micronesia, Federated States of, Kosrae', code: 'Pacific/Kosrae' },
  { label: 'Micronesia, Federated States of, Pohnpei', code: 'Pacific/Pohnpei' },
  { label: 'Moldova, Republic of, Chisinau', code: 'Europe/Chisinau' },
  { label: 'Monaco, Monaco', code: 'Europe/Monaco' },
  { label: 'Mongolia, Choibalsan', code: 'Asia/Choibalsan' },
  { label: 'Mongolia, Hovd', code: 'Asia/Hovd' },
  { label: 'Mongolia, Ulaanbaatar', code: 'Asia/Ulaanbaatar' },
  { label: 'Montenegro, Podgorica', code: 'Europe/Podgorica' },
  { label: 'Montserrat, Montserrat', code: 'America/Montserrat' },
  { label: 'Morocco, Casablanca', code: 'Africa/Casablanca' },
  { label: 'Morocco, El Aaiun', code: 'Africa/El_Aaiun' },
  { label: 'Mozambique, Maputo', code: 'Africa/Maputo' },
  { label: 'Myanmar, Yangon', code: 'Asia/Yangon' },
  { label: 'Namibia, Windhoek', code: 'Africa/Windhoek' },
  { label: 'Nauru, Nauru', code: 'Pacific/Nauru' },
  { label: 'Nepal, Kathmandu', code: 'Asia/Kathmandu' },
  { label: 'Netherlands, Amsterdam', code: 'Europe/Amsterdam' },
  { label: 'New Caledonia, Noumea', code: 'Pacific/Noumea' },
  { label: 'New Zealand, Auckland', code: 'Pacific/Auckland' },
  { label: 'New Zealand, Chatham', code: 'Pacific/Chatham' },
  { label: 'Nicaragua, Managua', code: 'America/Managua' },
  { label: 'Niger, Niamey', code: 'Africa/Niamey' },
  { label: 'Nigeria, Lagos', code: 'Africa/Lagos' },
  { label: 'Niue, Niue', code: 'Pacific/Niue' },
  { label: 'Norfolk Island, Norfolk', code: 'Pacific/Norfolk' },
  { label: 'Northern Mariana Islands, Saipan', code: 'Pacific/Saipan' },
  { label: 'Norway, Oslo', code: 'Europe/Oslo' },
  { label: 'Oman, Muscat', code: 'Asia/Muscat' },
  { label: 'Pakistan, Karachi', code: 'Asia/Karachi' },
  { label: 'Palau, Palau', code: 'Pacific/Palau' },
  { label: 'Palestine, State of, Gaza', code: 'Asia/Gaza' },
  { label: 'Palestine, State of, Hebron', code: 'Asia/Hebron' },
  { label: 'Panama, Panama', code: 'America/Panama' },
  { label: 'Papua New Guinea, Bougainville', code: 'Pacific/Bougainville' },
  { label: 'Papua New Guinea, Port Moresby', code: 'Pacific/Port_Moresby' },
  { label: 'Paraguay, Asuncion', code: 'America/Asuncion' },
  { label: 'Peru, Lima', code: 'America/Lima' },
  { label: 'Philippines, Manila', code: 'Asia/Manila' },
  { label: 'Pitcairn, Pitcairn', code: 'Pacific/Pitcairn' },
  { label: 'Poland, Warsaw', code: 'Europe/Warsaw' },
  { label: 'Portugal, Azores', code: 'Atlantic/Azores' },
  { label: 'Portugal, Madeira', code: 'Atlantic/Madeira' },
  { label: 'Portugal, Lisbon', code: 'Europe/Lisbon' },
  { label: 'Puerto Rico, Puerto Rico', code: 'America/Puerto_Rico' },
  { label: 'Qatar, Qatar', code: 'Asia/Qatar' },
  { label: 'Romania, Bucharest', code: 'Europe/Bucharest' },
  { label: 'Russian Federation, Anadyr', code: 'Asia/Anadyr' },
  { label: 'Russian Federation, Barnaul', code: 'Asia/Barnaul' },
  { label: 'Russian Federation, Chita', code: 'Asia/Chita' },
  { label: 'Russian Federation, Irkutsk', code: 'Asia/Irkutsk' },
  { label: 'Russian Federation, Kamchatka', code: 'Asia/Kamchatka' },
  { label: 'Russian Federation, Khandyga', code: 'Asia/Khandyga' },
  { label: 'Russian Federation, Krasnoyarsk', code: 'Asia/Krasnoyarsk' },
  { label: 'Russian Federation, Magadan', code: 'Asia/Magadan' },
  { label: 'Russian Federation, Novokuznetsk', code: 'Asia/Novokuznetsk' },
  { label: 'Russian Federation, Novosibirsk', code: 'Asia/Novosibirsk' },
  { label: 'Russian Federation, Omsk', code: 'Asia/Omsk' },
  { label: 'Russian Federation, Sakhalin', code: 'Asia/Sakhalin' },
  { label: 'Russian Federation, Srednekolymsk', code: 'Asia/Srednekolymsk' },
  { label: 'Russian Federation, Tomsk', code: 'Asia/Tomsk' },
  { label: 'Russian Federation, Ust-Nera', code: 'Asia/Ust-Nera' },
  { label: 'Russian Federation, Vladivostok', code: 'Asia/Vladivostok' },
  { label: 'Russian Federation, Yakutsk', code: 'Asia/Yakutsk' },
  { label: 'Russian Federation, Yekaterinburg', code: 'Asia/Yekaterinburg' },
  { label: 'Russian Federation, Astrakhan', code: 'Europe/Astrakhan' },
  { label: 'Russian Federation, Kaliningrad', code: 'Europe/Kaliningrad' },
  { label: 'Russian Federation, Kirov', code: 'Europe/Kirov' },
  { label: 'Russian Federation, Moscow', code: 'Europe/Moscow' },
  { label: 'Russian Federation, Samara', code: 'Europe/Samara' },
  { label: 'Russian Federation, Saratov', code: 'Europe/Saratov' },
  { label: 'Russian Federation, Ulyanovsk', code: 'Europe/Ulyanovsk' },
  { label: 'Russian Federation, Volgograd', code: 'Europe/Volgograd' },
  { label: 'Rwanda, Kigali', code: 'Africa/Kigali' },
  { label: 'Réunion, Reunion', code: 'Indian/Reunion' },
  { label: 'Saint Barthélemy, St Barthelemy', code: 'America/St_Barthelemy' },
  { label: 'Saint Helena, Ascension and Tristan da Cunha, St Helena', code: 'Atlantic/St_Helena' },
  { label: 'Saint Kitts and Nevis, St Kitts', code: 'America/St_Kitts' },
  { label: 'Saint Lucia, St Lucia', code: 'America/St_Lucia' },
  { label: 'Saint Martin (French part), Marigot', code: 'America/Marigot' },
  { label: 'Saint Pierre and Miquelon, Miquelon', code: 'America/Miquelon' },
  { label: 'Saint Vincent and the Grenadines, St Vincent', code: 'America/St_Vincent' },
  { label: 'Samoa, Apia', code: 'Pacific/Apia' },
  { label: 'San Marino, San Marino', code: 'Europe/San_Marino' },
  { label: 'Sao Tome and Principe, Sao Tome', code: 'Africa/Sao_Tome' },
  { label: 'Saudi Arabia, Riyadh', code: 'Asia/Riyadh' },
  { label: 'Senegal, Dakar', code: 'Africa/Dakar' },
  { label: 'Serbia, Belgrade', code: 'Europe/Belgrade' },
  { label: 'Seychelles, Mahe', code: 'Indian/Mahe' },
  { label: 'Sierra Leone, Freetown', code: 'Africa/Freetown' },
  { label: 'Singapore, Singapore', code: 'Asia/Singapore' },
  { label: 'Sint Maarten (Dutch part), Lower Princes', code: 'America/Lower_Princes' },
  { label: 'Slovakia, Bratislava', code: 'Europe/Bratislava' },
  { label: 'Slovenia, Ljubljana', code: 'Europe/Ljubljana' },
  { label: 'Solomon Islands, Guadalcanal', code: 'Pacific/Guadalcanal' },
  { label: 'Somalia, Mogadishu', code: 'Africa/Mogadishu' },
  { label: 'South Africa, Johannesburg', code: 'Africa/Johannesburg' },
  { label: 'South Georgia and the South Sandwich Islands, South Georgia', code: 'Atlantic/South_Georgia' },
  { label: 'South Sudan, Juba', code: 'Africa/Juba' },
  { label: 'Spain, Ceuta', code: 'Africa/Ceuta' },
  { label: 'Spain, Canary', code: 'Atlantic/Canary' },
  { label: 'Spain, Madrid', code: 'Europe/Madrid' },
  { label: 'Sri Lanka, Colombo', code: 'Asia/Colombo' },
  { label: 'Sudan, Khartoum', code: 'Africa/Khartoum' },
  { label: 'Suriname, Paramaribo', code: 'America/Paramaribo' },
  { label: 'Svalbard and Jan Mayen, Longyearbyen', code: 'Arctic/Longyearbyen' },
  { label: 'Swaziland, Mbabane', code: 'Africa/Mbabane' },
  { label: 'Sweden, Stockholm', code: 'Europe/Stockholm' },
  { label: 'Switzerland, Zurich', code: 'Europe/Zurich' },
  { label: 'Syrian Arab Republic, Damascus', code: 'Asia/Damascus' },
  { label: 'Taiwan, Province of China, Taipei', code: 'Asia/Taipei' },
  { label: 'Tajikistan, Dushanbe', code: 'Asia/Dushanbe' },
  { label: 'Tanzania, United Republic of, Dar es Salaam', code: 'Africa/Dar_es_Salaam' },
  { label: 'Thailand, Bangkok', code: 'Asia/Bangkok' },
  { label: 'Timor-Leste, Dili', code: 'Asia/Dili' },
  { label: 'Togo, Lome', code: 'Africa/Lome' },
  { label: 'Tokelau, Fakaofo', code: 'Pacific/Fakaofo' },
  { label: 'Tonga, Tongatapu', code: 'Pacific/Tongatapu' },
  { label: 'Trinidad and Tobago, Port of Spain', code: 'America/Port_of_Spain' },
  { label: 'Tunisia, Tunis', code: 'Africa/Tunis' },
  { label: 'Turkey, Istanbul', code: 'Europe/Istanbul' },
  { label: 'Turkmenistan, Ashgabat', code: 'Asia/Ashgabat' },
  { label: 'Turks and Caicos Islands, Grand Turk', code: 'America/Grand_Turk' },
  { label: 'Tuvalu, Funafuti', code: 'Pacific/Funafuti' },
  { label: 'Uganda, Kampala', code: 'Africa/Kampala' },
  { label: 'Ukraine, Kyiv', code: 'Europe/Kyiv' },
  { label: 'Ukraine, Simferopol', code: 'Europe/Simferopol' },
  { label: 'United Arab Emirates, Dubai', code: 'Asia/Dubai' },
  { label: 'United Kingdom, London', code: 'Europe/London' },
  { label: 'United States, Adak', code: 'America/Adak' },
  { label: 'United States, Anchorage', code: 'America/Anchorage' },
  { label: 'United States, Boise', code: 'America/Boise' },
  { label: 'United States, Chicago', code: 'America/Chicago' },
  { label: 'United States, Denver', code: 'America/Denver' },
  { label: 'United States, Detroit', code: 'America/Detroit' },
  { label: 'United States, Indianapolis', code: 'America/Indiana/Indianapolis' },
  { label: 'United States, Knox', code: 'America/Indiana/Knox' },
  { label: 'United States, Marengo', code: 'America/Indiana/Marengo' },
  { label: 'United States, Petersburg', code: 'America/Indiana/Petersburg' },
  { label: 'United States, Tell City', code: 'America/Indiana/Tell_City' },
  { label: 'United States, Vevay', code: 'America/Indiana/Vevay' },
  { label: 'United States, Vincennes', code: 'America/Indiana/Vincennes' },
  { label: 'United States, Winamac', code: 'America/Indiana/Winamac' },
  { label: 'United States, Juneau', code: 'America/Juneau' },
  { label: 'United States, Louisville', code: 'America/Kentucky/Louisville' },
  { label: 'United States, Monticello', code: 'America/Kentucky/Monticello' },
  { label: 'United States, Los Angeles', code: 'America/Los_Angeles' },
  { label: 'United States, Menominee', code: 'America/Menominee' },
  { label: 'United States, Metlakatla', code: 'America/Metlakatla' },
  { label: 'United States, New York', code: 'America/New_York' },
  { label: 'United States, Nome', code: 'America/Nome' },
  { label: 'United States, Beulah', code: 'America/North_Dakota/Beulah' },
  { label: 'United States, Center', code: 'America/North_Dakota/Center' },
  { label: 'United States, New Salem', code: 'America/North_Dakota/New_Salem' },
  { label: 'United States, Phoenix', code: 'America/Phoenix' },
  { label: 'United States, Sitka', code: 'America/Sitka' },
  { label: 'United States, Yakutat', code: 'America/Yakutat' },
  { label: 'United States, Honolulu', code: 'Pacific/Honolulu' },
  { label: 'United States Minor Outlying Islands, Midway', code: 'Pacific/Midway' },
  { label: 'United States Minor Outlying Islands, Wake', code: 'Pacific/Wake' },
  { label: 'Uruguay, Montevideo', code: 'America/Montevideo' },
  { label: 'Uzbekistan, Samarkand', code: 'Asia/Samarkand' },
  { label: 'Uzbekistan, Tashkent', code: 'Asia/Tashkent' },
  { label: 'Vanuatu, Efate', code: 'Pacific/Efate' },
  { label: 'Venezuela, Bolivarian Republic of, Caracas', code: 'America/Caracas' },
  { label: 'Viet Nam, Ho Chi Minh', code: 'Asia/Ho_Chi_Minh' },
  { label: 'Virgin Islands, British, Tortola', code: 'America/Tortola' },
  { label: 'Virgin Islands, U.S., St Thomas', code: 'America/St_Thomas' },
  { label: 'Wallis and Futuna, Wallis', code: 'Pacific/Wallis' },
  { label: 'Yemen, Aden', code: 'Asia/Aden' },
  { label: 'Zambia, Lusaka', code: 'Africa/Lusaka' },
  { label: 'Zimbabwe, Harare', code: 'Africa/Harare' },
  { label: 'Åland Islands, Mariehamn', code: 'Europe/Mariehamn' },
];

export default {
  find: (code) => timezones.find((tz) => tz.code === code),
  timezones: () => timezones,
};
