<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.company.officeLocations') }}
      <FeBadge v-if="attentionNeeded" alert small />
    </div>
    <div class="profile-row__value" v-if="data.office_locations.length > 0">
      <div class="tag-list tag-list--vertical">
        <div v-for="location in data.office_locations" :key="location.id" class="tag">

          <div class="tag__part">
            <i class="fa-solid fa-location-dot"></i>
            {{ location.address }}, {{ location.country.name }}
          </div>
          <div class="tag__part tag__part--action">
            <EditIconButton @click="openModal('update-' + location.id)" />
          </div>
          <div class="tag__part tag__part--action">
            <DeleteIconButton @click="openModal('remove-' + location.id)" />
          </div>
          <Teleport to="body">
            <ModalForm
              :form="{
                action: `/profile/office-locations/${location.id}`,
                method: 'patch',
                name: 'update-office-location',
              }"
              :modal="{
                title: __('profile.rows.companyLocations.updateModalTitle'),
                titleIcon: 'far fa-edit',
              }"
              @success="update($event); closeModal('update-' + location.id)"
              :ref="'update-' + location.id"
            >
              <FormTextarea
                :label="__('crm.companyOfficeLocation.address')"
                name="address"
                :modelValue="location.address"
                :rows="5"
                :max="255"
                required
                />
              <FormSelect
                :label="__('crm.companyOfficeLocation.country')"
                name="country_code"
                :options="countryOptions"
                :placeholder="__('ui.selectCountry')"
                :default="location.country_code"
                required
                />
              <template #footer>
                <div class="button-group">
                  <button
                    type="button"
                    class="button button--secondary"
                    @click="closeModal('update-' + location.id)"
                    >
                    {{ __('buttons.cancel') }}
                  </button>
                  <FormSubmit> {{ __('buttons.update') }} </FormSubmit>
                </div>
              </template>
            </ModalForm>

            <ModalForm
              :form="{
                action: `/profile/office-locations/${location.id}`,
                method: 'delete',
                name: 'delete-office-location',
                }"
              :modal="{
                title: __('ui.delete'),
                titleIcon: 'far fa-trash-can',
                width: 'narrow',
                }"
              @success="update($event); closeModal('remove-' + location.id)"
              :ref="'remove-' + location.id"
            >
              <p> {{ __('profile.rows.companyLocations.delete') }} </p>
              <p>
                <i class="fa-solid fa-location-dot"></i> &nbsp;
                {{ location.address }}, {{ location.country.name }}
              </p>
              <template #footer>
                <div class="button-group">
                  <button
                    type="button"
                    class="button button--secondary"
                    @click="closeModal('remove-' + location.id)"
                    >
                    {{ __('buttons.cancel') }}
                  </button>
                  <button type="submit" class="button button--warning">
                    {{ __('buttons.delete') }}
                  </button>
                </div>
              </template>
            </ModalForm>
          </Teleport>
        </div>
      </div>
    </div>
    <div class="profile-row__value profile-row__value--invalid" v-else> {{ __('ui.notAdded') }}</div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal('create')">
        <i class="far fa-plus-circle"></i>
        {{ __('buttons.add') }}
      </button>
    </div>
  </div>
  <ModalForm
    :form="{
      action: '/profile/office-locations',
      method: 'post',
      name: 'add-office-location',
      }"
    :modal="{
      title: __('profile.rows.companyLocations.addModalTitle'),
      titleIcon: 'far fa-plus-circle',
    }"
    @success="update($event); closeModal('create')"
    ref="create"
  >
    <FormTextarea
      :label="__('crm.companyOfficeLocation.address')"
      name="address"
      modelValue=""
      :rows="5"
      :max="255"
      required
      />
    <FormSelect
      :label="__('crm.companyOfficeLocation.country')"
      name="country_code"
      :options="countryOptions"
      :placeholder="__('ui.selectCountry')"
      required
      />
    <template #footer>
      <div class="button-group">
        <button type="button" class="button button--secondary" @click="closeModal('create')">
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit> {{ __('buttons.save') }}</FormSubmit>
      </div>
    </template>
  </ModalForm>
</template>

<script>
import useMyAccountStore from '~/Store/MyAccount';
import { mapActions } from 'pinia';
import FeBadge from '~/Components/Frontend/Core/Misc/FeBadge.vue';
import ModalForm from '~/Components/Frontend/Form/ModalForm.vue';
import FormSelect from '~/Components/Frontend/Form/Inputs/FormSelect.vue';
import FormTextarea from '~/Components/Frontend/Form/Inputs/FormTextarea.vue';
import DeleteIconButton from '~/Components/Frontend/Form/Inputs/DeleteIconButton.vue';
import EditIconButton from '~/Components/Frontend/Form/Inputs/EditIconButton.vue';
import FormSubmit from '~/Components/Frontend/Form/FormSubmit.vue';

export default {
  name: 'CompanyLocationsProfileRow',
  components: {
    FeBadge,
    FormSelect,
    FormSubmit,
    FormTextarea,
    ModalForm,
    DeleteIconButton,
    EditIconButton,
  },
  props: {
    officeLocations: {
      type: Array,
      default: () => [],
    },
    countries: Array,
  },
  data() {
    return {
      data: {
        office_locations: this.officeLocations,
      },
      countryOptions: this.countries.map((country) => ({
        value: country.code,
        text: country.name,
      })),
    };
  },
  computed: {
    attentionNeeded() {
      return this.data.office_locations.length === 0;
    },
  },
  watch: {
    attentionNeeded() {
      this.loadSectionStatus();
    },
  },
  methods: {
    ...mapActions(useMyAccountStore, ['loadSectionStatus']),
    getCountryByCode(countryCode) {
      return this.countries.find((data) => data.code === countryCode);
    },
    openModal(ref) {
      let modal = this.$refs[ref];
      if (Array.isArray(modal)) {
        // eslint-disable-next-line prefer-destructuring
        modal = modal[0];
      }
      modal.open();
    },
    closeModal(ref) {
      let modal = this.$refs[ref];
      if (Array.isArray(modal)) {
        // eslint-disable-next-line prefer-destructuring
        modal = modal[0];
      }
      modal.close();
    },
    update(data) {
      this.data = data;
    },
  },
};

</script>;
