<template>
  <BaseModal
    ref="modal"
    height="short"
    :title="title"
    titleIcon="far fa-search"
    @closed="$emit('update:visible', false)"
    @open="$emit('update:visible', true)"
  >
    <template v-if="saving">
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>

    <template v-else-if="success">
      <h2 style="text-align:center; margin:var(--sizing-4) auto">
        <i class="fas fa-circle-check info-icon--success"></i>
        {{ __('directory.saved_search_success') }}
      </h2>
    </template>

    <template v-else>
        <FrontendTextInput
          id="name"
          name="name"
          v-model="searchName"
          :label="__('directory.search_name')"
          :placeholder="__('directory.default_search_name', {date: new Date().toDateString()})"
          :errors="errors.name"
          :max="40"
          required
        />
        <FrontendSelectInput
          placeholder="Please select"
          :options="notificationFrequencies"
          id="notification_frequency"
          v-model="notificationFrequency"
          name="notification_frequency"
          :label="__('directory.notification_frequency')"
        />
    </template>

    <template #footer>
      <div class="button-group">
        <button
          type="button"
          class="button button--secondary"
          :disabled="saving"
          @click="cancel"
        >
          {{ __('filter_modal.cancel') }}
        </button>
        <button
          type="button"
          class="button button--primary"
          :disabled="success || saving"
          @click="save"
          >
          {{ __('directory.filters.save') }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import BaseModal from '~/Components/Frontend/BaseModal.vue';
import FrontendTextInput from '~/Components/Frontend/Inputs/FrontendTextInput.vue';
import FrontendSelectInput from '~/Components/Frontend/Inputs/FrontendSelectInput.vue';
import { store } from '../../../Frontend/store';

export default {
  name: 'SaveSearchModal',
  components: {
    BaseModal,
    FrontendSelectInput,
    FrontendTextInput,
  },
  props: {
    title: String,
    icon: String,
    visible: Boolean,
    selectedCriteria: Object,
    notificationFrequencies: {
      type: Array,
      default: () => ([]),
    },
    directoryId: Number,
  },
  emits: ['save', 'closed', 'close', 'update:visible'],
  data() {
    return {
      searchName: '',
      notificationFrequency: 'never',
      errors: {},
      success: false,
      saving: false,
    };
  },
  watch: {
    visible(value) {
      this.errors = {};
      this.success = false;

      if (value) {
        this.$refs.modal.open();
      } else {
        this.$refs.modal.close();
      }
    },
  },
  methods: {
    save() {
      this.saving = true;
      const url = `/${store.eventEdition.slug}/directories/${this.directoryId}/saved-searches`;
      axios.post(url, {
        selected: this.selectedCriteria,
        name: this.searchName,
        notification_frequency: this.notificationFrequency,
      }).then(() => {
        this.saving = false;
        this.success = true;
        window.setTimeout(() => {
          this.$refs.modal.close();
        }, 3000);
      })
        .catch(({ response }) => {
          this.saving = false;
          this.errors = response?.data?.errors ?? {};
        });
    },
    cancel() {
      this.$refs.modal.close();
    },
  },
};
</script>
