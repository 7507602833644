<template>
  <div :class="{'info-panel': !borderless}">
    <slot></slot>
  </div>
</template>

<script>

export default {
  props: {
    borderless: {
      type: Boolean,
    },
  },
};
</script>
