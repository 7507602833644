<template>
  <div class="form-input__label" v-if="label || (hasLimit && hasSize)">
    <div class="vertical-align vertical-align--small">
      <i :class="icon" v-if="icon">
      </i>
      <span class="form-input__label-tag">
        {{ label }}
        <span v-if="required" class="form-input__required-indicator"> * </span>
      </span>
    </div>
    <div
      v-if="hasSize && hasLimit"
      class="form-input__counter frontend-text-input__counter--textarea"
      :class="{ 'form-input__counter--error': size >= limit * 0.9 }"
    >
      {{ size }} / {{ limit }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      required: false,
    },
    limit: {
      type: Number,
      required: false,
    },
  },
  computed: {
    hasSize() {
      return this.size || this.size === 0;
    },
    hasLimit() {
      return this.limit || this.limit === 0;
    },
  },
};

</script>
