<template>
  <BaseModal
    ref="modal"
    startOpen
  >
    <div class="concierge-contact-modal">
      <i class="fa-solid fa-user-headset fa-6x"></i>
      <h5> {{ __("concierge_modal.info_text") }}</h5>
      <div class="concierge-contact-modal--buttons">
        <a
          v-if="eventEdition?.email_address"
          :href="`mailto:${eventEdition.email_address}`"
          class="button button--secondary"
          >
          <i class="fa-solid fa-envelope"></i> {{ eventEdition.email_address }}
        </a>
        <a
          v-if="eventEdition?.phone_number"
          :href="`tel:${eventEdition.phone_number}`"
          class="button button--tertiary"
          >
          <i class="fa-solid fa-phone-arrow-up-right"></i> {{ eventEdition.phone_number }}
        </a>
        <a
          v-if="eventEdition?.whatsapp_url"
          :href="`${eventEdition.whatsapp_url}`"
          class="button button-primary concierge-contact-modal--whatsapp-button"
        >
          <i class="fa-xl fa-brands fa-whatsapp"></i> {{ __("concierge_modal.whatsapp_button") }}
        </a>
      </div>
    </div>
  </BaseModal>
</template>

<script>

import { store } from '../../Frontend/store';

export default {
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
  },
};
</script>
