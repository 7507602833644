<template>

  <p v-if="store.eventEdition">
    <a class="button" href="#/profile/company-preview">
      {{ __('profile.pages.companyProfile.previewButton') }}
      <i class="fa fa-arrow-right"></i>
    </a>
  </p>

  <FeLoading v-if="loading" />

  <template v-else>
    <ProfileGroup :heading="__('profile.groups.companyInformation.heading')">
      <CompanyLegalNameProfileRow
        v-if="company"
        :legalName="company.legal_name"
        />
      <CompanyDisplayNameProfileRow
        v-if="company"
        :displayName="company.display_name"
      />
      <CompanyLogoProfileRow
        v-if="company"
        :logo="company.logo"
      />
      <CompanyShortBioProfileRow
        v-if="company"
        :shortBio="company.tagline"
      />
      <CompanyBioProfileRow
        v-if="company"
        :bio="company.bio"
      />
    </ProfileGroup>
    <ProfileGroup
      v-if="visibleDemographics.length"
      :heading="__('profile.groups.companyDemographics.heading')"
    >
      <DemographicProfileRow
        v-for="demographic in visibleDemographics"
        :key="demographic.id"
        :demographic="demographic"
        v-model="selected[demographic.id]"
      />
    </ProfileGroup>
    <ProfileGroup>
      <CompanyLocationsProfileRow
        v-if="company"
        :officeLocations="company.office_locations"
        :countries="countries"
        />
      <CompanyWebsitesProfileRow
        v-if="company"
        :websites="company.websites"
      />
      <CompanySocialsProfileRow
        v-if="company"
        :socials="company.socials"
        :socialTypes="socialTypes"
      />
    </ProfileGroup>
  </template>
</template>

<script>

import axios from 'axios';
import { store } from '~/Frontend/store';
import CompanyBioProfileRow from '~/Components/Frontend/Profile/Rows/CompanyBioProfileRow.vue';
import CompanyDisplayNameProfileRow from '~/Components/Frontend/Profile/Rows/CompanyDisplayNameProfileRow.vue';
import CompanyLegalNameProfileRow from '~/Components/Frontend/Profile/Rows/CompanyLegalNameProfileRow.vue';
import CompanyLocationsProfileRow from '~/Components/Frontend/Profile/Rows/CompanyLocationsProfileRow.vue';
import CompanyLogoProfileRow from '~/Components/Frontend/Profile/Rows/CompanyLogoProfileRow.vue';
import CompanyShortBioProfileRow from '~/Components/Frontend/Profile/Rows/CompanyShortBioProfileRow.vue';
import CompanySocialsProfileRow from '~/Components/Frontend/Profile/Rows/CompanySocialsProfileRow.vue';
import CompanyWebsitesProfileRow from '~/Components/Frontend/Profile/Rows/CompanyWebsitesProfileRow.vue';
import DemographicProfileRow from '~/Components/Frontend/Profile/Rows/DemographicProfileRow.vue';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import ProfileGroup from '~/Components/Frontend/Profile/ProfileGroup.vue';

function isSelected(demographic, selected) {
  if (demographic.dependent_on.length === 0) {
    return true;
  }
  return demographic.dependent_on.some(
    (demographicValue) => selected[demographicValue.demographic_id]?.includes(demographicValue.id),
  );
}

export default {
  name: 'CompanyProfilePage',
  components: {
    CompanyBioProfileRow,
    CompanyDisplayNameProfileRow,
    CompanyLegalNameProfileRow,
    CompanyLocationsProfileRow,
    CompanyLogoProfileRow,
    CompanyShortBioProfileRow,
    CompanySocialsProfileRow,
    CompanyWebsitesProfileRow,
    DemographicProfileRow,
    FeLoading,
    ProfileGroup,
  },
  data() {
    return {
      countries: [],
      demographics: [],
      socialTypes: [],
      company: null,
      selected: {},
      websites: null,
      socials: null,
      officeLocations: null,
      loading: true,
      store,
    };
  },
  computed: {
    visibleDemographics() {
      return this.demographics.filter((demographic) => isSelected(demographic, this.selected));
    },
  },
  mounted() {
    axios.get('/profile/company-profile')
      .then(({
        data: {
          countries,
          demographics,
          social_types: socialTypes,
          selected,
          company,
        },
      }) => {
        this.countries = countries;
        this.demographics = demographics;
        this.socialTypes = socialTypes;
        this.company = company;
        this.selected = selected;
      }).then(() => {
        this.loading = false;
      });
  },
};
</script>
