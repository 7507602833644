<template>
  <div
    @click.stop="openBooking"
    class="my-schedule__freeslot"
    :style="{
      '--schedule-booking-duration': duration,
      '--schedule-booking-start': minutesSince,
    }"
  >
    <div class="my-schedule__freeslot__add-container">
      <span class="add-calendar-widget">
        <i class="far fa-plus"></i>
      </span>
      <button type="button" @click.stop="openBooking" class="button button--gray button--compact">
        {{ __('my_schedule.other_bookings.buttons.addOther') }}
      </button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    minutesSince: {
      type: Number,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    startDateTime: {
      type: String,
      required: true,
    },
    endDateTime: {
      type: String,
      required: true,
    },
  },
  methods: {
    openBooking() {
      window.location.href = `#/other-bookings-create/${this.startDateTime}--${this.endDateTime}`;
    },
  },
  computed: {
    location() {
      return '';
    },
  },
};
</script>
