<template>
  <FeLoading v-if="loading"></FeLoading>
  <template v-else>
    <ProfileGroup :heading="__('profile.groups.password.heading')">
      <PasswordProfileRow />
    </ProfileGroup>
    <ProfileGroup
      :heading="__('profile.groups.timezone.heading')"
      :subheading="__('profile.groups.timezone.subheading')"
      >
      <TimezoneProfileRow />
    </ProfileGroup>
    <template v-if="managedBy.length > 0">
      <ProfileGroup
        :heading="__('profile.groups.accountAccess.heading')"
        :subheading="__('profile.groups.accountAccess.subheading')"
        >
        <ManagedByProfileRow v-bind="{ managedBy }" />
      </ProfileGroup>
    </template>
  </template>
</template>
<script>

import axios from 'axios';
import moment from 'moment';
import { user } from '~/Frontend/store';
import PasswordProfileRow from '~/Components/Frontend/Profile/Rows/PasswordProfileRow.vue';
import TimezoneProfileRow from '~/Components/Frontend/Profile/Rows/TimezoneProfileRow.vue';
import ManagedByProfileRow from '~/Components/Frontend/Profile/Rows/ManagedByProfileRow.vue';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import ProfileGroup from '~/Components/Frontend/Profile/ProfileGroup.vue';

export default {
  name: 'PasswordSettingsPage',
  components: {
    PasswordProfileRow,
    TimezoneProfileRow,
    ManagedByProfileRow,
    FeLoading,
    ProfileGroup,
  },
  data() {
    return {
      managedBy: [],
      user,
      loading: true,
    };
  },
  methods: {
    moment,
  },
  mounted() {
    axios.get('/profile/password-settings')
      .then(({ data: { managed_by: managedBy } }) => {
        this.managedBy = managedBy;
      })
      .then(() => { this.loading = false; });
  },
};
</script>
