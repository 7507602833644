<template>
  <BaseModal
    startOpen
    ref="modal"
    width="wide"
    :notifications="notifications"
    padding="double"
  >
    <!-- this is just a redirector -->
    <template v-if="loading">
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>

    <div v-else-if="message">
      <p style="margin: 2rem; text-align: center">{{ message }}</p>
    </div>

    <PurchaseUpgradeStep1
      v-else-if="!errored && step === 1 && purchaseItem.id"
      :purchaseItem="purchaseItem"
      @buyNow="buyNow"
      @cancel="modalClose"
    />

    <StripePaymentForm
      v-else-if="!errored && step === 2"
      :initiatePaymentUrl="initiatePaymentUrl"
      :successUrl="successUrl"
      :failureUrl="failureUrl"
      :purchaseItem="purchaseItem"
      :stripePublishableKey="stripePublishableKey"
      @paymentComplete="paymentComplete"
    />

    <div v-if="step === 3">
      <h2 class="purchase-upgrade__popup-title">
        <i class="fas fa-check-circle text-success"></i>
        {{ __('purchase_upgrade.payment_complete.heading') }}
      </h2>

      <p class="purchase-upgrade__popup-subtitle">
        {{ __('purchase_upgrade.payment_complete.message') }}
      </p>

      <div class="purchase-upgrade__payment-complete-holder">
        <p class="purchase-upgrade__payment-complete-text">
          1 x {{ purchaseItem.name }}
        </p>

        <p class="purchase-upgrade__payment-complete-text">
          {{ purchaseItem.currency_symbol }}
          {{ (totalPaidInCents/100).toFixed(2) }}
        </p>

        <div>
          <a
            class="button button--secondary"
            style="max-width: fit-content"
            target="_blank"
            :href="`/profile/payments/${paymentId}`"
          >
            <i class="far fa-print"></i> {{ __('purchase_upgrade.buttons.print_receipt') }}
          </a>
        </div>
      </div>

      <div class="button-group button-group--right" style="margin-top: 2rem;">
        <button
          type="button"
          class="button button--primary"
          @click="reloadPage"
          style="max-width: fit-content"
        >
          {{ __('purchase_upgrade.buttons.finish') }}
        </button>
      </div>
    </div>

  </BaseModal>
</template>

<script>
import axios from 'axios';
import { store } from '~/Frontend/store';
import PurchaseUpgradeStep1 from '~/Components/Frontend/CRM/PurchaseUpgrade/PurchaseUpgradeStep1.vue';
import StripePaymentForm from '~/Components/Frontend/CRM/PurchaseUpgrade/StripePaymentForm.vue';

export default {
  components: {
    PurchaseUpgradeStep1,
    StripePaymentForm,
  },
  props: {
    purchaseItemId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      step: 0,
      errored: false,
      loading: true,
      purchaseItem: {},
      notifications: [],
      agreeTerms: false,
      stripePublishableKey: null,
      stripeToken: null,
      paymentId: null,
      totalPaidInCents: 0,
    };
  },
  methods: {
    loadData() {
      axios
        .get(`/${this.eventEdition.slug}/purchase-items/${this.purchaseItemId}`)
        .then((response) => {
          this.purchaseItem = response.data.purchase_item ?? null;
          this.message = response.data.message;
          this.stripePublishableKey = response.data.stripe_publishable_key;
          this.step = 1;
        })
        .catch((response) => {
          this.errored = true;
          this.errorMessage = response?.data?.message;
          this.notifications.push({
            time: Date.now(),
            type: 'error',
            message: 'Someting went wrong.',
            icon: 'fa-circle-info',
          });
        })
        .then(() => {
          this.loading = false;
        });
    },
    buyNow() {
      this.step = 2;
    },
    modalClose() {
      this.$refs.modal.close();
    },
    paymentComplete(obj) {
      this.stripeToken = obj.token;
      this.paymentId = obj.paymentId;
      this.totalPaidInCents = obj.totalInCents;
      this.step = 3;
    },
    reloadPage() {
      window.location = window.location.pathname;
    },
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
    initiatePaymentUrl() {
      return `/${this.eventEdition.slug}/purchase-items/${this.purchaseItemId}/initiate`;
    },
    successUrl() {
      return `/${this.eventEdition.slug}/purchase-items/${this.purchaseItemId}/complete-payment`;
    },
    failureUrl() {
      return `/${this.eventEdition.slug}/purchase-items/${this.purchaseItemId}/log-failure`;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
