<template>
  <div class="profile-table-row">
    <slot></slot>
  </div>

</template>

<script>
export default {
  props: {
    draggable: Boolean,
  },
};
</script>
