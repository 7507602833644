<template>
  <label class="form-input" :class="{'form-input--invalid': invalid}">
    <FormLabel v-bind="{ label, required }" />
    <div v-if="oldProxy">
      <img class="form-input__preview" :src="oldProxy"/>
    </div>
    <div v-for="file in files" :key="file.name">
      <img class="form-input__preview" :src="window.URL.createObjectURL(file)"/>
      {{ file.name }}
    </div>
    <button
      v-if="!oldProxy && (!files || files.length == 0)"
      type="button"
      class="button button--secondary"
      @click="open"
      >
      {{ button }}
    </button>
    <button
      v-else
      type="button"
      class="button button--secondary"
      @click="clear"
      >
      {{ __('buttons.clear') }}
    </button>
    <FormError :errors="errors" />
  </label>
</template>

<script>
import { useFileDialog } from '@vueuse/core';

import { computed } from 'vue';

export default {
  props: {
    default: {
      required: false,
      default: '',
    },
    name: {
      required: true,
      type: String,
    },
    button: {
      required: false,
      type: String,
      default: 'Choose Field',
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    accept: {
      type: String,
      default: '*',
    },
    old: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // eslint-disable-next-line
    const { files, open, reset } = useFileDialog({
      multiple: props.multiple,
      accept: props.accept,
    });
    return { files, open, reset };
  },
  data() {
    return {
      modelValue: this.default,
      window,
      oldProxy: this.old,
    };
  },
  inject: ['feForm'],
  computed: {
    id() {
      return `${this.feForm.name}-${this.name}`;
    },
    errors() {
      return this.feForm.error(this.name).value || [];
    },
    invalid() {
      return this.errors.length > 0;
    },
  },
  mounted() {
    this.feForm.register(
      this.name,
      computed(() => {
        if (this.multiple) {
          return this.files;
        }
        return this.files ? this.files[0] : null;
      }),
    );
    this.feForm.register(`${this.name}_changed`, computed(() => !this.oldProxy));
  },
  methods: {
    clear() {
      this.oldProxy = null;
      this.reset();
    },
  },
};

</script>
