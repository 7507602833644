<template>
  <div class="fe-statistic-card">
    <div class="fe-statistic-card__icon" :style="iconStyle">
      <i :class="icon"></i>
    </div>
    <div class="fe-statistic-card__text-holder">
      <div class="fe-statistic-card__header">{{ header }}</div>
      <div class="fe-statistic-card__value">
        {{ value }}
        <small v-if="valueOfTotal">
          / {{ valueOfTotal }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeStatisticCard',
  props: {
    colorVar: String,
    icon: String,
    header: String,
    value: [String, Number],
    valueOfTotal: {
      type: [String, Number],
      required: false,
    },
  },
  computed: {
    iconStyle() {
      if (!this.colorVar) {
        return '';
      }
      return `--bg-color: var(--${this.colorVar})`;
    },
  },
};
</script>
