<template>
  <div>
    <div class="registration-not-active">
      <h2 class="registration--title">{{ form.external_title }}</h2>
      <p class="registration--description">
        {{ form.description }}
      </p>
      <p class="registration--description">
        {{ __('registrations.form.screens.not_active.description') }}
      </p>
    </div>
    <RegistrationFooter></RegistrationFooter>
  </div>
</template>

<script>
import RegistrationFooter from '~/Components/Frontend/Registration/Screens/RegistrationFooter.vue';

export default {
  name: 'RegistrationNotActive',
  components: [
    RegistrationFooter,
  ],
  props: {
    form: {
      required: true,
      type: Object,
    },
  },
};
</script>
