<template>
  <div class="frontend-card" :id="generateCardId">
    <div v-if="hasHeader" class="frontend-card__header" @click="toggleExpanded">
      <h3 class="frontend-card__title"> {{ title }} </h3>
      <button v-if="expandable" class="button-icon">
        <i v-if="expanded" class="far fa-chevron-up"></i>
        <i v-else class="far fa-chevron-down"></i>
      </button>
    </div>
    <div v-if="expanded" class="frontend-card__body">
        <slot>Content Goes Here</slot>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'FrontendCard',
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    title: String,
    expandable: {
      type: Boolean,
      default: true,
    },
    collapsed: {
      type: Boolean,
    },
    cardId: {
      type: String,
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    preventCollapse() {
      return this.editMode;
    },
    generateCardId() {
      return this.cardId ?? _.camelCase(this.title);
    },
  },
  data() {
    return {
      expanded: !this.collapsed,
    };
  },
  methods: {
    toggleExpanded() {
      if (this.expandable && !this.preventCollapse) {
        this.expanded = !this.expanded;
      }
    },
  },
};
</script>
