<template>
  <label class="form-input" style="position: relative">
    <FormLabel v-if="label" v-bind="labelAttrs" />
    <TransitionGroup name="t-fade" tag="div" class="t-fade__container">
      <div v-for="(value, index) in modelValue" :key="value.id">
        <div style="display: flex; gap: 0.5rem; position: relative">
          <select
            v-model="modelValue[index].type"
            class="form-input__input"
            @change="feForm.clearErrors(name)"
            style="margin-bottom: 16px; flex: 0 0 140px"
            >
            <option disabled></option>
            <option value="external"> {{ __('crm.link.type.website') }} </option>
            <option value="facebook">Facebook</option>
            <option value="linkedin">LinkedIn</option>
            <option value="instagram">Instagram</option>
            <option value="youtube">Youtube</option>
            <option value="twitter">Twitter</option>
          </select>
          <input
            v-model="modelValue[index].url"
            class="form-input__input"
            v-bind="inputAttrs"
            @input="feForm.clearErrors(name)"
            style="padding-right: 48px; margin-bottom: 16px"
            placeholder="https://www.google.com"
            />
          <button
            type="button"
            class="button button--link"
            style="color: red; position: absolute; right: 0; top: 16px; right: 16px;"
            @click="modelValue.splice(index, 1); removeError(index)"
            >
            <i class="far fa-trash-can"></i>
          </button>
        </div>
        <div>
          <FormError :errors="errors[index] ? Object.values(errors[index]).flat() : []" />
        </div>
      </div>
    </TransitionGroup>
    <p v-if="modelValue.length == 0" class="text-warning">
      {{ __('ui.noItemsToDisplay') }}
    </p>
    <button type="button" class="button button--link" @click="addLink">
      <span v-html="__('ui.add')"></span>
    </button>
  </label>
</template>

<script>

import { computed } from 'vue';

function addId(value) {
  return {
    // Add symbol id as need something to key v-for
    id: Symbol('linkId'),
    type: value ? value.type : '',
    url: value ? value.url : '',
  };
}

function newItem() {
  return addId();
}

export default {
  props: {
    /**
     * @typedef { url: String, type: Sting } Link
     *
     * @prop Link[] value
     */
    value: {
      type: Array,
      required: false,
    },
    name: {
      required: true,
      type: String,
    },
    label: {
      required: false,
      type: String,
    },
    icon: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      modelValue: this.value && this.value.length
        ? this.value.map(addId)
        : [newItem()],
    };
  },
  inject: ['feForm'],
  computed: {
    id() {
      return `${this.feForm.name}-${this.name}`;
    },
    errors() {
      return this.feForm.error(this.name).value || [];
    },
    invalid() {
      return this.errors.length > 0;
    },
    labelAttrs() {
      const attrs = {
        icon: this.icon,
        label: this.label,
      };

      if ('required' in this.$attrs) {
        attrs.required = this.$attrs.required;
      }

      return attrs;
    },
    inputAttrs() {
      const attrs = { ...this.$attrs };
      // Removing required from html as backend validation is nicer
      if ('required' in attrs) {
        delete attrs.required;
      }

      attrs.id = this.id;
      attrs.name = this.name;
      return attrs;
    },
  },
  methods: {
    addLink() {
      this.modelValue.push(newItem());
    },
    removeError(index) {
      this.feForm.removeError(this.name, index);
    },
  },
  mounted() {
    this.feForm.register(
      this.name,
      computed(() => this.modelValue.map(({ type, url }) => ({ type, url }))),
    );
  },
};
</script>

<style>
.t-fade__container {
  position: relative;
}
</style>
