<template>
  <component
    v-if="currentRoute"
    :is="currentView"
    v-bind="parameters"
    :key="currentPath"
    @closed="clearHash"
    :form="form"
    :registration-questions-route="registrationQuestionsRoute"
    :registration-save-route="registrationSaveRoute"
    :registration-action-route="registrationActionRoute"
    :registration-sponsorship-placement-route="registrationSponsorshipPlacementRoute"
    :countries="countries"
  ></component>
</template>

<script>
import _ from 'lodash';
import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import useRegistrationStore from '~/Store/Registration';
import RegistrationWelcome from '~/Components/Frontend/Registration/Screens/RegistrationWelcome.vue';
import RegistrationProcess from '~/Components/Frontend/Registration/Screens/RegistrationProcess.vue';
import RegistrationComingSoon from '~/Components/Frontend/Registration/Screens/RegistrationComingSoon.vue';
import RegistrationClosed from '~/Components/Frontend/Registration/Screens/RegistrationClosed.vue';
import RegistrationThankyou from '~/Components/Frontend/Registration/Screens/RegistrationThankyou.vue';
import RegistrationNotActive from '~/Components/Frontend/Registration/Screens/RegistrationNotActive.vue';
import RegistrationAlert from '~/Components/Frontend/Registration/Screens/RegistrationAlert.vue';

const routes = {
  '/welcome': RegistrationWelcome,
  '/process': RegistrationProcess,
  '/process/{registrationId}': RegistrationProcess,
  '/process/{registrationId}/{questionId}': RegistrationProcess,
  '/coming-soon': RegistrationComingSoon,
  '/not-active': RegistrationNotActive,
  '/closed': RegistrationClosed,
  '/thank-you': RegistrationThankyou,
  '/alert': RegistrationAlert,
};

function routeToPreg(route) {
  return new RegExp(`^#${route.replaceAll(/{(.*?)}/g, '([^/]*?)')}$`);
}

function routeToNames(route) {
  return [...route.matchAll(/{(.*?)}/g)].map((match) => match[1]);
}

export default {
  name: 'RegistrationFlow',
  props: {
    form: {
      type: Object,
    },
    countries: Array,
    registrationQuestionsRoute: {
      type: String,
      required: true,
    },
    registrationSaveRoute: {
      type: String,
      required: true,
    },
    registrationSponsorshipPlacementRoute: {
      type: String,
      required: true,
    },
    registrationActionRoute: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useRegistrationStore();
    const { getAlert } = storeToRefs(store);
    watch(getAlert, () => {
      if (getAlert.value.message) {
        window.location.hash = '/alert';
      }
    });
  },
  data() {
    return {
      currentPath: '',
    };
  },
  created() {
    this.validate();
  },
  computed: {
    compiledRoutes() {
      const permittedRoutes = routes;
      return Object.entries(permittedRoutes).map(([route, view]) => ({
        route, view, preg: routeToPreg(route),
      }));
    },
    currentRoute() {
      return this.compiledRoutes.find((route) => this.currentPath.match(route.preg));
    },
    currentView() {
      return this.currentRoute ? this.currentRoute.view : null;
    },
    parameters() {
      const matches = this.currentPath.match(this.currentRoute.preg);
      const paramNames = routeToNames(this.currentRoute.route);
      const parameters = {};
      for (let i = 0; i < paramNames.length; i++) {
        parameters[paramNames[i]] = matches[i + 1];
      }
      return parameters;
    },
  },
  methods: {
    clearHash() {
      if (this.currentView) {
        window.history.pushState(null, null, ' ');
        this.currentPath = '';
      }
    },
    setHash() {
      const { hash } = window.location;
      const isMatch = this.compiledRoutes.some((route) => hash.match(route.preg));
      if (isMatch) {
        this.currentPath = hash;
      }
    },
    validate() {
      if (this.form.is_closed) {
        window.location.hash = '/closed';
      } else if (this.form.is_upcoming) {
        window.location.hash = '/coming-soon';
      } else if (!this.form.is_open) {
        window.location.hash = '/not-active';
      } else if (this.form.is_available && !_.startsWith(window.location.hash, '#/process')) {
        window.location.hash = '/welcome';
      }
    },
  },
  mounted() {
    this.setHash();
    window.addEventListener('hashchange', () => {
      this.setHash();
    });
  },
};
</script>
