<template>
  <InfoPanel>
    <InfoPanelItem v-if="faqs.length > 0" >
      <FeAccordion :header="__('ui.faq')">
        <AccordionItem v-for="faq in faqs" :label="faq.question" :key="faq.question">
          <div v-html="faq.answer"></div>
        </AccordionItem>
      </FeAccordion>
    </InfoPanelItem>
    <InfoPanelItem>
      <NeedHelpItem></NeedHelpItem>
    </InfoPanelItem>
  </InfoPanel>
</template>

<script>
export default {
  props: {
    faqs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
