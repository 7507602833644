<template>
  <MeetingChatRow
    type="MeetingCancelledChat"
    :preMessage="preMessage"
    :message="meeting.cancelled_message"
    :when="chat.when"
    :sender="sender"
    :sentByYou="sentByYou"
    :read="chat.read"
    important
  >
    <template #chip>
      <div class="chip chip--small chip--alert">
        <i class="far fa-info-circle"></i>
        {{ __('conversations.chips.cancelled') }}
      </div>
    </template>
  </MeetingChatRow>
</template>
<script>

import MeetingChatRow from './MeetingChatRow.vue';

export default {
  components: { MeetingChatRow },
  props: {
    chat: {
      type: Object,
      required: true,
    },
    sender: {
      type: Object,
      required: true,
    },
    invite: {
      type: Object,
      required: false,
    },
    meeting: {
      type: Object,
      required: false,
    },
    statusMessage: {
      type: String,
    },
    policy: {
      type: Object,
      required: false,
    },
    sentByYou: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    preMessage() {
      // eslint-disable-next-line no-underscore-dangle
      let message = this.__('conversations.cancelled_by', {
        sender: this.sender.display_name,
      });
      if (this.meeting.cancellation_reason !== 'rather-not-say') {
        message = `${message}<br>${this.statusMessage}`;
      }
      return message;
    },
  },
};

</script>
