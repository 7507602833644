<template>
  <BaseModal
    :title="`${__('company.company_preview_modal.title', {
      company_display_name: truncatedCompanyName,
      interpolation: { escapeValue: false },
    })}`"
    height="tall"
    startOpen
  >
    <div
      class="base-modal__container--wide"
      v-if="companyMeetingComingSoonEnabled && (!canMeetCompany) && (!yourCompany)"
    >
      <NotificationBar type="info" center>
        Available to meet soon
      </NotificationBar>
    </div>

    <template v-if="company.id">
      <div class="base-modal__container--wide">
        <GallerySlider v-if="companyGallery?.length > 0" :galleryImages="companyGallery" />
      </div>

      <div class="company-modal__logo-container">
        <img
          v-if="company.logo?.logo?.url"
          class="company-card__logo"
          :src="company.logo.logo.url"
          :alt="company.display_name + ' logo'"
        />
      </div>
      <h5 v-if="standLocationShort" class="company__stand">
        <i class="fa-regular fa-location-dot"></i>
        {{ standLocationShort }}
      </h5>

      <p v-if="company.tagline && company.tagline !== '.'">
        {{ company.tagline }}
      </p>

      <ModalExpandable
        :title="__('company.company_preview_modal.company_profile')"
        v-if="truncatedBio.length > 0"
      >
        <template v-if="isIframed">
          {{ company?.bio }}
        </template>
        <template v-else>
          {{ truncatedBio }}
          <a
            v-if="company.bio !== truncatedBio"
            :href="`/${eventEdition?.slug}/companies/${companyId}/?initialTab=about`"
            :target="isIframed ? '_blank' : '_self'"
          >
            {{ __(`company.company_preview_modal.read_more`) }}
          </a>
        </template>
      </ModalExpandable>

      <ModalExpandable
        v-if="companyContacts.length > 0"
        :title="__('company.company_preview_modal.people')"
      >
        <ul class="contact__colleagues">
          <li
            class="contact__colleague"
            v-for="contact in companyContacts"
            :key="contact.id"
          >
            <ContactAvatar :contact="contact" />
            <div class="contact__colleague-role">
              {{ contact.job_title }}
            </div>
          </li>
        </ul>
        <a
          :href="`/${eventEdition?.slug}/companies/${companyId}/?initialTab=people`"
          :target="isIframed ? '_blank' : '_self'"
        >
          {{ __(`company.company_preview_modal.view_people`) }}
        </a>
      </ModalExpandable>

      <ModalExpandable
        v-if="companyDemographics.length > 0"
        :title="__('company.company_preview_modal.activities')"
      >
        <ul class="company-modal__demographic">
          <template v-for="companyDemographic in companyDemographics">
            <template v-if="companyDemographic.length > 1">
              <li class="company-modal__demographic-heading" :key="companyDemographic.id">
                {{ companyDemographic[0].demographic.name }}
                <ul
                  class="company-modal__demographic"
                  :key="companyDemographic.id"
                >
                  <li
                    class="company-modal__demographic-value"
                    v-for="demographicValue in companyDemographic"
                    :key="demographicValue.id"
                  >
                    {{ demographicValue.full_name }}
                  </li>
                </ul>
              </li>
            </template>
            <template v-else>
              <li class="company-modal__demographic-value" :key="companyDemographic.id">
                {{ companyDemographic[0].demographic.name }}:
                <strong>{{ companyDemographic[0].name }}</strong>
              </li>
            </template>
          </template>
        </ul>
      </ModalExpandable>
    </template>
    <template v-else>
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>

    <template #footer>
      <div class="button-group">
        <a v-if="company.id"
          class="button"
          :href="`/${eventEdition?.slug}/companies/${companyId}/?initialTab=about`"
          :target="isIframed ? '_blank' : '_self'"
        >
          {{ __(`company.company_preview_modal.view_profile_page`) }}
        </a>
        <a
          v-if="canBookMeeting"
          class="button"
          :href="`#/companies/${companyId}/book-meeting`"
          :disabled="!canBookMeeting"
        >
          {{ __(`company.company_preview_modal.book_meeting`) }}
        </a>
        <button
          v-else-if="companyMeetingComingSoonEnabled && !yourCompany"
          class="button"
          disabled
        >
          {{ __(`company.company_preview_modal.book_meeting`) }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import GallerySlider from '~/Components/Frontend/GallerySlider.vue';
import { user, store } from '../../../Frontend/store';

export default {
  props: {
    companyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errored: false,
      company: {},
      standLocationShort: null,
      companyContacts: [],
      canMeetCompany: false,
      companyGallery: [],
      companyDemographics: [],
      companyMeetingComingSoonEnabled: false,
    };
  },
  components: {
    GallerySlider,
  },
  mounted() {
    this.loadData();
  },
  methods: {
    goToCompany() {
      window.location.href = `/${this.eventEdition.slug}/companies/${this.companyId}`;
    },
    loadData() {
      axios
        .get(this.previewUrl)
        .then(({ data }) => {
          this.standLocationShort = data.stand_location_short;
          this.companyGallery = data.gallery;
          this.companyDemographics = data.visible_demographic_values;
          this.companyContacts = data.contact_cards;
          this.company = data.company;
          this.canMeetCompany = data.can_meet_company;
          this.companyMeetingComingSoonEnabled = data.feature_flag_company_meetings_coming_soon;
        })
        .catch(() => {
          this.errored = true;
        })
        .then(() => {
          if (
            this.eventEdition.slug !== 'licensing-expo-2023'
            && this.eventEdition.slug !== 'ble-2023'
          ) {
            return;
          }

          if (!document.body.classList.contains('env-prod')) {
            return;
          }

          const digitalData = {
            pagedata: {
              analyticsreportsuitID: this.eventEdition.slug === 'licensing-expo-2023' ? 'informalicensingexpo' : 'informabrandlicensing',
              analyticsSection: 'Matchmaking platform',
              analyticsSubSection: 'exhibitor-directory',
              analyticsSubSectionOne: 'exhibitors',
              analyticsSubSectionTwo: '',
              analyticsPageName: `${this.company.display_name}|${this.company.id}`,
              analyticssitename: '',
              analyticsPageURL: this.previewUrl,
            },
          };

          const event = new CustomEvent('mml-new-page', digitalData);
          // the directory must be called "exhibitor-list.html" for this to work
          // it is a rule from the client in their tracker
          window.dispatchEvent(event);
          // eslint-disable-next-line no-underscore-dangle
          window._satellite.track('mml-new-page');
        });
    },
  },
  computed: {
    yourCompany() {
      return user && user.activeContact.company_id === this.company.id;
    },
    eventEdition() {
      return store.eventEdition;
    },
    initials() {
      return this.company?.display_name
        .split(' ')
        .map((n) => n[0])
        .join('');
    },
    truncatedCompanyName() {
      return _.trim(
        _.truncate(this.company?.display_name, { length: 30, separator: ' ' }),
      );
    },
    truncatedBio() {
      return _.trim(_.truncate(this.company?.bio, { length: 250, separator: ' ' }));
    },
    isIframed() {
      return window.location.pathname.includes('iframe/company-directories');
    },
    previewUrl() {
      if (this.isIframed) {
        return `${window.location.pathname}/companies/${this.companyId}/preview`;
      }
      return `/${this.eventEdition?.slug}/companies/${this.companyId}/preview`;
    },
    canBookMeeting() {
      return this.company.id
        && !this.isIframed
        && this.eventEdition?.enable_meetings
        && this.eventEdition?.enable_book_contact_meetings_on_company
        && this.canMeetCompany;
    },
  },
};
</script>
