<template>
  <div class="fe-loading">
    <div
      class="fe-loading__ball"
      v-for="ball in 4"
      :key="ball"
      :style="{'--ball-number': ball }"
      ></div>
  </div>
</template>
