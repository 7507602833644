<template>
  <div v-if="errors.length > 0">{{ errors }}</div>
  <FeLoading v-if="loading" />
  <div class="saved-search-card-holder">
    <div v-if="searches.length > 0">
      <SavedSearchCard
        v-for="search in searches"
        :key="search.id"
        :search="search"
        @deleted="load"
        @update="update"
      />
    </div>
    <div v-else class="inbox__no-results" style="text-align:center;">
      <h1>
        <i class="fa-light fa-circle-check"></i> {{ __('directory.noSavedSearches.title') }}
      </h1>
      <p v-html="__('directory.noSavedSearches.message')">
      </p>
    </div>
  </div>
    <UpdateSearchModal
      :title="__('directory.edit_search')"
      :notificationFrequencies="notificationFrequencies"
      :searchId="searchId"
      :searchName="searchName"
      :directoryId="directoryId"
      v-model:visible="updateSearchOpen"
      @updated="load"
    />
</template>
<script>
import axios from 'axios';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import SavedSearchCard from '~/Components/Frontend/Core/Panels/SavedSearchCard.vue';
import UpdateSearchModal from './UpdateSearchModal.vue';

export default {
  components: {
    FeLoading,
    SavedSearchCard,
    UpdateSearchModal,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    notificationFrequencies: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      searches: [],
      updateSearchOpen: false,
      searchId: null,
      searchName: null,
      directoryId: null,
      errors: {},
    };
  },
  methods: {
    update(data) {
      this.searchId = data.searchId;
      this.searchName = data.searchName;
      this.directoryId = data.directoryId;
      this.updateSearchOpen = true;
    },
    load() {
      this.loading = true;

      axios.get(this.url)
        .then((response) => {
          this.searches = response.data.data;
          this.loading = false;
        })
        .catch(({ response }) => {
          this.errors = response?.data?.errors;
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>
