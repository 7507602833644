export default {
  'assets.image.image': 'Bild',
  'assets.image.name': 'Bildtitel',
  'assets.video.title': 'Videotitel',
  'assets.video.description': 'Videobeschreibung',
  'assets.video.title.default': 'Unbenanntes Video',
  'assets.video.video': 'Video',
  'buttons.add': 'Hinzufügen',
  'buttons.addMore': 'Mehr hinzufügen',
  'buttons.back': 'Zurück',
  'buttons.cancel': 'Abbrechen',
  'buttons.change': 'Ändern',
  'buttons.clear': 'Leeren',
  'buttons.close': 'Schließen',
  'buttons.contact': 'Kontakt',
  'buttons.delete': 'Löschen',
  'buttons.done': 'Fertig',
  'buttons.edit': 'Bearbeiten',
  'buttons.help': 'Hilfe',
  'buttons.impersonateUser': 'Verbinden als',
  'buttons.manage': 'Verwalten',
  'buttons.manageImages': 'Bilder verwalten',
  'buttons.new': 'Neu',
  'buttons.next': 'Weiter',
  'buttons.return': 'Zurückkehren',
  'buttons.save': 'Speichern',
  'buttons.sendLink': 'Link senden',
  'buttons.set': 'Erstellen',
  'buttons.set_password': 'Passwort festlegen',
  'buttons.update': 'Aktualisieren',
  'buttons.uploadImage': 'Bild hochladen',
  'buttons.locked': 'Gesperrt',
  'buttons.write_with_ai': 'Mit KI schreiben',
  'buttons.recreate_with_ai': 'Mit KI neu erstellen',
  'buttons.notifications': 'Benachrichtigungen',
  'buttons.run_search': 'Ausführen',
  'buttons.show_more': 'Mehr anzeigen',
  'buttons.show_less': 'Weniger anzeigen',
  'action.unfavourite': 'Entfavorisieren',
  'action.favourite': 'Favorisieren',
  'action.unhide': 'Einblenden',
  'action.hide': 'Ausblenden',
  'catalogue.catalogueEntry.description': 'Beschreibung',
  'catalogue.catalogueEntry.gallery': 'Bilder',
  'catalogue.catalogueEntry.highlights': 'Highlights',
  'catalogue.catalogueEntry.image': 'Bild',
  'catalogue.catalogueEntry.keywords': 'Schlüsselwörter',
  'catalogue.catalogueEntry.links': 'Links',
  'catalogue.catalogueEntry.moreOptions': 'Weitere Optionen',
  'catalogue.catalogueEntry.name': 'Titel',
  'catalogue_product_form.add_image_success': 'Sie haben erfolgreich ein Bild zu :filename hinzugefügt, es wird nach einem Seiten-Refresh sichtbar sein.',
  'catalogue_product_form.delete': 'Löschen',
  'catalogue_product_form.description': 'Beschreibung*:',
  'catalogue_product_form.edit': 'Bearbeiten',
  'catalogue_product_form.highlights.add': 'Höhepunkt hinzufügen',
  'catalogue_product_form.highlights.no_highlights': 'Keine Höhepunkte hinzugefügt',
  'catalogue_product_form.highlights.placeholder': '',
  'catalogue_product_form.highlights.title': 'Höhepunkte',
  'catalogue_product_form.image': 'Produktbild*',
  'catalogue_product_form.images': 'Bilder',
  'catalogue_product_form.keywords': 'Schlüsselwörter',
  'catalogue_product_form.keywords_descriptions': "Trennen Sie mehrere Schlüsselwörter mit einem Komma ','",
  'catalogue_product_form.name': 'Name*:',
  'catalogue_product_form.order': 'Reihenfolge:',
  'catalogue_product_form.order_description': 'Die Reihenfolge ist aufsteigend (1 wird vor 2 aufgelistet).',
  'catalogue_product_form.recommended_image_size': 'Empfohlene Größe 1280px mal 720px (Bilder werden im 16:9-Format angezeigt). Maximale Dateigröße 5 MB',
  'catalogue_product_form.remove_image_success': 'Sie haben erfolgreich ein Bild zu :filename hinzugefügt, es wird nach einem Seiten-Refresh sichtbar sein.',
  'catalogue_product_form.save': 'Speichern',
  'catalogue_product_form.save_then_upload': 'Bitte speichern Sie und klicken Sie dann auf Bearbeiten, um Bilder hochzuladen.',
  'catalogue_product_form.subtitle': 'Untertitel*:',
  'catalogue_product_form.tagline': 'Slogan*:',
  'catalogue_product_form.title.create': 'Erstellen {{ type }}',
  'catalogue_product_form.title.update': 'Bearbeiten {{ type }}',
  'catalogue_product_form.update': 'Speichern',
  'catalogue_product_form.update_photo': 'Foto bearbeiten',
  'catalogue_product_form.upload_photo': 'Foto hochladen',
  'catalogue_product_preview.buttons.view': 'Ansehen',
  'catalogue_product_preview.description': 'Beschreibung',
  'catalogue_product_preview.highlights': 'Höhepunkte',
  'catalogue_product_preview.learn_more': 'Mehr erfahren',
  'catalogue_product_preview.more': 'Mehr',
  'catalogue_product_preview.read_more': 'Mehr lesen',
  'catalogue_product_preview.show_less': 'Weniger anzeigen',
  'catalogue_product_preview.view_product': 'Produkt ansehen',
  'company.actions.business_card_dropped': 'Visitenkarte geteilt',
  'company.actions.drop_business_card': 'Visitenkarte teilen',
  'company.company_book_meeting_modal.cancel': 'Abbrechen',
  'company.company_book_meeting_modal.cannot_meet': 'Sie können keine Kontakte bei diesem Unternehmen treffen',
  'company.company_book_meeting_modal.meet': 'Treffen',
  'company.company_book_meeting_modal.more': 'Mehr',
  'company.company_book_meeting_modal.next': 'Weiter →',
  'company.company_book_meeting_modal.other_contacts': 'Andere Kontakte',
  'company.company_book_meeting_modal.recommended_contacts': 'Empfohlen',
  'company.company_book_meeting_modal.select_contact': 'Wählen Sie einen Kontakt aus, den Sie treffen möchten:',
  'company.company_book_meeting_modal.title': 'Meeting buchen',
  'company.company_preview_modal.activities': 'Aktivitäten & Interessen',
  'company.company_preview_modal.book_meeting': 'Meeting buchen',
  'company.company_preview_modal.company_profile': 'Firmprofil',
  'company.company_preview_modal.people': 'Personen',
  'company.company_preview_modal.read_more': 'Mehr lesen',
  'company.company_preview_modal.title': '{{ company_display_name }}',
  'company.company_preview_modal.view_people': 'Alle Kontakte anzeigen →',
  'company.company_preview_modal.view_profile_page': 'Profilseite anzeigen',
  'company.drop_business_card.agreement': 'Ich erteile hiermit die Zustimmung, dass mein <strong>Name, Titel und E-Mail-Adresse</strong> mit {{ company_display_name }} geteilt werden.',
  'company.drop_business_card.cancel': 'Abbrechen',
  'company.drop_business_card.disclaimer': 'Ich verstehe, dass meine Informationen von {{ company_display_name }} gespeichert werden und dass ich {{ company_display_name }} kontaktieren muss, um meine Kommunikationspräferenzen zu ändern.',
  'company.drop_business_card.header': 'Teilen Sie Ihre Kontaktinformationen',
  'company.drop_business_card.share': 'Ich stimme zu',
  'company.drop_business_card.share_success.done': 'Erledigt',
  'company.drop_business_card.share_success.message': 'Ihr Name, Titel, E-Mail-Adresse und Telefonnummer wurden mit {{ company_display_name }} geteilt',
  'company.drop_business_card.share_success.title': 'Details geteilt',
  'company_card.view_company': 'Firma anzeigen',
  'concierge_modal.info_text': 'Kontaktieren Sie das Concierge-Team der Veranstaltung mit Fragen und Hilfe zu Ihren Meetings.',
  'concierge_modal.link_text': 'Brauchen Sie Hilfe?',
  'concierge_modal.whatsapp_button': 'Auf WhatsApp chatten',

  // Conference sessions
  'conference_sessions.ask_a_question.box_closing': 'Dieses Fenster wird automatisch geschlossen.',
  'conference_sessions.ask_a_question.buttons.ask': 'Eine Frage stellen',
  'conference_sessions.ask_a_question.buttons.cancel': 'Abbrechen',
  'conference_sessions.ask_a_question.buttons.send': 'Senden',
  'conference_sessions.ask_a_question.description': 'Der Sitzungsmoderator wird Ihre Frage überprüfen',
  'conference_sessions.ask_a_question.header': 'Eine Frage stellen',
  'conference_sessions.ask_a_question.placeholder': 'Meine Frage...',
  'conference_sessions.ask_a_question.question_submitted': 'Ihre Frage wurde eingereicht.',
  'conference_sessions.preview_session.speakers': 'Sprecher',
  'conference_sessions.preview_session.title': 'Konferenz-Session',
  'conference_sessions.row.buttons.add_to_schedule': 'Zum Plan hinzufügen',
  'conference_sessions.row.buttons.join_session': 'Session beitreten',
  'conference_sessions.row.buttons.more': 'Mehr',
  'conference_sessions.row.buttons.preview_session': 'Vorschau',
  'conference_sessions.row.buttons.remove_from_schedule': 'Vom Plan entfernen',
  'conference_sessions.row.buttons.schedule_clash': 'Zeitplan-Konflikt',
  'conference_sessions.row.buttons.booking_capacity_full': 'Kapazität voll',
  'conference_sessions.row.buttons.watch_now': 'Jetzt ansehen',
  'conference_sessions.row.session': 'Sitzung',
  'conference_sessions.row.sponsored_by': 'Gesponsert von',
  'conference_sessions.row.starting_soon': 'Beginnt bald',

  'conversations.accepted_by': 'Meeting-Einladung angenommen von {{ sender }}',
  'conversations.buttons.send_invite': 'Einladung senden',
  'conversations.cancelled_by': 'Meeting-Einladung storniert von {{ sender }}',
  'conversations.chips.cancelled': 'Meeting storniert',
  'conversations.chips.colleague_added': 'Kollege hinzugefügt',
  'conversations.chips.confirmed': 'Meeting bestätigt',
  'conversations.chips.declined': 'Einladung abgelehnt',
  'conversations.chips.forwarded': 'Einladung weitergeleitet',
  'conversations.chips.new_invite': 'Meeting-Einladung',
  'conversations.chips.updated': 'Meeting aktualisiert',
  'conversations.chips.withdrawn': 'Einladung zurückgezogen',
  'conversations.colleague_added': '{{ added_by }} hat {{ new_colleague }} zum Meeting hinzugefügt.',
  'conversations.declined_by': 'Meeting-Einladung abgelehnt von {{ sender }}',
  'conversations.forwarded_to': 'Meeting-Einladung weitergeleitet an {{ recipient }}',
  'conversations.invitation_from': 'Meeting-Einladung von {{ sender }}',
  'conversations.view_meeting': 'Meeting anzeigen',
  'conversations.withdrawn_by': 'Meeting-Einladung zurückgezogen von {{ sender }}',
  'countdown.days': 'Tage',
  'countdown.hours': 'Stunden',
  'countdown.minutes': 'Minuten',
  'countdown.seconds': 'Sekunden',
  'crm.communicationPreference.channels.email': 'Benachrichtigungs- und Erinnerungsemails',
  'crm.communicationPreference.channels.phone': 'Unterstützende telefonische Kommunikation',
  'crm.communicationPreference.channels.sms': 'Benachrichtigungs- und Erinnerungstexte (SMS)',
  'crm.communicationPreference.name': 'Präferenzen',
  'crm.company.bio': 'Detaillierte Beschreibung Ihrers Unternehmens',
  'crm.company.displayName': 'Anzeigename',
  'crm.company.legalName': 'Rechtlicher Name',
  'crm.company.logo': 'Firmenlogo',
  'crm.company.officeLocation': 'Standort',
  'crm.company.officeLocations': 'Standorte',
  'crm.company.socials': 'Soziale Medien',
  'crm.company.tagline': 'Kurze Beschreibung Ihres Unternehmens',
  'crm.company.websites': 'Webseiten',
  'crm.companyOfficeLocation.address': 'Adresse',
  'crm.companyOfficeLocation.country': 'Land',
  'crm.contact.avatar': 'Profilfoto',
  'crm.contact.bio': 'Über mich',
  'crm.contact.country': 'Land',
  'crm.contact.email': 'E-Mail',
  'crm.contact.firstName': 'Vorname',
  'crm.contact.fullName': 'Name',
  'crm.contact.jobTitle': 'Berufsbezeichnung',
  'crm.contact.lastName': 'Nachname',
  'crm.contact.lookingFor': 'Suchen nach',
  'crm.contact.managedBy': 'Profilverwalter',
  'crm.contact.optouts': 'Opt-outs',
  'crm.contact.timezone': 'Zeitzone',
  'crm.contact.title': 'Titel',
  'crm.link.type.website': 'Webseite',
  'crm.link.url': 'URL',
  'crm.phone.types.mobile': 'Handynummer',
  'crm.phone.types.office': 'Büronummer',
  'directory.save_search': 'Suche speichern',
  'directory.search_last_run': 'Letzte Suche: {{ date }}',
  'directory.search_name': 'Suchname',
  'directory.saved_search_success': 'Suche erfolgreich gespeichert.',
  'directory.updated_search_success': 'Suche erfolgreich aktualisiert.',
  'directory.export_wishlist': 'Herunterladen',
  'directory.filters.apply': 'Anwenden',
  'directory.filters.clearAll': 'Alle löschen',
  'directory.filters.close': 'Filter schließen',
  'directory.filters.filters': 'Sortieren & filtern',
  'directory.filters.open': 'Sortieren & filtern',
  'directory.filters.primary.select_one_option': 'Wählen Sie <strong>eine</strong> Option:',
  'directory.filters.reset': 'Zurücksetzen',
  'directory.noResults.message': 'Wir konnten keine Ergebnisse finden, versuchen Sie Ihre Suche zu verfeinern',
  'directory.noResults.title': 'Keine Ergebnisse',
  'directory.noFavourites.message': 'Sie haben noch keine Favoriten.',
  'directory.noFavourites.title': 'Keine Favoriten',
  'directory.noRecommendations.message': 'Keine Ergebnisse anzuzeigen.',
  'directory.noRecommendations.title': 'Keine Ergebnisse',
  'directory.pagination.load_more': 'Weitere Ergebnisse laden',
  'directory.text_search': 'Suche: {{ value }}',
  'directory.initial_search': 'Initial: {{ value }}',
  'directory.noSavedSearches.message': 'Keine gespeicherten Suchanfragen. Beginnen Sie zu <a href="?initialTab=search">suchen ...</a>',
  'drop_image.buttons.cancel': 'Abbrechen',
  'drop_image.buttons.clear': 'Löschen',
  'drop_image.buttons.remove': 'Entfernen',
  'drop_image.buttons.submit': 'Einreichen',
  'drop_image.preview_text': 'Vorschau von {{ image_name }}',
  'drop_image.text': 'Ziehen und ablegen oder hier klicken',
  'event_editions.card.coming_soon': 'Kommt bald',
  'event_editions.card.event_closed': 'Veranstaltung geschlossen',
  'event_editions.card.launch': 'Veranstaltung starten',
  'event_editions.card.notify_me': 'Benachrichtige mich',
  'event_editions.card.reg_done': 'Sie haben sich für diese Veranstaltung registriert',
  'event_editions.card.reg_open': 'Anmeldung jetzt offen',
  'event_editions.card.reg_soon': 'Anmeldung kommt bald',
  'event_editions.card.register': 'Jetzt registrieren!',
  'expanded_contact.buttons.accept_invite': 'Annehmen',
  'expanded_contact.buttons.book_meeting': 'Treffen buchen',
  'expanded_contact.buttons.decline_invite': 'Ablehnen',
  'expanded_contact.buttons.forward_to_colleague': 'Weiterleiten',
  'expanded_contact.buttons.withdraw_invite': 'Einladung zurückziehen',
  'expanded_contact.buttons.match': 'Übereinstimmen',
  'expanded_contact.buttons.unmatch': 'Übereinstimmung aufheben',
  'expanded_contact.meeting.addColleague.button': 'Kollegen hinzufügen',
  'expanded_contact.meeting.addColleague.noColleagues': 'Keine Kollegen verfügbar',
  'expanded_contact.meeting.addColleague.subheader': 'Kollegen hinzufügen',
  'expanded_contact.meeting.back': 'Zurück zu den Details des Meetings',
  'expanded_contact.meeting.cancel.button': 'Stornierung senden',
  'expanded_contact.meeting.cancel.subheader': 'Ich kann an diesem Meeting nicht mehr teilnehmen',
  'expanded_contact.meeting.cancelledHeader': 'Meeting storniert',
  'expanded_contact.meeting.contactNumber': 'Handynummer: ',
  'expanded_contact.meeting.forwardToColleague.button': 'Kollegen auswählen',
  'expanded_contact.meeting.forwardToColleague.subheader': 'Meeting an einen Kollegen weiterleiten',
  'expanded_contact.meeting.informationHeader': 'Meeting-Informationen',
  'expanded_contact.meeting.informationSubheader': 'Wir senden 10 Minuten vor Beginn des Meetings eine SMS-Erinnerung.',
  'expanded_contact.meeting.inviteColleagues.button': 'Kollegen einladen',
  'expanded_contact.meeting.inviteColleagues.subheader': 'Meeting an einen Kollegen weiterleiten',
  'expanded_contact.meeting.missingContactNumber': 'Bitte fügen Sie eine Handynummer hinzu',
  'expanded_contact.meeting.participants': 'Teilnehmer',
  'expanded_contact.meeting.reschedule.button': 'Meeting neu planen',
  'expanded_contact.meeting.reschedule.subheader': 'Datum, Uhrzeit oder Ort des Meetings ändern',
  'expanded_contact.show.about.company.title': 'Firmeninformation',
  'expanded_contact.show.about.contact.country': 'Land',
  'expanded_contact.show.about.contact.looking_for': 'Sucht nach',
  'expanded_contact.show.about.contact.title': 'Über mich',
  'expanded_contact.show.colleagues': 'Kollegen',
  'expanded_contact.show.errors.cannot_load': 'Konnte Kontaktinformationen nicht laden.',
  'expanded_contact.show.other_contacts': 'Andere Kontakte bei {{ company_display_name }}',
  'expanded_contact.show.respond': 'Antworten',
  'expanded_contact.show.send_invite': 'Einladung senden',
  'expanded_contact.show.view_invite': 'Einladung anzeigen',
  'expanded_contact.show.view_meeting': 'Meeting anzeigen',
  'expanded_contact.show.view_profile': 'Profil anzeigen',

  // Exclusive events
  'exclusive_events.no_description': 'Es gibt keine Beschreibung für diese Veranstaltung.',
  'exclusive_events.row.buttons.booking_capacity_full': 'Kapazität voll',
  'exclusive_events.row.buttons.booking_add_to_schedule': 'Zum Zeitplan hinzufügen',
  'exclusive_events.row.buttons.booking_schedule_clash': 'Zeitkonflikt',
  'exclusive_events.row.buttons.booking_remove_from_schedule': 'Vom Zeitplan entfernen',

  'filter_modal.cancel': 'Abbrechen',
  'filter_modal.save_update': 'Speichern & aktualisieren',
  'form.image.changeTip': '<a href=\'#\'>Klicken zum Ändern</a> oder ziehen und ablegen. <br/>',
  'form.image.maxSize': 'Maximale Dateigröße 5MB',
  'form.image.tip': '<a href=\'#\'>Klicken zum Hochladen</a> oder ziehen und ablegen. <br/>',
  'form.image.unsupportedFileType': 'Ungültiger Dateityp. Verwenden Sie jpg, jpeg, png oder webp',
  'form.image.recommended_size': 'Empfohlene Größe: {{recommended_size}}',
  'form.phone.placeholder': 'Geben Sie Ihre Telefonnummer ein',
  'form.video.tip': '<a href=\'#\'>Klicken zum Hochladen</a> oder ziehen und ablegen. <br/>',
  'form.unsupportedFileTypePdf': 'Nicht unterstützter Dateityp.',
  'forms.select_all': '(alle)',
  'forms.toggle.on': 'Ein',
  'forms.toggle.off': 'Aus',
  'general.unknown_error': 'Entschuldigung, etwas ist schiefgelaufen. Bitte aktualisieren Sie Ihren Browser und versuchen Sie es erneut',

  // hubs general page
  'hubs.header.stand': 'Stand: {{ stand }}',
  'hubs.companyProfile': 'Firmenprofil',
  'hubs.definitions.confirmedMeetings': 'Bestätigte Meetings',
  'hubs.definitions.receivedPendingInvites': 'Offene eingegangene Meeting-Einladungen',
  'hubs.definitions.sentPendingInvites': 'Offene gesendete Meeting-Einladungen',
  'hubs.definitions.sentInvites': 'Gesendete Meeting-Einladungen (alle Stände)',

  // hubs event profile
  'hubs.eventProfile.activity.favourited': 'Favorisiert',
  'hubs.eventProfile.activity.hides': 'Verborgene',
  'hubs.eventProfile.activity.marketplacePerformance': 'Leistung',
  'hubs.eventProfile.activity.marketplacePerformanceScore': 'Top {{ score }}%',
  'hubs.eventProfile.activity.marketplaceViews': 'Ansichten',
  'hubs.eventProfile.activity.noData': 'Nicht genügend Daten',
  'hubs.eventProfile.activity.performance': 'Profilleistung',
  'hubs.eventProfile.activity.profileViews': 'Profilansichten',
  'hubs.eventProfile.catalogueActive': 'Einträge aktiv',
  'hubs.eventProfile.catalogueDescription': 'Nutzen Sie die Katalogverzeichnisse, um Ihre Produkte und Dienstleistungen zu bewerben.',
  'hubs.eventProfile.catalogueTitle': 'Katalogeinträge',
  'hubs.eventProfile.countPlays': '{{ count }} wiedergaben',
  'hubs.eventProfile.description': 'Verwalten Sie Ihr Profil und fördern Sie Ihre Präsenz auf der Veranstaltung.',
  'hubs.eventProfile.emptyList': 'Keine Einträge aufgeführt. Verwalten Sie Einträge in Ihrem <a href="#/my-account/company-profile">Firmenprofil <i class="fa "></i></a>',
  'hubs.eventProfile.link': 'Veranstaltungsprofil',
  'hubs.eventProfile.marketplaceActive': 'Aktiv',
  'hubs.eventProfile.marketplaceDescription': 'Bewerben Sie Ihre Produkte und Dienstleistungen über die Marktplatzverzeichnisse.',
  'hubs.eventProfile.marketplaceTitle': 'Marktplatz',
  'hubs.eventProfile.performanceScore': 'Top {{ score }}%',
  'hubs.eventProfile.title': 'Veranstaltungsprofil',
  'hubs.eventProfile.videos': 'Videos',
  'hubs.eventProfile.videosActive': 'Videos aktiv',
  'hubs.eventProfile.videosDescription': 'Verwalten Sie Ihre Werbevideos.',
  'hubs.eventProfile.videosTitle': 'Videos',
  'hubs.eventProfile.viewsPerDay': 'Profilansichten pro Tag',

  // hubs insights
  'hubs.insights.activity': 'Firmenprofil-Leistung',
  'hubs.insights.activity.favourited': 'Zu Favoriten hinzugefügt',
  'hubs.insights.activity.hides': 'Zu Verborgenen hinzugefügt',
  'hubs.insights.activity.profilePreviews': 'Profilvorschauen',
  'hubs.insights.activity.profileViews': 'Seitenaufrufe des Profils',
  'hubs.insights.activity.noData': 'nicht genügend Daten zum Anzeigen',
  'hubs.insights.description': 'Überprüfen Sie Ihre Veranstaltungsleistung.',
  'hubs.insights.meetings': 'Unternehmens-Meeting-Leistung',
  'hubs.insights.link': 'Einblicke',
  'hubs.insights.title': 'Einblicke',

  // hubs leads
  'hubs.leads.businessCardDropTable': 'Gekochte Visitenkarten',
  'hubs.leads.businessCardDropTableDescription': 'Die folgenden Personen haben ihre Visitenkarten auf Ihrem Unternehmensprofil abgelegt, um nachzuverfolgen.',
  'hubs.leads.description': 'Greifen Sie auf Ihre Leads und abgelegten Visitenkarten zu.',
  'hubs.leads.download': 'Leads herunterladen',
  'hubs.leads.downloadFormat': 'CSV / Excel',
  'hubs.leads.link': 'Leads',
  'hubs.leads.meetingsWith': 'Meetings mit {{ people }}',
  'hubs.leads.noDroppedBusinessCards': 'Es haben noch keine Kontakte ihre Visitenkarten abgelegt.',
  'hubs.leads.numberOfBusinessCardDrops': 'Visitenkarten',
  'hubs.leads.receivedInvitesFrom': 'Einladungen erhalten von {{ people }}',
  'hubs.leads.sentInvitesTo': 'Einladungen gesendet an {{ people }}',
  'hubs.leads.title': 'Lead-Management',

  // purchase items
  'hubs.purchase_items.available': 'Verfügbar',
  'hubs.purchase_items.emptyList': 'Keine Kaufartikel verfügbar.',
  'hubs.purchase_items.link': 'Upgrades & Angebote',
  'hubs.purchase_items.offers': 'Angebote',
  'hubs.purchase_items.out_of_stock': 'Nicht auf Lager',
  'hubs.purchase_items.product_not_available': 'Produkt nicht verfügbar',
  'hubs.purchase_items.purchased': 'Gekauft',
  'hubs.purchase_items.purchases': 'Käufe',
  'hubs.purchase_items.someAvailable': '{{ num }} verfügbar',
  'hubs.purchases.purchased_at': 'Gekauft am: ',
  'hubs.purchases.purchased_by': 'Gekauft von: {{ contact }}',

  // hubs meetings
  'hubs.meetings.confirmedMeetings': 'Meetings',
  'hubs.meetings.description': 'Überprüfen Sie die vorgeplanten Meeting-Aktivitäten Ihres Unternehmens.',
  'hubs.meetings.link': 'Meetings',
  'hubs.meetings.MeetingsByDayTitle': 'Meetings nach Tag',
  'hubs.meetings.MeetingsByLocationTitle': 'Meetings nach Standort',
  'hubs.meetings.performance': 'Meeting-Leistung',
  'hubs.meetings.performanceScore': 'Top {{score}}%',
  'hubs.meetings.receivedInvites': 'Ausstehende Einladungen',
  'hubs.meetings.sentInvites': 'Gesendete Einladungen',
  'hubs.meetings.teamHasMeetings': 'Hat Meetings',
  'hubs.meetings.teamHasPending': 'Hat ausstehende',
  'hubs.meetings.teamHasSent': 'Hat gesendet',
  'hubs.meetings.teamTitle': 'Meeting-Aktivität',
  'hubs.meetings.title': 'Meetings',
  'hubs.stats.average': 'Durchschnitt',
  'hubs.stats.belowAverage': 'Niedrig',

  // hubs meetings team schedules
  'hubs.meetings.teamSchedules.unavailable': 'Nicht verfügbar',
  'hubs.meetings.teamSchedules': 'Gemeinsame Teampläne',
  'hubs.meetings.teamSchedules.instructions': 'Wählen Sie einen Tag, um den Zeitplan eines Kontakts für einen bestimmten Zeitraum anzuzeigen.',
  'hubs.meetings.teamSchedules.bookings.empty': '{{sharer}} hat in diesem Zeitraum keine Buchungen.',
  'hubs.meetings.teamSchedules.combinedBookings.empty': 'Keine Buchungen in diesem Zeitraum.',

  // hubs team
  'hubs.team.activityChartSubtitle': 'letzte 60 Tage',
  'hubs.team.activityChartTitle': 'Teamaktivität - ',
  'hubs.team.badgeTable': 'Eventteam',
  'hubs.team.contactUpdatedPlatformProfile': 'Profile aktualisiert',
  'hubs.team.description': 'Überwachen Sie die Teamaktivität und -präsenz.',
  'hubs.team.lastLogin': 'zuletzt aktiv',
  'hubs.team.link': 'Team',
  'hubs.team.noOtherBadges': 'Keiner Ihrer Kollegen ist für das Event registriert.',
  'hubs.team.profilesActivated': 'Profile aktiviert',
  'hubs.team.profilesHidden': 'Profile ausgeblendet',
  'hubs.team.title': 'Eventteam',

  'hubs.tasks.link': 'Aufgaben',
  'hubs.tasks.title': 'Aufgaben',
  'hubs.tasks.taskProgress': 'Aufgabenfortschritt',
  'hubs.tasks.pendingTasksTitle': 'Ausstehende Aufgaben',
  'hubs.tasks.pendingCompanyTasksDescription': 'Die folgenden Aufgaben müssen von einem Vertreter Ihres Unternehmens erledigt werden.',
  'hubs.tasks.pendingContactTasksDescription': 'Die folgenden Aufgaben müssen von Ihnen erledigt werden.',
  'hubs.tasks.noPendingTasks': 'Es gibt keine Aufgaben zu erledigen.',
  'hubs.tasks.taskType.mandatory': 'Erforderlich',
  'hubs.tasks.taskType.important': 'Wichtig',
  'hubs.tasks.taskType.optional': 'Optional',
  'hubs.tasks.completedTasksTitle': 'Erledigte Aufgaben',
  'hubs.tasks.completedTasksDescription': 'Die folgenden Aufgaben wurden erledigt.',
  'hubs.tasks.nocompletedTasks': 'Sie haben keine erledigten Aufgaben.',
  'hubs.tasks.start': 'Starten',
  'hubs.tasks.view': 'Ansehen',
  'hubs.tasks.modal.title': 'Aufgabendetails',
  'hubs.tasks.modal.cancel': 'Abbrechen',
  'hubs.tasks.modal.markAsRead': 'Als gelesen markieren',
  'hubs.tasks.modal.submit': 'Absenden',
  'hubs.tasks.modal.close': 'Schließen',
  'hubs.tasks.completed_by': 'Erledigt von {{ responder }} @ {{ when }}',
  'hubs.tasks.file_submitted': 'Sie haben bereits eine Datei hochgeladen',
  'hubs.tasks.file_download': 'Datei herunterladen',
  'hubs.tasks.user_file_download': 'Datei herunterladen',
  'hubs.tasks.expired': 'Abgelaufen',
  'hubs.tasks.capacity_remaining': '{{ capacity_remaining }} verbleibend',
  'hubs.tasks.capacity_remaining_zero': 'Nicht auf Lager',
  'hubs.tasks.deadline': 'Frist',
  'hubs.tasks.completed': 'Abgeschlossen',

  'inbox.header': 'Posteingang',
  'inbox.invites.buttons.accept': 'Meeting annehmen',
  'inbox.invites.buttons.askQuestion': 'Nachricht',
  'inbox.invites.buttons.decline': 'Ablehnen',
  'inbox.invites.buttons.forward': 'Weiterleiten',
  'inbox.invites.buttons.moreOptions': 'Weitere Optionen ...',
  'inbox.invites.buttons.next_invite': 'Nächste Einladung',
  'inbox.invites.buttons.read_more': 'mehr',
  'inbox.invites.buttons.respond': 'Antworten',
  'inbox.invites.buttons.show_less': 'weniger anzeigen',
  'inbox.invites.buttons.view': 'Einladung ansehen',
  'inbox.invites.buttons.viewProfile': 'Profil ansehen',
  'inbox.invites.buttons.withdraw': 'Zurückziehen',
  'inbox.invites.declinedByActiveUser': 'Sie haben diese Einladung abgelehnt {{ declined_time }}',
  'inbox.invites.declinedByRecipient': '{{ declined_by }} hat diese Einladung abgelehnt {{ declined_time }}',
  'inbox.invites.expiry.expired': 'Abgelaufen',
  'inbox.invites.expiry.expires': 'Läuft ab',
  'inbox.invites.expiry.expiresSoon': 'Sie haben Meeting-Einladungen, die bald ablaufen.',
  'inbox.invites.expiry.whenExpired': 'Sie haben abgelaufene Besprechungseinladungen.',
  'inbox.invites.findPeople': 'Suche',
  'inbox.invites.forwardedByActiveUser': 'Sie haben diese Einladung an {{ forwardee }} weitergeleitet',
  'inbox.invites.forwardedByColleague': '{{ forwarded_by }} hat Ihnen diese Meeting-Einladung weitergeleitet.',
  'inbox.invites.forwardedByRecipient': 'Weitergeleitet an {{ forwardee }} von {{ forwarded_by }}',
  'inbox.invites.info.sent_message_concierge': 'Das Meetings-Concierge-Team wird diesen Benutzer kontaktieren, wenn er bald nicht antwortet.',
  'inbox.invites.noResults.message': '<a href="{{ findPeopleUrl }}">Suche</a> nach neuen Meeting-Möglichkeiten.',
  'inbox.invites.noResults.title': 'Keine Meeting-Einladungen',
  'inbox.invites.sort.newest': 'Neueste',
  'inbox.invites.sort.oldest': 'Älteste',
  'inbox.invites.sort.placeholder': 'Sortieren',
  'inbox.invites.statuses.accepted': 'Angenommen',
  'inbox.invites.statuses.declined': 'Abgelehnt',
  'inbox.invites.statuses.expired': 'Abgelaufen',
  'inbox.invites.statuses.forwarded': 'Weitergeleitet',
  'inbox.invites.statuses.pending': 'Wartet auf Antwort',
  'inbox.invites.statuses.sent': 'Gesendet',
  'inbox.invites.statuses.unread': 'Neue Nachricht',
  'inbox.invites.statuses.withdrawn': 'Zurückgezogen',
  'inbox.invites.statusFilters.accepted': 'Angenommen',
  'inbox.invites.statusFilters.declined': 'Abgelehnt',
  'inbox.invites.statusFilters.expired': 'Abgelaufen',
  'inbox.invites.statusFilters.forwarded': 'Weitergeleitet',
  'inbox.invites.statusFilters.pending': 'Bearbeitung erforderlich',
  'inbox.invites.statusFilters.sent': 'Gesendet',
  'inbox.invites.statusFilters.withdrawn': 'Zurückgezogen',
  'inbox.invites.whenReceived': 'Empfangen',
  'inbox.invites.whenSent': 'Gesendet',
  'inbox.invites.withdrawnByActiveUser': 'Sie haben diese Einladung zurückgezogen {{ withdrawn_time }}',
  'inbox.invites.withdrawnBySender': '{{ withdrawn_by }} hat diese Einladung zurückgezogen {{ withdrawn_time }}',
  'inbox.loading': 'Wird geladen...',
  'inbox.meetings.buttons.addColleagues': 'Kollegen einladen',
  'inbox.meetings.buttons.cancel': 'Meeting absagen',
  'inbox.meetings.buttons.moreOptions': 'Weitere Optionen ...',
  'inbox.meetings.buttons.rescheduleMeeting': 'Meeting verschieben',
  'inbox.meetings.cancelledBy': '{{ cancelled_by }} hat dieses Meeting {{ cancel_time }} abgesagt',
  'inbox.meetings.cancelledByActiveUser': 'Sie haben dieses Meeting {{ cancel_time }} abgesagt',
  'inbox.meetings.activeContactColleagueMeeting': 'Ihr Kollege {{ colleague_name }} hat bereits ein Meeting mit {{ contact_name }} gebucht',
  'inbox.meetings.previouslyCancelledByActiveUser': 'Sie haben zuvor ein Meeting mit {{ contact_name }} {{ cancel_time }} abgesagt',
  'inbox.meetings.filters.allDays': 'Alle',
  'inbox.meetings.filters.showCancelled': 'Stornierte Meetings anzeigen',
  'inbox.meetings.meetingsToRate_one': 'Sie haben ein Meeting zu bewerten. <a href="#/meetings/{{ nextId }}/rate">Jetzt bewerten</a>',
  'inbox.meetings.meetingsToRate_other': 'Sie haben {{ count }} Meetings zu bewerten. <a href="#/meetings/{{ nextId }}/rate">Jetzt bewerten</a>',
  'inbox.meetings.noResults.message': '<a href="{{ findPeopleUrl }}">Suche</a> nach neuen Meeting-Möglichkeiten.',
  'inbox.meetings.noResults.title': 'Sie haben noch keine Meetings',
  'inbox.meetings.rate': 'Meeting bewerten',
  'inbox.meetings.statuses.cancelled': 'Abgesagt',
  'inbox.meetings.statuses.confirmed': 'Bestätigtes Meeting',
  'inbox.meetings.view': 'Meeting-Informationen',
  'inbox.messages.info.moreMessagesInThread': 'Es gibt weitere Nachrichten in diesem Gespräch',
  'inbox.messages.info.newUnreadMessage': 'Neue Chat-Nachricht. <a href="{{url}}">Antworten</a>',
  'inbox.messages.meetingInformation': 'Meeting-Informationen',
  'inbox.messages.noResults.message': 'Sie haben keine Nachrichten.',
  'inbox.messages.noResults.title': 'Keine Nachrichten',
  'inbox.messages.sort.newest': 'Neueste',
  'inbox.messages.sort.oldest': 'Älteste',
  'inbox.messages.statusFilters.all': 'Alle',
  'inbox.messages.statusFilters.unread': 'Ungelesen',
  'inbox.messages.whenReceived': 'Empfangen',
  'inbox.messages.whenSent': 'Gesendet',
  'inbox.nextPage': 'Nächste Seite',
  'inbox.previousPage': 'Vorherige Seite',
  'inbox.relationshipLabels.from': 'Von:',
  'inbox.relationshipLabels.to': 'An:',
  'inbox.relationshipLabels.with': 'Mit:',
  'inbox.subtitle.meetings': 'Bestätigte Meetings',
  'inbox.subtitle.messages': 'Nachrichten',
  'inbox.subtitle.null': ' ',
  'inbox.subtitle.receivedInvites': 'Erhaltene Einladungen',
  'inbox.subtitle.sentInvites': 'Gesendete Einladungen',
  'meeting.add_colleagues.buttons.add': 'Kollegen hinzufügen',
  'meeting.add_colleagues.buttons.cancel': 'Abbrechen',
  'meeting.add_colleagues.buttons.done': 'Fertig',
  'meeting.add_colleagues.buttons.invite_more': 'Mehr einladen',
  'meeting.add_colleagues.header': 'Kollegen hinzufügen',
  'meeting.add_colleagues.no_colleagues': 'Keine Kollegen verfügbar, die hinzugefügt werden können',
  'meeting.add_colleagues.subheader': 'Fügen Sie Kollegen zu Ihrem Meeting mit <strong>{{display_name}}, {{job_title}}</strong> bei <a href="{{company_url}}">{{company_display_name}}</a> hinzu',
  'meeting.add_colleagues.success.message_one': 'Wir haben erfolgreich Ihren Kollegen zu diesem Meeting hinzugefügt.',
  'meeting.add_colleagues.success.message_other': 'Wir haben erfolgreich Ihre Kollegen zu diesem Meeting hinzugefügt.',
  'meeting.add_colleagues.success.title_one': 'Kollege hinzugefügt',
  'meeting.add_colleagues.success.title_other': 'Kollegen hinzugefügt',
  'meeting.addColleague.button': 'Kollegen hinzufügen',
  'meeting.addToCalendar': 'Zum Kalender hinzufügen',
  'meeting.attendees': 'Sie und {{ displayName }}',
  'meeting.attendeesCount': '{{count}} Personen',
  'meeting.attendeesTitle': 'Teilnehmer',
  'meeting.back_to_meeting': 'Zurück zum Meeting',
  'meeting.cancel.button': 'Stornierung senden',
  'meeting.cancel.cannotAttend': 'Ich kann an diesem Meeting nicht mehr teilnehmen',
  'meeting.cancel.keep': 'Meeting beibehalten',
  'meeting.cancel.messageLabel': 'Notiz',
  'meeting.cancel.messagePlaceholder': 'Leider muss ich dieses Meeting absagen, weil ...',
  'meeting.cancel.onlyOrganiserCanCancel': 'Nur der Hauptorganisator kann das Meeting absagen',
  'meeting.cancel.reasonLabel': 'Grund für die Absage',
  'meeting.cancel.reasonPlaceholder': 'Option wählen...',
  'meeting.cancel.subheader': 'Sagen Sie Ihr geplantes Meeting mit <strong>{{ displayName }}, {{ jobTitle }}</strong> bei <a href="{{ companyUrl }}">{{ companyDisplayName }}</a> ab.',
  'meeting.cancel.title': 'Meeting absagen',
  'meeting.cancellation_success.message': 'Sie haben Ihr Meeting mit <strong>{{display_name}}, {{job_title}}</strong> bei <a href="{{company_url}}">{{company_display_name}}</a> erfolgreich abgesagt.',
  'meeting.cancellation_success.title': 'Einladung abgelehnt',
  'meeting.cancelledBy': 'Dieses Meeting wurde von {{ cancelledBy_display_name }} {{cancelled_at_time}} abgesagt.',
  'meeting.close': 'Schließen',
  'meeting.countdown_minutes': 'Minuten, ',
  'meeting.countdown_seconds': 'Sekunden',
  'meeting.errors.cannot_load': 'Meeting kann nicht geladen werden.',
  'meeting.finished': 'Meeting ist beendet.',
  'meeting.forward.button': 'Kollegen auswählen',
  'meeting.forward.subheader': 'Meeting an einen Kollegen weiterleiten',
  'meeting.forward.title': 'An Kollegen weiterleiten',
  'meeting.in_progress': 'Meeting läuft.',
  'meeting.join_now': 'Jetzt teilnehmen',
  'meeting.meeting_confirmed.invite_colleagues': 'Kollegen einladen',
  'meeting.meeting_confirmed.message': 'Ihr Meeting-Partner wurde benachrichtigt.',
  'meeting.meeting_confirmed.next_invite': 'Nächste Einladung',
  'meeting.meeting_confirmed.title': 'Meeting bestätigt',
  'meeting.meeting_confirmed.view_meeting': 'Details ansehen',
  'meeting.meeting_starts_in_countdown': 'Meeting beginnt in',
  'meeting.meetingCancelled': 'Meeting abgesagt',
  'meeting.meetingConfirmed': 'Vollständig von allen Parteien bestätigt.',
  'meeting.mobileNumberLabel': 'Handynummer:',
  'meeting.panelTitle.cancelled': 'Meeting abgesagt',
  'meeting.panelTitle.information': 'Meeting-Informationen',
  'meeting.phoneNumberMissing': '<a href="{{ updatePhoneUrl }}">Bitte fügen Sie eine Handynummer hinzu</a>',
  'meeting.rate_meeting.back_to_meeting': 'Zurück zum Meeting',
  'meeting.rate_meeting.buttons.cancel': 'Abbrechen',
  'meeting.rate_meeting.buttons.done': 'Fertig',
  'meeting.rate_meeting.buttons.next': 'Weiter',
  'meeting.rate_meeting.buttons.rate': 'Bewerten',
  'meeting.rate_meeting.buttons.save': 'Feedback speichern',
  'meeting.rate_meeting.errors.already_rated': 'Dieses Meeting wurde bereits bewertet',
  'meeting.rate_meeting.fields.feedback.label': 'Meeting-Feedback (optional)',
  'meeting.rate_meeting.fields.meeting_did_not_happen.label': 'Meeting hat nicht stattgefunden',
  'meeting.rate_meeting.fields.rating.label': 'Bewerten Sie Ihr Meeting',
  'meeting.rate_meeting.header': 'Bewerten Sie Ihr Meeting',
  'meeting.rate_meeting.subheader': 'Wie war Ihr Meeting mit <strong>{{display_name}}, {{job_title}}</strong> von {{company_display_name}}?',
  'meeting.rate_meeting.success.title': 'Bewertung eingereicht',
  'meeting.reminder': 'Wir senden Ihnen 10 Minuten vor Beginn des Meetings eine SMS-Erinnerung.',
  'meeting.reschedule.back_to_meeting': 'Zurück zum Meeting',
  'meeting.reschedule.button': 'Meeting verschieben',
  'meeting.reschedule.onlyOrganiserCanReschedule': 'Nur der Hauptorganisator kann das Meeting verschieben',
  'meeting.reschedule.subheader': 'Ändern Sie das Datum, die Uhrzeit oder den Ort des Meetings',
  'meeting.reschedule.title': 'Meeting verschieben',
  'meeting.source_types.auto_accepted': 'Automatisch akzeptiert',
  'meeting.source_types.auto_match': 'Automatische Übereinstimmung',
  'meeting.source_types.concierge_booked': 'Concierge',
  'meeting.source_types.walk_up': 'Vor-Ort-Buchung',
  'meeting.stand': 'Stand {{ stand_number }}',
  'meeting.startingTime': 'Meeting beginnt {{ time_until_meeting }}',
  'meeting.statuses.cancelled': 'Abgesagt',
  'meeting.updatePhone': 'Nummer aktualisieren',
  'meeting_invite.back_to_conversation': 'Zurück zur Unterhaltung',
  'meeting_invite.decline.buttons.cancel': 'Abbrechen',
  'meeting_invite.decline.buttons.decline': 'Einladung ablehnen',
  'meeting_invite.decline.buttons.done': 'Fertig',
  'meeting_invite.decline.errors.cannot_load': 'Einladung kann nicht geladen werden.',
  'meeting_invite.decline.header': 'Meeting-Einladung ablehnen',
  'meeting_invite.decline.inputs.message.label': 'Nachricht hinzufügen',
  'meeting_invite.decline.inputs.reason.label': 'Grund für die Ablehnung',
  'meeting_invite.decline.inputs.reason.placeholder': 'Grund auswählen',
  'meeting_invite.decline.subheader': 'Lehnen Sie diese Meeting-Einladung von <strong>{{display_name}}, {{job_title}}</strong> bei <a href="{{company_url}}">{{company_display_name}}</a> ab.',
  'meeting_invite.decline.success.message': 'Sie haben erfolgreich eine Meeting-Einladung von <strong>{{display_name}}, {{job_title}}</strong> bei <a href="{{company_url}}">{{company_display_name}}</a> abgelehnt.',
  'meeting_invite.decline.success.title': 'Einladung abgelehnt',
  'meeting_invite.forward_invite.buttons.cancel': 'Einladung behalten',
  'meeting_invite.forward_invite.buttons.done': 'Fertig',
  'meeting_invite.forward_invite.buttons.forward': 'Einladung weiterleiten',
  'meeting_invite.forward_invite.header': 'An Kollegen weiterleiten',
  'meeting_invite.forward_invite.inputs.forward_to_colleague.label': 'Fügen Sie eine Notiz für Ihren Kollegen hinzu:',
  'meeting_invite.forward_invite.inputs.forward_to_colleague.placeholder': 'Ich habe eine Meeting-Einladung erhalten, die für Sie relevanter ist...',
  'meeting_invite.forward_invite.subheader': 'Leiten Sie Ihre Meeting-Einladung von <strong>{{display_name}}, {{job_title}}</strong> bei <a href="{{company_url}}">{{company_display_name}}</a> an einen Kollegen weiter',
  'meeting_invite.forward_invite.success.message': 'Wir haben diese Meeting-Einladung erfolgreich an Ihren Kollegen weitergeleitet.',
  'meeting_invite.forward_invite.success.title': 'Einladung an Kollegen weitergeleitet',
  'meeting_invite.opt_in_meetings.accept': 'Ja, für das Networking anmelden',
  'meeting_invite.opt_in_meetings.decline': 'Nein, ich möchte das Networking nicht nutzen',
  'meeting_invite.opt_in_meetings.opt_in_meetings_text': 'Melden Sie sich für das Networking an, um Personen zu finden, Meetings zu planen und Meeting-Einladungen von anderen Event-Teilnehmern zu erhalten.',
  'meeting_invite.opt_in_meetings.previously_requested_opt_out': 'Sie haben zuvor darum gebeten, den Networking-Dienst nicht zu nutzen.',
  'meeting_invite.opt_in_meetings.title': 'Networking-Anmeldung',
  'meeting_invite.pending_invites.limit_reached.body': 'Sie können weitere Einladungen senden, wenn Ihre aktuellen Meeting-Einladungen angenommen werden.',
  'meeting_invite.pending_invites.limit_reached.title': 'Einladungslimit erreicht',
  'meeting_invite.pending_invites.remaining': '<i class="fa-solid fa-circle-info"></i> Sie haben <strong>{{ invite_spent }} von {{ invite_credits }}</strong> ausstehenden Meeting-Einladungen genutzt.',
  'meeting_invite.send_invite.buttons.cancel': 'Abbrechen',
  'meeting_invite.send_invite.buttons.done': 'Fertig',
  'meeting_invite.send_invite.buttons.send': 'Einladung senden',
  'meeting_invite.send_invite.buttons.view_sent_invites': 'Gesendete Einladungen',
  'meeting_invite.send_invite.errors.cannot_send_invite': 'Einladung kann nicht an diesen Kontakt gesendet werden.',
  'meeting_invite.send_invite.header': 'Erstellen Sie Ihre Meeting-Einladung',
  'meeting_invite.send_invite.message_placeholder': 'Hi, ich würde mich gerne treffen, um über ... zu sprechen',
  'meeting_invite.send_invite.sub_text': 'Ihr Meeting-Partner wird Datum und Uhrzeit des Meetings bestätigen.',
  'meeting_invite.send_invite.success.message': 'Ihr Meeting-Partner wird Ihre Einladung prüfen und ein Datum, Uhrzeit und Ort basierend auf Ihrer Verfügbarkeit bestätigen.',
  'meeting_invite.send_invite.success.title': 'Einladung gesendet',
  'meeting_invite.send_invite.success.update_availability': 'Meine Verfügbarkeit aktualisieren',
  'meeting_invite.withdraw.buttons.cancel': 'Abbrechen',
  'meeting_invite.withdraw.buttons.done': 'Fertig',
  'meeting_invite.withdraw.buttons.withdraw': 'Einladung zurückziehen',
  'meeting_invite.withdraw.errors.cannot_load': 'Einladung kann nicht geladen werden.',
  'meeting_invite.withdraw.header': 'Einladung zurückziehen',
  'meeting_invite.withdraw.inputs.message.label': 'Nachricht hinzufügen',
  'meeting_invite.withdraw.inputs.reason.label': 'Grund für das Zurückziehen',
  'meeting_invite.withdraw.inputs.reason.placeholder': 'Grund auswählen',
  'meeting_invite.withdraw.subheader': 'Ziehen Sie diese Meeting-Einladung von <strong>{{display_name}}, {{job_title}}</strong> bei <a href="{{company_url}}">{{company_display_name}}</a> zurück',
  'meeting_invite.withdraw.success.message': 'Sie haben erfolgreich eine Meeting-Einladung für <strong>{{display_name}}, {{job_title}}</strong> bei <a href="{{company_url}}">{{company_display_name}}</a> zurückgezogen.',
  'meeting_invite.withdraw.success.title': 'Einladung zurückgezogen',
  'meeting_scheduler.acceptInvitation': 'Einladung annehmen',
  'meeting_scheduler.buttons.back': 'Zurück',
  'meeting_scheduler.buttons.done': 'Fertig',
  'meeting_scheduler.buttons.next': 'Weiter',
  'meeting_scheduler.errors.invite_already_actioned': 'Diese Einladung wurde bereits bearbeitet.',
  'meeting_scheduler.errors.invite_already_actioned_link': '<a href="#/contacts/{{ invite_counterpart_contact_id }}/conversation">Ansehen</a>',
  'meeting_scheduler.errors.no_available_slots': 'Keine verfügbaren Meeting-Zeiten.',
  'meeting_scheduler.floorplanClickForFull': 'Vollständiger Grundriss',
  'meeting_scheduler.meetingUpdatedTitle': 'Meeting aktualisiert',
  'meeting_scheduler.rescheduleMeeting': 'Meeting verschieben',
  'meeting_scheduler.standLocation': '{{ companyName }} Stand {{ standNumberFull }}',
  'meeting_scheduler.step.date': 'Datum',
  'meeting_scheduler.step.location': 'Ort',
  'meeting_scheduler.step.time': 'Uhrzeit',
  'meeting_scheduler.unavailable': 'Nicht verfügbar',
  'multi_links.add_link': 'Link hinzufügen',
  'multi_links.address': 'z.B. https://www.{address}.com/...',
  'multi_links.error': 'Bitte stellen Sie sicher, dass Typ und URL gesetzt sind.',
  'multi_links.please_select': 'Bitte auswählen',
  'multi_links.title': 'Links',
  'multi_links.type': 'Typ',
  'mux_video_upload.buttons.cancel': 'Abbrechen',
  'mux_video_upload.buttons.delete': 'Löschen',
  'mux_video_upload.buttons.rename': 'Umbenennen',
  'mux_video_upload.buttons.save': 'Speichern',
  'mux_video_upload.buttons.upload': 'Hochladen',
  'mux_video_upload.error_text': 'Beim Hochladen Ihres Videos ist ein Fehler aufgetreten, bitte versuchen Sie es erneut',
  'mux_video_upload.input.label': 'Titel',
  'mux_video_upload.success_text': 'Ihre Datei wurde erfolgreich hochgeladen, sie wird bald verfügbar sein',
  'mux_video_upload.video_status.created': 'Neu',
  'mux_video_upload.video_status.deleted': 'Gelöscht',
  'mux_video_upload.video_status.errored': 'Fehlerhaft',
  'mux_video_upload.video_status.new_video': 'Datei auswählen und hochladen',
  'mux_video_upload.video_status.processing': 'Verarbeitung',
  'mux_video_upload.video_status.ready': 'Bereit',
  'mux_video_upload.video_status.removed': 'Entfernt',
  'mux_video_upload.video_status.upload-cancelled': 'Upload abgebrochen',
  'mux_video_upload.video_status.upload-failed': 'Upload fehlgeschlagen',
  'mux_video_upload.video_status.upload_failed': 'Upload unvollständig, bitte löschen und erneut versuchen',
  'mux_video_upload.video_status.uploading': 'Wird hochgeladen',
  'mux_video_upload.video_status.waiting': 'Wartend',
  'my_schedule.bookings.share_schedule': 'Zeitplan teilen',
  'my_schedule.bookings.share_no_colleagues': 'Keine berechtigten Kollegen gefunden',
  'my_schedule.bookings.source_types.auto_accepted': 'Automatisch akzeptiert',
  'my_schedule.bookings.source_types.auto_match': 'Automatische Übereinstimmung',
  'my_schedule.bookings.source_types.concierge_booked': 'Concierge',
  'my_schedule.bookings.source_types.walk_up': 'Vor-Ort-Buchung',
  'my_schedule.bookings.table_number': '(Tisch {{ number }})',
  'my_schedule.other_bookings.buttons.add': 'Hinzufügen',
  'my_schedule.other_bookings.buttons.addOther': 'Weiteres',
  'my_schedule.other_bookings.buttons.cancel': 'Abbrechen',
  'my_schedule.other_bookings.buttons.delete': 'Löschen',
  'my_schedule.other_bookings.buttons.no_undos': 'Diese Aktion kann nicht rückgängig gemacht werden.',
  'my_schedule.other_bookings.buttons.update': 'Aktualisieren',
  'my_schedule.other_bookings.fields.description.label': 'Details',
  'my_schedule.other_bookings.fields.description.placeholder': 'Mittagessen mit Lieferant',
  'my_schedule.other_bookings.fields.end_datetime.label': 'Ende',
  'my_schedule.other_bookings.fields.start_datetime.label': 'Start',
  'my_schedule.other_bookings.header_add': 'Hinzufügen',
  'my_schedule.other_bookings.header_update': 'Aktualisieren',
  'my_schedule.other_bookings.sub_text': "Ihr Status wird während dieser Zeit 'nicht verfügbar' anzeigen.",
  'needHelp.body': 'Kontaktieren Sie das Concierge-Team.',
  'needHelp.dataPrivacy.body': 'Gesammelte Leads unterliegen den Nutzungsbedingungen und der Datenschutzrichtlinie.',
  'needHelp.dataPrivacy.header': 'Datenschutz',
  'needHelp.dataPrivacy.link': 'Datenschutzrichtlinie',
  'needHelp.header': 'Benötigen Sie Hilfe?',
  'needHelp.link': 'Kontaktieren Sie uns',
  'profile.account_settings.email': 'E-Mail',
  'profile.account_settings.first_name': 'Vorname',
  'profile.account_settings.last_name': 'Nachname',
  'profile.account_settings.salutation': 'Titel',
  'profile.account_settings.title': 'Kontoeinstellungen',
  'profile.buttons.cancel': 'Abbrechen',
  'profile.buttons.edit': 'Bearbeiten',
  'profile.buttons.save': 'Speichern',
  'profile.communication_preferences.email': 'Benachrichtigungs- und Erinnerungsemails',
  'profile.communication_preferences.phone': 'Support-Telefonkommunikation',
  'profile.communication_preferences.sms': 'Benachrichtigungs- und Erinnerungstextnachrichten',
  'profile.communication_preferences.something_went_wrong': 'Oops - etwas ist schief gelaufen, bitte versuchen Sie es später noch einmal',
  'profile.communication_preferences.subheader': 'Verwalten Sie Ihre Meeting-Programm-Einstellungen',
  'profile.communication_preferences.title': 'Kommunikationseinstellungen',
  'profile.demographics.subheader': 'Füllen Sie diese Informationen aus, um anderen Benutzern zu helfen, Sie in Suchen zu finden',
  'profile.demographics.title': 'Suchinformationen',
  'profile.forms.catalogueEntry.create.modalTitle': 'Neue {{ name, lowercase }} hinzufügen',
  'profile.forms.catalogueEntry.edit.modalTitle': '{{ name, lowercase }} bearbeiten',
  'profile.forms.image.create.heading': 'Fügen Sie ein neues Bild zu Ihrem Produktkatalog hinzu.',
  'profile.forms.image.create.modalTitle': 'Bild hinzufügen',
  'profile.forms.image.edit.heading': 'Ändern Sie den Titel Ihres Bildes',
  'profile.forms.image.edit.modalTitle': 'Bild bearbeiten',
  'profile.forms.image.progressMessage': 'Ihr Bild wird hochgeladen, bitte warten',
  'profile.forms.video.create.modalTitle': 'Video hochladen',
  'profile.forms.video.edit.modalTitle': 'Video bearbeiten',
  'profile.forms.video.progressMessage': 'Ihr Video wird hochgeladen, bitte warten',
  'profile.groups.aboutMe.heading': 'Über mich',
  'profile.groups.accountAccess.heading': 'Kontozugang',
  'profile.groups.accountAccess.subheading': 'Diese Benutzer können Ihr Profil, Chat und Meeting-Aktivitäten verwalten.',
  'profile.groups.catalogue.heading': '{{ plural }}',
  'profile.groups.companyDemographics.heading': 'Weitere Informationen',
  'profile.groups.companyInformation.heading': 'Firmeninformationen',
  'profile.groups.companyInformation.subheading': 'Verwalten Sie Ihr Firmenprofil',
  'profile.groups.contactInformation.heading': 'Kontaktinformationen',
  'profile.groups.eventOptouts.heading': 'Event-Optouts',
  'profile.groups.password.heading': 'Passwort',
  'profile.groups.password.lastChanged': 'Zuletzt geändert <b> {{ lastChangedAt }} </b>',
  'profile.groups.password.subheading': 'Ihr Passwort muss mindestens 8 Zeichen lang sein und einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.',
  'profile.groups.searchInformation.heading': 'Weitere Informationen',
  'profile.groups.team.heading': 'Teammitglieder <small>({{ count }} Personen)</small>',
  'profile.groups.timezone.heading': 'Zeitzone',
  'profile.groups.timezone.subheading': 'Zeigen Sie Event-Daten und -Zeiten in Ihrer bevorzugten Zeitzone an. <b>(Nur für virtuelle/Online-Events)</b>.',
  'profile.informationSharingNotification': 'Ihre Kontaktinformationen werden <b>NICHT</b> in Ihrem öffentlichen Profil angezeigt.',
  'profile.modals.previewCompanyCard.modalTitle': 'Vorschau Ihrer Firmenkarte',
  'profile.modals.previewContactCard.': 'Ihre Kontaktkarten-Vorschau',
  'profile.modals.previewContactCard.modalTitle': 'Ihre Kontaktkarten-Vorschau',
  'profile.optouts.something_went_wrong': 'Bitte aktualisieren Sie die Seite und versuchen Sie es erneut',
  'profile.optouts.subheader': 'Durch das Auswählen dieser Optionen wird Ihr Profil ausgeblendet und/oder alle Meetings und anderen Termine abgelehnt.',
  'profile.optouts.title': 'Event-Ausschlüsse',
  'profile.optouts.type_state_findable_false': 'Ich bin NICHT im Portal sichtbar.',
  'profile.optouts.type_state_findable_true': 'Ich bin im Portal sichtbar.',
  'profile.optouts.type_state_meetable_false': 'Ich stehe für Meetings NICHT zur Verfügung.',
  'profile.optouts.type_state_meetable_true': 'Ich stehe für Meetings zur Verfügung.',
  'profile.pages.catalogue.header': 'Marktplatz',
  'profile.pages.companyProfile.header': 'Firmenprofil',
  'profile.pages.companyProfile.previewButton': 'Firmenprofil anzeigen',
  'profile.pages.manageContent.header': 'Inhalte verwalten',
  'profile.pages.manageContent.warning': 'Event-Einstellungen können die Anzeige einiger Inhalte einschränken.',
  'profile.pages.myAccount.action': 'Event öffnen',
  'profile.pages.myAccount.eventAndBadges': 'Meine Events & Abzeichen',
  'profile.pages.myAccount.header': 'Mein Konto Home',
  'profile.pages.myAccount.welcome': 'Hallo {{ firstName }}, willkommen in deinem Konto.',
  'profile.pages.notificationSettings.header': 'Benachrichtigungseinstellungen',
  'profile.pages.passwordSettings.header': 'Passwort & Einstellungen',
  'profile.pages.purchases.header': 'Einkäufe',
  'profile.pages.purchases.receipt': 'Beleg',
  'profile.pages.profileInformation.header': 'Mein Profil',
  'profile.pages.profileInformation.previewButton': 'Mein Profil anzeigen',
  'profile.pages.profileInformation.myAccount': 'Mein Konto',
  'profile.pages.profileInformation.switchProfile': 'Profil wechseln',
  'profile.pages.team.header': 'Team',
  'profile.pageTitle': 'Mein Konto',
  'profile.rows.avatarModalTitle': 'Profilfoto hochladen',
  'profile.rows.bio.bioPlaceholder': 'Meine Arbeit beinhaltet …',
  'profile.rows.companyLocations.addModalTitle': 'Standort hinzufügen',
  'profile.rows.companyLocations.delete': 'Möchten Sie diesen Standort wirklich löschen?',
  'profile.rows.companyLocations.updateModalTitle': 'Standort bearbeiten',
  'profile.rows.companyWebsites.removeWarning': 'Möchten Sie dieses $t(crm.link.type.website, lowercase) wirklich aus Ihrem Profil entfernen?',
  'profile.rows.country.selectCountryPlaceholder': 'Wo befinden Sie sich?',
  'profile.rows.fullNameModalTitle': 'Name bearbeiten',
  'profile.rows.logo.logoModalTitle': 'Firmenlogo hochladen',
  'profile.rows.lookingFor.inputLabel': 'Für welche Art von Kontakten, Unternehmen, Produkten oder Dienstleistungen interessieren Sie Sich?',
  'profile.rows.lookingFor.inputPlaceholder': 'Ich suche nach ...',
  'profile.rows.lookingFor.label': 'Ich suche nach ...',
  'profile.rows.lookingFor.modalTitle': 'Ich suche nach ...',
  'profile.rows.password.changeMessage': 'Senden Sie einen Link zum Passwort ändern an:',
  'profile.rows.password.label': 'Aktuelles Passwort',
  'profile.rows.password.modalTitle': 'Passwort ändern',
  'profile.rows.password.passwordLinkSent': 'Passwortlink gesendet',
  'profile.rows.password.successMessage': '<h4> E-Mail gesendet </h4><p> Wir haben einen Link zum Zurücksetzen des Passwortes gesendet an:<br/><a href="mailto:{{email}}"> {{ email }} </a></p><p>Ihr Link wird in 1 Stunde ablaufen.</p>',
  'profile.rows.phone.deleteWarning': 'Diese Telefonnummer aus Ihrem Profil löschen?',
  'profile.rows.phone.info': 'So können wir Sie zu Ihren Meetings oder wichtigen Event-Updates per SMS oder Telefon kontaktieren. Ihre Telefonnummer wird nicht in Ihrem öffentlichen Profil angezeigt.',
  'profile.rows.socials.updateModalTitle': 'Soziale Netzwerke bearbeiten',
  'profile.rows.websites.addModalTitle': 'Website hinzufügen',
  'profile.rows.websites.updateModalTitle': 'Website bearbeiten',
  'profile.tables.catalogueEntry.confirmDelete': 'Möchten Sie diesen {{ name, lowercase }} wirklich löschen?',
  'profile.tables.catalogueEntry.emptyMessage': 'Keine Elemente zur Anzeige.',
  'profile.tables.imageGallery.addOne': 'Hinzufügen',
  'profile.tables.imageGallery.confirmDelete': 'Möchten Sie dieses Bild wirklich löschen?',
  'profile.tables.imageGallery.count_one': '({{ count }} Element)',
  'profile.tables.imageGallery.count_other': '({{ count }} Elemente)',
  'profile.tables.imageGallery.emptyMessage': 'Keine Elemente zur Anzeige.',
  'profile.tables.imageGallery.title': 'Bildgalerie',
  'profile.tables.videos.addOne': 'Hinzufügen',
  'profile.tables.videos.confirmDelete': 'Möchten Sie dieses Video wirklich löschen?',
  'profile.tables.videos.count': '({{ count }} / {{ limit }})',
  'profile.tables.videos.emptyMessage': 'Keine Elemente zur Anzeige.',
  'profile.tables.videos.title': 'Profilvideos',
  'profile.tips.edit.body': 'Details, die zur Überprüfung Ihrer Identität und Ihres Unternehmens verwendet werden, können nicht geändert werden. Kontaktinformationen, Präferenzen und einige Details können bearbeitet werden, aber wir können Sie bitten, Ihre Identität vor dem Speichern der Updates zu überprüfen.',
  'profile.tips.edit.title': 'Welche Details können bearbeitet werden?',
  'profile.tips.preview.title': 'Karten-Vorschau',
  'profile.tips.share.body': 'Wir teilen Ihre Kontaktinformationen nur mit bestätigten Meeting-Partnern.',
  'profile.tips.share.title': 'Welche Informationen werden mit anderen geteilt?',
  'profile.action.logout': 'Abmelden',
  'profile_select.footer.contact_support': 'Support kontaktieren',
  'profile_select.footer.multiple_profiles': 'Warum habe ich mehrere Profile?',
  'profile_select.footer.return_to_list': 'Zur Profil-Liste zurückkehren',
  'profile_select.header.info': 'Klicken Sie auf Profiloptionen, um <b>zu verbinden, zu zusammenführen oder einem Kollegen zuzuweisen</b>.',
  'profile_select.header.subheader': 'Es gibt mehr als ein Profil, das mit',
  'profile_select.header.title': 'Profil wechseln',
  'profile_select.info.duplicate_profiles.description': 'Möglicherweise haben Sie sich zweimal registriert.',
  'profile_select.info.duplicate_profiles.solution_text': "Wählen Sie 'zusammenführen' aus dem Profiloptionen-Menü und folgen Sie den Schritten, um die Daten in einem einzigen Benutzerprofil zu kombinieren. Wir empfehlen Ihnen, sich zu verbinden und zu überprüfen, welches Profil Sie behalten möchten.",
  'profile_select.info.duplicate_profiles.title': 'Es gibt doppelte Profile unter meinem Namen.',
  'profile_select.info.i_see_colleagues.description': 'Ihr Unternehmen hat möglicherweise mehrere Kollegen mit einer gemeinsamen E-Mail-Adresse registriert, z.B. info@.',
  'profile_select.info.i_see_colleagues.solution_text': 'Wählen Sie im Profiloptionen-Menü, die Verbindung zum Kollegen herzustellen.',
  'profile_select.info.i_see_colleagues.title': 'Ich sehe die Namen anderer Kollegen aus meinem Unternehmen.',
  'profile_select.info.listed_different_company.description': 'Möglicherweise sind Sie unter oder vertreten mehrere Unternehmen registriert. Wenn dies korrekt ist, können Sie mehrere Profile behalten und wechseln und diesen Profil-Auswahlbildschirm verwenden, um zwischen Profilen zu wechseln. Wenn dies nicht korrekt ist:',
  'profile_select.info.listed_different_company.solution_text': "Wählen Sie 'löschen' aus dem Profiloptionen-Menü. Dieser Vorgang kann nicht rückgängig gemacht werden, wir empfehlen Ihnen, sich zu verbinden und Profile zu überprüfen, bevor Sie diese löschen.",
  'profile_select.info.listed_different_company.title': 'Ich bin unter verschiedenen Firmennamen aufgeführt.',
  'profile_select.info.solution': 'Lösung:',
  'profile_select.info.title': 'Warum habe ich mehrere Profile?',
  'profile_select.profile.last_used': 'Zuletzt verwendet:',
  'profile_select.profile.never_logged_in': 'Nie',
  'profile_select.primary_contact': 'Primärer Kontakt',
  'ui.add': 'Hinzufügen',
  'ui.delete': 'Löschen',
  'ui.edit': 'Bearbeiten',
  'ui.faq': 'Häufig gestellte Fragen',
  'ui.help': 'Hilfe',
  'ui.language': 'Sprache',
  'ui.locked': 'Gesperrt',
  'ui.new': 'Neu',
  'ui.noItemsToDisplay': 'Keine Elemente zur Anzeige.',
  'ui.notAdded': 'Noch nicht vervollständigt',
  'ui.notSet': 'Nicht festgelegt',
  'ui.selectCountry': 'Land auswählen',
  'ui.support': 'Support',
  'ui.switchProfile': 'Profil wechseln',
  'update_availability.buttons.cancel': 'Abbrechen',
  'update_availability.buttons.update': 'Verfügbarkeit aktualisieren',
  'update_availability.header': 'Ihre Verfügbarkeit',
  'update_availability.key.available': 'Verfügbar',
  'update_availability.key.unavailable': 'Nicht verfügbar',
  'update_availability.subheader': 'Legen Sie fest, an welchen Tagen und zu welchen Zeiten Sie auf der Veranstalyung zur Verfügungs stehen.',
  'video_meeting.message_concierge.box_closing': 'Dieses Feld wird in 5 Sekunden automatisch geschlossen.',
  'video_meeting.message_concierge.buttons.cancel': 'Abbrechen',
  'video_meeting.message_concierge.buttons.message_concierge': 'Concierge benachrichtigen',
  'video_meeting.message_concierge.buttons.send': 'Senden',
  'video_meeting.message_concierge.connecting': 'verbinden',
  'video_meeting.message_concierge.header': 'Bitten Sie den Concierge um Hilfe',
  'video_meeting.message_concierge.last_seen': '(Zuletzt gesehen {{ last_seen }})',
  'video_meeting.message_concierge.message_sent': 'Ihre Nachricht wurde an den Concierge gesendet, beobachten Sie das Chat-Fenster für eine Antwort.',
  'video_meeting.message_concierge.online': 'online',
  'video_meeting.message_concierge.waiting_join': 'Warten auf den Benutzer beizutreten',
  chat_visible: 'Chat für alle Teilnehmer sichtbar',
  'purchase_upgrade.agree_terms_link': 'Allgemeinen Geschäftsbedingungen',
  'purchase_upgrade.agree_terms_post': '.',
  'purchase_upgrade.agree_terms_pre': 'Ich stimme den',
  'purchase_upgrade.buttons.agree': 'Ich stimme zu',
  'purchase_upgrade.buttons.back': 'Zurück',
  'purchase_upgrade.buttons.buy_now': 'Jetzt kaufen',
  'purchase_upgrade.buttons.cancel': 'Abbrechen',
  'purchase_upgrade.buttons.finish': 'Fertigstellen',
  'purchase_upgrade.buttons.pay_now': 'Jetzt bezahlen',
  'purchase_upgrade.buttons.print_receipt': 'Beleg drucken',
  'purchase_upgrade.buttons.try_again': 'Noch einmal versuchen',
  'purchase_upgrade.checkout_heading': 'Kasse',
  'purchase_upgrade.error_heading': 'Fehler',
  'purchase_upgrade.payment_complete.heading': 'Zahlung abgeschlossen',
  'purchase_upgrade.payment_complete.message': 'Ihre Bestellung wurde bearbeitet und auf das Konto angewendet.',
  'purchase_upgrade.terms.subtitle': 'Untertitel',
  'purchase_upgrade.terms.title': 'Allgemeine Geschäftsbedingungen',

  'meeting_invite.notification.confirmed_meeting': 'Sie haben ein <a href="{{hash}}">bestätigtes Treffen</a> mit diesem Kontakt.',
  'meeting_invite.notification.invite_already_sent': '<a href="{{hash}}">Einladung bereits versendet</a> an diesen Kontakt.',
  'meeting_invite.notification.invite_already_received': 'Sie haben eine <a href="{{hash}}">neue Besprechungseinladung</a> von diesem Kontakt.',
  'meeting_invite.notification.invite_withdrew_by_ac': 'Einladung zurückgezogen am {{withdrawn_time}}. <a href="{{hash}}">Details ansehen',
  'meeting_invite.notification.meeting_cancelled_by': 'Ein vorheriges Treffen wurde abgesagt.<a href="{{hash}}">Treffen anzeigen</a>.',
  'meeting_invite.notification.meeting_with_contact_colleague': 'Sie haben ein <a href="{{hash}}">bestätigtes Treffen</a> mit diesem Unternehmen.',
  'meeting_invite.notification.meeting_with_contact_declined': 'Ihre <a href="{{hash}}">Einladung zum Meeting</a> wurde abgelehnt.',
  'meeting_invite.notification.meeting_with_contact_declined_by_you': 'Sie haben eine <a href="{{hash}}">Einladung zu einem Treffen</a> von diesem Kontakt abgelehnt.',
  'meeting_invite.notification.colleague_meeting_intersect': 'Ihr Kollege trifft sich mit diesem Unternehmen.',
  'meeting_invite.notification.meeting_with_colleague_confirmed': 'Ihr Kollege trifft sich mit diesem Kontakt.',
  'meeting.notification.view_meeting': 'Meeting anzeigen',

  // Registrations
  'registrations.form.questions.component.name.first_name': 'Vorname',
  'registrations.form.questions.component.name.last_name': 'Nachname',
  'registrations.form.questions.component.name.first_name_placeholder': 'Vorname eingeben',
  'registrations.form.questions.component.name.last_name_placeholder': 'Nachname eingeben',
  'registrations.form.actions.submit': 'Absenden',
  'registrations.form.actions.next': 'Weiter',
  'registrations.form.screens.thankyou.heading': '<strong>Glückwunsch {{ name }}</strong>, Ihre Registrierung für {{ eventName }} ist abgeschlossen.',
  'registrations.form.screens.thankyou.sub_heading': 'Wir haben Ihnen eine Bestätigungs-E-Mail gesendet.',
  'registrations.form.screens.not_active.description': 'Dieses Registrierungsformular ist derzeit nicht verfügbar, bitte versuchen Sie es später erneut.',
  'registrations.form.screens.closed.alert_message': 'Die Registrierung ist geschlossen.',
  'registrations.form.screens.coming_soon.alert_message': 'Die Registrierung öffnet bald.',
  'registrations.form.actions.send_login_link': 'Einen Login-Link senden',
  'registrations.form.actions.change_email_address': 'E-Mail-Adresse ändern',
  'registrations.form.actions.send_link_to_continue': 'Einen Link senden, um fortzufahren',
  'registrations.form.validation.email_already_registered_message': 'Ihre E-Mail-Adresse ist bereits registriert...',
  'registrations.form.validation.email_already_have_partial_registration_message': 'Ihre E-Mail-Adresse hat eine teilweise Registrierung...',
  'registrations.form.buttons.login': 'Anmelden',
  'registrations.form.actions.resend_email': 'E-Mail erneut senden',
  'registrations.form.actions.resend_email.message': 'E-Mail gesendet!',

  // Videos
  'video_card.more': 'Mehr Details',
  'video_card.view_detail': 'Videodetails',
  'videoModal.buttons.view_company': 'Firma anzeigen',
};
