<template>
  <div
    class="inbox-row__concierge-info"
    :class="{'inbox-row__additional-info-warn': isWarning }"
  >
    <p class="additional-message">
      <span class="additional-message-title">
        <i
          v-if="titleIcon"
          :class="titleIcon"
          class="fa-fw me-1"
        ></i>
        <span v-if="title" v-html="title"></span>
      </span>
      <br>
      {{ message }}
      <span v-if="additionalMessage">
        <br>
        {{ additionalMessage }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    titleIcon: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    message: {
      type: String,
      required: false,
    },
    additionalMessage: {
      type: String,
      required: false,
    },
    isWarning: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
