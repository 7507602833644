<template>
  <label class="form-input" :class="{'form-check--invalid': invalid}">
    <div class="form-input__checkfield-option">
      <FrontendSwitch
        v-model="modelValue"
        >
        <span class="form-input__check-label">
          <template v-if="modelValue">
            {{ labelTrue || label }}
          </template>
          <template v-else>
            {{ labelFalse || label }}
          </template>
        </span>

      </FrontendSwitch>
    </div>
    <FormError :errors="errors" />
  </label>
</template>

<script>

import { computed } from 'vue';

export default {
  props: {
    label: {
      type: String,
      required: false,
    },
    /**
     * Alternate label to show if the checkbox is selected
     * */
    labelTrue: {
      type: String,
      required: false,
    },
    /**
     * Alternate label to show if the checkbox is not selected
     * */
    labelFalse: {
      type: String,
      required: false,
    },

    checked: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelValue: this.checked,
    };
  },
  inject: ['feForm'],
  computed: {
    id() {
      return `${this.feForm.name}-${this.name}`;
    },
    errors() {
      return this.feForm.error(this.name).value || [];
    },
    invalid() {
      return this.errors.length > 0;
    },
  },
  mounted() {
    this.feForm.register(this.name, computed(() => this.modelValue));
  },
};

</script>
