<template>
  <div class="dialog" data-dialog-type="System">
    <div class="dialog__body">
      <i :class="icon"></i>
      <span class="dialog__title"> {{ message }}{{ reason ? `: ${reason}`: '' }} </span>
    </div>
  </div>
  <span class="dialog__time"> {{ when }} </span>
</template>

<script>

export default {
  name: 'SystemChat',
  props: {
    message: {
      type: String,
      required: true,
    },
    reason: {
      type: String,
    },
    when: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      default: 'fas fa-info-circle',
    },
  },
};

</script>
