<template>
  <BaseModal
    ref="modal"
    :title="`${__('conference_sessions.ask_a_question.header')}`"
  >
    <div v-if="!questionAsked">
      <div>
        <p>{{ __("conference_sessions.ask_a_question.description") }}</p>
      </div>
      <form @submit.prevent="askQuestion">
        <FrontendTextInput
          type="textarea"
          :max="255"
          :placeholder="__('conference_sessions.ask_a_question.placeholder')"
          v-model="question"
          v-model:errors="errors.question"
          :required="true"
        />
      </form>
    </div>
    <div v-else
      class="conf-sessions__ask-a-question-success">
      <div>
        <i class="fa-regular fa-circle-check info-icon info-icon--success"></i>
      </div>
      <br>
      <div>
        <h3>{{ __("conference_sessions.ask_a_question.question_submitted") }}</h3>
        <p>{{ __("conference_sessions.ask_a_question.box_closing") }}</p>
      </div>
    </div>
    <template #footer v-if="!questionAsked">
      <div class="button-group">
        <button
          type="button"
          class="button button--primary"
          @click="askQuestion"
        >
          {{ __("conference_sessions.ask_a_question.buttons.send") }}
        </button>
        <button
          type="button"
          class="button button--tertiary"
          @click="cancelQuestion"
        >
          {{ __("conference_sessions.ask_a_question.buttons.cancel") }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>

import axios from 'axios';
import FrontendTextInput from './Inputs/FrontendTextInput.vue';

export default {
  components: { FrontendTextInput },
  props: {
    conferenceSession: Object,
  },
  data() {
    return {
      question: null,
      errors: {},
      questionAsked: false,
    };
  },
  methods: {
    cancelQuestion() {
      this.modalClose();
    },
    askQuestion() {
      axios.post(
        `${window.location.pathname}/ask-question`,
        {
          question: this.question,
        },
      ).then(() => {
        this.questionAsked = true;
        setTimeout((this.modalClose), 2000);
      }).catch((response) => {
        this.errors = response.data?.errors ?? {};
      });
      return false;
    },
    modalClose() {
      this.$refs.modal.close();
    },
    modalOpen() {
      this.$refs.modal.open();
    },
  },
};
</script>
