<template>
  <FeLoading v-if="loading"/>
  <ProfileGroup v-else>
    <div class="profile-table">
      <div class="profile-table__header">
        <div class="profile-table__header-label">
          <h3 v-html="__('profile.groups.team.heading', {count: team.length})"></h3>
        </div>
      </div>
      <div class="profile-table__body">
        <div class="profile-table__row" v-for="colleague in team" :key="colleague.id">
          <div class="profile-table__row-avatar">
            <ContactAvatar
              :contact="colleague"
              size="xsmall"
              />
          </div>
          <div class="profile-table__row-text">
            <div style="word-wrap: break-word; max-width: 100%; hyphens: auto;">
              <b> {{ colleague.full_name }} </b>, {{ colleague.job_title }}
              <template v-if="colleague.id === activeContact.id">
                (You)
              </template>
            </div>
          </div>
          <div class="profile-table__row-action">
            <Popper
              v-if="managedContacts.has(colleague.id)"
              :content="__('buttons.impersonateUser')"
              hover
              arrow
            >
              <a :href="managedContacts.get(colleague.id)">
                <i class="far fa-user-group-simple"></i>
              </a>
          </Popper>
          </div>
        </div>
      </div>
    </div>
  </ProfileGroup>
</template>

<script>

import axios from 'axios';
import Popper from 'vue3-popper';
import { user } from '~/Frontend/store';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import ContactAvatar from '~/Components/Frontend/CRM/Components/ContactAvatar.vue';
import ProfileGroup from '~/Components/Frontend/Profile/ProfileGroup.vue';

export default {
  name: 'ProfileTeamPage',
  components: {
    FeLoading,
    ContactAvatar,
    Popper,
    ProfileGroup,
  },
  data() {
    return {
      team: [],
      managedContacts: new Map(),
      loading: true,
    };
  },
  computed: {
    activeContact: () => user.activeContact,
  },
  mounted() {
    axios.get('/profile/team')
      .then((response) => {
        this.team = response.data.team;
        this.managedContacts = new Map(response.data.managed_contacts.map(({ contactId, impersonateRoute }) => [contactId, impersonateRoute]));
      }).then(() => {
        this.loading = false;
      });
  },
};
</script>
