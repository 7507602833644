<template>
  <div :style="[complete ? 'opacity: 0.5' : '']">
    <div style="padding: var(--sizing-4); background-color: var(--gray-1); margin-bottom: var(--sizing-2)">
      <label class="multilevel-dropdown__option">
        <input
          type="checkbox"
          class="multilevel-dropdown__checkbox"
          :checked="payload.agree"
          @input="updatePayload($event.target.checked)"
          @change="updatePayload($event.target.checked)"
          :disabled="complete"
        >
        <span class="multilevel-dropdown__label">{{ details.tickbox }}</span>
      </label>
      <div style="font-size: var(--font-small); color: var(--gray-4);" v-html="details.helper"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  emits: ['updatePayload'],
  props: {
    complete: Boolean,
    details: Object,
    payload: Object,
    errors: Object,
  },
  methods: {
    updatePayload(value) {
      this.$emit('updatePayload', 'agree', value);
      this.$emit('updatePayload', 'isValid', value);
    },
  },
};
</script>
