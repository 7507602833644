<template>
  <label
    class="filter-option"
    :class="{
      'filter-option--selected': modelValue === value,
      'filter-option--disabled': disabled,
    }"
  >
    {{ label }}

    <FeBadge
      v-if="badgeNumber"
      :value="badgeNumber"
      :positive="badgeType === 'positive'"
      :informational="badgeType === 'informational'"
    />

    <input type="radio"
     :name="name"
     :value="value"
     :checked="modelValue === value"
     @change="$emit('update:modelValue', $event.target.value)"
     :disabled="disabled"
     hidden
    >
  </label>
</template>

<script>
import FeBadge from '~/Components/Frontend/Core/Misc/FeBadge.vue';

export default {
  name: 'FilterRadioOption',
  components: { FeBadge },
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    badgeNumber: {
      type: Number,
    },
    badgeType: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
};
</script>
