<template>
  <BaseModal
    height="tall"
    startOpen
    ref="modal"
    :notifications="notifications"
  >

    <template #header v-if="invite.id">
      <ContactModalHeader
        v-if="invite.id"
        :key="invite.sender.id"
        :contact="invite.sender"
      />
    </template>

    <template v-if="(!invite.id || submitting) && !errored">
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>

    <form
      v-if="invite.id && !submitting && !complete"
      @submit.prevent="submit"
      ref="declineInviteForm"
    >
      <h2 class="meeting-card__title">{{ __('meeting_invite.decline.header') }}</h2>
      <p v-html="
        __('meeting_invite.decline.subheader',
            {
              display_name: invite.sender.display_name,
              job_title: invite.sender.job_title,
              company_url: `/${this.eventEdition?.slug}/companies/${invite.sender.company.id}`,
              company_display_name: invite.sender.company.display_name
            }
          )">
      </p>
      <FrontendSelectInput
        :label="__('meeting_invite.decline.inputs.reason.label')"
        :placeholder="__('meeting_invite.decline.inputs.reason.placeholder')"
        v-model="declineReason"
        :options="declineReasons"
        required
      />
      <FrontendTextInput
        id="decline-message"
        name="message"
        :label="__('meeting_invite.decline.inputs.message.label')"
        v-model="declineMessage"
        type="textarea"
        :error="errorMessage"
        :max="500"
      />
    </form>

    <div class="meeting-invite-form__success" v-if="complete">
      <i class="fa-regular fa-circle-check info-icon info-icon--success"></i>
      <h1>{{ __('meeting_invite.decline.success.title') }}</h1>
      <p v-html="
      __('meeting_invite.decline.success.message',
          {
            company_url: `/${this.eventEdition?.slug}/companies/${invite.sender.company.id}`,
            company_display_name: invite.sender.company.display_name,
            display_name: invite.sender.display_name,
            job_title: invite.sender.job_title
          }
        )">
      </p>
      <div class="button-group button-group--center">
        <a v-if="nextInviteId"
          class="button button--wide button--primary"
          :href="`#/invites/${nextInviteId}/show`"
        >
          {{ __('meeting.meeting_confirmed.next_invite') }} <i class="fa-light fa-arrow-right"></i>
        </a>
        <a v-else class="button button--wide" @click="modalClose">
          {{ __('meeting_invite.decline.buttons.done') }}
        </a>
      </div>
    </div>

    <template #footer>
      <div class="button-group" v-if="invite.id && incomplete">
        <button class="button button--secondary button--warning" @click.prevent="submit">
          {{ __('meeting_invite.decline.buttons.decline') }}
        </button>
        <button class="button button--tertiary" @click="modalClose">
          {{ __('meeting_invite.decline.buttons.cancel') }}
        </button>
      </div>
    </template>

  </BaseModal>
</template>

<script>
import axios from 'axios';
import FrontendSelectInput from '../Inputs/FrontendSelectInput.vue';
import FrontendTextInput from '../Inputs/FrontendTextInput.vue';
import { store, actionRequiredCounts } from '../../../Frontend/store';

export default {
  name: 'DeclineMeetingModal',
  components: { FrontendSelectInput, FrontendTextInput },
  inject: ['eventEditionManager'],
  props: {
    inviteId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      complete: false,
      invite: {},
      declineReasons: [],
      declineReason: '',
      declineMessage: null,
      nextInviteId: null,
      errored: false,
      errorMessage: null,
      notifications: [],
      actionRequiredCounts,
    };
  },
  mounted() {
    axios.get(`/${this.eventEdition?.slug}/meeting-invites/${this.inviteId}/decline`)
      .then((response) => {
        if (response.data?.invite.id) {
          this.invite = response.data.invite;
          if (response.data?.decline_reasons) {
            this.declineReasons = response.data.decline_reasons;
          }
        }
      }).catch((error) => {
        this.errored = true;
        let messageText = '';
        if (error.response.status === 404) {
          // eslint-disable-next-line no-underscore-dangle
          messageText = this.__('meeting_invite.decline.errors.cannot_load');
        } else {
          // eslint-disable-next-line no-underscore-dangle
          messageText = this.__('meeting_scheduler.errors.invite_already_actioned');
        }
        this.notifications.push({
          time: Date.now(),
          type: 'warning',
          message: messageText,
          icon: 'fa-circle-info',
        });
      });
  },
  methods: {
    submit() {
      if (!this.$refs.declineInviteForm.checkValidity()) {
        this.$refs.declineInviteForm.reportValidity();
        return false;
      }

      this.submitting = true;

      axios.post(`/${this.eventEdition?.slug}/meeting-invites/${this.inviteId}/decline`, {
        decline_reason: this.declineReason,
        decline_message: this.declineMessage,
      })
        .then((response) => {
          this.nextInviteId = response.data.next_invite?.id;
          this.emitter.emit('invites.declined', {
            inviteId: response.data.invite_id,
            inviteSenderId: this.invite.sender_id,
          });
          this.actionRequiredCounts.setInvitesReceivedPending(
            response.data.invites_received_pending,
          );
          this.complete = true;
        })
        .catch((error) => {
          this.errorMessage = error.response?.data?.message;
          this.notifications.push({
            time: Date.now(),
            type: 'warning',
            message: this.errorMessage,
            icon: 'fa-circle-info',
          });
        })
        .then(() => {
          this.submitting = false;
        });
      return true;
    },
    modalClose() {
      this.$refs.modal.close();
    },
  },
  computed: {
    incomplete() {
      return !this.complete;
    },
    eventEdition() {
      return store.eventEdition;
    },
  },
};
</script>
