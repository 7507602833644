<template>
  <BaseModal
    height="tall"
    startOpen
    ref="modal"
    :notifications="notifications"
  >

    <template #header v-if="invite.id">
      <ContactModalHeader
        v-if="invite.recipient"
        :key="invite.recipient.id"
        :contact="invite.recipient"
      />
    </template>

    <template v-if="(!invite.id || submitting) && !errored">
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>

    <form
      v-if="invite.id && !submitting && !complete"
      @submit.prevent="submit"
      ref="withdrawInviteForm"
    >
      <h1 class="meeting-card__title">{{ __('meeting_invite.withdraw.header') }}</h1>
      <p v-html="
        __('meeting_invite.withdraw.subheader',
            {
              display_name: invite.recipient.display_name,
              job_title: invite.recipient.job_title,
              company_url: `/${this.eventEdition?.slug}/companies/${invite.recipient.company.id}`,
              company_display_name: invite.recipient.company.display_name
            }
          )">
      </p>
      <FrontendSelectInput
        :label="__('meeting_invite.withdraw.inputs.reason.label')"
        :placeholder="__('meeting_invite.withdraw.inputs.reason.placeholder')"
        v-model="withdrawReason"
        :options="withdrawReasons"
        required
      />
      <FrontendTextInput
        id="withdraw-message"
        name="message"
        :label="__('meeting_invite.withdraw.inputs.message.label')"
        v-model="withdrawMessage"
        type="textarea"
        :error="errorMessage"
        :max="500"
      />
    </form>

    <div class="meeting-invite-form__success" v-if="complete">
      <i class="fa-regular fa-circle-check info-icon info-icon--success"></i>
      <h1>{{ __('meeting_invite.withdraw.success.title') }}</h1>
      <p v-html="
      __('meeting_invite.withdraw.success.message',
          {
            company_url: `/${this.eventEdition?.slug}/companies/${invite.recipient.company.id}`,
            company_display_name: invite.recipient.company.display_name,
            display_name: invite.recipient.display_name,
            job_title: invite.recipient.job_title,
          }
        )">
      </p>
      <p class="meeting-invite-form__pending_invites"
          v-html="
      __('meeting_invite.pending_invites.remaining',
          {
            invite_spent: contactInviteCredits.invite_spent,
            invite_credits: contactInviteCredits.invite_credits
          }
        )"
      />
      <button class="button" @click="modalClose">
        {{ __('meeting_invite.withdraw.buttons.done') }}
      </button>
    </div>

    <template #footer>
      <div class="button-group" v-if="invite.id && incomplete">
        <button class="button button--secondary button--warning" @click.prevent="submit">
          {{ __('meeting_invite.withdraw.buttons.withdraw') }}
        </button>
        <button class="button button--tertiary" @click="modalClose">
          {{ __('meeting_invite.withdraw.buttons.cancel') }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import FrontendSelectInput from '../Inputs/FrontendSelectInput.vue';
import FrontendTextInput from '../Inputs/FrontendTextInput.vue';
import { store, contactInviteCredits } from '../../../Frontend/store';

export default {
  name: 'WithdrawMeetingModal',
  components: { FrontendSelectInput, FrontendTextInput },
  inject: ['eventEditionManager'],
  props: {
    inviteId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      complete: false,
      invite: {},
      withdrawReasons: [],
      withdrawReason: '',
      withdrawMessage: null,
      contactInviteCredits,
      errored: false,
      errorMessage: null,
      notifications: [],
    };
  },
  mounted() {
    axios.get(`/${this.eventEdition?.slug}/meeting-invites/${this.inviteId}/withdraw`, {})
      .then((response) => {
        if (response.data?.invite.id) {
          this.invite = response.data.invite;
          this.withdrawReasons = response.data.withdraw_reasons;
        }
      })
      .catch((error) => {
        this.errored = true;
        let messageText = '';
        if (error.response.status === 404) {
          // eslint-disable-next-line no-underscore-dangle
          messageText = this.__('meeting_invite.withdraw.errors.cannot_load');
        } else {
          // eslint-disable-next-line no-underscore-dangle
          messageText = this.__('meeting_scheduler.errors.invite_already_actioned');
        }
        this.notifications.push({
          time: Date.now(),
          type: 'warning',
          message: messageText,
          icon: 'fa-circle-info',
        });
      });
  },
  methods: {
    submit() {
      if (!this.$refs.withdrawInviteForm.checkValidity()) {
        this.$refs.withdrawInviteForm.reportValidity();
        return false;
      }

      this.submitting = true;

      axios.post(`/${this.eventEdition?.slug}/meeting-invites/${this.inviteId}/withdraw`, {
        withdraw_reason: this.withdrawReason,
        withdraw_message: this.withdrawMessage,
      })
        .then((response) => {
          this.emitter.emit('invites.withdrawn', {
            inviteId: response.data.invite_id,
            inviteRecipientId: this.invite.recipient_id,
          });
          this.contactInviteCredits.setInviteSpent(response.data.invite_spent);
          this.complete = true;
        })
        .catch((error) => {
          this.errored = true;
          this.notifications.push({
            time: Date.now(),
            type: 'warning',
            message: error.response?.message,
            icon: 'fa-circle-info',
          });
        })
        .then(() => {
          this.submitting = false;
        });

      return true;
    },
    modalClose() {
      this.$refs.modal.close();
    },
  },
  computed: {
    incomplete() {
      return !this.complete;
    },
    eventEdition() {
      return store.eventEdition;
    },
  },
};
</script>
