<template>
  <section class="page-header">

    <div class="page-header__heading-container">
      <IconButton
      v-if="showBack"
      @click="$emit('back')"
      style="flex-shrink: 0"
      large
      icon="fa-arrow-left"
      />

      <slot name="header" v-if="slots.header"></slot>
      <div class="page-header__heading" v-else>
        <h1>
          <i class="far" :class="icon"></i>
          {{ header }}
        </h1>
        <div class="page-header__subheading" v-if="subheader" v-html="subheader"></div>
      </div>

      <IconButton
        v-if="showHelp"
        @click="$emit('help')"
        style="flex-shrink: 0"
        large
        icon="fa-question"
      />
    </div>

    <slot></slot>

    <div id="teleport-page-header" class="page-header__bottom"></div>
  </section>

</template>

<script setup>
import { useSlots } from 'vue';

const slots = useSlots();

defineProps({
  header: {
    type: String,
    required: false,
  },
  subheader: {
    type: String,
    required: false,
  },
  showBack: {
    type: Boolean,
    default: false,
  },
  showHelp: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: '',
  },
  company: {
    type: Object,
  },
});

defineEmits(['back', 'help']);
</script>
