<template>
  <BaseModal
    startOpen
    width="narrow"
    titleIcon="fa-eye"
    :title="__('profile.modals.previewContactCard.modalTitle')"
    ref="modal"
    >
    <ContactCard v-if="loaded" :model="cardPreview" />
    <template #footer>
      <div class="button-group">
        <button class="button" @click="$refs.modal.close()">
          <i class="fas fa-arrow-circle-left"></i> {{ __('buttons.return') }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import { store } from '~/Frontend/store';

export default {
  data() {
    return {
      loaded: false,
      cardPreview: null,
    };
  },
  mounted() {
    axios.get(`/${store.eventEdition.slug}/profile/preview`, this.input)
      .then(({ data: { cardPreview } }) => {
        this.cardPreview = cardPreview;
        this.loaded = true;
      });
  },
};
</script>
