<template>
  <div>
    <div class="registration-closed">
      <h2 class="registration--title">{{ form.external_title }}</h2>
      <p class="registration--description">
        {{ form.post_close_message }}
      </p>
      <p class="registration--description">
        {{ __('registrations.form.screens.closed.alert_message') }}
      </p>
    </div>
    <RegistrationFooter></RegistrationFooter>
  </div>
</template>

<script>
import RegistrationFooter from '~/Components/Frontend/Registration/Screens/RegistrationFooter.vue';

export default {
  name: 'RegistrationClosed',
  components: [
    RegistrationFooter,
  ],
  props: {
    form: {
      required: true,
      type: Object,
    },
  },
};
</script>
