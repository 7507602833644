<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.company.tagline') }}
      <FeBadge v-if="!data.tagline" alert small />
    </div>
    <div class="profile-row__value" v-if="data.tagline"> {{ data.tagline }} </div>
    <div class="profile-row__value profile-row__value--invalid" v-else>
      {{ __('ui.notAdded') }}
    </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal">
        <i class="fa fa-edit"></i>
        {{ __('buttons.edit') }}
      </button>
    </div>
  </div>
  <ModalForm
    :form="{
      action: '/profile/company-short-bio',
      method: 'patch',
      name: 'tagline',
    }"
    :modal="{
      title: __('ui.edit'),
      titleIcon: 'far fa-edit',
    }"
    @success="update"
    ref="modal"
  >
    <FormTextarea
      name="tagline"
      :label="__('crm.company.tagline')"
      v-model="inputBio"
      :rows="5"
      :max="500"
      :generate-ai-url="store.platform.enable_ai_generated_company_bios ? '/profile/generate-company-short-bio-text' : ''"
      required
    />
    <template #footer>
      <div class="button-group">
        <button type="button" class="button button--secondary" @click="closeModal">
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit>
          {{ __('buttons.save') }}
        </FormSubmit>
      </div>
    </template>
  </ModalForm>
</template>

<script>
import { store } from '~/Frontend/store';

export default {
  props: {
    shortBio: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      store,
      inputBio: this.shortBio || '',
      data: {
        tagline: this.shortBio,
      },
    };
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    update(data) {
      this.data = data;
      this.closeModal();
    },
  },
};

</script>;
