<template>
  <div :style="[complete ? 'opacity: 0.5' : '']">
    <FrontendTextInput
      :label="details.label"
      :placeholder="details.placeholder"
      :modelValue="payload.answer"
      @update:modelValue="newValue => updatePayload('answer', newValue)"
      type="text"
      required
      :disabled="complete"
    />
    <FrontendTextInput
      v-if="!complete"
      :label="details.confirm_label"
      :placeholder="details.confirm_placeholder"
      :modelValue="payload.confirm_answer"
      @update:modelValue="newValue => updatePayload('confirm_answer', newValue)"
      type="text"
      required
    >
      <template #description>
        <p class="frontend-text-input__description" v-html="details.helper"></p>
      </template>
    </FrontendTextInput>
  </div>
</template>

<script>
import FrontendTextInput from '~/Components/Frontend/Inputs/FrontendTextInput.vue';

export default {
  components: {
    FrontendTextInput,
  },
  emits: ['updatePayload'],
  props: {
    complete: Boolean,
    details: Object,
    payload: Object,
    errors: Object,
  },
  methods: {
    updatePayload(field, value) {
      this.$emit('updatePayload', field, value);

      const valid = this.payload.answer.length && (this.payload.answer === this.payload.confirm_answer);

      this.$emit('updatePayload', 'isValid', valid);
    },
  },
};
</script>
