<template>
  <button
    :disabled="disabled || loading"
    class="button button--secondary button--wide"
    @click="$emit('loadMore')"
    ref="loadMoreButton"
  >
    <span v-show="loading">
      <i class="fa-solid fa-circle-notch fa-spin"></i>
    </span>
    {{ __('directory.pagination.load_more') }}
  </button>

</template>

<script>
export default {
  props: {
    disabled: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      observer: null,
    };
  },
  emits: ['loadMore'],
  mounted() {
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.$emit('loadMore');
      }
    });

    this.observer.observe(this.$refs.loadMoreButton);
  },
  unmounted() {
    this.observer.disconnect();
  },
};
</script>
