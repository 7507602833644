<template>
  <section>
    <h2>{{ __('hubs.meetings.teamSchedules') }}</h2>
    <p>{{ __('hubs.meetings.teamSchedules.instructions') }}</p>
    <FeLoading v-if="loading && periods.length === 0" />
    <h3 v-else-if="error"> {{ error }} </h3>
    <template v-else>
      <div class="profile-table">
        <div class="profile-table__header-action">
          <div class="filter-row no-scroll-bars">
            <FrontendRadioInput
              v-model="selectedPeriod"
              :options="periods.map((period) => ({
                text: period.start_datetime,
                value: period.id,
              }))"
            />
          </div>
        </div>
      </div>
      <div class="profile-table__body list-group">
        <FeLoading v-if="loading" />
        <div v-for="bookable in bookables"
:key="bookable.id"
        class="profile-table__row profile-table__row--with-status-border profile-table__row--spaced"
        >
        <div>
          <strong>{{ bookable.start_datetime }}</strong>
          <br>
          {{ bookable.duration }}
        </div>
        <div>
          <template v-if="bookable.type === 'Unavailability'">
            {{ __('hubs.meetings.teamSchedules.unavailable') }} <br>
          </template>
          <template v-else>
            {{ bookable.title }} <br>
          </template>
          <MultipleContactAvatar :contacts="bookable.contacts" size="xsmall" />
        </div>
      </div>
      <div class="profile-table__body list-group" v-if="(!loading) && bookables.length === 0">
        <div class="profile-table__row profile-table__row--with-status-border profile-table__row--spaced">
          {{ __('hubs.meetings.teamSchedules.combinedBookings.empty') }}
        </div>
      </div>
    </div>
  </template>
</section>
</template>

<script>
import axios from 'axios';
import FeLoading from './Core/Misc/FeLoading.vue';

export default {
  components: {
    FeLoading,
  },
  data() {
    return {
      bookables: [],
      loading: true,
      error: null,
      selectedPeriod: null,
      periods: [],
    };
  },
  watch: {
    selectedPeriod() {
      this.getScheduleByPeriod();
    },
  },
  methods: {
    getScheduleByPeriod() {
      const url = window.location.pathname;

      this.loading = true;
      axios.get(`${url}/my-schedule/combined-schedules`, {
        params: {
          period: this.selectedPeriod,
        },
      }).then((response) => {
        this.bookables = response.data.bookables;
        this.loading = false;
      });
    },
  },
  mounted() {
    const url = window.location.pathname;
    axios.get(`${url}/my-schedule/combined-schedules`)
      .then((response) => {
        this.bookables = response.data.bookables;
        this.periods = response.data.periods;
        this.selectedPeriod = response.data.period_id;
      }).catch((err) => {
        if (!('response' in err)) {
          throw (err);
        }
        if (err.response.status === 403) {
          this.error = 'Insufficient badge permissions';
        }
      }).then(() => {
        this.loading = false;
      });
  },
};
</script>
