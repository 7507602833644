<!-- src/components/ShowHideText.vue -->
<template>
  <PreWrap :text="displayText" />
  <button
    v-if="this.text.length > this.maxLength"
    class="button button--link text-small ms-1"
    @click="toggle"
  >
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  name: 'ShowHideText',
  props: {
    text: {
      type: String,
    },
    maxLength: {
      type: Number,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    buttonText() {
      return this.isExpanded ? this.__('buttons.show_less') : this.__('buttons.show_more');
    },
    displayText() {
      return !this.isExpanded && this.text.length > this.maxLength ? `${this.text.slice(0, this.maxLength)}...` : this.text;
    },
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
