<template>
  <BaseModal
    height="tall"
    startOpen
    ref="modal"
    :notifications="notifications"
  >
    <!-- this is just a redirector -->
    <template v-if="(!loaded || !contact.id) && !errored">
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>

  </BaseModal>
</template>

<script>
import axios from 'axios';
import { store } from '../../../Frontend/store';

export default {
  props: {
    contactId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errored: false,
      loaded: false,
      contact: {},
      notifications: [],
    };
  },
  methods: {
    loadData() {
      axios
        .get(`/${this.eventEdition.slug}/contacts/${this.contactId}/conversation`)
        .then((response) => {
          if (response.data.meeting_id) {
            window.location.hash = `/meetings/${response.data.meeting_id}/details`;
          } else if (response.data.invite_id) {
            window.location.hash = `/invites/${response.data.invite_id}/show`;
          } else {
            window.location.hash = `/contacts/${this.contactId}/profile`;
          }
        })
        .catch((response) => {
          this.errored = true;
          this.errorMessage = response?.data?.message;
          this.notifications.push({
            time: Date.now(),
            type: 'error',
            message: "Couldn't load contact information.",
            icon: 'fa-circle-info',
          });
        });
    },
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
