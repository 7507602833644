<template>
  <BaseModal
    :title="title"
    ref="modal"
  >
    <div v-if="hasDroppedCard" class="company__confirm-drop-business-card-success">
      <i class="fa-regular fa-circle-check info-icon info-icon--success"></i>
      <h1>{{ __('company.drop_business_card.share_success.title') }}</h1>
      <p v-html="__('company.drop_business_card.share_success.message', {
        company_display_name: company.display_name
      })">
      </p>
    </div>
    <template v-else>
      <p v-html="__('company.drop_business_card.agreement', {
          company_display_name: company.display_name,
        })">
      </p>

      <p class="company__drop-business-card confirm-text"
          v-html="__('company.drop_business_card.disclaimer', {
            company_display_name: company.display_name
          })
        ">
      </p>
    </template>

    <template #footer>
      <template v-if="hasDroppedCard">
        <button class="button button--primary" @click="modalClose">
          {{ __('company.drop_business_card.share_success.done') }}
        </button>
      </template>
      <template v-else>
        <button class="button button--primary" @click="shareBusinessCard">
          {{ __('company.drop_business_card.share') }}
        </button>
        <a class="button button--tertiary" @click="modalClose">
          {{ __('company.drop_business_card.cancel') }}
        </a>
      </template>
    </template>

  </BaseModal>

  <button v-if="!hasDroppedCard" class="button" :class="{'button--secondary': isSecondary === true}" @click.stop="modalOpen">
    <i class="fas fa-share-from-square"></i> {{ __('company.actions.drop_business_card') }}
  </button>
  <button v-if="hasDroppedCard" class="button button--secondary disabled" :class="{'button--secondary': isSecondary === true}" >
    <i class="fas fa-circle-check"></i> {{ __('company.actions.business_card_dropped') }}
  </button>

</template>

<script>
import axios from 'axios';
import { store } from '../../../Frontend/store';

export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
    isSecondary: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      hasDroppedCard: this.company.hasDroppedCard,
    };
  },
  methods: {
    modalOpen() {
      this.$refs.modal.open();
    },
    modalClose() {
      this.$refs.modal.close();
    },
    shareBusinessCard() {
      this.loading = true;
      axios.post(`/${this.eventEdition?.slug}/companies/${this.company.id}/drop-business-card`)
        .then(() => {
          this.emitter.emit('businessCardDropped', { companyId: this.company.id });
        }).catch((response) => {
          this.errors = response.data?.errors ?? {};
        }).then(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.emitter.on('businessCardDropped', (eventData) => {
      if (eventData.companyId === this.company.id) {
        this.hasDroppedCard = true;
      }
    });
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
    title() {
      // eslint-disable-next-line no-underscore-dangle
      return this.hasDroppedCard ? null : this.__('company.drop_business_card.header');
    },
  },
};
</script>
