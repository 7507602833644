<template>
  <label class="form-input" :class="{'form-input--invalid': invalid}">
    <FormLabel v-bind="{label, required}" />
    <select class="form-input__input" v-bind="inputAttrs" v-model="modelValue" ref="inputElement">
      <option v-if="placeholder" disabled value=""> {{ placeholder }} </option>
      <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.text }}
      </option>
    </select>
    <FormError :errors="errors" />
  </label>
</template>

<script>

import { computed } from 'vue';

export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    default: {
      type: String,
      default: '',
    },
    label: {
      required: false,
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    name: {
      required: true,
      type: String,
    },
    /**
     * @type Object option
     * @type String option.value
     * @type String option.text
     * @prop option[] options
     * */
    options: {
      required: Array,
    },
    placeholder: {
      type: String,
      default: '',
    },
    autofocus: Boolean,
  },
  emits: ['change'],
  data() {
    return {
      modelValue: this.default,
    };
  },
  inject: ['feForm'],
  computed: {
    id() {
      return `${this.feForm.name}-${this.name}`;
    },
    errors() {
      return this.feForm.error(this.name).value || [];
    },
    invalid() {
      return this.errors.length > 0;
    },
    labelAttrs() {
      const attrs = {
        icon: this.icon,
        label: this.label,
      };

      if ('required' in this.$attrs) {
        attrs.required = this.$attrs.required;
      }

      return attrs;
    },
    inputAttrs() {
      const attrs = { ...this.$attrs };
      // Removing required from html as backend validation is nicer
      if ('required' in attrs) {
        delete attrs.required;
      }

      attrs.id = this.id;
      attrs.name = this.name;
      return attrs;
    },

  },
  mounted() {
    this.feForm.register(this.name, computed(() => this.modelValue));
    if (this.autofocus) {
      this.$refs.inputElement.focus();
    }
  },
};

</script>
