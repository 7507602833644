<template>
  <BaseModal
    height="tall"
    startOpen
    ref="modal"
  >

    <template #header>
      <ContactModalHeader
        v-if="originalInvite?.sender"
        :key="originalInvite.sender_id"
        :contact="originalInvite.sender"
      />
    </template>

    <template v-if="!originalInvite.id || submitting">
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>

    <form
      v-if="originalInvite?.id && incomplete && !submitting"
      @submit.prevent="submit"
      ref="forwardInviteForm"
    >
      <h2>{{ __('meeting_invite.forward_invite.header') }}</h2>
      <p v-html="
      __('meeting_invite.forward_invite.subheader',
          {
            display_name: originalInvite?.sender.display_name,
            job_title: originalInvite?.sender.job_title,
            company_url: `/${eventEdition?.slug}/companies/${originalInvite?.sender.company.id}`,
            company_display_name: originalInvite?.sender.company.display_name
          }
        )"
      />
      <div class="meeting-invite-form__recipients">
        <FrontendRadioInputs
          v-model="to"
          :options="recipientOptions"
          :errors="errors?.forward_contact"
          name="forward_to"
          required
        />
      </div>
      <FrontendTextInput
        id="invite-message"
        name="message"
        :label="__('meeting_invite.forward_invite.inputs.forward_to_colleague.label')"
        v-model="message"
        :placeholder="__('meeting_invite.forward_invite.inputs.forward_to_colleague.placeholder')"
        type="textarea"
        :max="255"
        :errors="errors?.message"
      />
    </form>

    <div v-if="complete && !submitting" class="meeting-invite-form__success">
      <i class="fa-regular fa-circle-check info-icon info-icon--success"></i>
      <h1> {{ __('meeting_invite.forward_invite.success.title') }} </h1>
      <p v-html="
      __('meeting_invite.forward_invite.success.message',
          {
            full_name: originalInvite?.sender.full_name,
            job_title: originalInvite?.sender.job_title,
            company_url: `/${eventEdition?.slug}/companies/${originalInvite?.sender.company.id}`,
            company_display_name: originalInvite?.sender.company.display_name,
          }
        )"
      />
      <p class="meeting-invite-form__pending_invites"
          v-html="
      __('meeting_invite.pending_invites.remaining',
          {
            invite_spent: contactInviteCredits.invite_spent,
            invite_credits: contactInviteCredits.invite_credits
          }
        )"
      />
      <a
        v-if="nextInviteId"
        class="button button--wide button--primary"
        :href="`#/invites/${nextInviteId}/show`"
      >
        {{ __('meeting.meeting_confirmed.next_invite') }} <i class="fa-light fa-arrow-right"></i>
      </a>
      <button v-else class="button button--wide" @click="modalClose">
        {{ __('meeting_invite.forward_invite.buttons.done') }}
      </button>
    </div>

    <template #footer>
      <div class="button-group" v-if="originalInvite?.id && incomplete">
        <button class="button button--wide button--primary" @click.prevent="submit">
          {{ __('meeting_invite.forward_invite.buttons.forward') }}
        </button>
        <a
          :href="`#/invites/${originalInvite?.id}/show`"
          class="button button--wide button--secondary"
        >
          {{ __('meeting_invite.forward_invite.buttons.cancel') }}
        </a>
      </div>
    </template>

  </BaseModal>
</template>

<script>
import axios from 'axios';
import FrontendTextInput from '../Inputs/FrontendTextInput.vue';
import FrontendRadioInputs from '../Inputs/FrontendRadioInput.vue';
import { store, contactInviteCredits, actionRequiredCounts } from '../../../Frontend/store';

export default {
  name: 'ForwardMeetingInviteModal',
  components: { FrontendRadioInputs, FrontendTextInput },
  inject: ['eventEditionManager'],
  props: {
    inviteId: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ['forwardedInvite', 'cancel'],
  data() {
    return {
      recipients: [],
      submitting: false,
      complete: false,
      to: null,
      message: '',
      errorMessage: null,
      contactInviteCredits,
      originalInvite: {},
      nextInviteId: null,
      errors: {},
      actionRequiredCounts,
    };
  },
  created() {
    axios.get(`/${this.eventEdition?.slug}/meeting-invites/${this.inviteId}/forward`)
      .then((response) => {
        this.recipients = response.data?.recipients || [];
        this.originalInvite = response.data?.invite;
      }).catch(({ response }) => {
        this.errorMessage = response?.data?.message;
        this.errors = response?.data?.errors;
      });
  },
  methods: {
    submit() {
      if (!this.$refs.forwardInviteForm.checkValidity()) {
        this.$refs.forwardInviteForm.reportValidity();
        return false;
      }

      this.submitting = true;

      axios.post(`/${this.eventEdition?.slug}/meeting-invites/${this.inviteId}/forward`, { forward_contact: this.to, message: this.message })
        .then((response) => {
          this.complete = true;
          this.nextInviteId = response.data.next_invite?.id;
          this.emitter.emit('invites.forwarded', {
            inviteId: this.originalInvite.id,
            inviteSenderId: this.originalInvite.sender.id,
          });
          this.contactInviteCredits.setInviteSpent(response.data.invite_spent);
          this.actionRequiredCounts.setInvitesReceivedPending(
            response.data.invites_received_pending,
          );
        }).catch(({ response }) => {
          this.errorMessage = response?.data?.message;
          this.errors = response?.data?.errors ?? {};
        }).then(() => {
          this.submitting = false;
        });

      return true;
    },
    modalClose() {
      this.$refs.modal.close();
    },
  },
  computed: {
    recipientOptions() {
      return this.recipients.map((recipient) => ({
        value: recipient.id,
        text: recipient.full_name,
      }));
    },
    incomplete() {
      return !this.complete;
    },
    eventEdition() {
      return store.eventEdition;
    },
    recipient() {
      return this.recipients.find((recipient) => recipient.id === this.to);
    },
  },
};
</script>
