<template>
  <BaseModal
    ref="modal"
    :title="__('my_schedule.bookings.share_schedule')"
    startOpen
    centered
  >
    <template #default>
      <FeLoading v-if="loading"></FeLoading>
      <template v-else>
        <div class="profile-select__profile" v-for="colleague of colleagues" :key="colleague.id">
            <div class="profile-select__avatar">
              <ContactAvatar :contact="colleague" size="small" />
            </div>
            <div class="profile-select__name">
              <b>{{ colleague.display_name }}</b>
              <span class="hide-for-mobile">, </span>
              <br class="hide-for-tablet-up" />
              {{ colleague.job_title }} <br />
            </div>
            <FrontendSwitch
              v-model="selectedColleagueIDs"
              :modelValue="selectedColleagueIDs"
              :value="colleague.id"
              :trueValue="colleague.id"
            />
        </div>
        <div v-if="! eligibleColleaguesExist">{{ __('my_schedule.bookings.share_no_colleagues') }}</div>
      </template>
    </template>
    <template #footer>
      <button class="button" @click="save" v-if="eligibleColleaguesExist">
        {{ __('buttons.save') }}
      </button>
      <button class="button" @click="close" v-else>
        {{ __('buttons.close') }}
      </button>
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import BaseModal from '~/Components/Frontend/BaseModal.vue';
import ContactAvatar from '~/Components/Frontend/CRM/Components/ContactAvatar.vue';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import FrontendSwitch from '~/Components/Frontend/Inputs/FrontendSwitch.vue';

export default {
  components: {
    BaseModal,
    ContactAvatar,
    FeLoading,
    FrontendSwitch,
  },
  data() {
    return {
      colleagues: {},
      selectedColleagueIDs: [],
      loading: false,
    };
  },
  computed: {
    eligibleColleaguesExist() {
      return this.colleagues.length > 0;
    },
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    save() {
      const url = window.location.pathname;

      axios.patch(`${url}/schedule-share-list`, {
        colleagues: this.selectedColleagueIDs,
      }).then(() => {
        this.$refs.modal.close();
      // eslint-disable-next-line no-console
      }).catch((err) => console.log(err));
    },
  },
  mounted() {
    this.loading = true;
    axios.get('my-schedule/shareable-colleagues')
      .then((response) => {
        if (response.data.colleagues.length > 0) {
          this.colleagues = response.data.colleagues;
          this.selectedColleagueIDs = response.data.shared_with;
        } else {
          this.colleagues = [];
        }
        this.loading = false;
      });
  },
};
</script>
