<template>
  <div :style="[complete ? 'opacity: 0.5' : '']">
    <div style="padding: var(--sizing-4); background-color: var(--gray-1); margin-bottom: var(--sizing-2); display: grid; gap: var(--sizing-2)">
      <div>
        <label class="multilevel-dropdown__option">
          <input
            type="radio"
            class="multilevel-dropdown__checkbox"
            :checked="payload.answer === 'yes'"
            @input="updatePayloadYes($event.target.checked)"
            @change="updatePayloadYes($event.target.checked)"
            :disabled="complete"
          >
          <span class="multilevel-dropdown__label">{{ details.yes_label }}</span>
        </label>
      </div>
      <div>
        <label class="multilevel-dropdown__option">
          <input
            type="radio"
            class="multilevel-dropdown__checkbox"
            :checked="payload.answer === 'no'"
            @input="updatePayloadNo($event.target.checked)"
            @change="updatePayloadNo($event.target.checked)"
            :disabled="complete"
          >
          <span class="multilevel-dropdown__label">{{ details.no_label }}</span>
        </label>
      </div>
      <div style="font-size: var(--font-small); color: var(--gray-4);" v-html="details.helper"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  emits: ['updatePayload'],
  props: {
    complete: Boolean,
    details: Object,
    payload: Object,
    errors: Object,
  },
  methods: {
    updatePayloadYes(value) {
      if (value) {
        this.$emit('updatePayload', 'answer', 'yes');
        this.$emit('updatePayload', 'isValid', true);
      }
    },
    updatePayloadNo(value) {
      if (value) {
        this.$emit('updatePayload', 'answer', 'no');
        this.$emit('updatePayload', 'isValid', true);
      }
    },
  },
};
</script>
