<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __(`crm.phone.types.${type}`) }}
      <FeBadge v-if="attentionNeeded" alert small />
    </div>
    <div class="profile-row__value tag-list tag-list--vertical" v-if="data.phones.length > 0">
      <div v-for="phone in data.phones" :key="phone.id" class="tag">
        <div class="tag__part">
          <div
            class="vti__flag"
            :class="phone.phone_country.toLowerCase()"
            style="margin-left: -2px; margin-right: -1px"
            ></div>
          <!-- Weird alignment... -->
          <div style="margin-top: 1px">
            {{ phone.phone }}
          </div>
        </div>
        <div class="tag__part tag__part--action">
          <EditIconButton @click="openModal(`updateModal${phone.id}`)" />
        </div>
        <div class="tag__part tag__part--action">
          <DeleteIconButton @click="openModal(`removeModal${phone.id}`)" />
        </div>
      </div>
    </div>
    <div class="profile-row__value profile-row__value--invalid" v-else>
      {{ __('ui.notAdded') }}
    </div>
    <div class="profile-row__action">
      <button
        class="button button--link"
        @click="openModal('createModal')"
        >
        <i class="far fa-plus-circle"></i>
        {{ __('buttons.add') }}
      </button>
    </div>
  </div>
  <!-- Create Form -->
  <ModalForm
    :form="{
      action: '/profile/phones',
      method: 'post',
      name: 'createPhone',
    }"
    :modal="{
      title: __('ui.add'),
      titleIcon: 'far fa-plus-circle',
    }"
    minHeight="500px"
    ref="createModal"
    @success="({ phone }) => {data.phones.push(phone); closeModal('createModal')}"
  >
    <FormPhone :label="__(`crm.phone.types.${type}`)" name="phone"></FormPhone>
    <FormHidden name="phone_type" :value="type" />
    <div>
      <p><small><i class="far fa-info-circle"></i> {{ __('profile.rows.phone.info') }}</small></p>
    </div>
    <template #footer>
      <div class="button-group">
        <button
          type="button"
          class="button button--secondary"
          @click="closeModal('createModal')"
          >
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit> {{ __('buttons.save') }}</FormSubmit>
      </div>
    </template>
  </ModalForm>
  <!-- Update Forms -->
  <ModalForm
    v-for="phone, index in phones"
    :key="phone.id"

    :form="{
      action: `/profile/phones/${phone.id}`,
      method: 'patch',
      name: 'updatePhone',
      }"
    :modal="{
      title: __('ui.edit'),
      titleIcon: 'far fa-edit',
    }"
    minHeight="500px"
    :ref="`updateModal${phone.id}`"
    @success="({phone: updatePhone}) => {
      data.phones[index] = updatePhone;
      closeModal(`updateModal${phone.id}`)
    }"
    >
    <div>
      <FormPhone :label="__(`crm.phone.types.${type}`)" :default="phone" name="phone"/>
      <FormHidden name="phone_type" :value="type" />
      <p> {{ __('profile.rows.phone.info') }} </p>
    </div>
    <template #footer>
      <div class="button-group">
        <button
          type="button"
          class="button button--secondary"
          @click="closeModal(`updateModal${phone.id}`)"
          >
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit> {{ __('buttons.save') }} </FormSubmit>
      </div>
    </template>
  </ModalForm>
  <!-- Delete Forms -->
  <ModalForm
    v-for="phone, index in phones"
    :key="phone.id"

    :form="{
      action: `/profile/phones/${phone.id}`,
      method: 'delete',
      name: 'deletePhone',
      }"

    :modal="{ title: __('ui.delete') }"
    :ref="`removeModal${phone.id}`"
    @success="() => { data.phones.splice(index, 1); closeModal(`removeModal${phone.id}`)}"
    >
    <p> {{ __('profile.rows.phone.deleteWarning') }} </p>
    <div class="tag-list">
      <div class="tag tag__part">
        <span
          class="vti__flag"
          :class="phone.phone_country.toLowerCase()"
          style="display: inline-block; margin-left: -2px; margin-right: -1px;"
          ></span>
        {{ phone.phone }}
      </div>
    </div>
    <template #footer>
      <div class="button-group">
        <button
          type="button"
          class="button button--secondary"
          @click="closeModal(`removeModal${phone.id}`)"
          >
          {{ __('buttons.cancel') }}
        </button>
        <button type="submit" class="button button--warning">
          {{ __('buttons.delete') }}
        </button>
      </div>
    </template>
  </ModalForm>
</template>

<script>
import useMyAccountStore from '~/Store/MyAccount';
import { mapActions } from 'pinia';
import FeBadge from '~/Components/Frontend/Core/Misc/FeBadge.vue';
import ModalForm from '~/Components/Frontend/Form/ModalForm.vue';
import FormPhone from '~/Components/Frontend/Form/Inputs/FormPhone.vue';
import FormHidden from '~/Components/Frontend/Form/Inputs/FormHidden.vue';
import DeleteIconButton from '~/Components/Frontend/Form/Inputs/DeleteIconButton.vue';
import EditIconButton from '~/Components/Frontend/Form/Inputs/EditIconButton.vue';
import FormSubmit from '~/Components/Frontend/Form/FormSubmit.vue';

export default {
  name: 'PhoneProfileRow',
  components: {
    FeBadge,
    FormHidden,
    FormPhone,
    FormSubmit,
    ModalForm,
    DeleteIconButton,
    EditIconButton,
  },
  props: {
    phones: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'mobile',
      validator: (val) => ['mobile', 'office'].includes(val),
    },
  },
  computed: {
    label() {
      return this.type === 'mobile' ? 'mobile' : 'office';
    },
    labelUpper() {
      return this.type === 'mobile' ? 'Mobile' : 'Office';
    },
    attentionNeeded() {
      return this.data.phones.length === 0;
    },
  },
  data() {
    return {
      data: {
        phones: this.phones,
      },
    };
  },
  methods: {
    ...mapActions(useMyAccountStore, ['loadSectionStatus']),
    openModal(ref) {
      let modal = this.$refs[ref];
      if (Array.isArray(modal)) {
        [modal] = modal;
      }
      if (!modal) {
        throw new Error(`No modal with ref ${ref}`);
      } else {
        modal.open();
      }
    },
    closeModal(ref) {
      let modal = this.$refs[ref];
      if (Array.isArray(modal)) {
        [modal] = modal;
      }
      if (!modal) {
        throw new Error(`No modal with ${ref}`);
      } else {
        modal.close();
      }
    },
    update(data) {
      this.data = data;
      this.closeModal();
    },
  },
  watch: {
    attentionNeeded() {
      this.loadSectionStatus();
    },
  },
};

</script>;
