<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('profile.rows.password.label') }}
      <FeBadge v-if="!passwordSet" alert small />
    </div>
    <div class="profile-row__value" v-if="passwordSet">
      ************
    </div>
    <div class="profile-row__value profile-row__value--invalid" v-else> {{ __('ui.notSet') }}</div>
    <div class="profile-row__action">
      <button class="button button--link" disabled v-if="success">
        {{ __('profile.rows.password.passwordLinkSent') }}
      </button>
      <button class="button button--link" @click="$refs.formModal.open()" v-else>
        <i class="far fa-key"></i> {{ passwordSet ? __('buttons.change') : __('buttons.set') }}
      </button>
    </div>
    <NotificationBar type="gray" v-if="user && user.user && user.user.password_last_changed_at">
      <span v-html=" __('profile.groups.password.lastChanged', {
        lastChangedAt: moment(user.user.password_last_changed_at).fromNow()
      })">
      </span>
    </NotificationBar>
  </div>

  <BaseModal
    ref="formModal"
    centered
    titleIcon="far fa-key"
    :title="__('profile.rows.password.modalTitle')"
    >
    <p>
      {{ __('profile.rows.password.changeMessage') }}
    </p>
    <p>
      <b> {{ user.user.email }} </b>
    </p>
    <template #footer>
      <div class="button-group">
        <button
          class="button button--primary"
          @click="setPassword"
          >
          <i class="fa fa-paper-plane"></i>
          {{ __('buttons.sendLink') }}
        </button>
      </div>
    </template>
  </BaseModal>
  <BaseModal ref="errorModal" :duration="5000" centered>
    <p class="margin-bottom: 3rem">
      <i class="fa-regular fa-circle-x text-warning" style="font-size: 5rem"></i>
    </p>
    <p>
      {{ error }}
    </p>
  </BaseModal>
  <BaseModal ref="successModal" :duration="5000" centered>
    <IconSuccess />
    <TX
      k="profile.rows.password.successMessage"
      :params="{ email: user.user.email }"
      >
    </TX>
    <template #footer>
      <div class="button-group">
        <button
          class="button button--primary"
          @click="$refs.successModal.close"
          >
          {{ __('buttons.done') }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import { user } from '~/Frontend/store';

export default {
  data() {
    return {
      loading: false,
      success: false,
      error: null,
      user,
      moment,
    };
  },
  computed: {
    passwordSet() {
      return this.user && this.user.user && this.user.user.has_password;
    },
  },
  methods: {
    setPassword() {
      this.loading = true;
      this.success = false;
      this.error = null;
      axios.post('/reset-password')
        .then(() => {
          this.success = true;
          this.$refs.successModal.open();
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.error = response.data.message;
            this.success = false;
            this.$refs.errorModal.open();
          }
        })
        .then(() => {
          this.loading = false;
          this.$refs.formModal.close();
        });
    },
  },
};

</script>;
