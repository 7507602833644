<template>
  <div class="profile-table">
    <div class="profile-table__header">
      <div class="profile-table__header-label">
        <h3>
          {{ __('profile.tables.videos.title') }}
          <small>
            {{ __('profile.tables.videos.count', {
              count: videosThatCountTowardsLimit.length,
              limit: limit
            }) }}
          </small>
        </h3>
      </div>
      <div class="profile-table__header-action">
        <button
          class="button button--link"
          @click="$refs.createModal.open()"
          :disabled="!canCreate"
          :class="{ 'button--disabled': !canCreate }"
          >
          <i class="far fa-plus-circle"></i>  {{ __('buttons.add') }}
        </button>
      </div>
      <VideoForm
        @uploadStarted="handleUploadStarted"
        ref="createModal"
        :demographics="demographics"
      />
    </div>
    <div v-if="list.length == 0" class="profile-table-row">
      {{ __('profile.tables.videos.emptyMessage') }}
      <a v-if="false" href="#" @click="$refs.createModal.open()">
        <span v-html="__('profile.tables.videos.addOne')"></span>
      </a>
    </div>
    <draggable
      tag="div"
      :component-data="{
        tag: 'div',
        type: 'transition-group',
        name: !drag ? 'flip-list' : null
      }"
      v-model="list"
      :animation="200"
      @start="handleStart"
      @end="drag = false"
      @sort="handleSort"
      item-key="id"
      class="profile-table__body list-group"
      ghost-class="ghost"
      handle=".handle"
      v-if="list.length > 0"
    >
      <template #item="{  element: video }">
        <VideoTableRow
          :video="video"
          :demographics="demographics"
          @updated="patchVideo"
          @deleted="patchVideo"
        />
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import _ from 'lodash';
import axios from 'axios';

export default {
  components: {
    draggable,
  },
  props: {
    videos: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
    },
    demographics: {
      type: Array,
    },
  },
  data() {
    return {
      list: this.videos,
      drag: false,
    };
  },
  computed: {
    canCreate() {
      return this.videosThatCountTowardsLimit.length < this.limit;
    },
    videosThatCountTowardsLimit() {
      const statuses = [
        'upload-failed',
        'errored',
        'upload-cancelled',
        'errored',
        'removed',
        'deleted',
      ];
      return this.list.filter((video) => !statuses.includes(video.status));
    },
  },
  methods: {
    patchVideo(videoAsset) {
      const index = this.list.findIndex((v) => v.id === videoAsset.id);
      this.list[index] = videoAsset;
    },
    handleStart(start) {
      const img = new Image();
      img.src = 'https://www.litmus.com/wp-content/uploads/2021/02/ease-applied-to-tween-with-bouncein-example.gif';
      start.originalEvent.dataTransfer.setDragImage(img, 10, 10);
      this.drag = true;
    },
    handleSort() {
      axios.patch('/company-profile/video/reorder', {
        keys: _.map(this.list, 'id'),
      }).catch((response) => { this.errors = response?.data?.errors; });
    },
    handleUploadStarted(result) {
      // A little hack to trigger a change
      result.fresh = true;
      this.list.push(result);
    },
    handleUpdate(result) {
      const newVideo = result.video;
      const index = this.list.findIndex((video) => video.id === newVideo.id);
      this.list[index] = newVideo;
    },
  },
};

</script>

<style>
  .ghost {
    opacity: 1;
    background: #c8ebfb;
  }
</style>
