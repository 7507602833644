<template>
  <div class="profile-table">
    <div class="profile-table__header">
      <div class="profile-table__header-label">
          <h3 v-html="__('profile.groups.catalogue.heading', type)"></h3>
      </div>
      <div class="profile-table__header-action">
        <button class="button button--link" @click="$refs.createModal.open()" v-if="! type.is_locked">
          <i class="far fa-plus-circle"></i> {{ __('buttons.add') }}
        </button>
        <div v-else class="profile-table__row-locked">
          <span>
            <i class="fas fa-lock"></i>
          </span>
          <span v-html="type.locked_message"></span>
        </div>
      </div>
      <CatalogueEntryForm
        :type="type"
        :demographics="demographics"
        :colleagues="colleagues"
        @success="handleCreate"
        ref="createModal"
        />
    </div>
    <div v-if="list.length == 0" class="profile-table-row">
      {{ __('profile.tables.catalogueEntry.emptyMessage', type) }}
    </div>
    <draggable
      tag="div"
      :component-data="{
        tag: 'div',
        type: 'transition-group',
        name: !drag ? 'flip-list' : null
      }"
      v-model="list"
      :animation="200"
      @start="handleStart"
      @end="drag = false"
      @sort="handleSort"
      item-key="id"
      class="profile-table__body list-group"
      ghost-class="ghost"
      handle=".handle"
      v-if="list.length > 0"
    >
      <template #item="{ index }">
        <CatalogueEntryTableRow
          v-model:entry="list[index]"
          :type="type"
          :demographics="demographics"
          :colleagues="colleagues"
          @deleted="list.splice(index, 1)"
          />
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import _ from 'lodash';
import axios from 'axios';

export default {
  components: {
    draggable,
  },
  props: {
    type: {
      type: Object,
      required: true,
    },
    entries: {
      type: Array,
      default: () => [],
    },
    demographics: {
      type: Array,
      default: () => [],
    },
    colleagues: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      list: this.entries,
      drag: false,
    };
  },
  methods: {
    handleStart(start) {
      const img = new Image();
      img.src = 'https://www.litmus.com/wp-content/uploads/2021/02/ease-applied-to-tween-with-bouncein-example.gif';
      start.originalEvent.dataTransfer.setDragImage(img, 10, 10);
      this.drag = true;
    },
    handleSort() {
      axios.patch('/profile/catalogue-entries/sort', {
        catalogueEntryIds: _.map(this.list, 'id'),
      }).catch((response) => { this.errors = response?.data?.errors; });
    },
    handleCreate(result) {
      // A little hack to trigger a change
      result.catalogue_entry.fresh = true;

      this.list.push(result.catalogue_entry);
      this.$refs.createModal.close();
    },
  },
};

</script>

<style>
.ghost {
  opacity: 1;
  background: #c8ebfb;
}
</style>
