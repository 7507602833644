<template>
  <TransitionGroup name="t-chat">
    <component
      v-for="chat in chats"
      :key="chat.type + chat.id"
      :is="chatTypes[chat.type]"
      v-bind="chat.data"
      :chat="chat"
    />
  </TransitionGroup>
  <div style="height: 1rem"></div>
</template>

<script>

import axios from 'axios';
import _ from 'lodash';
import { store, actionRequiredCounts } from '~/Frontend/store';
import InviteAcceptedChat from './Chats/InviteAcceptedChat.vue';
import InviteDeclinedChat from './Chats/InviteDeclinedChat.vue';
import InviteForwardedOnToChat from './Chats/InviteForwardedOnToChat.vue';
import InviteForwardedOnToMeChat from './Chats/InviteForwardedOnToMeChat.vue';
import InviteForwardedChat from './Chats/InviteForwardedChat.vue';
import InviteSentChat from './Chats/InviteSentChat.vue';
import InviteWithdrawnChat from './Chats/InviteWithdrawnChat.vue';
import MeetingCancelledChat from './Chats/MeetingCancelledChat.vue';
import AdminChatMessage from './Chats/AdminChatMessage.vue';
import ChatMessage from './Chats/ChatMessage.vue';
import ColleagueAddedToMeeting from './Chats/ColleagueAddedToMeeting.vue';

export default {
  emits: ['loaded'],
  props: {
    meetingInviteId: {
      type: [String, Number],
    },
    meetingId: {
      type: [String, Number],
    },
    isBeingViewed: Boolean,
  },
  inject: ['eventEditionManager'],
  data() {
    return {
      chats: [],
      loading: false,
      timeoutTracker: false,
    };
  },
  methods: {
    update() {
      if (this.loading) {
        return;
      }

      clearTimeout(this.timeoutTracker);
      this.loading = true;

      axios.get(this.endpoint)
        .then((response) => {
          this.chats = response.data.chats;
          this.loading = false;
          this.$emit('loaded');

          if (this.unreadChats.length > 0 && this.isBeingViewed) {
            setTimeout(this.markAsRead, 100);
          }
        })
        .then(() => {
          this.updateSoon();
        });
    },
    updateSoon() {
      if (!this.loading) {
        const wait = _.random(5000, 9000);
        clearTimeout(this.timeoutTracker);
        this.timeoutTracker = setTimeout(this.update, wait);
      }
    },
    markAsRead() {
      axios.post(`${this.endpoint}/mark-as-read`)
        .then((response) => {
          this.emitter.emit('inboxMessages.read');
          actionRequiredCounts.setUnreadMessages(
            response.data.unread_messages,
          );
        });
    },
  },
  mounted() {
    this.update();
  },
  unmounted() {
    clearTimeout(this.timeoutTracker);
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
    endpoint() {
      if (this.meetingId) {
        return `/${this.eventEdition?.slug}/meetings/${this.meetingId}/conversation`;
      }
      return `/${this.eventEdition?.slug}/meeting-invites/${this.meetingInviteId}/conversation`;
    },
    chatTypes() {
      return {
        AdminChatMessage,
        ChatMessage,
        ColleagueAddedToMeeting,
        InviteAcceptedChat,
        InviteDeclinedChat,
        InviteForwardedOnToChat,
        InviteForwardedOnToMeChat,
        InviteForwardedChat,
        InviteSentChat,
        InviteWithdrawnChat,
        MeetingCancelledChat,
      };
    },
    unreadChats() {
      return this.chats.filter((chat) => !chat.read);
    },
  },
};

</script>
