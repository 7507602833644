<template>
  <div class="video-directory-card" :class="{'video-directory-card--faded': isHidden}" @click="openModal">
    <div class="video-directory-card__sub-menu">
      <SubOptions>
        <template v-slot:button="{toggle}">
          <IconButton @click.stop="toggle" small icon="fa-ellipsis" />
        </template>
        <SubOptionFavourite
          :favouritableId="model.id"
          favouritableType="VideoAsset"
          v-model:isFavourited="isFavourited"
        />
        <SubOption :link="modalHref" icon="fas fa-eye" :label="__('video_card.view_detail')" />
        <SubOptionHide
          :hideableId="model.id"
          hideableType="VideoAsset"
          v-model:isHidden="isHidden"
        />
      </SubOptions>
    </div>
    <div class="video-directory-card__video-container">
      <VideoPlayer
         :videoAsset="model"
         className="video-directory-card__video"
      />
    </div>
    <h5 class="video-directory-card__title">{{ model.title }}</h5>
    <p class="video-directory-card__description">
      <TextClamp
        :text="model.description"
        :maxLines="2"
        maxHeight="48px"
        ellipsis="…"
        autoResize
      >
        <template #before>
          <i
            v-if="isFavourited"
            class="fa-2xs fa fa-star"
            style="margin-right: 4px"
          ></i>
        </template>
      </TextClamp>
    </p>

    <div class="video-directory-card__demographic-container">
      <div
        v-if="model.visible_demographic_values?.length > 0"
        class="video-directory-card__demographic"
      >
        {{ model.visible_demographic_values[0].full_name }}
      </div>
      <div v-if="model.visible_demographic_values?.length > 1"
        class="video-directory-card__demographic"
        style="flex-shrink: 0"
      >
        + {{ model.visible_demographic_values.length - 1 }}
      </div>
    </div>

    <div class="video-directory-card__action">
      <a class="button button--secondary" @click.stop="openModal">
        {{ __('video_card.more') }}
      </a>
    </div>
  </div>
</template>

<script>
import TextClamp from 'vue3-text-clamp';
import SubOptionFavourite from '~/Components/Frontend/Profile/Actions/SubOptionFavourite.vue';
import SubOptionHide from '~/Components/Frontend/Profile/Actions/SubOptionHide.vue';
import SubOption from '~/Components/Frontend/SubOption.vue';
import SubOptions from '~/Components/Frontend/SubOptions.vue';

export default {
  components: {
    TextClamp, SubOptionFavourite, SubOptionHide, SubOption, SubOptions,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isFavourited: this.model.isFavourited,
      isHidden: this.model.isHidden,
    };
  },
  methods: {
    openModal() {
      window.location.href = this.modalHref;
    },
  },
  computed: {
    modalHref() {
      return `#/video/${this.model.id}/preview`;
    },
  },
};
</script>
