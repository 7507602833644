<template>
  <div v-html="
      __('meeting_invite.pending_invites.remaining',
          {
            invite_spent: contactInviteCredits.invite_spent,
            invite_credits: contactInviteCredits.invite_credits
          }
        )"
  />
</template>

<script>

import { contactInviteCredits } from '~/Frontend/store';

export default {
  name: 'PendingLimitReactiveDisplay',
  data() {
    return {
      contactInviteCredits,
    };
  },
};
</script>
