<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.contact.email') }}
      <FeBadge v-if="!email" alert small />
    </div>
    <div class="profile-row__value" v-if="email"> {{ email }} </div>
    <div class="profile-row__value profile-row__value--invalid" v-else>
      {{ __('ui.notAdded') }}
    </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal">
        <i class="fa fa-edit"></i>
        {{ __('buttons.edit') }}
      </button>
    </div>
  </div>
 <ModalForm
   :form="{
      action: '/profile/email',
      method: 'patch',
      name: 'email',
    }"
   :modal="{
     title: __('ui.edit'),
     titleIcon: 'far fa-edit',
   }"
    ref="modal"
    @success="update"
  >
    <FormText name="email"
:default="email"
:label="__('crm.contact.email')"
required
/>
    <template #footer>
      <div class="button-group">
        <button type="button" class="button button--secondary" @click="closeModal">
          {{ __('buttons.cancel') }}
        </button>

        <FormSubmit> {{ __('buttons.save') }} </FormSubmit>
      </div>
    </template>
  </ModalForm>
</template>

<script>

import { user } from '~/Frontend/store';

export default {
  computed: {
    email: () => user.user.email,
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    update({ email }) {
      user.user.email = email;
      this.closeModal();
    },
  },
};

</script>
