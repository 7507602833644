<template>
  <p v-if="store.eventEdition">
    <a class="button" href="#/profile/preview">
      {{ __('profile.pages.profileInformation.previewButton') }}
      <i class="far fa-arrow-right"></i>
    </a>
  </p>

  <NotificationBar type="dark">
    <span v-html="__('profile.informationSharingNotification')"></span>
  </NotificationBar>

  <FeLoading v-if="loading"></FeLoading>

  <template v-else>
    <ProfileGroup
      :heading="__('profile.groups.aboutMe.heading')"
    >
      <FullNameProfileRow />
      <JobTitleProfileRow />
      <AvatarProfileRow />
      <BioProfileRow />
      <CountryProfileRow :countries="countries" />
    </ProfileGroup>
    <ProfileGroup
      :heading="__('profile.groups.contactInformation.heading')"
    >
      <EmailProfileRow />
      <PhoneProfileRow v-if="mobilePhones" type="mobile" :phones="mobilePhones" />
      <PhoneProfileRow v-if="officePhones" type="office" :phones="officePhones" />
    </ProfileGroup>
    <ProfileGroup
      :heading="__('profile.groups.searchInformation.heading')"
    >
      <DemographicProfileRow
        v-for="demographic in visibleDemographics"
        :key="demographic.id"
        :demographic="demographic"
        v-model="selected[demographic.id]"
      />
      <LookingForProfileRow />
    </ProfileGroup>
  </template>
</template>

<script>

import axios from 'axios';
import { store } from '~/Frontend/store';
import AvatarProfileRow from '~/Components/Frontend/Profile/Rows/AvatarProfileRow.vue';
import BioProfileRow from '~/Components/Frontend/Profile/Rows/BioProfileRow.vue';
import CountryProfileRow from '~/Components/Frontend/Profile/Rows/CountryProfileRow.vue';
import DemographicProfileRow from '~/Components/Frontend/Profile/Rows/DemographicProfileRow.vue';
import EmailProfileRow from '~/Components/Frontend/Profile/Rows/EmailProfileRow.vue';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import FullNameProfileRow from '~/Components/Frontend/Profile/Rows/FullNameProfileRow.vue';
import JobTitleProfileRow from '~/Components/Frontend/Profile/Rows/JobTitleProfileRow.vue';
import LookingForProfileRow from '~/Components/Frontend/Profile/Rows/LookingForProfileRow.vue';
import NotificationBar from '~/Components/Frontend/NotificationBar.vue';
import PhoneProfileRow from '~/Components/Frontend/Profile/Rows/PhoneProfileRow.vue';
import ProfileGroup from '~/Components/Frontend/Profile/ProfileGroup.vue';

function isSelected(demographic, selected) {
  return demographic.dependent_on.length === 0 || demographic.dependent_on.some((demog) => selected[demog.demographic_id]?.includes(demog.id));
}

export default {
  name: 'ProfileInformationPage',
  components: {
    AvatarProfileRow,
    BioProfileRow,
    CountryProfileRow,
    DemographicProfileRow,
    EmailProfileRow,
    FeLoading,
    FullNameProfileRow,
    JobTitleProfileRow,
    LookingForProfileRow,
    NotificationBar,
    PhoneProfileRow,
    ProfileGroup,
  },
  data() {
    return {
      loading: true,
      countries: [],
      demographics: [],
      officePhones: null,
      mobilePhones: null,
      selected: {},
      store,
    };
  },
  computed: {
    visibleDemographics() {
      return this.demographics.filter((demographic) => isSelected(demographic, this.selected));
    },
  },
  mounted() {
    axios.get('/profile/profile-information')
      .then(({ data }) => {
        this.countries = data.countries;
        this.demographics = data.demographics;
        this.mobilePhones = data.mobile_phones;
        this.officePhones = data.office_phones;
        this.selected = data.selected;
        this.loading = false;
      });
  },
};
</script>
