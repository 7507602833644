<template>
  <div class="directory__filter" style="position: relative">
    <FrontendSwitch v-model="selected">
      <span class="form-input__check-label"> {{ filter.label }} </span>
    </FrontendSwitch>
  </div>
</template>

<script>

export default {
  name: 'DirectoryRadioFilter',
  props: {
    filter: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    selected: {
      get() { return this.modelValue; },
      set(value) { this.$emit('update:modelValue', value); },
    },
  },
};

</script>
