<template>
  <SubOption
    @clicked="toggle"
    :icon="icon"
    :keepOpen="true"
    :warning="isFavourited"
    :label="isFavourited ? __('action.unfavourite') : __('action.favourite')"
   />
</template>

<script>
import axios from 'axios';
import SubOption from '~/Components/Frontend/SubOption.vue';

export default {
  inject: ['subOptions'],
  emits: ['update:isFavourited'],
  components: {
    SubOption,
  },
  props: {
    favouritableId: {
      required: true,
      type: Number,
    },
    favouritableType: {
      required: true,
      type: String,
    },
    isFavourited: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    icon() {
      if (this.loading) {
        return 'far fa-spinner fa-spin-pulse';
      }
      return 'far fa-star';
    },
  },
  methods: {
    toggle() {
      if (this.loading) return;
      this.loading = true;
      axios.post(`/favourites/${this.favouritableType}/${this.favouritableId}/toggle`)
        .then(() => {
          this.$emit('update:isFavourited', !this.isFavourited);
          setTimeout(() => { this.subOptions.close(); }, 300);
        }).catch(({ response }) => { this.errors = response?.data?.errors; })
        .then(() => { this.loading = false; });
    },
  },
};
</script>
