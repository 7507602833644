<template>
  <div style="flex-grow:1" id="file-drag-drop">
    <form v-show="!image" id="drop-form" @drop="handleFileDrop( $event )">
      <label class="drop-image">
        <i class="far fa-upload drop-image__icon"></i>
        <span class="drop-image__text">
          {{ __('drop_image.text') }}
          <input id="file-click-upload" type="file" style="display: none" @change="handleFileInput( $event )">
        </span>
      </label>
    </form>
    <div v-if="image"
       key="image"
       class="drop-image__preview">
      <img id="drag-and-drop-preview" :class="previewClass" :src="preview"/>
      <p class="drop-image__preview-text">{{ __('drop_image.preview_text', {image_name: image.name }) }}</p>
    </div>
    <p class="error-text">{{ errorText }}</p>
    <div class="button-group" v-if="!imageUploaded">
      <button v-show="image" :class="submitClasses" @click="submitImage">
        <span v-if="!loading">{{ __('drop_image.buttons.submit') }}</span>
      </button>
      <button v-if="image && !loading && showRemoveUploadOption" class="button button--secondary" @click="removeFile">{{ __('drop_image.buttons.remove') }}</button>
      <button v-if="!loading" class="button button--secondary" @click="$emit('cancel')" >{{ __('drop_image.buttons.cancel') }}</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    url: String,
    profile: Object,
    previewClass: String,
    showRemoveUploadOption: {
      Type: Boolean,
      default: true,
    },
  },
  emits: ['success', 'cancel'],
  data() {
    return {
      imageUploaded: false,
      image: null,
      uploadPercentage: 0,
      loading: false,
      errorText: null,
      preview: null,
    };
  },
  computed: {
    submitClasses() {
      return ['button', { 'button--loading': this.loading }];
    },
    dragAndDropCapable() {
      const div = document.createElement('div');
      return (('draggable' in div)
              || ('ondragstart' in div && 'ondrop' in div))
          && 'FormData' in window
          && 'FileReader' in window;
    },
  },
  mounted() {
    if (this.dragAndDropCapable) {
      this.bindEvents();
    }
  },
  methods: {
    bindEvents() {
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
        document.getElementById('file-drag-drop').addEventListener(evt, (e) => {
          e.preventDefault();
          e.stopImmediatePropagation();
        }, false);
      });
    },
    checkFileTypeValid(fileName) {
      return (/\.(jpe?g|png|gif|webp)$/i.test(fileName));
    },
    handleFileInput(event) {
      if (this.checkFileTypeValid(event.target.files[0].name)) {
        // eslint-disable-next-line prefer-destructuring
        this.image = event.target.files[0];
        this.getImagePreview();
        return;
      }
      this.errorText = 'Unsupported File type, please use jpeg, png, gif or webp format';
    },
    handleFileDrop(event) {
      this.errorText = null;
      if (this.checkFileTypeValid(event.dataTransfer.files[0].name)) {
        // eslint-disable-next-line prefer-destructuring
        this.image = event.dataTransfer.files[0];
        this.getImagePreview();
        return;
      }
      this.errorText = 'Unsupported File type, please use jpeg, png, gif or webp format';
    },
    getImagePreview() {
      if (this.checkFileTypeValid(this.image.name)) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          this.preview = reader.result;
        }, false);
        reader.readAsDataURL(this.image);
      }
    },
    removeFile() {
      this.image = null;
    },
    submitImage() {
      if (this.loading === true) {
        return;
      }
      const formData = new FormData();
      formData.append('image', this.image);
      this.loading = true;
      axios.post(
        this.url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ).then(() => {
        this.imageUploaded = true;
        this.$emit('success');
      }).catch(({ response: { data: { errors } } }) => {
        // eslint-disable-next-line prefer-destructuring
        this.errorText = errors?.image[0];
      }).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>
