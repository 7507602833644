<template>
  <section class="company-card" :class="{'company-card--faded': isHidden}" @click="openModal">
    <div
      class="company-card__type"
      v-if="model.badge"
      :style="{ backgroundColor: model.badge.color }"
    >
      {{ model.badge.label }}
    </div>
    <div class="company-card__sub-menu" v-if="!model.is_iframed">
      <SubOptions>
        <template v-slot:button="{toggle}">
          <IconButton @click.stop="toggle" small icon="fa-ellipsis" />
        </template>
        <SubOptionFavourite
          :favouritableId="model.id"
          favouritableType="Company"
          v-model:isFavourited="isFavourited"
        />
        <SubOption :link="modalHref" icon="fas fa-eye" :label="__('company_card.view_company')" />
        <SubOptionHide
          :hideableId="model.id"
          hideableType="Company"
          v-model:isHidden="isHidden"
        />
      </SubOptions>
    </div>
    <div class="company-card__logo-holder">
      <img
        class="company-card__logo"
        :src="imageUrl"
        :alt="model.display_name"
      />
    </div>
    <div class="company-card__staff">
      <div class="company-card__avatar-holder" >
        <img
          v-for="(thumbnail, ndx) in model.avatar_thumbnails.slice(0,4)"
          :key="`thumbnail_${ndx}`"
          :src="thumbnail ?? defaultAvatar"
          class="contact__avatar thumb"
        />
        <span
          v-if="model.avatar_thumbnails.length > 4"
          style="font-size: small"
        >
          +{{ model.avatar_thumbnails.length - 4 }}
        </span>
      </div>
    </div>
    <div
      :title="model.display_name"
      class="company-card__name"
    >
      <text-clamp
        :text="model.display_name"
        :max-lines="2"
        max-height="48px"
        ellipsis="…"
        auto-resize
      >
        <template #before>
          <i
            v-if="isFavourited"
            class="fa-2xs fa fa-star"
            style="margin-right: 4px"
          ></i>
        </template>
      </text-clamp>
    </div>
    <div class="company-card__stand">
      <span v-if="model.standLocationShort">
        <i class="fa-regular fa-location-dot"></i>
        {{ model.standLocationShort }}
      </span>
    </div>

    <div class="company-card__actions">
      <a class="button button--secondary" @click.stop="openModal">
        {{ __('company_card.view_company') }}
      </a>
    </div>
  </section>
</template>

<script>
import TextClamp from 'vue3-text-clamp';
import SubOptionFavourite from '~/Components/Frontend/Profile/Actions/SubOptionFavourite.vue';
import SubOptionHide from '~/Components/Frontend/Profile/Actions/SubOptionHide.vue';
import SubOption from '~/Components/Frontend/SubOption.vue';
import SubOptions from '~/Components/Frontend/SubOptions.vue';
import { store } from '../../../Frontend/store';

export default {
  components: {
    TextClamp, SubOptionFavourite, SubOptionHide, SubOption, SubOptions,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      defaultImage: 'https://s3.eu-west-1.amazonaws.com/storage.v4.eventnetworking.com/assets/default-company-card-banner.png',
      defaultAvatar: 'https://s3.eu-west-1.amazonaws.com/storage.v4.eventnetworking.com/assets/default-company-card-team-avatar.png',
      isFavourited: this.model.isFavourited,
      isHidden: this.model.isHidden,
    };
  },
  methods: {
    openModal() {
      window.location.href = this.modalHref;
    },
    goToCompany() {
      window.location.href = this.pageHref;
    },
  },
  computed: {
    imageUrl() {
      return this.model.logo_url ?? this.defaultImage;
    },
    eventEdition() {
      return store.eventEdition;
    },
    modalHref() {
      return `#/companies/${this.model.id}`;
    },
    pageHref() {
      return `/${this.eventEdition?.slug}/companies/${this.model.id}`;
    },
  },
};
</script>
