<template>
  <BaseModal
    :title="__('hubs.tasks.modal.title')"
    ref="modal"
    startOpen
    @closed="$emit('closed')"
  >
    <h2 style="margin-top: 1rem;">
      <i :class="icon"></i>
      {{ task.title }}
    </h2>
    <p>
      {{ task.description }}
    </p>
    <p v-if="task.file_data">
      <a :href="task.file_data_temporary_url" target="_blank">
        <span class="chip chip--neutral chip--small">
          <i class="far fa-paperclip"></i> {{ task.file_data.file_name }}
        </span>
      </a>
    </p>

    <component
      :is="task.component"
      :complete="task.status === 'complete'"
      :details="task.details"
      :payload="payload"
      :errors="errors"
      @updatePayload="updatePayload"
    ></component>

    <div v-if="task.status === 'complete'">
      <span class="chip chip--small chip--neutral'">
        {{ __('hubs.tasks.completed_by', {
          responder: task.response.responder,
          when: task.response.responded_at_diff,
        }) }}
      </span>
    </div>

    <template #footer>
      <div class="button-group">
        <button
          type="button"
          class="button button--tertiary"
          @click="$refs.modal.close()"
        >
          {{ task.status === 'complete' ? __('hubs.tasks.modal.close') : __('hubs.tasks.modal.cancel') }}
        </button>
        <template v-if="task.status !== 'complete'">
          <button
            type="button"
            class="button"
            @click.once="respond"
            :disabled="!payload.isValid"
          >
            <VLoading :loading="submitting" v-if="submitting"></VLoading>
            <span v-else>{{ buttonText }}</span>
          </button>
        </template>
      </div>
    </template>
  </BaseModal>
 </template>

<script>
import _ from 'lodash';
import axios from 'axios';
import BaseModal from '~/Components/Frontend/BaseModal.vue';
import HubTaskFileUpload from '~/Components/Frontend/Hubs/Modals/HubTaskFileUpload.vue';
import HubTaskInfoOnly from '~/Components/Frontend/Hubs/Modals/HubTaskInfoOnly.vue';
import HubTaskQuestion from '~/Components/Frontend/Hubs/Modals/HubTaskQuestion.vue';
import HubTaskTickbox from '~/Components/Frontend/Hubs/Modals/HubTaskTickbox.vue';
import VLoading from '~/Components/Office/VLoading.vue';

export default {
  components: {
    BaseModal,
    HubTaskFileUpload,
    HubTaskInfoOnly,
    HubTaskQuestion,
    HubTaskTickbox,
    VLoading,
  },
  props: {
    icon: String,
    outOfStock: Boolean,
    task: {
      type: Object,
      required: true,
    },
  },
  emits: ['responded', 'closed'],
  data() {
    const data = {
      errors: {},
      loading: false,
      submitting: false,
      payload: {},
    };

    if (this.task.response?.response_details) {
      data.payload = _.clone(this.task.response.response_details);
    }

    if (this.task.response?.uploaded_file_url) {
      data.payload.uploaded_file_url = this.task.response?.uploaded_file_url;
      data.payload.uploaded_file_name = this.task.response?.uploaded_file_name;
    }

    data.payload.isValid = false;
    return data;
  },
  computed: {
    buttonText() {
      if (this.task.response_type === 'info-only') {
        return this.__('hubs.tasks.modal.markAsRead');
      }

      return this.__('hubs.tasks.modal.submit');
    },
  },
  methods: {
    updatePayload(field, value) {
      this.payload[field] = value;
    },
    respond() {
      const url = new URL(window.location);
      const ajaxUrl = `${url.pathname}/tasks`.replace('//', '/');

      const payload = {
        task_id: this.task.id,
        ...this.payload,
      };

      this.submitting = true;
      axios.post(ajaxUrl, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        this.submitting = false;
        this.$emit('responded', response.data.tasks);
        this.$refs.modal.close();
      });
    },
  },
};

</script>
