<template>
  <div :class="{ 'contact-row': true }">
    <template v-if="contact">
      <ContactAvatar :contact="contact" size="small" />
      <div class="contact-row__items">
        <div class="contact-row__name">
          {{ contact.full_name || contact.display_name }}
        </div>
        <div class="contact-row__row-2">
          <span v-show="contact.job_title" class="contact-row__job_title">
              {{ contact.job_title }},
          </span>
          <span class="contact-row__company-name">
              <a class="contact-row__company-name-link"
                :href="`/${eventEdition.slug}/companies/${contact.company_id}`"
              >
                {{ contact.company.display_name}}
              </a>
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ContactAvatar from './Components/ContactAvatar.vue';
import { store } from '../../../Frontend/store';

export default {
  name: 'ContactModalHeader',
  components: {
    ContactAvatar,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
  },
};

</script>
