<template>
  <div v-if="open" class="overlay mobile-nav-header__navbar-container" :style="backgroundBanner">
    <div class="mobile-nav-header__top-row">
      <div
        @click.stop="openProfile()"
        class="mobile-nav-header__avatar-container"
      >
        <ContactAvatar :contact="activeContact" size="medium" />
        <div class="mobile-nav-header__active-contact-name">
          {{ activeContact.display_name }}
          <br>
          <a href="#/my-account/profile-information" @clicked="open = false">
            {{ __('profile.pages.profileInformation.myAccount') }}
          </a>
          <a v-if="storeGet('user.activeContact.profiles_count') > 1" href="#/profile-select" @clicked="open = false">
            {{ __('profile.pages.profileInformation.switchProfile') }}
          </a>
        </div>
      </div>
      <div class="mobile-nav-header__close-button-holder">
        <a class="mobile-nav-header__close-button" @click="toggleNavbar" href="#">
          <i class="fa-solid fa-times"></i>
        </a>
      </div>
    </div>
    <div class="mobile-nav-header__tab-container">
      <MobileNavbarItem
        v-for="tab in navigationTabs"
        :key="tab.name"
        :icon="tab.icon"
        :url="tab.url"
        :target="tab.url.startsWith('http') ? '_blank' : '_self'"
        :label="tab.label"
      />
      <MobileNavbarItem
        icon="far fa-sign-out"
        :url="logoutUrl"
        :label="__('profile.action.logout')"
      />
      <a :href="platformClientUrl" target="_blank" rel="noopener noreferrer" class="mobile-nav-header__client-link">
        <i class="fa-solid fa-up-right-from-square"></i> Visit {{ platformClientName }}
      </a>
      <div class="mobile-nav-header__tab-scroll-spacer"></div>
    </div>
  </div>
  <div class="mobile-nav-header__body-container"
    :style="headerBanner"
  >
    <div class="mobile-nav-header_logo-holder">
      <img
        class="mobile-nav-header__logo"
        :alt="eventEditionName"
        :src="eventEditionHeaderLogo"
        >
    </div>
    <a class="mobile-nav-header__burger-icon" href="#" @click="toggleNavbar">
      <i class="fa fa-bars"></i>
    </a>
  </div>
</template>

<script>
import { store } from '~/Frontend/store';
import { useBreakpoints } from '~/Frontend/useBreakpoints';
import { ref, watch } from 'vue';

export default {
  name: 'MobileEventEditionNav',

  props: {
    activeContact: {
      type: Object,
      required: true,
    },
    navigationTabs: {
      type: Object,
      required: true,
    },
    eventEditionHeaderLogo: {
      type: String,
      required: true,
    },
    eventEditionHeaderBanner: {
      type: String,
      required: true,
    },
    eventEditionBackgroundBanner: {
      type: String,
      required: true,
    },
    eventEditionName: {
      type: String,
      required: true,
    },
    platformClientUrl: {
      type: String,
      required: true,
    },
    platformClientName: {
      type: String,
      required: true,
    },
    logoutUrl: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { mobile } = useBreakpoints();
    const open = ref(false);

    watch(mobile, (val) => {
      if (!val) {
        open.value = false;
      }
    });
    return { open };
  },
  methods: {
    toggleNavbar() {
      this.open = !this.open;
    },
    openProfile() {
      window.location = '#/my-account/profile-information';
      this.open = false;
    },
  },
  computed: {
    headerBanner() {
      return `background-image: url('${this.eventEditionHeaderBanner}')`;
    },
    backgroundBanner() {
      return `background-image: url('${this.eventEditionBackgroundBanner}')`; // needs mobile specific background upload
    },
    eventEdition() {
      return store.eventEdition;
    },
  },
};
</script>
