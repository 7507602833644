<template>
  <label
    class="bubble-filter"
    :class="{
      'bubble-filter--selected': optionsSelected.includes(option.value),
      'bubble-filter--disabled': disabledOptions.includes(option.value),
    }"
    v-for="option in options"
    :key="option.value"
  >
    <input
      style="opacity: 0; width: 0px;"
      v-bind="inputProps"
      :value="modelValue"
      type="checkbox"
      @change="addOption(option.value), $emit('update:modelValue', [ ...optionsSelected ])"
      :disabled="disabledOptions.includes(option.value)"
    />{{ option.text }}
  </label>
  <ul class="frontend-text-input__error" v-if="errors && errors.length > 0">
    <li class="frontend-radio-input__error" v-for="error in errors" :key="error">
      {{ error }}
    </li>
  </ul>
  <div v-if="errorMessage" class="frontend-radio-input__error">
    {{ errorMessage }}
  </div>
</template>

<script>

export default {
  name: 'FrontendCheckboxInput',
  emits: [
    'update:modelValue',
    'blur',
    'focus',
  ],
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    errorMessage: {
      type: String,
      default: null,
    },
    label: {
      type: String,
    },
    modelValue: {
      required: true,
    },
    name: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    disabledOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      optionsSelected: this.modelValue ?? [],
    };
  },
  computed: {
    inputProps() {
      return {
        name: this.name,
        value: this.modelValue,
      };
    },
  },
  methods: {
    addOption(option) {
      if (!this.optionsSelected.includes(option)) {
        this.optionsSelected.push(option);
      } else {
        this.optionsSelected.splice(this.optionsSelected.indexOf(option), 1);
      }
    },
    update(event) {
      this.$emit('update:errors', []);
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>
