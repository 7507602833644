<template>
  <BaseModal
    ref="modal"
    padding="double"
    :loading="loading"
    startOpen
    centered
    :notifications="notifications"
  >
    <template #subheader>
        <h1 v-if="this.otherBookingId">
          <i class='far fa-calendar-lines-pen'></i>
          {{ __('my_schedule.other_bookings.header_update') }}
        </h1>
        <h1 v-else>
          <i class='far fa-calendar-circle-plus'></i>
          {{ __('my_schedule.other_bookings.header_add') }}
        </h1>
        <p>
          <small>
            <i class="far fa-exclamation-circle"></i>
            {{ __('my_schedule.other_bookings.sub_text') }}
          </small>
        </p>
    </template>
    <form v-if="!loading" @submit.prevent="update">
      <div class="other-booking-modal__start-end-time-container">
        <FrontendSelectInput
            id="start_datetime"
            name="start_datetime"
            v-model="startDatetime"
            :options="startTimeOptions"
            :label="__('my_schedule.other_bookings.fields.start_datetime.label')"
            required
            :errors="validationErrors.startDatetime"
        />
        <FrontendSelectInput
            id="end_datetime"
            name="end_datetime"
            v-model="endDatetime"
            :options="endTimeOptions"
            :label="__('my_schedule.other_bookings.fields.end_datetime.label')"
            required
            :errors="validationErrors.endDatetime"
        />
      </div>
      <FrontendTextInput
          id="description"
          name="description"
          v-model="description"
          :label="__('my_schedule.other_bookings.fields.description.label')"
          :placeholder="__('my_schedule.other_bookings.fields.description.placeholder')"
          :max="500"
          required
          :errors="validationErrors.description"
        />

      <div class="other-booking-modal__delete-container" v-if="otherBookingId">
        <button type="button" class="button button--link" @click="deleteBooking">
          <i class="far fa-trash-can"></i>
          {{ __('my_schedule.other_bookings.buttons.delete') }}
        </button>
        <p style="margin-bottom: 0">
          <small>
            {{ __('my_schedule.other_bookings.buttons.no_undos') }}
          </small>
        </p>
      </div>
    </form>
    <template #footer>
      <div class="button-group">
        <button
          type="button"
          class="update-availability__save button"
          @click="update"
          v-if="otherBookingId"
        >
          {{ __('my_schedule.other_bookings.buttons.update') }}
        </button>
        <button
          type="button"
          class="update-availability__save button"
          @click="update"
          v-else
        >
          {{ __('my_schedule.other_bookings.buttons.add') }}
        </button>
        <button
          type="button"
          class="button button--tertiary"
          @click="modalClose"
        >
          {{ __('my_schedule.other_bookings.buttons.cancel') }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import { store } from '../../../Frontend/store';

export default {
  props: {
    otherBookingId: {
      type: String,
      required: false,
    },
    datetimeRange: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      error: null,
      loading: true,
      notifications: [],
      description: null,
      startDatetime: null,
      endDatetime: null,
      startTimeOptions: [],
      endTimeOptions: [],
      validationErrors: {
        description: [],
        startDatetime: [],
        endDatetime: [],
      },
    };
  },
  mounted() {
    this.loading = true;
    this.error = null;
    axios.get(this.endpoint).then(
      (response) => {
        this.description = response.data.otherBooking.description;
        this.startDatetime = response.data.booking.start_datetime_atom;
        this.endDatetime = response.data.booking.end_datetime_atom;
        this.startTimeOptions = response.data.start_time_options;
        this.endTimeOptions = response.data.end_time_options;
      },
    ).catch(() => {
      // eslint-disable-next-line no-underscore-dangle
      this.error = this.__('general.unknown_error');
      this.notifications.push({
        time: Date.now(),
        type: 'error',
        message: this.error,
        icon: 'fa-circle-info',
      });
    }).then(() => { this.loading = false; });
  },
  methods: {
    update() {
      this.error = null;
      this.loading = true;

      axios.post(this.endpoint, {
        start_datetime: this.startDatetime,
        end_datetime: this.endDatetime,
        description: this.description,
      }).then(() => {
        this.modalClose();
        window.location.reload();
      }).catch((error) => {
        if (error.response?.data?.errors) {
          this.validationErrors.description = error.response.data.errors?.description;
          this.validationErrors.startDatetime = error.response.data.errors?.start_datetime;
          this.validationErrors.endDatetime = error.response.data.errors?.end_datetime;
        } else {
          // eslint-disable-next-line no-underscore-dangle
          this.error = this.__('general.unknown_error');
          this.notifications.push({
            time: Date.now(),
            type: 'error',
            message: this.error,
            icon: 'fa-circle-info',
          });
        }
      }).then(() => {
        this.loading = false;
      });
    },
    deleteBooking() {
      this.error = null;
      this.loading = true;

      axios.delete(this.endpoint)
        .then(() => {
          this.modalClose();
          window.location.reload();
        }).catch(() => {
          // eslint-disable-next-line no-underscore-dangle
          this.error = this.__('general.unknown_error');
          this.notifications.push({
            time: Date.now(),
            type: 'error',
            message: this.error,
            icon: 'fa-circle-info',
          });
        }).then(() => {
          this.loading = false;
        });
    },
    modalOpen() {
      this.$refs.modal.open();
    },
    modalClose() {
      this.$refs.modal.close();
    },
  },
  computed: {
    validEndTimeOptions() {
      return this.endTimeOptions
        .filter((item) => item.value > this.startDatetime);
    },
    endpoint() {
      if (this.otherBookingId) {
        return `/${this.eventEdition?.slug}/schedule/other-bookings/${this.otherBookingId}`;
      }
      const datetimes = this.datetimeRange.split('--');
      const params = new URLSearchParams([
        ['start_datetime', datetimes[0]],
        ['end_datetime', datetimes[1]],
      ]);
      return `/${this.eventEdition?.slug}/schedule/other-bookings/create?${params}`;
    },
    eventEdition() {
      return store.eventEdition;
    },
  },
};

</script>
