<template>
  <div
    v-if="registrationStore.getCurrentQuestion.answer?.length > 0"
    class="registration-selected-items-counter">
    {{ registrationStore.getCurrentQuestion.answer?.length }} selected
  </div>
  <div class="registration-demographic-multi-select"
    :class="{'registration-demographic-multi-select--inline' :
          registrationStore.getCurrentQuestion.layout === 'inline',
          'registration-demographic-multi-select--stacked' :
          registrationStore.getCurrentQuestion.layout === 'stacked'}"
    >
      <FrontendCheckboxInput
        :label="registrationStore.getCurrentQuestion.title"
        v-model="registrationStore.getCurrentQuestion.answer"
        :options="registrationStore.getCurrentQuestion.options.map((option) => ({
          value: option.id,
          text: option.name,
        }))"
        :errors="registrationStore.getCurrentQuestion.error"
        :name="registrationStore.getCurrentQuestion.component_type"
        :required="registrationStore.getCurrentQuestion.is_required"
      />
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import FrontendCheckboxInput from '~/Components/Frontend/Inputs/FrontendCheckboxInput.vue';
import useRegistrationStore from '~/Store/Registration';

export default {
  type: 'lazy',
  name: 'RegistrationDemographicMultiSelectComponent',
  components: {
    FrontendCheckboxInput,
  },
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
  },
};
</script>
