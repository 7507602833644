<template>
  <div class="directory__filter" style="position: relative">
    <input
      v-model="selected"
      class="filter__search-input"
      name="filter.name"
      :placeholder="filter.label"
      type="text"
      />
    <i class="fa fa-search filter__search-input-icon"></i>
  </div>
</template>

<script>

export default {
  name: 'DirectoryTextSearchFilter',
  props: {
    filter: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: [String, Array],
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    selected: {
      get() { return this.modelValue; },
      set(value) { this.$emit('update:modelValue', value); },
    },
  },
};

</script>
