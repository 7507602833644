<template>

  <FeLoading v-if="loading"></FeLoading>

  <template v-else v-for="type in types" :key="type.id">
    <ProfileGroup>
      <CatalogueProfileTable
        :entries="catalogue[type.id]"
        :type="type"
        :demographics="demographics"
        :colleagues="colleagues"
      />
    </ProfileGroup>
  </template>
</template>

<script>

import axios from 'axios';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import ProfileGroup from '~/Components/Frontend/Profile/ProfileGroup.vue';
import CatalogueProfileTable from '~/Components/Frontend/Profile/Tables/CatalogueProfileTable.vue';

export default {
  name: 'ProfileCataloguePage',
  components: {
    FeLoading,
    ProfileGroup,
    CatalogueProfileTable,
  },
  data() {
    return {
      types: [],
      catalogue: null,
      demographics: null,
      loading: true,
      colleagues: [],
    };
  },
  mounted() {
    axios.get('/profile/catalogue')
      .then(({
        data: {
          catalogue, types, demographics, colleagues,
        },
      }) => {
        this.types = types;
        this.catalogue = catalogue;
        this.demographics = demographics;
        this.colleagues = colleagues;
      }).then(() => {
        this.loading = false;
      });
  },
};
</script>
