<template>
  <div class="need-help-item">
    <h3 class="need-help-item__header">
      <i class="far fa-headset"></i>
      &nbsp;{{ __('needHelp.header') }}
    </h3>
    <p v-if="customContent" class="need-help-item__body" v-html="customContent">
    </p>
    <p v-else class="need-help-item__body">
      {{ __('needHelp.body') }}<br>
      <a href="#/concierge-contact">
        {{ __('needHelp.link') }}
        &nbsp;<i class="far fa-arrow-right-long"></i>
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'NeedHelpItem',
  props: {
    customContent: {
      type: String,
      default: '',
    },
  },
};
</script>
