<template>
  <div class="form-input" :class="{'form-input--invalid': invalid}">
    <FormLabel v-bind="{label, required}" />
    <!-- <Teleport to="body"> -->
      <vue-tel-input
        v-model="number"
        @country-changed="handleCountryChanged"
        :defaultCountry="country"
        oldDropdownContainer="document.body"
        :inputOptions="{
          type: 'tel',
          autocomplete: 'on',
          maxlength: 25,
          name: 'telephone',
          placeholder: placeholder || __('form.phone.placeholder'),
          tabindex: 0,
        }"
        old-style="{
          width: width + 'px',
          position: 'absolute',
          left: left + 'px',
          top: top  + 'px',
          'z-index': 1000
        }"
        :class="{'form-input--invalid': invalid}"
        ></vue-tel-input>

    <!-- </Teleport> -->
    <!-- <div ref="anchor" style="height: 54px"></div> -->
    <FormError :errors="errors" />
  </div>
</template>

<script>

import { computed, ref } from 'vue';
import { useElementBounding } from '@vueuse/core';

export default {
  props: {
    default: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    name: {
      required: true,
      type: String,
    },
    label: {
      required: false,
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
  },
  setup() {
    const anchor = ref(null);
    const {
      top, left, width, update,
    } = useElementBounding(anchor);
    return {
      anchor, top, left, width, update,
    };
  },
  data() {
    return {
      number: this.default?.phone,
      country: this.default?.phone_country,
    };
  },
  inject: ['feForm'],
  methods: {
    handleCountryChanged(country) {
      this.country = country.iso2;
    },
  },
  computed: {
    id() {
      return `${this.feForm.name}-${this.name}`;
    },
    numberErrors() {
      return this.feForm.error(this.name).value || [];
    },
    countryErrors() {
      return this.feForm.error(`${this.name}_country`).value || [];
    },
    errors() {
      return [...this.numberErrors, ...this.countryErrors];
    },
    invalid() {
      return this.errors.length > 0;
    },
  },
  mounted() {
    this.feForm.register(this.name, computed(() => this.number));
    this.feForm.register(`${this.name}_country`, computed(() => this.country));
  },
  watch: {
    errors() {
      // useBoundingBox is not updating here... quick hack to solve by manually updating
      // need nextTick as need to wait for redraw before recomputing
      this.$nextTick(() => this.update());
    },
  },
};

</script>
