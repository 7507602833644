<template>
  <FrontendSelectInput
    :placeholder="registrationStore.getCurrentQuestion.placeholder"
    v-model="registrationStore.getCurrentQuestion.answer"
    v-model:errors="registrationStore.getCurrentQuestion.error"
    :options="registrationStore.getCountries"
    :required="registrationStore.getCurrentQuestion.is_required"
  />
</template>

<script>
import { mapStores } from 'pinia';
import FrontendSelectInput from '~/Components/Frontend/Inputs/FrontendSelectInput.vue';
import useRegistrationStore from '~/Store/Registration';

export default {
  type: 'lazy',
  name: 'RegistrationCountryComponent',
  components: {
    FrontendSelectInput,
  },
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
  },
};
</script>
