<template>
  <!-- Flipping the direction of contact avatars here so they stack nice with z-index -->
  <span class="contact__avatar-multi">
    <ContactAvatar
      v-for="contact in contacts.slice().reverse()"
      :key="contact.id"
      :contact="contact"
      :size="size"
      />
  </span>
</template>

<script>
export default {
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      required: false,
      default: 'small',
    },
  },
};
</script>
