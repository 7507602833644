<template>
   <div class="catalogue-entry__gallery">
        <div class="catalogue-entry__asset"
            v-for="(image, index) in imageCollection"
            :key="index"
            alt=""
            @click="expand(index)">
            <img :src="image.gallery.url" />
        </div>
    </div>
</template>

<script>
import { modalOpen, modalPush } from '../../../modal';
import FullpageGallery from '../FullpageGallery.vue';

const limit = 10;

export default {
  props: {
    images: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageCollection() {
      return this.images.filter((image) => image?.gallery?.url)
        .slice(0, limit);
    },
  },
  methods: {
    expand(index) {
      const url = new URL('#gallery', window.location);
      window.history.pushState({}, '', url);
      modalPush(
        FullpageGallery,
        {
          fullPage: true,
          images: this.images,
          initial: index,
        },
      );
      modalOpen();
    },
  },
};

</script>
