<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.contact.managedBy') }}
      <FeBadge v-if="profileManagers.length == 0" alert small />
    </div>
    <div class="profile-row__value tag-list" v-if="profileManagers.length > 0">
      <span class="tag tag__part" v-for="manager in profileManagers" :key="manager.id">
        {{ manager.full_name }} ({{ manager.job_title }})
      </span>
    </div>
    <div class="profile-row__value profile-row__value--invalid" v-else>\
      {{ __('ui.noneEnabled') }}
    </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal">
        {{ __('buttons.manage') }}
      </button>
    </div>
  </div>
  <ModalForm
    :form="{
      action: '/profile/managed-by',
      method: 'patch',
      name: 'managed_by'
    }"
    :modal="{
      title: __('ui.edit'),
      titleIcon: 'far fa-edit'
    }"
    @success="update"
    ref="modal"
    >
    <FormCheckfield
      name="managed_by"
      :options="options"
    />
    <template #footer>
      <div class="button-group">
        <button
          type="button"
          class="button button--secondary"
          @click="closeModal"
          >
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit> {{ __('buttons.save') }}</FormSubmit>
      </div>
    </template>
  </ModalForm>
</template>

<script>
export default {
  props: {
    // Array of Contacts
    managedBy: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      data: {
        managed_by: this.managedBy,
      },
    };
  },
  computed: {
    options() {
      return this.data.managed_by.map((access) => ({
        checked: access.is_enabled,
        value: access.id,
        label: `${access.manager.full_name} (${access.manager.job_title})`,
      }));
    },
    profileManagers() {
      return this.data.managed_by.filter((access) => access.is_enabled).map((access) => access.manager);
    },
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    update(data) {
      this.data = data;
      this.closeModal();
    },
  },
};

</script>;
