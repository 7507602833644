<template>
  <a
    v-if="visible"
    :hidden="!visible"
    @click="install"
  >
    <slot></slot>
  </a>
</template>

<script setup>
import { ref } from 'vue';

const visible = ref(false);

let installPrompt = null;

window.addEventListener(
  'beforeinstallprompt',
  (event) => {
    installPrompt = event;
    visible.value = true;
  },
);

async function install() {
  if (installPrompt) {
    await installPrompt.prompt();
    installPrompt = null;
    visible.value = false;
  }
}

</script>
