<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.contact.fullName') }}
    </div>
    <div class="profile-row__value"> {{ fullName }} </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal">
        <i class="fa fa-edit"></i>
        {{ __('buttons.edit') }}
      </button>
    </div>
  </div>
  <ModalForm
    :form="{
      action: '/profile/full-name',
      method: 'patch',
      name: 'name',
    }"
    @success="update"
    :modal="{
      title: __('profile.rows.fullNameModalTitle'),
      titleIcon: 'far fa-edit',
    }"
    ref="modal"
    >
      <FormText
        v-if="false"
        name="salutation"
        :default="salutation"
        :label="__('crm.contact.title')"
        />
      <FormText
        name="first_name"
        :default="firstName"
        :label="__('crm.contact.firstName')"
        required
        />
      <FormText
        name="last_name"
        :default="lastName"
        :label="__('crm.contact.lastName')"
        required
        />
      <template #footer>
        <div class="button-group">
          <button
            type="button"
            class="button button--secondary"
            @click="closeModal">
            {{ __('buttons.cancel') }}
          </button>
          <FormSubmit> {{ __('buttons.save') }} </FormSubmit>
        </div>
      </template>
  </ModalForm>
</template>

<script>

import { user } from '~/Frontend/store';

export default {
  computed: {
    activeContact: () => user.activeContact,
    salutation: () => user.activeContact.salutation,
    firstName: () => user.activeContact.first_name,
    lastName: () => user.activeContact.last_name,
    fullName() {
      return [this.firstName, this.lastName].join(' ');
    },
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    update({
      full_name: fullName,
      salutation,
      first_name: firstName,
      last_name: lastName,
    }) {
      // @toto move to an action (don't interact with store directly)
      user.activeContact.full_name = fullName;
      user.activeContact.last_name = lastName;
      user.activeContact.first_name = firstName;
      user.activeContact.salutation = salutation;
      this.closeModal();
    },
  },
};

</script>
