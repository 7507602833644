<template>
  <button type="submit" class="button" @click="$event.target.blur()" :disabled="submitting">
    <div class="button__icon" v-if="submitting" >
      <i class="fas fa-beat-fade fa-ellipsis"></i>
    </div>
    <template v-else>
      <slot v-if="!submitting"></slot>
    </template>
  </button>
</template>

<script>

export default {
  inject: [
    'feForm',
  ],
  computed: {
    submitting() {
      return this.feForm.submitting.value;
    },
  },
};

</script>
