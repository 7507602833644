<template>
  <BaseModal
    height="tall"
    startOpen
    ref="modal"
    :notifications="notifications"
    centered
    padding="double"
  >
    <template v-if="!meeting.id || submitting">
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>

    <template v-if="meeting.id && !submitting">
      <form
        v-if="incomplete"
        ref="rateMeetingForm"
      >
        <h2>{{ __('meeting.rate_meeting.header') }}</h2>
        <p><span v-html="
        __('meeting.rate_meeting.subheader',
            {
              display_name: counterpartLead.full_name,
              job_title: counterpartLead.job_title,
              company_display_name: counterpartLead.company.display_name
            }
          )"
        ></span>
        </p>
        <div class="meeting-rating-form__star-container">
          <div class="meeting-rating-form__stars">
            <FrontendStarRatingInput v-model="rating" name="rating" />
          </div>
        </div>
        <div class="meeting-rating-form__feedback">
          <FrontendTextInput
            id="meetingRatingFeedback"
            :label="__('meeting.rate_meeting.fields.feedback.label')"
            type="textarea"
            :max="255"
            v-model="feedback"
          />
        </div>
      </form>

      <div v-if="complete" class="meeting-rating-form__success">
        <i class="fa-regular fa-circle-check info-icon info-icon--success"></i>
        <h1>
          {{ __('meeting.rate_meeting.success.title') }}
        </h1>
        <div class="button-group button-group--center">
          <a v-if="nextMeetingToRateId"
            class="button button--wide button--secondary"
            :href='`#/meetings/${nextMeetingToRateId}/rate`'
          >
            {{ __('meeting.rate_meeting.buttons.next') }} <i class="fa-light fa-arrow-right"></i>
          </a>
          <a class="button button--wide" @click="modalClose">
            {{ __('meeting.rate_meeting.buttons.done') }}
          </a>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="button-group button-group--center" v-if="meeting.id && incomplete">
        <button
          type="submit"
          class="button button--primary"
          :class="{disabled: submitting || errored || !canSubmit}"
          @click.prevent="submit"
        >
          {{ __('meeting.rate_meeting.buttons.save') }}
        </button>
        <button
            type="button"
            class="button button--secondary"
            @click.stop="handleMeetingDidNotHappen"
          >
            {{ __('meeting.rate_meeting.fields.meeting_did_not_happen.label') }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import { store, actionRequiredCounts, user } from '../../../Frontend/store';
import FrontendStarRatingInput from '../Inputs/FrontendStarRatingInput.vue';

export default {
  components: {
    FrontendStarRatingInput,
  },
  name: 'RateMeetingModal',
  props: {
    meetingId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      meeting: {},
      submitting: false,
      complete: false,
      feedback: '',
      rating: 0,
      meetingDidNotHappen: false,
      errorMessage: '',
      nextMeetingToRateId: null,
      notifications: [],
      errored: false,
      actionRequiredCounts,
    };
  },
  mounted() {
    axios.get(`/${this.eventEdition?.slug}/meetings/${this.meetingId}`)
      .then((response) => {
        this.meeting = response.data?.meeting;
        if (response.data?.meeting.can_rate) {
          this.meeting = response.data?.meeting;
        } else {
          this.errored = true;
          // eslint-disable-next-line no-underscore-dangle
          this.errorMessage = this.__('meeting.rate_meeting.errors.already_rated');
          this.notifications.push({
            time: Date.now(),
            type: 'warning',
            message: this.errorMessage,
            icon: 'fa-circle-info',
          });
        }
      })
      .catch((error) => {
        this.errorMessage = error.response?.data?.message;
        this.notifications.push({
          time: Date.now(),
          type: 'warning',
          message: this.errorMessage,
          icon: 'fa-circle-info',
        });
      });
  },
  methods: {
    submit() {
      if (!this.$refs.rateMeetingForm.checkValidity()) {
        this.$refs.rateMeetingForm.reportValidity();
        return false;
      }

      this.submitting = true;
      axios.post(
        `/${this.eventEdition?.slug}/meetings/${this.meetingId}/rate`,
        {
          rating: this.rating,
          feedback: this.feedback,
          meeting: this.meeting,
          meeting_did_not_happen: this.meetingDidNotHappen,
        },
      ).then((response) => {
        this.complete = true;
        this.emitter.emit(
          'meetings.rated',
          { meetingId: this.meetingId },
        );
        this.nextMeetingToRateId = response?.data?.next_meeting_id_to_rate;
        this.actionRequiredCounts.setMeetingsToRate(
          response?.data?.meetings_to_rate_count,
        );
        this.complete = true;
      }).catch((error) => {
        this.errorMessage = error.response?.data?.message;
        this.notifications.push({
          time: Date.now(),
          type: 'warning',
          message: this.errorMessage,
          icon: 'fa-circle-info',
        });
      }).then(() => {
        this.submitting = false;
      });

      return true;
    },
    modalClose() {
      this.$refs.modal.close();
      this.emitter.emit('meetings.rated_closed');
    },
    handleMeetingDidNotHappen() {
      this.meetingDidNotHappen = true;
      this.rating = 0;
      this.submit();
    },
  },
  computed: {
    counterpartLead() {
      return this.meeting.attendees.find((attendee) => attendee.is_lead && attendee.company_id !== this.activeContact.company_id);
    },
    activeContact() {
      return user.activeContact;
    },
    incomplete() {
      return !this.complete;
    },
    eventEdition() {
      return store.eventEdition;
    },
    canSubmit() {
      return this.rating !== 0 | this.meetingDidNotHappen;
    },
  },
};
</script>
