<template>
  <slot name="button" :toggle="toggle">
    <button
      @click.stop="toggle"
      class="sub-options__open"
      :class="{'sub-options__open--expanded': expanded}"
      >
      <div class="sub-options__default-button">
        <i :class="icon"></i>
      </div>
    </button>
  </slot>
  <div
    v-if="expanded"
    @click.stop="toggle"
    class="sub-options__backdrop"
  >
  </div>
  <Transition :name="transition">
    <ul
      class="sub-options__options"
      v-if="expanded"
      @click.stop
      :style="{inset, position: 'fixed'}"
      ref="suboptions"
      >
        <li
          @click.stop="toggle"
          class="suboptions-option__mobile-close"
        ></li>
      <slot></slot>
    </ul>
  </Transition>
</template>

<script>

import { ref } from 'vue';
import { useElementSize, useElementBounding } from '@vueuse/core';
import { clamp } from 'lodash';
import { useBreakpoints } from '~/Frontend/useBreakpoints';

// Static ref forces close of other instance when one opens
const activeSubOptions = ref(null);

export default {
  props: {
    icon: {
      type: String,
      default: 'fas fa-ellipsis-vertical',
    },
    position: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'bottom', 'bottom-up'].includes(value),
    },
    target: {
      type: String,
      default: null,
    },
  },
  provide() {
    return {
      subOptions: {
        close: () => { this.expanded = false; },
      },
    };
  },
  computed: {
    transition() {
      return this.mobile ? 't-reveal-up' : 't-fade';
    },
    inset() {
      let suboptionsLeft = 0;
      let suboptionsTop = 0;

      if (this.position === 'bottom') {
        suboptionsTop = this.activatorBottom;
        suboptionsLeft = this.activatorLeft;
      }

      if (this.position === 'left') {
        suboptionsLeft = this.activatorRight - this.suboptionsWidth;
        suboptionsTop = this.activatorBottom;
      }

      if (this.position === 'bottom-up') {
        suboptionsLeft = this.activatorRight - this.suboptionsWidth;
        suboptionsTop = this.activatorBottom - this.suboptionsHeight;
      }

      // Don't fall off document
      suboptionsTop = clamp(suboptionsTop, 0, this.documentHeight - this.suboptionsHeight);
      suboptionsLeft = clamp(suboptionsLeft, 0, this.documentWidth - this.suboptionsWidth);

      // Convert to css
      return [
        suboptionsTop === null ? 'auto' : `${suboptionsTop}px`,
        'auto',
        'auto',
        suboptionsLeft === null ? 'auto' : `${suboptionsLeft}px`,
      ].join(' ');
    },
  },
  data() {
    return {
      expanded: false,
      symbol: Symbol('suboptions'),
    };
  },
  setup() {
    const activator = ref(null);

    const {
      left: activatorLeft,
      right: activatorRight,
      top: activatorTop,
      bottom: activatorBottom,
    } = useElementBounding(activator);

    const suboptions = ref(null);

    const { width: suboptionsWidth, height: suboptionsHeight } = useElementBounding(suboptions);

    const { width: documentWidth, height: documentHeight } = useElementSize(window.document.body);

    const { tabletOrSmaller, mobile } = useBreakpoints();

    return {
      activator,
      suboptions,
      activatorLeft,
      activatorRight,
      activatorTop,
      activatorBottom,
      suboptionsWidth,
      suboptionsHeight,
      documentWidth,
      documentHeight,
      activeSubOptions,
      tabletOrSmaller,
      mobile,
    };
  },
  methods: {
    toggle(event) {
      this.activator = this.target ? document.getElementById(this.target) : event.target;
      this.activeSubOptions = this.symbol;
      this.expanded = !this.expanded;
    },
  },
  watch: {
    // Close if another suboptions opens
    activeSubOptions(value) {
      if (value !== this.symbol) {
        this.expanded = false;
      }
    },
  },
};

</script>
