<template>
  <div :style="[complete ? 'opacity: 0.5' : '']">
    <div v-if="complete" style="margin: var(--sizing-4) 0;">
      <p>{{ __('hubs.tasks.file_submitted') }}</p>
      <a class="button button--secondary text-small" :href="payload.uploaded_file_url">
        <i class="far fa-file-pdf"></i>
        {{ __('hubs.tasks.user_file_download') }}
        {{ payload.uploaded_file_name }}
      </a>
    </div>
    <div v-else>
      <label for="multilevel-dropdown__label">{{ details.file }}</label>
      <FrontendFileUpload
        ref="file_upload"
        @changed="(newFile) => updatePayload(newFile)"
      ></FrontendFileUpload>
    </div>
  </div>
</template>

<script>
import FrontendFileUpload from '~/Components/Frontend/Inputs/FrontendFileUpload.vue';

export default {
  components: {
    FrontendFileUpload,
  },
  emits: ['updatePayload'],
  props: {
    complete: Boolean,
    details: Object,
    payload: Object,
    errors: Object,
  },
  methods: {
    updatePayload(value) {
      this.$emit('updatePayload', 'file', value);
      this.$emit('updatePayload', 'isValid', !!value);
    },
  },
};
</script>
