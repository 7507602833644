<template>
  <div class="alert-icon" style="margin-bottom: 2rem">
    <div class="alert-icon__icon sa-success animate">
      <span class="alert-icon__line alert-icon__tip animateSuccessTip"></span>
      <span class="alert-icon__line alert-icon__long animateSuccessLong"></span>
      <div class="alert-icon__placeholder"></div>
      <div class="alert-icon__fix"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      finished: false,
    };
  },
  emits: ['finished'],
  mounted() {
    window.setTimeout(() => {
      this.finished = true;
      this.$emit('finished');
    }, 1000);
  },
};
</script>
