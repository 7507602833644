<template>
  <video
    :controls="isHovered"
    v-show="videoAsset.hls"
    ref="video"
    :class="className"
    v-bind="$attrs"
    :id="videoAsset.id"
    @play="log"
    style="background-color: var(--gray-5)"
    />
  <div v-show="!videoAsset.hls" :class="className">
    <div class="fe-image__placeholder">
      <i class="fa-duotone" :class="placeholderIcon"></i>
    </div>
  </div>
</template>

<script>
import Hls from 'hls.js';
import { ref } from 'vue';
import { useElementHover } from '@vueuse/core';
import axios from 'axios';
import { store } from '~/Frontend/store';

export default {
  name: 'VideoPlayer',
  props: {
    videoAsset: {
      type: Object,
      required: true,
    },
    className: {
      type: String,
    },
    placeholderIcon: {
      type: String,
      default: 'fa-phone-film',
    },
  },
  setup() {
    const video = ref();
    const isHovered = useElementHover(video);
    return { video, isHovered };
  },
  methods: {
    log($event) {
      const prefix = store.eventEdition ? `/${store.eventEdition.slug}` : '';
      axios.post(`${prefix}/video-assets/${this.videoAsset.id}/log`, { event: $event.type });
    },
  },
  mounted() {
    const videoElement = this.$refs.video;
    if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      videoElement.src = this.videoAsset.hls;
    } else if (Hls.isSupported()) {
      const hls = new Hls();
      hls.attachMedia(videoElement);
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(this.videoAsset.hls);
      });
    }
  },
};
</script>
