<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.contact.bio') }}
      <FeBadge v-if="!bio" alert small />
    </div>
    <div class="profile-row__value" v-if="bio">
      <ShowHideText
        :text="bio"
        :maxLength="320"
      />
    </div>
    <div class="profile-row__value profile-row__value--invalid" v-else>
      {{ __('ui.notAdded') }}
    </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal">
        <i class="fa fa-edit"></i>
       {{ __('buttons.edit') }}
      </button>
    </div>
  </div>
  <ModalForm
    :form="{
      action: '/profile/bio',
      method: 'patch',
      name: 'bio'
      }"
    :modal="{
      title: __('ui.edit'),
      titleIcon: 'far fa-edit',
    }"
    @success="update"
    ref="modal"
  >
    <FormTextarea
      name="bio"
      v-model="inputBio"
      :label="__('crm.contact.bio')"
      :rows="10"
      :max="2000"
      :placeholder="__('profile.rows.bio.bioPlaceholder')"
      :generate-ai-url="store.platform.enable_ai_generated_contact_bios ? '/profile/generate-bio-text' : ''"
      required
      />
    <template #footer>
      <div class="button-group">
        <button type="button" class="button button--secondary" @click="closeModal">
          {{ __('buttons.cancel') }}
        </button>
        <button type="submit" class="button">
          {{ __('buttons.save') }}
        </button>
      </div>
    </template>
  </ModalForm>
</template>

<script>
import { user, store } from '~/Frontend/store';
import ShowHideText from '~/Components/Frontend/Core/ShowHideText.vue';

export default {
  components: {
    ShowHideText,
  },
  data() {
    return {
      store,
      inputBio: user.activeContact.bio || '',
    };
  },
  computed: {
    bio: () => user.activeContact.bio,
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    update({ bio }) {
      user.activeContact.bio = bio;
      this.closeModal();
    },
  },
};

</script>
