<template>
  <a
    :href="url"
    :target="target"
    @click="$emit('clicked')"
    class="mobile-nav-header__nav-bar-item"
  >
    <i :class="icon" class="mobile-nav-header__nav-bar-icon"></i>
    <span class="mobile-nav-header__nav-bar-label">{{ label }}</span>
    <i class="mobile-nav-header__nav-bar-chevron fa-regular fa-chevron-right"></i>
  </a>
</template>

<script>
export default {
  emits: ['clicked'],
  props: {
    icon: String,
    label: String,
    url: String,
    target: {
      type: String,
      default: '_self',
    },
  },
};
</script>
