let eventEdition;

export default {
  get() {
    return eventEdition;
  },
  set(eventEditionObject) {
    eventEdition = eventEditionObject;
  },
};
