<template>
  <SubOptions position="left" v-if="locales.length > 1">
    <template v-slot:button="{toggle}">
      <a href="" :class="buttonClasses" @click.prevent="toggle">
        <i :class="icon"></i>
      </a>
    </template>
    <SubOption
      v-for="locale in locales"
      :key="locale.code"
      @clicked="switchLocale(locale.code)"
    >
      <template v-if="locale.code === activeLocale">
        <strong>
          {{ locale.local_name }}
          <i class="far fa-circle-check"></i>
        </strong>
      </template>
      <template v-else>
        {{ locale.local_name }}
      </template>
    </SubOption>
  </SubOptions>
</template>

<script>
import SubOption from '~/Components/Frontend/SubOption.vue';
import SubOptions from '~/Components/Frontend/SubOptions.vue';

export default {
  name: 'LocaleSwitcher',
  components: {
    SubOptions,
    SubOption,
  },
  props: {
    locales: {
      type: Array,
      required: true,
    },
    buttonClasses: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  methods: {
    switchLocale(newLocale) {
      window.location.href = `/set-locale/${newLocale}`;
    },
  },
  data() {
    return {
      activeLocale: document.head.querySelector('meta[name="locale-code"]').content,
    };
  },
};
</script>
