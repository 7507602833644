<template>
  <form @submit.prevent="submit">
    <slot></slot>
  </form>
</template>

<script>
import axios from 'axios';
import { computed } from 'vue';

/**
 * A more advanced version of formData.append, which handle objects
 * and arrays in a laravel friendly way
 *
 * @param String name
 * @param String|Number|Object|Array|Boolean value
 * @param FormData formData
 *
 * @return void
 */
function formAppend(name, value, formData) {
  if (value instanceof Blob) {
    formData.append(name, value);
  } else if (value instanceof Array) {
    value.forEach(
      (arrayValue, index) => { formAppend(`${name}[${index}]`, arrayValue, formData); },
    );
  } else if (value instanceof Object) {
    Object.entries(value).forEach(
      ([key, objectValue]) => { formAppend(`${name}[${key}]`, objectValue, formData); },
    );
  } else {
    formData.append(name, value);
  }
}

// Convert laravels array syntax into something more friendly
function deserialise(str, value, carry) {
  const parts = str.split('.');
  const [key, ...rest] = parts;

  if (rest.length > 0) {
    const [nextKey] = rest;
    const isNextKeyInteger = /^\d+$/.test(nextKey);
    const next = isNextKeyInteger ? [] : {};

    if (Array.isArray(carry)) {
      while (carry.length <= key) {
        carry.push(null);
      }
      if (!carry[key]) {
        carry[key] = next;
      }
    } else if (!(key in carry)) {
      carry[key] = next;
    }

    deserialise(rest.join('.'), value, carry[key]);
  } else {
    carry[key] = value;
  }
}

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      require: true,
    },
    method: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      input: {},
      errors: {},
      flatErrors: {},
      submitting: false,
    };
  },
  provide() {
    return {
      feForm: {
        register: (name, value) => { this.input[name] = value; },
        name: this.name,
        error: (name) => computed(() => this.errors[name]),
        flatError: (name) => computed(() => this.flatErrors[name]),
        removeError: (name, index) => this.errors[name].splice(index, 1),
        submitting: computed(() => this.submitting),
        clearErrors: (name) => { this.errors[name] = []; },
        input: computed(() => this.input),
      },
    };
  },
  methods: {
    submit() {
      this.submitting = true;

      const formData = new FormData();
      formData.append('_method', this.method);

      Object.entries(this.input).forEach(([name, value]) => {
        formAppend(name, value, formData);
      });

      axios.post(this.action, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => this.$emit('success', response.data))
        .catch((error) => {
          if (!('response' in error)) {
            throw error;
          }
          const { response } = error;
          if (response && response.status === 422) {
            const errors = {};
            if (response.data.errors || false) {
              Object.entries(response.data.errors).forEach(
                ([field, message]) => { deserialise(field, message, errors); },
              );
            }
            this.errors = errors;

            const flatErrors = response.data.errors;
            Object.keys(response.data.errors).forEach((key) => {
              // socials.0.url needs to become socials[0][url]
              const temp = key.split('.');
              const newKey = `${temp.shift()}[${temp.join('][')}]`;
              flatErrors[newKey] = response.data.errors[key];
            });
            this.flatErrors = flatErrors;

            this.$emit('invalid', response.data.errors);
          } else {
            this.submitting = false;
            throw error;
          }
        }).then(() => { this.submitting = false; });
    },
  },
};
</script>
