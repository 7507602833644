<template>
  <button v-bind="$attrs" :class="classes" :data-tooltip="label">
    <i :class="iconClasses"></i>
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    tinted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'icon-button': true,
        'icon-button--large': this.large,
        'icon-button--small': this.small,
        'icon-button--tinted': this.tinted,
      };
    },
    iconClasses() {
      return [
        'far',
        ...this.icon.split(' '),
      ];
    },
  },
};
</script>
