<template>
  <div class="fe-divider">
    <div class="fe-divider__line"></div>
    <div class="fe-divider__text"> <slot></slot> </div>
    <div class="fe-divider__line"></div>
  </div>
</template>

<style>

.fe-divider {
  display: flex;
  align-items: center;
}

.fe-divider__line {
  height: 2px;
  background-color: var(--gray-2);
  flex-grow: 1;
  flex-shrink: 1;
}

.fe-divider__text {
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 1ch;
  padding-right: 1ch;
}

</style>
