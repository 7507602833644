<template>
  <BaseModal
    ref="modal"
    width="wide"
    height="tall"
    :loading="loading"
    startOpen
  >
    <div class="login__legal-page markdown" v-if="privacyPolicy" v-html="privacyPolicy"/>
  </BaseModal>
</template>

<script>
import axios from 'axios';

export default {
  name: 'privacyPolicy',
  data() {
    return {
      privacyPolicy: '',
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    axios.get('/legal/privacy-policy')
      .then((response) => {
        this.privacyPolicy = response.data;
      }).catch((response) => {
        this.errors = response.data?.errors ?? {};
      }).then(() => {
        this.loading = false;
      });
  },
};
</script>
