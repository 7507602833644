<template>
  <slot name="countdown" v-if="!isPast">
    <span v-if="format === 'span'">
      {{ countdownIntroMessage }}
      {{ timeUntil.minutes() }} {{ __('countdown.minutes') }},
      {{ timeUntil.seconds() }} {{ __('countdown.seconds') }}
    </span>
    <p v-else-if="format === 'paragraph'">
      {{ countdownIntroMessage }}
      {{ timeUntil.minutes() }} {{ __('countdown.minutes') }},
      {{ timeUntil.seconds() }} {{ __('countdown.seconds') }}
    </p>
    <div
      v-else-if="format === 'countdown'"
      class="countdown-timer"
      :class="[{
        'countdown-timer--almost-past': isAlmostPast,
        },
        'countdown-timer--' + size,
      ]"
    >
      <div class="countdown-timer__time-block">
        {{ timeUntil.hours() }}<span class="countdown-timer__time-label">h</span>
      </div>
      <div class="countdown-timer__time-block">
        {{ timeUntil.minutes() }}<span class="countdown-timer__time-label">m</span>
      </div>
      <div class="countdown-timer__time-block">
        {{ timeUntil.seconds() }}<span class="countdown-timer__time-label">s</span>
      </div>
    </div>
  </slot>
  <slot name="past" v-if="isPast"></slot>
  <slot name="almost-past" v-else-if="isAlmostPast"></slot>
</template>

<script>
import moment from 'moment';

export default {
  name: 'CountdownTimer',
  props: {
    deadline: Number,
    gracePeriodSeconds: {
      type: Number,
      default: 0,
    },
    format: {
      type: String,
      default: 'span',
    },
    size: {
      type: String,
      default: 'large',
    },
    countdownIntroMessage: {
      type: String,
    },
  },
  data() {
    return {
      date: moment(this.deadline),
      now: moment(),
      clockInterval: null,
    };
  },
  computed: {
    timeUntil() {
      return moment.duration(this.date.diff(this.now));
    },
    isAlmostPast() {
      return this.timeUntil.asSeconds() < this.gracePeriodSeconds;
    },
    isPast() {
      return this.timeUntil.asSeconds() < 1;
    },
  },
  mounted() {
    this.clockInterval = setInterval(() => {
      this.now = moment();
    }, 1000);
  },
  unmounted() {
    clearInterval(this.clockInterval);
  },
};
</script>
