<template>
  <div class="frontend-text-input__container" :class="{'has-error': errors && errors.length > 0}">
    <div class="frontend-text-input__label-container">
      <label class="frontend-text-input__label" :for="id">{{ label }}</label>
    </div>

    <div class="frontend-text-input__holder">
      <span v-if="placeholderIcon" class="frontend-text-input__placeholder-icon">
        <i :class="placeholderIcon"></i>
      </span>
      <select
        class="frontend-text-input"
        v-bind="inputProps"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        >
        <option v-if="placeholder" value="" :text="placeholder"> </option>
        <option v-for="{value, text} in options" :key="value" v-bind="{value, text}"></option>
      </select>
    </div>

    <ul class="frontend-text-input__error" v-if="errors && errors.length > 0">
      <li v-for="error in errors" :key="error">
        <i class="far fa-triangle-exclamation"></i>
        {{ error }}
      </li>
    </ul>

    <div v-if="errorMessage" class="frontend-text-input__error">
      {{ errorMessage }}
    </div>
    <p class="frontend-text-input__description" v-if="description" :id="id + '-described-by'">
      {{ description }}
    </p>
  </div>
</template>

<script>

let uuidCounter = 0;

function uuid(key) {
  uuidCounter += 1;
  return `${key}-${uuidCounter}`;
}

export default {
  name: 'FrontendSelectInput',
  emits: ['update:modelValue'],
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    errorMessage: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: () => uuid('check-field-input'),
    },
    label: {
      type: String,
    },
    modelValue: {
      required: true,
    },
    name: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
    },
    placeholderIcon: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
    },
    description: {
      type: String,
      required: false,
    },
  },
  computed: {
    inputProps() {
      return {
        id: this.id,
        name: this.name,
        required: this.required,
      };
    },
  },
  methods: {
    update(event) {
      this.$emit('update:errors', []);
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>
