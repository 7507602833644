<template>
  <div class="profile-table">
    <div class="profile-table__header">
      <div class="profile-table__header-label">
        <!-- placeholder to add spacing -->
      </div>
    </div>
    <div class="profile-table__body list-group">
      <div
        v-for="video in videos"
        :key="video.id"
        class="profile-table__row profile-table__row--with-status-border"
        :class="{
          'profile-table__row--success': video.status === 'ready',
          'profile-table__row--warning': video.status !== 'ready'
        }"
      >
        <VideoPlayer
          className="profile-table__row-image"
          :placeholderIcon="placeholderIcon(video)"
          :videoAsset="video"
        />
        <div class="profile-table__row-text">
          <div>
            <div>
              <strong>{{ video.title }}</strong>
            </div>
            <div class="chip-list">
              <span
                class="chip chip--small chip--neutral"
                :class="{
                  'chip--success': ready(video),
                  'chip--alert': failed(video) || removed(video),
                }"
              >
                {{ __(`mux_video_upload.video_status.${video.status}`) }}
              </span>
              <span class="chip chip--small">
                {{ __('hubs.eventProfile.countPlays', { count: video.plays_count }) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="videos.length === 0" class="hubs-empty-state">
        <div class="hubs-empty-state__icon">
          <i class="far fa-video"></i>
        </div>
        <h2>{{ __('hubs.eventProfile.videos') }}</h2>
        <p v-html="__('hubs.eventProfile.emptyList')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from '~/Components/Frontend/VideoPlayer.vue';

export default {
  name: 'EventProfilePageVideoTable',
  components: {
    VideoPlayer,
  },
  props: {
    videos: Array,
  },
  methods: {
    ready(video) {
      return video.status === 'ready';
    },
    failed(video) {
      return video.status === 'upload-failed'
        || video.status === 'upload-cancelled'
        || video.status === 'errored';
    },
    removed(video) {
      return video.status === 'removed'
        || video.status === 'deleted';
    },
    placeholderIcon(video) {
      if (this.failed(video)) {
        return 'fa-ban';
      }
      if (this.removed(video)) {
        return 'fa-trash-can';
      }
      return 'fa-timer';
    },
  },
};

</script>
