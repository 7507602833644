<template>

  <div class="modal-expandable">
    <div v-if="hasHeader" class="modal-expandable__header" @click="toggleExpanded">
      <h3 class="modal-expandable__title"> {{ title }} </h3>
      <button class="modal-expandable__collapse-icon">
        <i v-if="expanded" class="far fa-chevron-up"></i>
        <i v-else class="far fa-chevron-down"></i>
      </button>
    </div>
    <div v-if="$slots.default"
      class="modal-expandable__body"
      :class="{ 'modal-expandable__body--expanded': expanded }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalExpandable',
  props: {
    title: String,
    hasHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      expanded: true,
    };
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
