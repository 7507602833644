<template>
  <MeetingChatRow
    type="ColleagueAddedToMeeting"
    :message="__('conversations.colleague_added', {
        added_by: sender.company.display_name,
        new_colleague: newColleague.display_name,
      })"
    :when="chat.when"
    :sender="sender"
    :sentByYou="sentByYou"
    :read="chat.read"
    important
  >
    <template #chip>
      <div class="chip chip--small chip--alert">
        <i class="fas fa-user-plus"></i>
        {{ __('conversations.chips.colleague_added') }}
      </div>
    </template>
  </MeetingChatRow>
</template>
<script>

export default {
  props: {
    chat: {
      type: Object,
      required: true,
    },
    sender: {
      type: Object,
      required: true,
    },
    invite: {
      type: Object,
      required: false,
    },
    meeting: {
      type: Object,
      required: false,
    },
    statusMessage: {
      type: String,
    },
    policy: {
      type: Object,
      required: false,
    },
    sentByYou: {
      type: Boolean,
      required: true,
    },
    newColleague: {
      type: Object,
      required: false,
    },
  },
};

</script>
