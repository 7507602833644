<template>
  <div class="progress-bar" :style="{'--progress': progress }">
    <div class="progress-bar__label">
      <slot></slot>
    </div>
    <div class="progress-bar__bar">
      <div class="progress-bar__progress">
      </div>
      <label
        class="progress-bar__tip"
        :style="{ color: (progress > 50 ? 'white' : 'black') }"
        >
        {{ Math.round(this.progress) }} %
      </label>
    </div>
  </div>
</template>
<script>

import moment from 'moment';

export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    showTimer: {
      type: Boolean,
    },
  },
  data() {
    return {
      startTime: null,
      currentTime: null,
      elapsedTime: null,
      remainingTime: null,
    };
  },
  created() {
    this.startTime = moment();
  },
  watch: {
    progress(newValue, oldValue) {
      if (newValue > 0 && newValue - oldValue < 0.01) {
        return;
      }
      const elapsedTime = moment() - this.startTime;
      if (this.progress > 0) {
        this.remainingTime = ((100 - this.progress) / (this.progress)) * elapsedTime;
      }
    },
  },
};
</script>

<style>
  .progress-bar {
    --progress-bar-color: var(--success-color);
    --progress: 0;
    margin: 1rem;
  }

  .progress-bar__bar {
    position: relative;
    border-radius: 50px;
    background: var(--gray-2);
    overflow: hidden;
    margin-bottom: 0.5rem;
  }

  .progress-bar__progress {
    height: 16px;
    width: calc(100% * (var(--progress) / 100));
    background: var(--progress-bar-color);
    transition: all 1s;
    border-radius: 50px;
  }

  .progress-bar__tip {
    width: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    top: 0;
    color: black;
    font-weight: bold;
  }

  .progress-bar__label {
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
</style>
