<template>
  <div
    class="inbox-row"
    @click="openMessage()"
  >
    <div class="inbox-row__header">
      <InboxContactHeader
        :contact="message.counterpart"
        :relationshipLabel="__('inbox.relationshipLabels.with')"
      ></InboxContactHeader>
      <div class="inbox-row__inbox-status-container">
        <span
          v-if="!message.read"
          class="inbox-row__inbox-status inbox-row__invite-status--unread"
        >
          <i class="far fa-exclamation-circle me-1"></i>
              {{ __('inbox.invites.statuses.unread') }}
        </span>
      </div>
    </div>
    <div class="inbox-row__divider"></div>
    <div class="inbox-row__body">
      <div class="inbox-row__details">
        <div class="inbox-row__when">
          <i class="fa-solid fa-fw me-1"
            :class="{
              'fa-inbox-out': sentByActiveContact,
              'fa-inbox-in': !sentByActiveContact,
            }"
          ></i>
          <template v-if="sentByActiveContact">
            {{ __('inbox.messages.whenSent') }}
          </template>
          <template v-else>
            {{ __('inbox.messages.whenReceived') }}
          </template>
          {{ message.created_at_diff }}
        </div>
      </div>
      <div
        class="inbox-row__message"
        :class="{ 'inbox-row__message-sent': sentByActiveContact }"
      >
        <p>
          <PreWrap :text="message.chat_body" />
          <template v-if="message.admin">
            <br>
            <small>
              <i class="fas fa-user-headset"></i>
              {{ message.admin.first_name }} {{ message.admin.last_name }}
              (Event concierge)
            </small>
          </template>
        </p>
      </div>
      <div class="inbox-row__additional-info">
        <i class="far fa-info-circle"></i>
        {{ __('inbox.messages.info.moreMessagesInThread') }}
      </div>
      <div class="button-group">
        <a
          v-if="message.can_accept"
          class="button button--primary"
          type="button"
          :href="`#/invites/${message.chatable_id}/accept`"
          >
          {{ __('inbox.invites.buttons.accept') }}
          <i class="far fa-arrow-right-long"></i>
        </a>
        <a
          v-else-if="message.chatable_type === 'meeting'"
          class="button button--primary"
          type="button"
          :href="`#/meetings/${message.chatable_id}/details`"
          >
          {{ __('inbox.messages.meetingInformation') }}
        </a>
        <button
          class="button button--secondary"
          type="button"
          @click="openMessage"
          >
          {{ __('inbox.invites.buttons.respond') }}
        </button>
      </div>
    </div>
  </div>

</template>
<script>
import { store } from '~/Frontend/store';
import PreWrap from '~/Components/Common/PreWrap.vue';
import InboxContactHeader from './InboxContactHeader.vue';

export default {
  components: {
    InboxContactHeader,
    PreWrap,
  },
  props: {
    message: Object,
    activeContact: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    sentByActiveContact() {
      return this.message?.contact?.id === this.activeContact.id;
    },
    eventEdition() {
      return store.eventEdition;
    },
  },
  methods: {
    goToProfile(id) {
      window.location.href = `#/contacts/${id}/profile`;
    },
    companyModalLink(id) {
      return `#/companies/${id}`;
    },
    openMessage() {
      if (this.message.chatable_type === 'meeting') {
        window.location.href = `#/meetings/${this.message.chatable_id}/details/chat`;
      } else {
        window.location.href = `#/invites/${this.message.chatable_id}/show`;
      }
    },
  },
};
</script>
