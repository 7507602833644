<template>
  <button
    v-if="generateAiUrl"
    @click="generateAiText"
    type="button"
    class="button button--secondary frontend-text-input__ai-assistant"
    :disabled="isTyping"
  >
    <i :class="loading ? 'fas fa-spinner fa-spin-pulse' : 'fas fa-microchip-ai'"></i>
    {{ hasGeneratedAiText ? __('buttons.recreate_with_ai' ) : __('buttons.write_with_ai' ) }}
  </button>
</template>

<script>
import axios from 'axios';

export default {
  emits: ['update:text'],
  name: 'GenerateAiButton',
  props: {
    generateAiUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      hasGeneratedAiText: false,
      isTyping: false,
      content: '',
      typingSpeed: 17, // in milliseconds
      typingIndex: 0,
      timeout: null,
    };
  },
  methods: {
    generateAiText() {
      if (this.loading) return;
      this.loading = true;
      clearTimeout(this.timeout);
      axios.post(this.generateAiUrl)
        .then((response) => {
          this.content = response.data.text;
          this.hasGeneratedAiText = true;
          this.loading = false;
        });
    },
    startTypewriter() {
      this.typingIndex = 0;
      this.isTyping = true;
      this.typeNextCharacter();
    },
    typeNextCharacter() {
      if (this.typingIndex > this.content.length) {
        this.isTyping = false;
        return;
      }
      this.$emit('update:text', this.content.substring(0, this.typingIndex));
      this.typingIndex += 1;
      this.timeout = setTimeout(this.typeNextCharacter, this.typingSpeed);
    },
  },
  watch: {
    content() {
      this.startTypewriter();
    },
  },
  unmounted() {
    clearTimeout(this.timeout);
  },
};
</script>
