<template>
  <FeLoading v-if="loading" />

  <template v-else>

    <section v-if="meetingStatistics" class="hub-section">

      <h1>{{ __('hubs.meetings.title') }}</h1>
      <p>{{ __('hubs.meetings.description') }}</p>

      <div class="fe-statistic-card__container">
        <FeStatisticCard
          v-if="companyMeetingPercentile"
          colorVar="statistics-palette-2"
          icon="far fa-chart-line-up"
          :header="__('hubs.meetings.performance')"
          :value="companyMeetingPercentileLabel"
        />

        <FeStatisticCard
          v-if="'confirmedMeetings' in meetingStatistics"
          colorVar="statistics-palette-1"
          icon="far fa-handshake"
          :header="__('hubs.meetings.confirmedMeetings')"
          :value="meetingStatistics.confirmedMeetings"
        />

        <FeStatisticCard
          v-if="'receivedInvites' in meetingStatistics"
          colorVar="statistics-palette-3"
          icon="far fa-inbox-in"
          :header="__('hubs.meetings.receivedInvites')"
          :value="meetingStatistics.receivedInvites"
        />
      </div>

      <div class="hub-row-2-col" v-if="meetingStatistics?.confirmedMeetings > 0">

        <HubMeetingsByDayChart
          :data="meetingsByDayData"
        />

        <HubMeetingsByLocationPie
          :data="meetingsByLocationData"
          :labels="meetingsByLocationLabels"
        />

      </div>

    </section>

    <section>
      <h2>{{ __('hubs.meetings.teamTitle') }}</h2>

      <div class="fe-statistic-card__container" v-if="false && teamStatistics">
        <FeStatisticCard
          v-if="'has_meetings' in teamStatistics"
          colorVar="statistics-palette-2"
          icon="far fa-users"
          :header="__('hubs.meetings.teamHasMeetings')"
          :value="teamStatistics.has_meetings"
        />

        <FeStatisticCard
          v-if="'has_sent' in teamStatistics"
          colorVar="statistics-palette-1"
          icon="far fa-user-magnifying-glass"
          :header="__('hubs.meetings.teamHasSent')"
          :value="teamStatistics.has_sent"
        />

        <FeStatisticCard
          v-if="'has_pending' in teamStatistics"
          colorVar="statistics-palette-3"
          icon="far fa-user-clock"
          :header="__('hubs.meetings.teamHasPending')"
          :value="teamStatistics.has_pending"
        />

      </div>

      <div class="profile-group">
        <div class="profile-table">
           <div class="profile-table__header">
            <div class="profile-table__header-label">
              <div class="chip-list" style="justify-content: flex-end;">
                <span class="chip chip--small chip--success">
                  <i class="far fa-handshake"></i>
                  {{ __('hubs.definitions.confirmedMeetings') }}
                </span>

                <span class="chip chip--small chip--primary">
                  <i class="far fa-inbox-out"></i>
                  {{ __('hubs.definitions.sentInvites') }}
                </span>

                <span class="chip chip--small chip--alert">
                  <i class="far fa-inbox-in"></i>
                  {{ __('hubs.definitions.receivedPendingInvites') }}
                </span>
              </div>
            </div>
          </div>
          <div class="profile-table__body list-group">
            <div
              v-for="contact in team"
              :key="contact.id"
              class="profile-table__row profile-table__row--with-status-border profile-table__row--spaced"
              :class="{
                'profile-table__row--success': contact.active,
                'profile-table__row--warning': contact.opted_out
              }"
            >
              <div class="profile-table__row-avatar">
                <ContactAvatar :contact="contact" size="small" />
              </div>
              <div class="profile-table__row-text">
                <div>
                  <div>
                    <strong>{{ contact.display_name }}</strong>,
                    {{ contact.job_title }}
                  </div>
                  <div class="chip-list" style="display: none">
                    <Popper
                      :content="__('hubs.definitions.confirmedMeetings')"
                      :disabled="contact.meetings === 0"
                      hover
                      arrow
                    >
                      <span
                        class="chip chip--small chip--neutral"
                        :class="{ 'chip--disabled': contact.meetings === 0 }"
                      >
                        <i class="far fa-handshake"></i> {{ contact.meetings }}
                      </span>
                    </Popper>
                    <Popper
                      :content="__('hubs.definitions.sentInvites')"
                      :disabled="contact.sent === 0"
                      hover
                      arrow
                    >
                      <span
                        class="chip chip--small chip--neutral"
                        :class="{ 'chip--disabled': contact.sent === 0 }"
                      >
                        <i class="far fa-inbox-out"></i> {{ contact.sent }}
                      </span>
                    </Popper>
                    <Popper
                      :content="__('hubs.definitions.receivedPendingInvites')"
                      :disabled="contact.pending === 0"
                      hover
                      arrow
                    >
                      <span
                        class="chip chip--small chip--neutral"
                        :class="{ 'chip--disabled': contact.pending === 0 }"
                      >
                        <i class="far fa-inbox-in"></i> {{ contact.pending }}
                      </span>
                    </Popper>
                  </div>
                </div>
              </div>
              <div class="profile-table__row-action">
                <div class="chip-list">
                  <Popper
                    :content="__('hubs.definitions.confirmedMeetings')"
                    :disabled="contact.meetings === 0"
                    hover
                    arrow
                  >
                    <span
                      class="chip chip--success"
                      :class="{ 'chip--disabled': contact.meetings === 0 }"
                    >
                      <i class="far fa-handshake"></i> {{ contact.meetings }}
                    </span>
                  </Popper>
                  <Popper
                    :content="__('hubs.definitions.sentInvites')"
                    :disabled="contact.sent === 0"
                    hover
                    arrow
                  >
                    <span
                      class="chip chip--primary"
                      :class="{ 'chip--disabled': contact.sent === 0 }"
                    >
                      <i class="far fa-inbox-out"></i> {{ contact.sent }}
                    </span>
                  </Popper>
                  <Popper
                    :content="__('hubs.definitions.receivedPendingInvites')"
                    :disabled="contact.pending === 0"
                    hover
                    arrow
                  >
                    <span
                      class="chip chip--alert"
                      :class="{ 'chip--disabled': contact.pending === 0 }"
                    >
                      <i class="far fa-inbox-in"></i> {{ contact.pending }}
                    </span>
                  </Popper>
                </div>
              </div>
              <div class="profile-table__row-action" style="width: 28px">
                <Popper
                  v-if="contact.impersonate_route"
                  :content="__('buttons.impersonateUser')"
                  hover
                  arrow
                >
                  <a v-if="contact.impersonate_route" :href="contact.impersonate_route">
                    <i class="far fa-arrow-right"></i>
                    <i class="far fa-user"></i>
                  </a>
                </Popper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <CombinedTeamSchedule v-if="canShareSchedule" />
  </template>
</template>

<script>
import axios from 'axios';
import Popper from 'vue3-popper';
import { store, user } from '~/Frontend/store';
import ContactAvatar from '~/Components/Frontend/CRM/Components/ContactAvatar.vue';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import FeStatisticCard from '~/Components/Frontend/Core/Misc/FeStatisticCard.vue';
import HubMeetingsByDayChart from '~/Components/Frontend/Hubs/Charts/HubMeetingsByDayChart.vue';
// import NotificationBar from '~/Components/Frontend/NotificationBar.vue';
import CombinedTeamSchedule from '../../CombinedTeamSchedule.vue';

export default {
  name: 'HubMeetingsPage',
  components: {
    CombinedTeamSchedule,
    ContactAvatar,
    FeLoading,
    FeStatisticCard,
    HubMeetingsByDayChart,
    Popper,
  },
  data() {
    return {
      loading: false,
      companyMeetingPercentile: 0,
      meetingStatistics: null,
      meetingsByDayData: [{
        name: 'Meetings by day',
        data: [],
      }],
      meetingsByLocationLabels: [],
      meetingsByLocationData: [],
      team: [],
      teamStatistics: null,
      canShareSchedule: false,
    };
  },
  computed: {
    activeContact() {
      return user.activeContact;
    },
    eventEdition() {
      return store.eventEdition;
    },
    companyMeetingPercentileLabel() {
      if (this.companyMeetingPercentile < 35) {
        return this.__('hubs.stats.belowAverage');
      }

      if (this.companyMeetingPercentile < 50) {
        return this.__('hubs.stats.average');
      }

      return this.__('hubs.meetings.performanceScore', { score: 100 - this.companyMeetingPercentile });
    },
  },
  methods: {
    loadData() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const url = new URL(window.location);
      const ajaxUrl = `${url.pathname}/meetings`.replace('//', '/');

      axios.get(`${ajaxUrl}?ajax=true`).then((response) => {
        this.companyMeetingPercentile = response.data.company_meeting_percentile;

        this.meetingStatistics = response.data.meeting_statistics;

        this.meetingsByDayData[0].data = response.data.meetings_by_day.map((item) => (
          {
            x: item.day,
            y: item.count,
          }
        ));

        this.meetingsByLocationData = response.data.meetings_by_location.map((item) => item.count);
        this.meetingsByLocationLabels = response.data.meetings_by_location.map((item) => item.location);

        this.team = response.data.team;
        this.teamStatistics = response.data.team_statistics;
        this.canShareSchedule = response.data.can_share_schedule;
      }).then(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
