<template>
  <div :class="classes" v-if="displayValue != 0">
    {{ displayValue }}
  </div>
</template>

<script>
export default {
  props: {
    alert: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      required: false,
    },
    positive: {
      type: Boolean,
    },
    informational: {
      type: Boolean,
    },
    neutral: {
      type: Boolean,
    },
    small: {
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return {
        badge: true,
        'badge--informational': this.informational,
        'badge--positive': this.positive,
        'badge--neutral': this.neutral,
        'badge--small': this.small,
      };
    },
    displayValue() {
      if (this.alert) {
        return '!';
      } if (this.value > 9) {
        return '9+';
      }
      return this.value ?? 0;
    },
  },
};
</script>
