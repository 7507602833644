<template>
  <div class="chart-card">
      <div class="chart-card__statistic">
        <strong>{{ __('hubs.meetings.MeetingsByLocationTitle') }}</strong>
      </div>
      <apexchart
        v-if="data.length"
        type="pie"
        :options="options"
        :series="data"
      />
    </div>
</template>

<script>
import Vue3Apexcharts from 'vue3-apexcharts';

export default {
  name: 'HubMeetingsByLocationPie',
  components: {
    apexchart: Vue3Apexcharts,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      options: {
        chart: {
          id: 'meetings-by-location',
          background: '#fff',
          offsetY: 64,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
          formatter(value, opts) {
            return [opts.w.globals.labels[opts.seriesIndex], `${Math.round(value)}%`];
          },
        },
        labels: this.labels,
        legend: {
          show: false,
        },
      },
    };
  },
};
</script>
