<template>
  <ProfileTableRow
    class="catalogue-entry-row"
    :class="{shake: recentlyUpdated, disabled: type.is_locked}"
    draggable
    >
    <FeImage
      class="catalogue-entry-row__image"
      :image="mainImage"
      format="card"
      :alt="mainImage?.image_name ?? ''"
      />

    <div class="catalogue-entry-row__name">
      {{ entry.name }}
    </div>

    <template v-if="smallLaptopAndAbove && ! type.is_locked">
      <Popper
        :content="__('buttons.manageImages')"
        hover
        arrow
      >
        <button
          class="button button--link"
          @click="$refs.imagesModal.open()"
          >
          <i class="far fa-image"></i>
        </button>
      </Popper>

      <EditIconButton @click="$refs.editModal.open()" />

      <DeleteIconButton @click="$refs.deleteModal.open()" />
    </template>

    <SubOptions icon="far fa-gear" v-else-if="! type.is_locked">
      <template v-slot:button="{toggle}">
        <button class="button button--link" @click.prevent="toggle">
          <i class="far fa-lg fa-cog"></i>
        </button>
      </template>
      <SubOption @click="$refs.imagesModal.open()" icon="far fa-image">
        {{ __('buttons.manageImages') }}
      </SubOption>
      <SubOption @click="$refs.editModal.open()" icon="far fa-edit">
        {{ __('buttons.edit') }}
      </SubOption>
      <SubOption @click="$refs.deleteModal.open()" icon="far fa-trash">
        {{ __('buttons.delete') }}
      </SubOption>
    </SubOptions>

    <div class="profile-table-row__dragable" v-if="! type.is_locked">
      <i class="catalogue-entry-row__grip far fa-grip-lines handle"></i>
    </div>

    <Teleport to="body">
      <BaseModal
        ref="deleteModal"
        :title="__('ui.delete')"
        titleIcon="far fa-trash-can"
      >
        <p>
          {{ __('profile.tables.catalogueEntry.confirmDelete', type) }}
          <br>
          <strong>{{ entry.name }}</strong>
        </p>

        <template #footer>
          <div class="button-group">
            <button
              class="button button--secondary"
              @click="$refs.deleteModal.close()"
              >
              {{ __('buttons.cancel') }}
            </button>
            <button
              class="button button--warning"
              @click="destroy"
              >
              {{ __('buttons.delete') }}
            </button>
          </div>
        </template>
      </BaseModal>

      <CatalogueEntryForm
        :entry="localEntry"
        :type="type"
        :demographics="demographics"
        :colleagues="colleagues"
        @success="updated"
        ref="editModal"
        />

      <CatalogueEntryImagesModal
        v-model:gallery="localEntry.gallery"
        :entryName="entry.name"
        :entryId="entry.id"
        ref="imagesModal"
        :type="type"
      />
    </Teleport>
  </ProfileTableRow>
</template>

<script>

import axios from 'axios';
import Popper from 'vue3-popper';
import { useBreakpoints } from '~/Frontend/useBreakpoints';

export default {
  components: { Popper },
  props: {
    entry: {
      type: Object,
      required: true,
    },
    demographics: {
      type: Array,
      required: true,
    },
    type: {
      type: Object,
      required: true,
    },
    colleagues: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      recentlyUpdated: false,
      disabledRow: false,
    };
  },
  computed: {
    localEntry: {
      get() {
        return this.entry;
      },
      set(entry) {
        this.$emit('update:entry', entry);
      },
    },
    mainImage() {
      return this.entry.gallery[0] ?? null;
    },
  },
  setup() {
    const breakpoints = useBreakpoints();
    const smallLaptopAndAbove = breakpoints.greaterOrEqual('smallLaptop');
    return { smallLaptopAndAbove };
  },
  emits: ['update:entry', 'deleted'],
  created() {
    if (this.entry.fresh) {
      this.recentlyUpdated = true;
    }
  },
  watch: {
    recentlyUpdated(newValue) {
      if (newValue) {
        window.setTimeout(() => { this.recentlyUpdated = false; }, 1000);
      }
    },
  },
  methods: {
    destroy() {
      axios.delete(`/profile/catalogue-entries/${this.entry.id}`)
        .then(() => {
          this.$refs.deleteModal.close();
          this.$emit('deleted');
        });
    },
    updated(result) {
      this.$emit('update:entry', result.catalogue_entry);
      // this.$emit('updated', result.catalogue_entry);
      this.recentlyUpdated = true;
      this.$refs.editModal.close();
    },
  },
};
</script>

<style>
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
.disabled {
  background: #eaeaea;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
