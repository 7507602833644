<template>
   <div class="registration-legal-component">
    <input type="checkbox"
      v-model="value"
      :name="registrationStore.getCurrentQuestion.component_type"
      id="legal"
      value="agree"
      class="registration-legal-component__checkbox"
    />
    <label for="legal">
      <span class="registration-legal-component__terms">
        {{ registrationStore.getCurrentQuestion.placeholder }}
      </span>
      <p class="registration-legal-component__consent-statement"
      v-html="registrationStore.getCurrentQuestion.consent_statement_ml">
      </p>
    </label>
  </div>
  <ul class="registration-input-error"
    v-if="registrationStore.getCurrentQuestion.error &&
      registrationStore.getCurrentQuestion.error.length > 0">
    <li
      v-for="error in registrationStore.getCurrentQuestion.error"
      :key="error">
      {{ error }}
    </li>
  </ul>
</template>

<script>
import { mapStores } from 'pinia';
import useRegistrationStore from '~/Store/Registration';

export default {
  type: 'lazy',
  name: 'RegistrationLegalComponent',
  data() {
    return {
      value: [],
    };
  },
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
  },
  mounted() {
    this.value = this.registrationStore.getCurrentQuestion?.answer?.length > 0
      ? this.registrationStore.getCurrentQuestion?.answer : [];
  },
  watch: {
    value(newValue) {
      this.registrationStore.getCurrentQuestion.answer = newValue;
    },
  },
};
</script>
