import i18next from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import en from '../Frontend/lang/en';
import ar from '../Frontend/lang/ar';
import ptBR from '../Frontend/lang/pt_BR';
import frFR from '../Frontend/lang/fr_FR';
import esES from '../Frontend/lang/es_ES';
import enUS from '../Frontend/lang/en_US';
import deDE from '../Frontend/lang/de_DE';

i18next
  .use(intervalPlural)
  .init({
    lng: document.head.querySelector('meta[name="locale-code"]').content.replace('_', '-'),
    fallbackLng: 'en',
    debug: true,
    resources: {
      en: {
        translation: en,
      },
      'pt-BR': {
        translation: ptBR,
      },
      'fr-FR': {
        translation: frFR,
      },
      'es-ES': {
        translation: esES,
      },
      'en-US': {
        translation: enUS,
      },
      'de-DE': {
        translation: deDE,
      },
      ar: {
        translation: ar,
      },
    },
  });

/**
 * Formatters
 *
 * @link https://www.i18next.com/translation-function/formatting
 */
i18next.services.formatter.add('lowercase', (value) => value.toLowerCase());

// eslint-disable-next-line
export const translations = {
  methods: {
    __: (key, replace = {}) => i18next.t(key, { postProcess: 'interval', ...replace }), // -> "one item"
    choice: (key, number, replace = {}) => i18next.t(key, { postProcess: 'interval', ...replace, count: number }), // -> "two items"
  },
};
