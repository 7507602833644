<template>
  <div ref="inputImage" class="image-input">
    <label v-if="label" class="image-input__label"> {{ label }} </label>
    <span v-if="errors" class="image-input__error"> {{ errors[0] }} </span>
    <div class="image-input__input">
      <img v-if="modelValue" class="image-input__image" :src="preview" >
      <button v-if="modelValue" class="button button--secondary" @click.stop="clear">
        {{ __('drop_image.buttons.clear') }}
      </button>
    </div>
    <label v-if="!modelValue" class="image-input__drop-zone" @drop="handleFileDrop( $event )">
      <i class="far fa-upload image-input__icon"></i>
      <span class="image-input__tip"> {{ __('drop_image.text') }} </span>

      <!-- use opacity 0 so validation messages still appear -->
      <input
        type="file"
        style="opacity: 0; height: 0;"
        @change="handleFileInput( $event )"
        :required="required"
        ref="fileInput"
        />
    </label>
     <small v-if="modelValue?.name"> <br> ( {{ modelValue.name }} ) </small>
  </div>
</template>

<script>

export default {
  props: {
    modelValue: {
      required: true,
    },
    errors: {
      type: Array,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'success', 'cancel'],
  data() {
    return {
      preview: this.modelValue,
    };
  },
  computed: {
    dragAndDropCapable() {
      const div = document.createElement('div');
      return (('draggable' in div)
              || ('ondragstart' in div && 'ondrop' in div))
          && 'FormData' in window
          && 'FileReader' in window;
    },
  },
  mounted() {
    if (this.dragAndDropCapable) {
      this.bindEvents();
    }
  },
  methods: {
    bindEvents() {
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
        this.$refs.inputImage.addEventListener(evt, (e) => {
          e.preventDefault();
          e.stopImmediatePropagation();
        }, false);
      });
    },
    validate(file) {
      return (/\.(jpe?g|png|gif|webp)$/i.test(file.name));
    },
    handleFileInput(event) {
      this.setImage(event.target.files[0]);
    },
    handleFileDrop(event) {
      this.setImage(event.dataTransfer.files[0]);
    },
    setImage(file) {
      if (!this.validate(file)) {
        this.$emit(
          'update:errors',
          ['Unsupported File type, please use jpeg, png, gif or webp format'],
        );
        this.$refs.fileInput.value = null;
        return;
      }
      this.errorText = null;
      this.$emit('update:modelValue', file);
      this.$emit('update:errors', null);
    },
    clear() {
      this.$refs.fileInput.value = null;
      this.$emit('update:modelValue', null);
      this.$emit('update:errors', null);
    },
  },
  watch: {
    modelValue(newValue) {
      if (!(newValue instanceof Blob)) {
        this.preview = newValue;
        return;
      }
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.preview = reader.result;
      }, false);
      reader.readAsDataURL(newValue);
    },
  },
};
</script>
