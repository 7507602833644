<template>
  <BaseModal
    v-for="image in list"
    :ref="'delete-modal-' + image.id"
    :key="image.id"
  >
    <p>Are you sure you want to delete this Image? </p>
    <div class="form-input">
      <div class="form-input__input form-input--image">
        <span class="form-input__label"> {{ image.image_name }} </span>
        <FeImage :image="image" format="card" className="form-input__preview" />
      </div>
    </div>
    <div class="button-group">
      <button
        class="button button--warning"
        @click="deleteImage(image.id)"
        >
        Delete
      </button>
      <button
        class="button button--tertiary"
        @click="closeModal('delete-modal-' + image.id)"
        >
        Cancel
      </button>
    </div>
  </BaseModal>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
};
</script>
