<template>
  <BaseModal
    :title="__('company.company_book_meeting_modal.title')"
    height="tall"
    padding="double"
    startOpen
  >
    <template v-if="company.id">
      <div
        class="company-modal__logo-container"
        style="max-width: 100%"
      >
        <img
          v-if="company.logo?.logo?.url"
          class="company-card__logo"
          style="max-width: 200px"
          :src="company.logo.logo.url"
          :alt="company.display_name + ' logo'"
        />
        <h3 v-else>{{ company.display_name }}</h3>
      </div>
      <template v-if="recommendedCompanyContacts.length > 0">
        <h2 style="text-align: center;">
          {{ __('company.company_book_meeting_modal.select_contact') }}
        </h2>
        <div>
          <strong>
            {{ __('company.company_book_meeting_modal.recommended_contacts') }}
          </strong>
        </div>
        <div
          v-for="contact in recommendedCompanyContacts"
          :key="contact.id"
          class="profile-select__profile"
          :class="{
            'profile-select__profile--disabled': !contact.policy.can_meet,
            'profile-select__profile--active': selectedContact?.id === contact.id,
          }"
          @click="selectContact(contact)"
        >
          <div class="profile-select__avatar">
            <ContactAvatar :contact="contact" size="small" />
          </div>
          <div class="profile-select__name">
            <b>{{ contact.display_name }}</b>
            <span class="hide-for-mobile">, </span>
            <br class="hide-for-tablet-up" />
            {{ contact.job_title }} <br />
            {{ company.display_name }}
            <div>
              <span v-if="contact.primary_contact" class="chip chip--small"><i class="fa fa-circle-check"></i>&nbsp; recommended</span>
            </div>
          </div>
          <span v-if="selectedContact?.id === contact.id">
            <i class="fa-solid fa-circle-check info-icon--success"></i>
          </span>
          <span v-else-if="contact.policy.can_meet">
            <i class="fa-light fa-circle"></i>
          </span>
        </div>
      </template>
      <template v-else>
        <h3>
          {{ __('company.company_book_meeting_modal.cannot_meet') }}
        </h3>
      </template>
      <template v-if="companyContacts.length > 0">
        <div>
          <strong>
            {{ __('company.company_book_meeting_modal.other_contacts') }}
          </strong>
        </div>
        <div
          v-for="contact in companyContacts"
          :key="contact.id"
          class="profile-select__profile"
          :class="{
            'profile-select__profile--disabled': !contact.policy.can_meet,
            'profile-select__profile--active': selectedContact?.id === contact.id,
          }"
          @click="selectContact(contact)"
        >
          <div class="profile-select__avatar">
            <ContactAvatar :contact="contact" size="small" />
          </div>
          <div class="profile-select__name">
            <b>{{ contact.display_name }}</b>
            <span class="hide-for-mobile">, </span>
            <br class="hide-for-tablet-up" />
            {{ contact.job_title }} <br />
            {{ company.display_name }}
          </div>
          <span v-if="selectedContact?.id === contact.id">
            <i class="fa-solid fa-circle-check info-icon--success"></i>
          </span>
          <span v-else-if="contact.policy.can_meet">
            <i class="fa-light fa-circle"></i>
          </span>
        </div>
        <div
          style="display:flex; justify-content: center"
          class="button-group">
          <LoadMoreButton
            :isLastPage="!(contacts.length < numberOfRecords)"
            :loading="loading"
            @loadMore="loadMoreContacts"
          />
        </div>
      </template>
    </template>
    <template v-else>
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>
    <template #footer>
        <a v-if="company.id &&
          eventEdition?.enable_meetings &&
          eventEdition?.enable_book_contact_meetings_on_company &&
          selectedContact"
           class="button button--full"
           :href="selectedContact.send_invite_route"
        >
          {{ __(`company.company_book_meeting_modal.next`) }}
        </a>
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import { store } from '../../../Frontend/store';

export default {
  props: {
    companyId: {
      type: String,
      required: true,
    },
    catalogueEntryId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      errored: false,
      company: {},
      numberOfRecords: 0,
      showMoreContactsButton: true,
      selectedContact: null,
      contacts: [],
      loading: false,
    };
  },
  methods: {
    goToCompany() {
      window.location.href = `/${this.eventEdition.slug}/companies/${this.companyId}`;
    },
    selectContact(contact) {
      if (contact.policy.can_meet) {
        this.selectedContact = contact;
      }
    },
    loadData() {
      this.loading = true;
      axios
        .get(this.bookMeetingUrl)
        .then((response) => {
          this.contacts = response.data.meetable_contacts;
          if (this.meetableContacts.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            this.selectedContact = this.meetableContacts[0];
          }
          this.numberOfRecords = response.data.number_of_records;
          this.company = response.data.company;
          this.loading = false;
        })
        .catch(() => {
          this.errored = true;
          this.loading = false;
        });
    },
    loadMoreContacts() {
      this.loading = true;
      axios
        .get(`${this.bookMeetingUrl}/?start_record=${this.contacts.length}`)
        .then((response) => {
          this.contacts = this.contacts.concat(response.data.meetable_contacts);
          this.loading = false;
        })
        .catch(() => {
          this.errored = true;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loadData();
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
    bookMeetingUrl() {
      if (this.catalogueEntryId) {
        return `/${this.eventEdition?.slug}/companies/${this.companyId}/book-meeting/${this.catalogueEntryId}`;
      }

      return `/${this.eventEdition?.slug}/companies/${this.companyId}/book-meeting`;
    },
    recommendedCompanyContacts() {
      return this.contacts.slice(0, 2);
    },
    companyContacts() {
      return this.contacts.slice(2);
    },
    meetableContacts() {
      return this.contacts.filter((contact) => contact.policy.can_meet);
    },
  },
};
</script>
