<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.contact.jobTitle') }}
    </div>
    <div class="profile-row__value"> {{ jobTitle }} </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="$refs.modal.open()">
        <i class="fa fa-edit"></i>
        {{ __('buttons.edit') }}
      </button>
    </div>
  </div>
  <ModalForm
    :form="{
      action: '/profile/job-title',
      method: 'patch',
      name: 'job-title',
    }"
    :modal="{
      title: __('ui.edit'),
      titleIcon: 'far fa-edit',
    }"
    ref="modal"
    @success="update"
  >
  <FormText
    name="job_title"
    :default="jobTitle"
    :label="__('crm.contact.jobTitle')"
    required
    />
      <template #footer>
        <div class="button-group">
          <button type="button" class="button button--secondary" @click="$refs.modal.close()">
            {{ __('buttons.cancel') }}
          </button>
          <FormSubmit>
            {{ __('buttons.save') }}
          </FormSubmit>
        </div>
      </template>
  </ModalForm>
</template>

<script>

import { user } from '~/Frontend/store';

export default {
  computed: {
    jobTitle: () => user.activeContact.job_title,
  },
  methods: {
    update(data) {
      user.activeContact.job_title = data.job_title;
      this.$refs.modal.close();
    },
  },
};

</script>;
