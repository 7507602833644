<template>
  <ModalForm
    :form="{
      action: action,
      method: method,
      name: 'video-form',
    }"
    :modal="{
      title: __(`profile.forms.video.${formType}.modalTitle`),
      titleIcon: isCreate ? 'far fa-plus-circle' : 'far fa-edit',
      height: 'tall',
    }"
    @success="stepOneSuccess"
    ref="modal"
  >
    <template v-if="!uploading">
      <FormText
        :default="isUpdate ? video.title : ''"
        name="title"
        :label="__('assets.video.title')"
        :max="255"
        required
      />
      <FormTextarea
        :modelValue="isUpdate ? video.description : ''"
        name="description"
        :label="__('assets.video.description')"
        required
      />

      <FormTreeSelect
        v-for="demographic in demographics"
        :key="demographic.id"
        :name="`demographic_values[${demographic.id}]`"
        :label="demographic.name"
        :options="optionsMap.get(demographic.id)"
        :multiple="demographic.is_multi"
        :selected="selectedMap.get(demographic.id)"
        :valueLimit="demographic.value_limit"
      />

      <FormVideo
        v-if="isCreate"
        name="video"
        :label="__('assets.video.video')"
        required
        v-model="videoFile"
      />
      <div v-else class="form-input">
        <FormLabel>
          <i class="far fa-lock"></i> {{ __('assets.video.video') }}
        </FormLabel>
        <div class="form-input__input form-input--image">
          <VideoPlayer
            :videoAsset="video"
            :placeholderIcon="video.status === 'ready' ? null : 'fa-timer'"
          />
        </div>
      </div>
  </template>
  <ProgressBar  v-if="progress !== null && !completed" :progress="progress">
    {{ __('profile.forms.video.progressMessage') }}
  </ProgressBar>
  <IconSuccess
    v-if="completed"
    @finished="$emit('finished')"
  />

  <template #footer>
    <div class="button-group" v-if="!uploading">
      <button
        type="button"
        class="button button--secondary"
        @click="$refs.modal.close()"
      >
        {{ __('buttons.cancel') }}
      </button>
      <FormSubmit> {{ isUpdate ? __('buttons.update') : __('buttons.save') }} </FormSubmit>
    </div>
  </template>
</ModalForm>

</template>

<script>

import * as upChunk from '@mux/upchunk';
import axios from 'axios';

const toTreeOption = (value) => ({
  value: value.id,
  label: value.name,
  options: value.descendants?.map(toTreeOption),
});

export default {
  props: {
    video: {
      type: Object,
      required: false,
    },
    demographics: {
      type: Array,
    },
  },
  expose: ['open', 'close'],
  emits: ['uploadStarted', 'success'],
  data() {
    return {
      progress: null,
      videoFile: null,
      completed: false,
    };
  },
  computed: {
    formType() {
      return this.isCreate ? 'create' : 'edit';
    },
    isCreate() {
      return !this.video;
    },
    isUpdate() {
      return !!this.video;
    },
    method() {
      return this.isCreate ? 'post' : 'patch';
    },
    action() {
      const url = '/company-profile/video';
      return this.isCreate ? url : `${url}/${this.video.id}`;
    },
    uploading() {
      return this.progress !== null;
    },
    optionsMap() {
      return new Map(this.demographics.map((demographic) => [
        demographic.id,
        demographic.values.map(toTreeOption),
      ]));
    },
    selectedMap() {
      return new Map(this.demographics.map((demographic) => [
        demographic.id,
        this.video
          ? this.video.demographic_values
            .filter((value) => value.demographic_id === demographic.id)
            .map((value) => value.id)
          : [],
      ]));
    },
  },
  methods: {
    open() {
      this.progress = null;
      this.videoFile = null;
      this.completed = false;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    upload(url, video) {
      this.progress = 0;

      const upload = upChunk.createUpload({
        endpoint: url,
        file: this.videoFile,
        chunkSize: 5120,
      });

      upload.on('error', () => {
        this.errorText = 'There was an error uploading your video please try again';
        axios.post(`/company-profile/video/${video.id}/record-upload-failed`);
      });

      upload.on('progress', (progress) => {
        this.progress = progress.detail;
      });

      upload.on('success', () => {
        this.completed = true;
        this.progress = 100;
        window.setTimeout(() => {
          this.stepTwoSuccess(video);
        }, 1500);
        axios.post(`/company-profile/video/${video.id}/record-successful-upload`);
      });
    },
    stepOneSuccess(result) {
      if (this.isCreate) {
        this.$emit('uploadStarted', result.video_asset);
        this.upload(result.upload_url, result.video_asset);
      } else {
        this.$emit('success', result.video_asset);
        this.close();
      }
    },
    stepTwoSuccess(videoAsset) {
      this.$emit('success', videoAsset);
      this.close();
    },
  },
};
</script>
