<template>
  <SubOption
    @clicked="toggle"
    :warning="true"
    :icon="icon"
    keepOpen
    :label="isHidden ? __('action.unhide') : __('action.hide')"
  />
</template>

<script>
import axios from 'axios';
import SubOption from '~/Components/Frontend/SubOption.vue';

export default {
  inject: ['subOptions'],
  emits: ['update:isHidden'],
  components: {
    SubOption,
  },
  props: {
    hideableId: {
      required: true,
      type: Number,
    },
    hideableType: {
      required: true,
      type: String,
    },
    isHidden: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    icon() {
      if (this.loading) {
        return 'far fa-spinner fa-spin-pulse';
      } if (this.isHidden) {
        return 'fas fa-eye';
      }
      return 'fas fa-eye-slash';
    },
  },
  methods: {
    toggle() {
      if (this.loading) return;
      this.loading = true;
      axios.post(`/hides/${this.hideableType}/${this.hideableId}/toggle`)
        .then(() => {
          this.$emit('update:isHidden', !this.isHidden);
          this.subOptions.close();
        }).catch(({ response }) => { this.errors = response?.data?.errors; })
        .then(() => { this.loading = false; });
    },
  },
};
</script>
