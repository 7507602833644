<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('crm.communicationPreference.name') }}
    </div>
    <div
      class="profile-row__value tag-list tag-list--vertical"
      v-if="questions.length > 0"
    >
      <div v-for="question in questions" :key="question.id" class="tag tag__part">
        <span v-if="preferenceMap.get(question.id) !== 'optout'" class="text-success">
          <i class="fas fa-check"></i>
        </span>
        <span v-else class="text-warning">
          <i class="fas fa-xmark"></i>
        </span>
      {{ __(`crm.communicationPreference.channels.${question.channel}`) }}
      </div>
    </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal">
        <i class="fa fa-edit"></i>
        {{ __('buttons.edit') }}
      </button>
    </div>
  </div>
  <ModalForm
    :form="{
      action: '/profile/communication-preferences',
      method: 'patch',
      name: 'update-preferences',
    }"
    @success="update"
    :modal="{
      title: __('ui.edit'),
      titleIcon: 'far fa-edit',
    }"
    ref="modal"
  >
    <FormCheckbox
      v-for="question in questions"
      :key="question.id"
      :name="'prefs[' + question.id + ']'"
      :label="__(`profile.communication_preferences.${question.channel}`)"
      :checked="preferenceMap.get(question.id) !== 'optout'"
    />

    <template #footer>
      <div class="button-group">
        <button type="button" class="button button--secondary" @click="closeModal">
          {{ __('buttons.cancel') }}
        </button>
        <button type="submit" class="button">
          {{ __('buttons.save') }}
        </button>
      </div>
    </template>
  </ModalForm>
</template>

<script>

export default {
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
    preferences: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: {
        preferences: this.preferences,
      },
    };
  },
  computed: {
    preferenceMap() {
      return new Map(
        this.data.preferences.map((pref) => [pref.communication_question_id, pref.response]),
      );
    },
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    update(data) {
      this.data.preferences = data.prefs;
      this.closeModal();
    },
  },
};

</script>
