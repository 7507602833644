<template>
  <img
    v-if="!!src"
    :src="src"
    v-bind="$attrs"
    style="max-width: 100%"
  />
  <div v-else v-bind="$attrs">
    <div v-if="placeholderIcon" class="fe-image__placeholder">
      <i class="fa-duotone" :class="placeholderIcon"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      required: false,
    },
    format: {
      type: String,
      required: true,
    },
    placeholderIcon: {
      type: String,
      default: 'fa-image',
    },
    alt: {
      type: String,
      required: true,
    },
  },
  computed: {
    src() {
      return (this.image && this.image[this.format]?.url) ? this.image[this.format].url : null;
    },
  },
};
</script>
