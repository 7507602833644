<template>
  <li @click="click" :class="classes.navigation" v-if="!hidden">
    <span class="navigation-panel__item-icon-container">
      <slot><i :class="icon + ' fa-fw'"></i></slot>
    </span>
    <span class="navigation-panel__item-label">
      {{ label }}
      <FeBadge
        :alert="attentionNeeded"
        :value="count"
        />
    </span>
    <i class="navigation-panel__item-control far fa-chevron-right fa-lg fa-fw">
    </i>
  </li>
</template>

<script>
import { actionRequiredCounts as actionCounts } from '~/Frontend/store';
import FeBadge from '~/Components/Frontend/Core/Misc/FeBadge.vue';

export default {
  name: 'NavigationItem',
  components: {
    FeBadge,
  },
  emits: [
    'tabClick',
  ],
  props: {
    name: {
      type: String,
      required: false,
    },
    code: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    activeTab: {
      type: String,
    },
    attentionNeeded: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    notifyCount: {
      type: Number,
      required: false,
    },
    actionCountsKey: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      default: 'far fa-user-cog',
    },
    // Just doing this to stop vue shouting at me... and for consistency.
    header: {
      type: String,
      required: false,
    },
  },
  computed: {
    classes() {
      return {
        navigation: [
          'navigation-panel__item',
          {
            'navigation-panel__item--active': this.isActive,
          },
        ],
      };
    },
    count() {
      return actionCounts[this.actionCountsKey] ?? this.notifyCount;
    },
    isActive() {
      return this.activeTab === this.name;
    },
  },
  methods: {
    click() {
      this.$emit('tabClick', this.name);
    },
  },
};
</script>
