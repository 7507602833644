<template>
  <div
    class="profile-table__row profile-table__row--with-status-border"
    :class="enabled ? 'profile-table__row-highlight' :''"
    >
    <FeImage
      :image="entry.main_image"
      format="card"
      :alt="`{entry.name} main image`"
      class="profile-table__row-image"
      />
    <div class="profile-table__row-text">
      <div>
        <div>
          <strong>{{ entry.name }}</strong>
        </div>
        <div class="chip-list">
          <span class="chip chip--small">
            {{ entry.views_count }} views
          </span>
        </div>
      </div>
    </div>
    <div>
      <FrontendSwitch :modelValue="enabled" @toggled="toggle" showOnOff />
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import { store } from '~/Frontend/store';

// Use delayed debouncing - milleseconds
const DEBOUNCE_DURATION = 200;

export default {
  emits: ['enabled', 'disabled'],
  props: {
    // The catalogue entry
    // @type Object<CatalogueEntry>
    entry: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      toggleTimeout: null,
      enabled: this.entry.enabled,
    };
  },
  methods: {
    toggle() {
      if (this.toggleTimeout) {
        clearTimeout(this.toggleTimeout);
        // Two toggles should do nothing
        return;
      }
      this.enabled = !this.enabled;

      this.toggleTimeout = setTimeout(() => {
        this.toggleTimeout = null;
        axios.post(`/${store.eventEdition.slug}/catalogue-entries/${this.entry.id}/toggle`)
          .then((response) => {
            this.enabled = response.data.enabled;
            if (this.enabled) {
              this.$emit('enabled');
            } else {
              this.$emit('disabled');
            }
          })
          .catch(() => {
            this.enabled = !this.enabled;
          });
      }, DEBOUNCE_DURATION);
    },
  },
};
</script>
