<template>
  <h2>{{ __('hubs.leads.title') }}</h2>
  <p>{{ __('hubs.leads.description') }}</p>

  <FeLoading v-if="loading" />

  <template v-else>

    <section class="hub-section">
      <div class="fe-statistic-card__container">
        <FeStatisticCard
          colorVar="statistics-palette-2"
          icon="far fa-address-card"
          :header="__('hubs.leads.numberOfBusinessCardDrops')"
          :value="leads.length"
        />
        <a v-if="enableDownloadLeads" :href="downloadUrl" target="_blank">
          <FeStatisticCard
            colorVar="statistics-palette-1"
            icon="far fa-cloud-download"
            :header="__('hubs.leads.download')"
            :value="__('hubs.leads.downloadFormat')"
          />
        </a>
      </div>
    </section>

    <section class="hub-section">
      <h2>{{ __('hubs.leads.businessCardDropTable') }}</h2>
      <p>{{ __('hubs.leads.businessCardDropTableDescription') }}</p>

      <div class="profile-group">
        <div class="profile-table">

          <div class="profile-table__body list-group">

            <div v-if="leads.length === 0" class="hubs-empty-state">
                <div class="hubs-empty-state__icon">
                  <i class="far fa-address-card"></i>
                </div>
                <h2>Business cards</h2>
                <p>{{ __('hubs.leads.noDroppedBusinessCards') }}</p>
            </div>

            <div
              v-for="lead in leads"
              :key="lead.id"
              class="profile-table__row profile-table__row--with-status-border profile-table__row--spaced"
              :class="{
                'profile-table__row--success': lead.meetings_with.length > 0,
                'profile-table__row--warning': lead.sent_invites_to.length > 0
              }"
            >
              <div class="profile-table__row-avatar">
                <ContactAvatar :contact="lead" size="small" />
              </div>
              <div class="profile-table__row-text">
                <div>
                  <div style="margin-bottom: var(--sizing-1);">
                    <a href="#" @click.prevent="viewProfilePopup(lead.id)"><strong>{{ lead.display_name }}</strong></a>,
                      {{ lead.job_title }},
                    <a href="#" @click.prevent="viewCompanyPopup(lead.company.id)" class="text-black">
                      {{ lead.company.display_name }}
                    </a>
                  </div>
                  <div>
                    <span v-if="lead.email">
                      <i class="far fa-envelope"></i> {{ lead.email }}
                      &nbsp;
                    </span>
                    <span v-if="lead.phone">
                      <i class="far fa-phone"></i> {{ lead.phone }}
                    </span>
                  </div>
                  <div class="chip-list" v-if="false">
                    <!-- disabling this until we know if we should display them -->
                    <span
                      v-for="badgeLabel in lead.badge_types"
                      :key="badgeLabel"
                      class="chip chip--small"
                    >
                      {{ badgeLabel }}
                    </span>
                    <Popper
                      :content="__('hubs.leads.meetingsWith', {
                        people: lead.meetings_with.join(', ')
                      })"
                      :disabled="lead.meetings_with.length === 0"
                      hover
                      arrow
                    >
                      <span
                        class="chip chip--small chip--neutral"
                        :class="{ 'chip--disabled': lead.meetings_with.length === 0 }"
                      >
                        <i class="far fa-handshake"></i> {{ lead.meetings_with.length }}
                      </span>
                    </Popper>
                    <Popper
                      :content="__('hubs.leads.sentInvitesTo', {
                        people: lead.sent_invites_to.join(', ')
                      })"
                      :disabled="lead.sent_invites_to.length === 0"
                      hover
                      arrow
                    >
                      <span
                        class="chip chip--small chip--neutral"
                        :class="{ 'chip--disabled': lead.sent_invites_to.length === 0 }"
                      >
                        <i class="far fa-inbox-out"></i> {{ lead.sent_invites_to.length }}
                      </span>
                    </Popper>
                    <Popper
                      :content="__('hubs.leads.receivedInvitesFrom', {
                        people: lead.received_invites_from.join(', ')
                      })"
                      :disabled="lead.received_invites_from.length === 0"
                      hover
                      arrow
                    >
                      <span
                        class="chip chip--small chip--neutral"
                        :class="{ 'chip--disabled': lead.received_invites_from.length === 0 }"
                      >
                        <i class="far fa-inbox-in"></i> {{ lead.received_invites_from.length }}
                      </span>
                    </Popper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>

  </template>

</template>

<script>
import axios from 'axios';
import Popper from 'vue3-popper';
import { store, user } from '~/Frontend/store';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import FeStatisticCard from '~/Components/Frontend/Core/Misc/FeStatisticCard.vue';

export default {
  name: 'HubLeadsPage',
  components: {
    FeLoading,
    FeStatisticCard,
    Popper,
  },
  data() {
    return {
      loading: false,
      leads: [],
      enableDownloadLeads: false,
    };
  },
  computed: {
    downloadUrl() {
      const url = new URL(window.location);
      return `${url.pathname}/download-leads`.replace('//', '/');
    },
    activeContact() {
      return user.activeContact;
    },
    eventEdition() {
      return store.eventEdition;
    },
  },
  methods: {
    loadData() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const url = new URL(window.location);
      const ajaxUrl = `${url.pathname}/leads`.replace('//', '/');

      axios.get(`${ajaxUrl}?ajax=true`).then((response) => {
        this.leads = response.data.leads;
        this.enableDownloadLeads = response.data.hub.enable_download_leads;
      }).then(() => {
        this.loading = false;
      });
    },
    viewProfilePopup(contactId) {
      window.location.href = `#/contacts/${contactId}/profile`;
    },
    viewCompanyPopup(companyId) {
      window.location.href = `#/companies/${companyId}`;
    },
  },
  mounted() {
    this.loadData();
  },
};

</script>
