<template>
  <div>
    <div class="registration-coming-soon">
      <h2 class="registration--title">{{ form.external_title }}</h2>
      <p class="registration--description">
        {{ form.pre_open_message }}
      </p>
      <p class="registration--description">
        {{ __('registrations.form.screens.coming_soon.alert_message') }}
      </p>
    </div>
    <RegistrationFooter></RegistrationFooter>
  </div>
</template>

<script>
import RegistrationFooter from '~/Components/Frontend/Registration/Screens/RegistrationFooter.vue';

export default {
  name: 'RegistrationComingSoon',
  components: [
    RegistrationFooter,
  ],
  props: {
    form: {
      required: true,
      type: Object,
    },
  },
};
</script>
