<template>
  <div class="form-input" :class="{'form-input--invalid': invalid}">
    <FormLabel v-bind="{label, required}" />
    <label
      ref="input"
      @drop="handleDrop"
      class="form-input__input form-input--image"
      :class="{ active: dragActive }"
      @drag.stop.prevent
      @dragend.stop.prevent="dragActive = false"
      @dragenter.stop.prevent="dragActive = true"
      @dragleave.stop.prevent="dragActive = false"
      @dragover.stop.prevent="dragActive = true"
      @dragstart.stop.prevent
      @drop.stop.prevent="handleDrop"
    >

      <video v-if="preview" class="form-input__preview" :src="preview" controls >
      </video>
      <i v-else class="form-input__icon fa-duotone fa-photo-film"></i>

      <input
        type="file"
        style="display: none"
        ref="fileInput"
        @change="handleChange"
        accept="video/*"
      />

      <p @click.prevent="openDialog" style="margin-bottom: 0" v-html="__('form.video.tip')">
      </p>
    </label>

    <div class="form-input__error" v-if="errors.length > 0">
      <i class="far fa-exclamation-circle"></i> {{ errors[0] }}
    </div>
  </div>
</template>

<script>

import { computed } from 'vue';

export default {
  name: 'FormImage',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    modelValue: {
      type: File,
    },
    // @type Object<VideoAsset>
    original: {
      type: Object,
    },
  },
  inject: ['feForm'],
  data() {
    return {
      preview: this.default,
      dragActive: false,
      modelValueProxy: null,
    };
  },
  computed: {
    dragAndDropCapable() {
      const div = document.createElement('div');
      return (('draggable' in div)
              || ('ondragstart' in div && 'ondrop' in div))
          && 'FormData' in window
          && 'FileReader' in window;
    },
    id() {
      return `${this.feForm.name}-${this.name}`;
    },
    errors() {
      const formErrors = this.feForm.error(this.name).value || [];
      return [...this.localErrors, ...formErrors];
    },
    localErrors() {
      const errors = [];

      return errors.filter((error) => !error.test).map((error) => error.message);
    },
    invalid() {
      return this.errors.length > 0;
    },
    fileTypeValid() {
      return true;
    },
  },
  methods: {
    openDialog() {
      this.$refs.input.click();
    },
    handleChange(event) {
      // eslint-disable-next-line prefer-destructuring
      this.modelValueProxy = event.target.files[0];
    },
    handleDrop(event) {
      this.errorText = null;
      this.dragActive = false;
      // eslint-disable-next-line prefer-destructuring
      this.modelValueProxy = event.dataTransfer.files[0];
    },
    previewImage() {
      if (!this.modelValueProxy) {
        return;
      }
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.preview = reader.result;
      }, false);
      reader.readAsDataURL(this.modelValueProxy);
    },
    removeFile() {
      this.modelValueProxy = null;
    },
  },
  mounted() {
    this.feForm.register(this.name, computed(() => (this.modelValueProxy ? true : '')));
  },
  watch: {
    modelValueProxy(newValue) {
      this.previewImage();
      this.$emit('update:modelValue', newValue);
    },
  },
};
</script>
