<template>
    <div class="mux-video-upload">
      <div v-if="video.hls" class="mux-video-upload__video">
        <VideoPlayer :videoAsset="video" />
      </div>
       <div v-if="video.status !== 'ready'" class="mux-video-upload__video-placeholder-container">
         <div class="mux-video-upload__video-placeholder">
           <span>
             {{ __(`mux_video_upload.video_status.${videoStatusKey}`) }}
           </span>
         </div>
      </div>
      <p
        v-if="errored"
        class="mux-video-upload__error-text"
        >
        {{ __(`mux_video_upload.error_text`) }}
      </p>
      <p
        v-if="success"
        class="mux-video-upload__success-text"
        >
        {{ __(`mux_video_upload.success_text`) }}
      </p>
      <div class="mux-video-upload__video-details">
        <span v-if="video.status !== 'new-video'">
          <FrontendTextInput
            v-if="edit"
            :id="video.upload_id"
            :label="__(`mux_video_upload.input.label`)"
            v-model="localVideoData.title"
            />
          <FrontendDetail
            v-else
            :id="video.upload_id"
            :value="video.title"
            :label="__(`mux_video_upload.input.label`)"
            />
        </span>
      </div>

      <button
        v-if="uploadFailed"
        class="button button--tertiary button--warning"
        @click="abortUpload(video.id)"
        >
        {{ __(`mux_video_upload.buttons.delete`) }}
      </button>

      <div v-else-if="video.status === 'new-video'" class="mux-video-upload__upload-actions">
        <input
          class="mux-video-upload__input"
          v-if="!uploading"
          @change="filePicker"
          type="file"
          accept="video/*"
        />
        <button
          :class="['button', { 'button--loading': uploading }]"
          :disabled="!this.videoFile"
          @click="getUploadUrlThenUpload"
        >
          {{ __(`mux_video_upload.buttons.upload`) }}
        </button>
        <span class="mux-video-upload__progress-bar">
          <progress
            v-if="uploading"
            max="100"
            :value="uploadPercentage || 0"
          />
        </span>
      </div>

      <div v-else-if="edit" class="button-group mux-video-upload__buttons">
        <button class="button button--success" @click="saveChanges(video.id)">
          {{ __(`mux_video_upload.buttons.save`) }}
        </button>
        <button class="button button--tertiary button--warning" @click="toggleEdit">
          {{ __(`mux_video_upload.buttons.cancel`) }}
        </button>
      </div>

      <div v-else class="button-group mux-video-upload__buttons">
        <button class="button button--secondary" @click="toggleEdit">
          {{ __(`mux_video_upload.buttons.rename`) }}
        </button>
        <button class="button button--tertiary button--warning" @click="destroyAsset(video.id)">
          {{ __(`mux_video_upload.buttons.delete`) }}
        </button>
      </div>
    </div>
</template>

<script>
import * as UpChunk from '@mux/upchunk';
import axios from 'axios';
import VideoPlayer from './VideoPlayer.vue';
import FrontendTextInput from './Inputs/FrontendTextInput.vue';
import FrontendDetail from './FrontendDetail.vue';

export default {
  name: 'MuxVideoUploadAndEdit',
  components: { FrontendDetail, FrontendTextInput, VideoPlayer },
  emits: ['updateVideos', 'updateRemainingVideoSlots', 'deleted'],
  props: {
    video: Object,
    baseRoute: String,
    limitReached: Boolean,
  },
  data() {
    return {
      uploadUrl: '',
      videoFile: null,
      uploading: false,
      uploadPercentage: null,
      errored: false,
      success: false,
      upChunkInstance: null,
      localVideoData: this.video,
      edit: false,
    };
  },
  methods: {
    filePicker(event) {
      const files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        this.videoFile = null; return;
      }
      [this.videoFile] = files;
    },
    upload(videoAsset) {
      this.uploading = true;
      this.upChunkInstance = UpChunk.createUpload({
        endpoint: this.uploadUrl,
        file: this.videoFile,
        chunkSize: 5120,
      });

      this.upChunkInstance.on('error', () => {
        this.errorText = 'There was an error uploading your video please try again';
        this.recordUploadFailed(videoAsset.id);
      });

      this.upChunkInstance.on('progress', (progress) => {
        this.uploadPercentage = progress.detail;
      });

      this.upChunkInstance.on('success', () => {
        this.recordSuccessfulUpload(videoAsset.id);
        this.clearUpload();
        this.success = true;
      });
    },
    getUploadUrlThenUpload() {
      if (this.videoFile === null) {
        return;
      }
      this.uploading = true;
      this.errored = false;
      this.success = false;
      axios.get(`${this.baseRoute}/upload-url`)
        .then((response) => {
          this.uploadUrl = response.data.upload_url;
          this.upload(response.data.video_asset);
        }).catch(() => { this.uploading = false; this.errored = true; });
    },
    clearUpload() {
      this.uploading = false;
      this.uploadPercentage = null;
      this.videoFile = null;
    },
    emitResponseToParent(data) {
      this.$emit('updateVideos', data.profileVideos);
      this.$emit('updateRemainingVideoSlots', data.remainingVideoSlots);
    },
    abortUpload(videoId) {
      if (this.upChunkInstance) {
        this.upChunkInstance.abort();
      }
      axios.post(`${this.baseRoute}/${videoId}/record-upload-cancelled`)
        .then((response) => {
          this.emitResponseToParent(response.data);
        }).then(() => {
          this.clearUpload();
        });
    },
    destroyAsset(videoId) {
      axios.post(`${this.baseRoute}/${videoId}/destroy`)
        .then(() => {
          this.$emit('deleted');
        });
    },
    toggleEdit() {
      this.edit = !this.edit;
    },
    saveChanges(videoId) {
      axios.post(`${this.baseRoute}/${videoId}/save-changes`, { title: this.localVideoData.title })
        .then((response) => {
          this.emitResponseToParent(response.data);
        }).then(() => {
          this.clearUpload();
          this.edit = false;
        });
    },
    recordSuccessfulUpload(videoId) {
      const filename = this.returnExtensionlessFilename();
      axios.post(`${this.baseRoute}/${videoId}/record-successful-upload`, { title: filename })
        .then((response) => {
          this.emitResponseToParent(response.data);
        }).then(() => {
          this.clearUpload();
          this.localVideoData.title = filename;
        });
    },
    recordUploadFailed(videoId) {
      axios.post(`${this.baseRoute}/${videoId}/record-upload-failed`)
        .then((response) => {
          this.emitResponseToParent(response.data);
        }).then(() => {
          this.clearUpload();
        });
    },
    returnExtensionlessFilename() {
      return this.videoFile.name.replace(/\.[^/\\.]+$/, '');
    },
  },
  computed: {
    uploadFailed() {
      return this.video.status === 'uploading' && !this.uploading;
    },
    videoStatusKey() {
      if (this.uploading) {
        return 'uploading';
      }
      if (this.video.status === 'new-video') {
        return 'new_video';
      }
      if (this.uploadFailed) {
        return 'upload_failed';
      }
      return 'processing';
    },
  },
};
</script>
