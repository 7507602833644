<template>
  <div
    class="profile-select__profile"
    :class="{'profile-select__profile--active': isActive }"
    @click="connect"
    >
    <div class="profile-select__avatar">
      <ContactAvatar :contact="profile" size="small" />
    </div>
    <div class="profile-select__name">
      <b>{{ profile.display_name }}</b>
      <span class="hide-for-mobile">, </span>
      <br class="hide-for-tablet-up" />
      {{ profile.job_title }} <br />
      {{ profile.company.display_name }}
      <span class="hide-for-tablet-up" v-if="!isActive"> <br />
        {{ __('profile_select.profile.last_used') }}
        <strong>{{
          profile.last_logged_in_for_humans ?? __('profile_select.profile.never_logged_in')
          }}</strong>
      </span>
    </div>
    <slot>
      <template v-if="isActive">
        <span class="chip chip--success"> <i class="fa-solid fa-circle-dot"></i> Active </span>
      </template>
      <template v-else>
        <div class="profile-select__last-used hide-for-mobile">
          {{ __('profile_select.profile.last_used') }}
          <strong>{{
            profile.last_logged_in_for_humans ?? __('profile_select.profile.never_logged_in')
            }}</strong>
        </div>
        <SubOptions v-if="!noActions">
          <SubOption @clicked="connect" icon="fas fa-arrow-right-to-bracket" label="Connect" />
          <SubOption @clicked="$emit('merge', profile)" icon="fas fa-merge" label="Merge contact" />
          <SubOption @clicked="$emit('notYou', profile)" icon="far fa-share" label="Not you" />
        </SubOptions>
      </template>
    </slot>
  </div>
</template>

<script>

import { user } from '~/Frontend/store';
import ContactAvatar from './CRM/Components/ContactAvatar.vue';

export default {
  name: 'ProfileSelectRow',
  components: { ContactAvatar },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    // Disable all actions on the row
    noActions: {
      type: Boolean,
    },
    eventEditionRoute: String,
  },
  emits: ['notYou', 'merge'],
  data() {
    return {
      moreOptions: false,
    };
  },
  computed: {
    /**
     * Is the current profile the active contact
     * */
    isActive() {
      return this.profile.id === user.activeContact.id;
    },
    hotlinkToken() {
      return user && user.user ? user.user.hotlink_token : null;
    },
  },
  methods: {
    showMoreOptions() {
      this.moreOptions = !this.moreOptions;
    },
    connect() {
      if (!this.noActions) {
        if (this.eventEditionRoute) {
          window.location.href = `/token/${this.hotlinkToken}?contact_id=${this.profile.id}&passthrough=${this.eventEditionRoute}`;
        } else {
          window.location.href = `/token/${this.hotlinkToken}?contact_id=${this.profile.id}`;
        }
      }
    },
  },
};
</script>
