import { defineStore } from 'pinia';
import axios from 'axios';
import _ from 'lodash';
import StringReplace from '~/Utilities/StringReplace';

const useRegistrationStore = defineStore({
  id: 'registration',
  state: () => ({
    questions: [],
    countries: [],
    currentQuestionId: null,
    loading: null,
    errorMessage: null,
    alert: {},
    errors: {},
    progress: 0,
    placement: {},
    personalization: {},
    timeout: null,
    currentAction: null,
    registration: {},
  }),
  getters: {
    getCurrentQuestion: (state) => state.questions.find((question) => question.id
    === state.currentQuestionId),
    getCurrentQuestionIndex: (state) => state.questions.findIndex((question) => question.id
    === state.currentQuestionId),
    getQuestions: (state) => state.questions,
    getProgress: (state) => state.progress,
    getCountries: (state) => state.countries,
    isLastQuestion: (state) => (state.questions.length
        === (state.questions.findIndex((question) => question.id
      === state.currentQuestionId)) + 1),
    getPlacement: (state) => state.placement,
    getErrors: (state) => state.errors,
    getAlert: (state) => state.alert,
    getCurrentQuestionTitle: (state) => StringReplace(state.questions.find((row) => row.id
    === state.currentQuestionId)?.title, state.personalization),
    getPersonalization: (state) => state.personalization,
  },
  actions: {
    updateQuestions(questions, questionId) {
      this.questions = questions.map((question) => (question?.options?.length > 0
        ? {
          ...this.validateQuestionPayload(question),
          options: this.sort(
            question.options,
            question.sort_by,
          ),
        }
        : this.validateQuestionPayload(question)));
      this.goToQuestion(questionId, 'load');
    },
    updateQuestionField(index, field, value) {
      if (field === 'error') {
        this.questions[index].error = value[this.getCurrentQuestion.component_type];
        this.questions[index].errors = value;
      } else {
        this.questions[index][field] = value;
      }
    },
    clearQuestionExtraProperties(index) {
      this.questions[index].error = [];
      this.questions[index].errors = [];
      this.questions[index].action = '';
    },
    updateProgress() {
      const index = this.getQuestions.findIndex((question) => question.id
                                === this.getCurrentQuestion.id);
      this.progress = Math.floor((index / this.getQuestions.length) * 100);
    },
    updatePersonalization(personalization) {
      this.personalization = personalization;
    },
    goToQuestion(questionId, currentAction) {
      /* Incase if someone comes back to complete their registration from a specific
        question to onward, so we have to validate some points.
        1: First time registration take to the first question.
        2: If he/she try to start from some specific question so validate all previous questions
        has been answered, there is possibilties some new questions has been added
        from back office, if it is, then take him to back to that question that has been not
        answered yet.
        3: Otherwise allow him to start and complete their onward registration. */
      if (Number(questionId)) {
        for (let i = 0; i < this.questions.length; i++) {
          if (((!this.questions[i]?.answer || this.questions[i]?.answer?.length === 0)
              && Number(questionId) !== this.questions[i]?.id
              && !this.questions[i]?.demographic_dependencies)
              || Number(questionId) === this.questions[i]?.id) {
            this.currentQuestionId = this.questions[i]?.id; break;
          }
        }
      }
      if (!this.currentQuestionId) {
        this.currentQuestionId = this.questions[0]?.id;
      }
      this.currentAction = currentAction;
    },
    setLoading(value) {
      this.loading = value;
    },
    setErrorMessage(value) {
      this.errorMessage = value;
    },
    setAlert(value) {
      this.alert = value;
    },
    clear() {
      this.questions = [];
      this.countries = [];
      this.currentQuestionId = null;
      this.loading = null;
      this.errorMessage = null;
      this.errors = {};
      this.alert = {};
      this.progress = 0;
    },
    action(route, resend = false) {
      axios.post(route.replace('**action**', this.getCurrentQuestion?.action), {
        email: this.getCurrentQuestion.answer,
        registrationId: this.registration.id,
        id: this.getCurrentQuestion?.id,
      })
        .then((response) => {
          this.setAlert({
            login_route: response.data.login_route,
            action: response.data.action,
            message: response.data.message,
            resend,
          });
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => {
            this.setAlert({ ...this.getAlert, resend: false });
          }, 5000);
        });
    },
    save(isCompleted = false) {
      axios.post(this.registration.saveRoute, {
        question: this.getCurrentQuestion,
        registrationId: this.registration.id,
        response: this.getQuestions,
        is_completed: isCompleted === true ? isCompleted : this.isLastQuestion,
      })
        .then((response) => {
          if (response.data.is_completed) {
            window.location.hash = '/thank-you';
          } else {
            this.clearQuestionExtraProperties(this.getCurrentQuestionIndex);
            this.goToQuestion(this.getQuestions[this.getCurrentQuestionIndex + 1].id, 'next');
            window.location.hash = `/process/${response.data.registration.uuid}/${this.getCurrentQuestion.id}`;
          }
        }).then(() => {
          this.setLoading('');
        })
        .catch((error) => {
          if (error?.response?.status === 406) {
            window.location.hash = '/welcome';
          } else if (error?.response?.data?.errors?.registration_pending) {
            this.setAlert({
              message: error?.response?.data?.message,
            });
            window.location.hash = '/welcome';
          } else {
            this.updateQuestionField(
              this.getCurrentQuestionIndex,
              'error',
              error?.response?.data?.errors,
            );
          }
        });
    },
    setErrors(errors) {
      this.errors = errors;
    },
    updateCountries(countries) {
      this.countries = countries.map((country) => ({
        value: country.code,
        text: country.name,
      }));
    },
    validateQuestionPayload(row) { // For every component type
      let question = row;
      if (question.component_type === 'name') {
        question = {
          ...question,
          answer: {
            first_name: question?.answer?.first_name ?? '',
            last_name: question?.answer?.last_name ?? '',
          },
        };
      } else if (question.component_type === 'demographic-tree-view') {
        question = {
          ...question,
          answer: question?.answer ?? [],
        };
      }
      return question;
    },
    updatePlacement(placement) {
      this.placement = placement;
    },
    sort(nodes, sort = 'implicit-ordering') {
      if (sort !== 'implicit-ordering') {
        const newNodes = [];
        const items = sort === 'alphabetic' ? nodes.sort((a, b) => ((a.label < b.label || a.name < b.name) ? -1 : 1)) : _.shuffle(nodes);
        // eslint-disable-next-line
        for (const n of items) {
          if (n.options) {
            const nextNodes = this.sort(n.options, sort);
            newNodes.push({ ...n, options: nextNodes.length > 0 ? nextNodes : [] });
          } else {
            newNodes.push(n);
          }
        }
        return newNodes;
      }
      return nodes;
    },
    updateRegistration(registration) {
      this.registration = registration;
    },
    // eslint-disable-next-line consistent-return
    validateQuestion() {
      if (this.getCurrentQuestion?.demographic_dependencies?.length > 0) {
        const findAnswers = this.questions.find((row) => {
          if (_.includes(['demographic-single-select', 'demographic-multi-select', 'demographic-tree-view'], row.component_type)
            && ((_.isArray(row?.answer)
            // eslint-disable-next-line max-len
            && _.intersection(row?.answer, this.getCurrentQuestion?.demographic_dependencies)?.length > 0)
            // eslint-disable-next-line max-len
            || (!_.isArray(row?.answer) && _.includes(this.getCurrentQuestion?.demographic_dependencies, row?.answer)))) {
            return true;
          }
          return false;
        });
        if (findAnswers === undefined) {
          const goToQuestion = _.includes(['load', 'next'], this.currentAction) ? this.questions[this.getCurrentQuestionIndex + 1] : this.questions[this.getCurrentQuestionIndex - 1];
          if (goToQuestion !== undefined) {
            this.currentQuestionId = goToQuestion.id;
            if (this.registration.id) {
              window.location.hash = `/process/${this.registration.id}/${goToQuestion.id}`;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (this.getQuestions[this.getQuestions.length - 1].id === this.getCurrentQuestion.id) {
              // In case last question skipped
              this.currentQuestionId = this.getQuestions[this.getQuestions.length - 2].id;
              this.save(true);
            } else if (this.getCurrentQuestion.id === this.getQuestions[0].id) {
              // In case first question skipped
              this.currentQuestionId = this.getQuestions[1].id;
              window.location.hash = `/process/${this.registration.id}/${this.getQuestions[1].id}`;
            }
          }
        }
      }
    },
  },
});

export default useRegistrationStore;
