<template>
  <li
    class="sub-options__option"
    :class="{
      'sub-options__option--warning': warning,
      'sub-options__option--disabled': disabled,
    }"
    @click.stop="handleClick"
  >
    <i v-if="icon" class="sub-options__option-icon" :class="icon"></i>
    &nbsp;
    <slot> {{ label }} </slot>
  </li>
</template>

<script>
export default {
  inject: ['subOptions'],
  props: {
    icon: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    warning: {
      type: Boolean,
    },
    link: {
      type: String,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    keepOpen: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '_self',
    },
  },
  methods: {
    handleClick() {
      if (this.link) {
        const isHash = this.link.charAt(0) === '#';
        if (isHash) {
          window.location.hash = this.link.substring(1);
        } else {
          window.open(this.link, this.target);
        }
      }
      this.$emit('clicked');
      if (!this.keepOpen) {
        this.subOptions.close();
      }
    },
  },
  emits: ['clicked'],
};
</script>
