<template>
  <section
    class="directory__spot"
    :class="{'directory__spot--leaderboard' : model.enable_iab_format}"
    :style="{ 'background-color': !model.enable_iab_format ? model.bg_colour : 'none' }"
  >
    <a :href="impressionUrl">
      <img class="directory__spot-img" :src="model.spot_image_url">
      <img v-if="model.spot_mobile_image_url" class="directory__spot-mobile-img" :alt="model.spot_id" :src="model.spot_mobile_image_url">
      <img v-else class="directory__spot-mobile-img" :alt="model.spot_id" :src="model.spot_image_url">
    </a>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const data = {
      impressionUrl: '',
    };
    return data;
  },
  mounted() {
    axios.get(`/directory-spots/register/${this.model.spot_id}?url=${window.location}`)
      .then((response) => {
        this.impressionUrl = response.data.impression_url;
      });
  },
};
</script>
