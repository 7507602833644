<template>
  <div class="form-input" :class="{'form-input--invalid': invalid}">
    <FormLabel v-bind="{label, required}" />
    <!-- <Teleport to="body"> -->
      <vue-tel-input
        v-model="number"
        @country-changed="handleCountryChanged"
        :defaultCountry="country"
        oldDropdownContainer="document.body"
        :inputOptions="{
          type: 'tel',
          autocomplete: 'on',
          maxlength: 25,
          name: name,
          placeholder: placeholder || __('form.phone.placeholder'),
          tabindex: 0,
        }"
        old-style="{
          width: width + 'px',
          position: 'absolute',
          left: left + 'px',
          top: top  + 'px',
          'z-index': 1000
        }"
        :class="{'form-input--invalid': invalid}"
        ></vue-tel-input>
    <!-- </Teleport> -->
    <!-- <div ref="anchor" style="height: 54px"></div> -->
    <ul class="frontend-text-input__error" v-if="allErrors && allErrors.length > 0">
      <li v-for="error in allErrors" :key="error">
        <i class="far fa-triangle-exclamation"></i>
        {{ error }}
      </li>
    </ul>
    <p class="frontend-text-input__description" v-if="description" :id="id + '-described-by'">
      {{ description }}
    </p>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import { useElementBounding } from '@vueuse/core';

export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    name: {
      required: true,
      type: String,
    },
    label: {
      required: false,
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    error: String,
    errors: Array,
    description: {
      type: String,
      required: false,
    },
  },
  setup() {
    const anchor = ref(null);
    const {
      top, left, width, update,
    } = useElementBounding(anchor);
    return {
      anchor, top, left, width, update,
    };
  },
  data() {
    return {
      number: this.modelValue?.phone,
      country: this.modelValue?.phone_country,
    };
  },
  methods: {
    handleCountryChanged(country) {
      this.country = country.iso2;
    },
  },
  computed: {
    allErrors() {
      return this.error ? [this.error] : this.errors;
    },
    value() {
      return {
        phone: this.number,
        phone_country: this.country,
      };
    },
    invalid() {
      return this.allErrors?.length > 0;
    },
  },
  watch: {
    errors() {
      // useBoundingBox is not updating here... quick hack to solve by manually updating
      // need nextTick as need to wait for redraw before recomputing
      this.$nextTick(() => this.update());
    },
    value() {
      this.$emit('update:modelValue', computed(() => this.value));
    },
  },
};

</script>
