<template>
  <MeetingChatRow
    type="InviteSent"
    :message="invite.message"
    :when="chat.when"
    :sender="sender"
    :sentByYou="sentByYou"
    :read="chat.read"
    important
  >
    <template #chip>
      <div class="chip chip--small chip--success">
        <i class="far fa-info-circle"></i>
        {{ __('conversations.chips.new_invite') }}
      </div>
    </template>
    <template #actions>
      <div class="button-group" v-if="policy.can_accept">
        <a
          class="button button--link"
          :href="`#/invites/${invite.id}/accept`"
        >
          {{ __('expanded_contact.buttons.accept_invite') }}
          <i class="far fa-arrow-right-long"></i>
        </a>
      </div>
      <div class="button-group" v-if="policy.can_withdraw">
        <a
          class="button button--secondary"
          :href="`#/invites/${invite.id}/withdraw`"
        >
          {{ __('expanded_contact.buttons.withdraw_invite') }}
        </a>
      </div>
    </template>
  </MeetingChatRow>
</template>

<script>

export default {
  props: {
    chat: {
      type: Object,
      required: false,
    },
    invite: {
      type: Object,
      required: false,
    },
    sender: {
      type: Object,
      required: false,
    },
    recipient: {
      type: Object,
      required: false,
    },
    policy: {
      type: Object,
      required: false,
    },
    sentByYou: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    message() {
      // eslint-disable-next-line no-underscore-dangle
      return this.__('conversations.invitation_from', { sender: this.sender.display_name });
    },
  },
};

</script>
