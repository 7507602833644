<template>
  <div class="directory__filter">
    <div class="filter__label" v-if="!hideLabel">
        {{ filter.label }}
    </div>
    <MultilevelDropdown
      v-model="selected"
      :options="filter.options"
      :isMulti="!!filter.enable_multi_select"
    />
  </div>
</template>

<script>

export default {
  name: 'MultilevelDropdownFilter',
  props: {
    filter: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    hideLabel: Boolean,
  },
  emits: ['update:modelValue'],
  computed: {
    selected: {
      get() { return this.modelValue; },
      set(value) { this.$emit('update:modelValue', value); },
    },
  },
};

</script>
