<template>
  <div class="fe-carousel"
:style="{
    '--current-slide': currentSlide,
    '--slide-count': slideCount,
    }">
    <div class="fe-carousel__arrow" @click="prev">
      <i class="fal fa-chevron-left"></i>
    </div>
    <div class="fe-carousel__viewport">
      <div class="fe-carousel__container">
        <component
          v-for="(slide, n) in slides"
          :is="slide"
          :key="n"
          >
        </component>
      </div>
    </div>
    <div class="fe-carousel__arrow" @click="next">
      <i class="fal fa-chevron-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    autoplayDuration: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      currentSlide: 0,
      autoplayInterval: -1,
    };
  },
  computed: {
    slides() {
      return this.$slots.default();
    },
    slideCount() {
      return this.slides.length;
    },
  },
  methods: {
    prev() {
      this.clearPageInterval();
      this.currentSlide = (this.currentSlide - 1 + this.slideCount) % this.slideCount;
    },
    next() {
      this.clearPageInterval();
      this.currentSlide = (this.currentSlide + 1) % this.slideCount;
    },
    clearPageInterval() {
      clearTimeout(this.autoplayInterval);
    },
    setPageInterval() {
      if (this.autoplayInterval !== -1) {
        this.currentSlide = (this.currentSlide + 1) % this.slideCount;
      }
      this.autoplayInterval = setTimeout(this.setPageInterval, this.autoplayDuration * 1000);
    },
  },
  mounted() {
    if (this.autoplayDuration) {
      this.setPageInterval();
    }
  },
  unmounted() {
    this.clearPageInterval();
  },
};
</script>
