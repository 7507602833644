<template>
  <div class="frontend-detail">
    <div class="frontend-text-input__label-container">
      <label :for="id" class="frontend-text-input__label">{{ label }}</label>
    </div>
    <slot>
      <span v-if="type !=='textarea'"
            :id="id"
            class="frontend-detail__display">
        <!-- Nbsp is needed to set height when value is empty -->
        {{ value || '&nbsp;' }}
      </span>
      <textarea v-if="type ==='textarea'"
        :value="value"
        :id="id"
        :rows="rows"
        class="frontend-detail__textarea frontend-detail__display"
        disabled
        :placeholder="placeholder"
      />
    </slot>
  </div>
</template>

<script>
export default {
  name: 'FrontendDetail',
  props: {
    id: {
      type: String,
      required: true,
    },
    rows: Number,
    label: String,
    value: String,
    placeholder: String,
    type: {
      type: String,
      validator: (value) => ['text', 'textarea'].includes(value),
      default: 'text',
    },
  },
};
</script>
