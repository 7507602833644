<template>
  <label class="switch-button__control">
    <div class="switch-button" :class="{ enabled: isEnabled }" >
      <div class="switch-button__inner">
        <i v-if="isEnabled" class="fas fa-check"></i>
      </div>
    </div>
    <span class="switch-button__label">
      <slot>
        <template v-if="showOnOff">
          <span v-if="isEnabled" style="display: inline-block; width: 2rem">
            {{ __('forms.toggle.on') }}
          </span>
          <span v-else style="display: inline-block; width: 2rem">
            {{ __('forms.toggle.off') }}
          </span>
        </template>
      </slot>
    </span>
      <input
        type="checkbox"
        style="visibility: hidden"
        :value="value"
        :checked="isEnabled"
        @change="toggle"
      />
  </label>

</template>

<script>
export default {
  models: {
    prop: 'modelValue',
    event: 'change',
  },
  name: 'FrontendSwitch',
  emits: ['update:modelValue', 'toggled'],
  props: {
    value: {
      type: [String, Number],
      required: false,
    },
    modelValue: {
      type: [Array, Boolean],
      default: false,
    },
    trueValue: {
      default: true,
    },
    falseValue: {
      default: false,
    },
    showOnOff: Boolean,
  },
  computed: {
    isEnabled() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      return this.modelValue;
    },
  },
  methods: {
    toggle(event) {
      this.$emit('toggled');
      const isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit('update:modelValue', newValue);
      } else {
        this.$emit('update:modelValue', isChecked);
      }
    },
  },
};
</script>
