<template>
  <div class="profile-table">
    <div class="profile-table__header">
      <div class="profile-table__header-label">
        <strong>{{ header }}</strong>
        &nbsp;
        <span>{{ activeEntries }} / {{ entries.length }}</span>
      </div>
    </div>
    <div class="profile-table__body list-group">
      <div v-if="!entries || entries.length == 0" class="hubs-empty-state">
        <div class="hubs-empty-state__icon">
          <i class="far fa-gears"></i>
        </div>
        <h2>{{ type.plural }}</h2>
        <p v-html="__('hubs.eventProfile.emptyList')"></p>
      </div>

      <HubsCatalogueTableRow
        v-else
        v-for="entry in entries"
        :key="entry.id"
        :entry="entry"
        @disabled="$emit('disabled', entry.id)"
        @enabled="$emit('enabled', entry.id)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventProfilePageCatalogueTable',
  emits: ['enabled', 'disabled'],
  props: {
    header: String,
    entries: Array,
    type: Object,
  },
  computed: {
    activeEntries() {
      return this.entries.filter((item) => item.enabled).length;
    },
  },
};

</script>
