<template>

  <FeLoading v-if="loading"></FeLoading>

    <ProfileGroup>
      <div class="profile-table">
        <div
          v-for="payment in successfulPayments"
          :key="payment.id"
          class="profile-table-row"
        >
          <div>
            <strong>
              {{ payment.purchase_item.name }}
            </strong>
            <br>
            {{ payment.created_at }}
          </div>

          <div style="margin-left: auto">
            {{ (payment.total_in_cents/100).toFixed(2) }}
            {{ payment.currency }}
            <br>
            <a
              :href="`/profile/payments/${payment.id}`"
              target="_blank"
            >
              <i class="far fa-print"></i>
              {{ __('profile.pages.purchases.receipt') }}
            </a>
          </div>
        </div>
      </div>
    </ProfileGroup>

</template>

<script>

import axios from 'axios';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import ProfileGroup from '~/Components/Frontend/Profile/ProfileGroup.vue';

export default {
  name: 'ProfilePurchasesPage',
  components: {
    FeLoading,
    ProfileGroup,
  },
  data() {
    return {
      loading: true,
      payments: [],
    };
  },
  computed: {
    successfulPayments() {
      return this.payments.filter((payment) => payment.success);
    },
  },
  mounted() {
    axios.get('/profile/payments')
      .then(({ data: { payments } }) => {
        this.payments = payments;
      }).then(() => {
        this.loading = false;
      });
  },
};
</script>
