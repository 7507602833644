<template>
    <section class="hub-section">
      <h1>{{ hub.custom_page_title }}</h1>
    </section>

    <div v-html="hub.custom_page_content"></div>

</template>

<script>

export default {
  name: 'HubCustomPage',
  props: {
    hub: {
      type: Object,
      required: true,
    },
  },
};

</script>
