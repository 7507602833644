<template>
  <h2>{{ __('hubs.tasks.title') }}</h2>

  <FeLoading v-if="loading" />

  <template v-else>
    <section class="hub-section">
      <div class="fe-statistic-card__container">
        <FeStatisticCard
          colorVar="statistics-palette-2"
          icon="far fa-check-circle"
          :header="__('hubs.tasks.taskProgress')"
          :value="completedTasks.length"
          :valueOfTotal="pendingTasks.length + completedTasks.length"
        />
      </div>
    </section>

    <section class="hub-section">

      <div class="profile-group">

        <div class="profile-table">
          <div class="profile-table__header">
            <h2>{{ __('hubs.tasks.pendingTasksTitle') }}</h2>
          </div>

          <!-- shared empty state -->
          <div v-if="pendingTasks.length === 0" class="hubs-empty-state">
            <div class="hubs-empty-state__icon">
              <i class="far fa-list-check"></i>
            </div>
            <h2>{{ __('hubs.tasks.noPendingTasks') }}</h2>
          </div>

          <template v-else>
            <template  v-if="companyPendingTasks.length > 0">
              <div class="profile-table__header">
                <p>
                  {{ __('hubs.tasks.pendingCompanyTasksDescription') }}
                </p>
              </div>
              <div class="profile-table__body list-group">
                <HubsTasksTableRow
                  v-for="task in companyPendingTasks"
                  :key="task.id"
                  :task="task"
                  @responded="updateTasks"
                />
              </div>
            </template>

            <template  v-if="yourPendingTasks.length > 0">
              <div class="profile-table__header">
                <p :style="{
                    'margin-top': companyPendingTasks.length > 0 ? 'var(--sizing-4)' : ''
                  }"
                >
                  {{ __('hubs.tasks.pendingContactTasksDescription') }}
                </p>
              </div>
              <div class="profile-table__body list-group">
                <HubsTasksTableRow
                  v-for="task in yourPendingTasks"
                  :key="task.id"
                  :task="task"
                  @responded="updateTasks"
                />
              </div>
            </template>

          </template>
        </div>

      </div>
    </section>

    <section class="hub-section">
      <div class="profile-group">
        <div class="profile-table">
          <div class="profile-table__header">
            <h2>{{ __('hubs.tasks.completedTasksTitle') }}</h2>
          </div>
          <div class="profile-table__header">
            <p>{{ __('hubs.tasks.completedTasksDescription') }}</p>
          </div>
          <div class="profile-table__body list-group">
            <div v-if="completedTasks.length === 0" class="hubs-empty-state">
                <div class="hubs-empty-state__icon">
                  <i class="far fa-list-check"></i>
                </div>
                <h2>{{ __('hubs.tasks.nocompletedTasks') }}</h2>
            </div>
            <HubsTasksTableRow
              v-for="task in completedTasks"
              :key="task.id"
              :task="task"
            />
          </div>
        </div>
      </div>
    </section>

  </template>

</template>

<script>
import axios from 'axios';
import { store, user } from '~/Frontend/store';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import FeStatisticCard from '~/Components/Frontend/Core/Misc/FeStatisticCard.vue';
import HubsTasksTableRow from '~/Components/Frontend/Hubs/Tables/HubsTasksTableRow.vue';

export default {
  name: 'HubTasksPage',
  components: {
    FeLoading,
    FeStatisticCard,
    HubsTasksTableRow,
  },
  data() {
    return {
      loading: false,
      tasks: [],
    };
  },
  computed: {
    pendingTasks() {
      return this.tasks.filter((task) => task.status === 'pending');
    },
    yourPendingTasks() {
      return this.pendingTasks.filter((task) => task.attaches_to.endsWith('Contact'));
    },
    companyPendingTasks() {
      return this.pendingTasks.filter((task) => task.attaches_to.endsWith('Company'));
    },
    completedTasks() {
      return this.tasks.filter((task) => task.status === 'complete');
    },
    activeContact() {
      return user.activeContact;
    },
    eventEdition() {
      return store.eventEdition;
    },
  },
  methods: {
    loadData() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const url = new URL(window.location);
      const ajaxUrl = `${url.pathname}/tasks`.replace('//', '/');

      axios.get(`${ajaxUrl}?ajax=true`).then((response) => {
        this.tasks = response.data.tasks;
      }).then(() => {
        this.loading = false;
      });
    },
    updateTasks(tasks) {
      this.tasks = tasks;
    },
  },
  mounted() {
    this.loadData();
  },
};

</script>
