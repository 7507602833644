<template>
  <div class="accordion__item" @click="expand" role="button" tabindex="0">
    <div
      class="accordion__item-label"
      :class="{'accordion__item-label--expanded': expanded}"
      >
      <span>
        <slot name="label"> {{ label }} </slot>
      </span>
      <i
        class="accordion__item-control fas fa-fw fa-lg"
        :class="[ expanded ? 'fa-caret-up' : 'fa-caret-down' ]"
        ></i>
    </div>
    <Transition name="t-grow">
      <div class="accordion__item-content" v-if="expanded">
        <slot></slot>
      </div>
    </Transition>
  </div>
</template>

<script>

import { ref, watch } from 'vue';

const expandedTab = ref(null);

export default {
  props: {
    label: {
      type: String,
      required: false,
    },
  },
  setup() {
    const expanded = ref(false);
    const key = Symbol('key');

    watch(expandedTab, (newVal) => {
      expanded.value = newVal === key;
    });

    const expand = () => {
      expandedTab.value = (expanded.value) ? null : key;
    };

    return { expanded, expand };
  },
};
</script>
