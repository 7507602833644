<template>
  <FeLoading v-if="loading" />

  <template v-else>
    <section class="hub-section">

      <h1> {{ __('hubs.eventProfile.title') }} </h1>
      <p> {{ __('hubs.eventProfile.description') }} </p>

      <div class="fe-statistic-card__container">
        <FeStatisticCard
          colorVar="statistics-palette-2"
          icon="far fa-chart-line"
          :header="__('hubs.eventProfile.activity.performance')"
          :value="profilePerformanceLabel"
        />
        <FeStatisticCard
          colorVar="statistics-palette-1"
          icon="far fa-window"
          :header="__('hubs.eventProfile.activity.profileViews')"
          :value="profileViews"
        />
        <FeStatisticCard
          colorVar="alert-color"
          icon="far fa-star"
          :header="__('hubs.eventProfile.activity.favourited')"
          :value="popularityStatistics.favouritedBy"
        />
      </div>

      <div class="chart-card">
        <div class="chart-card__statistic">
          <strong>{{ __('hubs.eventProfile.viewsPerDay') }}</strong>
        </div>
        <ProfileViewsPerDayChart
          v-if="popularityStatistics.profileViewsPerDay"
          :profileViewsPerDay="popularityStatistics.profileViewsPerDay"
        />
        <p v-else>{{ __('hubs.eventProfile.activity.noData') }}</p>
      </div>

    </section>

      <section class="hub-section">
      <h2>{{ __('hubs.eventProfile.marketplaceTitle') }}</h2>
      <p>{{ __('hubs.eventProfile.marketplaceDescription') }}</p>

      <div class="fe-statistic-card__container">
        <FeStatisticCard
          colorVar="statistics-palette-2"
          icon="far fa-chart-line"
          :header="__('hubs.eventProfile.activity.marketplacePerformance')"
          :value="marketplacePerformanceLabel"
        />
        <FeStatisticCard
          colorVar="statistics-palette-1"
          icon="far fa-window"
          :header="__('hubs.eventProfile.activity.marketplaceViews')"
          :value="marketplaceViews"
        />
        <FeStatisticCard
          colorVar="alert-color"
          icon="far fa-box-open"
          :header="__('hubs.eventProfile.marketplaceActive')"
          :value="enabledCatalogueEntryIds.size"
          :valueOfTotal="catalogueEntryLimit"
        />
      </div>
    </section>
    <section class="hub-section">
      <div
        class="profile-group"
        v-for="type in catalogueEntryTypes"
        :key="type.id"
      >
        <EventProfilePageCatalogueTable
          :header="type.plural"
          :type="type"
          :entries="catalogueEntriesByTypeId[type.id] ? catalogueEntriesByTypeId[type.id] : []"
          @enabled="(entryId) => enable(type.id, entryId)"
          @disabled="(entryId) => disable(type.id, entryId)"
          />
      </div>
    </section>

    <section class="hub-section">
      <h2>{{ __('hubs.eventProfile.videosTitle') }}</h2>
      <p>{{ __('hubs.eventProfile.videosDescription') }}</p>

      <div class="profile-group">
        <EventProfilePageVideoTable :videos="videos" />
      </div>
    </section>

  </template>

</template>

<script>
import axios from 'axios';
import { store, user } from '~/Frontend/store';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import FeStatisticCard from '~/Components/Frontend/Core/Misc/FeStatisticCard.vue';
import EventProfilePageCatalogueTable from './EventProfilePageCatalogueTable.vue';
import EventProfilePageVideoTable from './EventProfilePageVideoTable.vue';

export default {
  name: 'EventProfilePage',
  components: {
    EventProfilePageCatalogueTable,
    EventProfilePageVideoTable,
    FeLoading,
    FeStatisticCard,
  },
  data() {
    return {
      loading: false,
      videos: [],
      videoLimit: 0,
      catalogueEntriesByTypeId: {},
      catalogueEntryTypes: [],
      catalogueEntryLimit: 0,
      enabledCatalogueEntryIds: new Set(),
      popularityStatistics: [],
      performance: 0,
      marketplacePerformance: 0,
      marketplaceViews: 0,
    };
  },
  computed: {
    activeContact() {
      return user.activeContact;
    },
    eventEdition() {
      return store.eventEdition;
    },
    catalogueEntryCount() {
      return Object.values(this.catalogueEntriesByTypeId)
        .map((group) => group.length)
        .reduce((carry, val) => carry + val, 0);
    },
    profileViews() {
      if (!(this.popularityStatistics && 'profileViewsPerDay' in this.popularityStatistics)) {
        return null;
      }
      return this.popularityStatistics.profileViewsPerDay.reduce(
        (total, perDay) => total + perDay.value,
        0,
      );
    },
    profilePerformanceLabel() {
      if (this.performance.profile_performance < 35) {
        return this.__('hubs.stats.belowAverage');
      }

      if (this.performance.profile_performance < 50) {
        return this.__('hubs.stats.average');
      }

      return this.__('hubs.eventProfile.performanceScore', { score: 100 - this.performance.profile_performance });
    },

    marketplacePerformanceLabel() {
      if (this.marketplacePerformance.marketplace_performance < 35) {
        return this.__('hubs.stats.belowAverage');
      }

      if (this.marketplacePerformance.marketplace_performance < 50) {
        return this.__('hubs.stats.average');
      }

      return this.__('hubs.eventProfile.activity.marketplacePerformanceScore', { score: 100 - this.marketplacePerformance.marketplace_performance });
    },
  },
  methods: {
    loadData() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const url = new URL(window.location);
      const ajaxUrl = `${url.pathname}/event_profile`.replace('//', '/');

      axios.get(`${ajaxUrl}?ajax=true`).then((response) => {
        this.videos = response.data.videos;
        this.videoLimit = response.data.video_limit;
        this.catalogueEntriesByTypeId = response.data.catalogue_entries;
        this.catalogueEntryTypes = response.data.catalogue_entry_types;
        this.catalogueEntryLimit = response.data.catalogue_entry_limit;
        this.enabledCatalogueEntryIds = new Set(response.data.enabled_catalogue_entry_ids);
        this.popularityStatistics = response.data.popularity_statistics;
        this.performance = response.data.performance;
        this.marketplacePerformance = response.data.marketplace_performance;
        this.marketplaceViews = response.data.marketplace_views;
      }).then(() => {
        this.loading = false;
      });
    },
    enable(typeId, entryId) {
      const entry = this.catalogueEntriesByTypeId[typeId].find((item) => item.id === entryId);
      entry.enabled = true;
      this.enabledCatalogueEntryIds.add(entry.id);
    },
    disable(typeId, entryId) {
      const entry = this.catalogueEntriesByTypeId[typeId].find((item) => item.id === entryId);
      entry.enabled = false;
      this.enabledCatalogueEntryIds.delete(entry.id);
    },
  },
  mounted() {
    this.loadData();
  },
};

</script>
