const events = ['open', 'push', 'close'];

/**
 * Make an object with event as the key and empty array as the value
 *
 * {
 *   open: [],
 *   push: [],
 *   ...
 *  }
 */
const subscribers = Object.fromEntries(events.map((name) => [name, []]));

function publish(event, args) {
  subscribers[event].forEach((subscriber) => subscriber(args));
}

export function modalSubscribe(event, subscriber) {
  subscribers[event].push(subscriber);
}

export function modalOpen() {
  document.getElementById('blur-layer').classList.add('blur');
  document.body.classList.add('modal-open');
  publish('open');
}

export function modalPush(component, props, handlers) {
  publish('push', { component, props, handlers });
}

export function modalClose() {
  document.getElementById('blur-layer').classList.remove('blur');
  document.body.classList.remove('modal-open');
  publish('close');
}
