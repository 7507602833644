<template>
  <FeForm :key="key" v-bind="form" @success="$emit('success', $event)">
    <BaseModal
      ref="baseModal"
      @opened="modalOpen = true; $emit('opened')"
      @closed="key++; $emit('closed')"
      v-bind="modal"
      :minHeight="minHeight"
    >
      <slot></slot>
      <template #footer>
        <slot name="footer"></slot>
      </template>
    </BaseModal>
  </FeForm>
</template>

<script>
export default {
  expose: ['open', 'close'],
  emits: ['opened', 'closed', 'success'],
  props: {
    modal: {
      type: Object,
    },
    form: {
      type: Object,
    },
    minHeight: {
      type: String,
    },
  },
  data() {
    return {
      // v-if does not work here, because we need modal to be alive at all times
      // otherwise the ref does not work - think we should avoid exposing methods
      // in future vue components as it create lots of pain points
      key: 0,
    };
  },
  methods: {
    open() {
      this.$refs.baseModal.open();
    },
    close() {
      this.$refs.baseModal.close();
    },
  },
};
</script>
