<template>
  <PageHeader
    :header="__('inbox.header')"
    :subheader="activeTab && !navRevealed ?  __(`inbox.subtitle.${subtitleLangKey}`) : null"
    :showBack="(!navRevealed) && tabletOrSmaller"
    :showHelp="smallLaptopOrSmaller && (!navRevealed)"
    @back="navRevealed = true"
    @help="$refs.helpModal.open()"
  />
  <Layout3Column :activeColumn="activeColumn">
    <template #left>
      <ul class="navigation-panel">
        <NavigationItem
          :label="__('inbox.subtitle.receivedInvites')"
          icon="far fa-inbox-in"
          :activeTab="activeTab"
          code="received_invites"
          name="received_invites"
          @tabClick="setPage('received_invites')"
          actionCountsKey="invites_received_pending"
          />
        <NavigationItem
          :label="__('inbox.subtitle.sentInvites')"
          icon="far fa-inbox-out"
          :activeTab="activeTab"
          code="sent_invites"
          name="sent_invites"
          @tabClick="setPage('sent_invites')"
          />
        <NavigationItem
          :label="__('inbox.subtitle.meetings')"
          icon="far fa-people-arrows-left-right"
          :activeTab="activeTab"
          code="meetings"
          name="meetings"
          @tabClick="setPage('meetings')"
          />
        <NavigationItem
          :label="__('inbox.subtitle.messages')"
          icon="far fa-messages"
          :activeTab="activeTab"
          code="messages"
          name="messages"
          @tabClick="setPage('messages')"
          actionCountsKey="unread_messages"
          />
      </ul>
    </template>
    <component :is="page" :defaultSearchPage="defaultSearchPage"></component>
    <template #right>
      <HelpPanel
        v-if="activeTab"
        :helpVideo="helpPanes[activeTab].helpVideo"
        faqHeader="FAQs"
        :faqs="helpPanes[activeTab].faqs"
      />
    </template>
    <teleport to="body">
      <BaseModal ref="helpModal" height="tall">
        <HelpPanel
          v-if="activeTab"
          :helpVideo="helpPanes[activeTab].helpVideo"
          faqHeader="FAQs"
          :faqs="helpPanes[activeTab].faqs"
          borderless
        />
      </BaseModal>
    </teleport>
  </Layout3Column>
</template>

<script>

import _ from 'lodash';
import { useBreakpoints } from '~/Frontend/useBreakpoints';
import HelpPanel from '~/Components/Frontend/Core/Panels/HelpPanel.vue';
import MeetingsPage from './MeetingsPage.vue';
import MessagesPage from './MessagesPage.vue';
import ReceivedInvitesPage from './ReceivedInvitesPage.vue';
import SentInvitesPage from './SentInvitesPage.vue';

export default {
  name: 'InboxMain',
  components: {
    HelpPanel,
    MeetingsPage,
    MessagesPage,
    ReceivedInvitesPage,
    SentInvitesPage,
  },
  props: {
    defaultSearchPage: {
      type: String,
      required: true,
    },
    helpPanes: {
      type: Object,
      required: true,
    },
    initialTab: String,
  },
  data() {
    return {
      activeTab: this.initialTab ?? 'received_invites',
      navRevealed: this.tabletOrSmaller,
    };
  },
  computed: {
    activeColumn() {
      return this.navRevealed ? 1 : 2;
    },
    page() {
      return (this.activeTab)
        ? {
          received_invites: ReceivedInvitesPage,
          sent_invites: SentInvitesPage,
          meetings: MeetingsPage,
          messages: MessagesPage,
        }[this.activeTab]
        : null;
    },
    subtitleLangKey() {
      return this.activeTab ? _.camelCase(this.activeTab) : null;
    },
  },
  setup() {
    const { smallLaptopOrSmaller, mobile, tabletOrSmaller } = useBreakpoints();
    return { smallLaptopOrSmaller, mobile, tabletOrSmaller };
  },
  methods: {
    setPage(name) {
      this.navRevealed = false;
      this.activeTab = name;
    },
  },
};
</script>
