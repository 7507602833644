<template>
    <div class="fe-statistic-card__container hub-section">
        <FeStatisticCard
          colorVar="statistics-palette-2"
          icon="far fa-basket-shopping"
          :header="__('hubs.purchase_items.purchases')"
          :value="purchases.length"
        />
    </div>

    <FeLoading v-if="loading" />
 <!-- Purchase items Table -->
<div class="profile-table hub-section">
    <div class="profile-table__header">
      <div class="profile-table__header-label">
        <strong>{{ __('hubs.purchase_items.offers') }}</strong>
      </div>
    </div>
    <div class="profile-table__body list-group">
      <div v-if="!purchaseItems || purchaseItems.length == 0" class="hubs-empty-state">
        <div class="hubs-empty-state__icon">
          <i class="far fa-gears"></i>
        </div>
        <p v-html="__('hubs.purchase_items.emptyList')"></p>
      </div>
      <HubsPurchaseItemTableRow
        v-else
        v-for="purchaseItem in purchaseItems"
        :key="purchaseItem.id"
        :purchaseItem="purchaseItem"
      />
    </div>
</div>

<!-- Purchases Table -->
<div v-if="purchases.length > 0" class="profile-table hub-section">
    <div class="profile-table__header">
      <div class="profile-table__header-label">
        <strong>{{ __('hubs.purchase_items.purchases') }}</strong>
      </div>
    </div>
    <div class="profile-table__body list-group hub-section">
      <HubsPurchasesTableRow
        v-for="purchase in purchases"
        :key="purchase.payment.id"
        :purchase="purchase"
      />
    </div>
</div>
</template>

<script setup>

import FeStatisticCard from '~/Components/Frontend/Core/Misc/FeStatisticCard.vue';
import { onMounted, ref } from 'vue';
import axios from 'axios';
import HubsPurchaseItemTableRow from '../Tables/HubsPurchaseItemTableRow.vue';

const url = new URL(window.location);
const ajaxUrl = `${url.pathname}/purchase_items`.replace('//', '/');
const purchaseItems = ref([]);
const purchases = ref([]);
const loading = ref(false);

async function loadData() {
  try {
    loading.value = true;
    const res = await axios.get(`${ajaxUrl}?ajax=true`);
    purchaseItems.value = res.data.purchase_items;
    purchases.value = res.data.purchases;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('failed to fetch data');
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  loadData();
});
</script>
