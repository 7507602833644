<template>
  <nav id="event-edition-nav-bar" class="event-edition-nav" >
    <button
      class="event-edition-nav__arrow"
      :class="{'event-edition-nav__arrow-disabled': left}"
      @click="scrollToLeft"
      >
      <i v-if="!left || !right"
         class="fa-solid fa-circle-arrow-left"
      >
      </i>
    </button>
    <ul class="event-edition-nav__items" ref="nav">
      <li v-for="navItem in navItems" :key="navItem.name" class="event-edition-nav__item">
        <a
          :class="['event-edition-nav__link', { 'event-edition-nav__item--active': navItem.is_active }]"
          :href="navItem.url"
          :target="navItem.url.startsWith(domain) || !navItem.url.startsWith('http') ? '_self' : '_blank'"
        >
          <i
            class="event-edition-nav__icon"
            :class="navItem.icon"
          >
          </i>
          {{ navItem.label }}
        </a>
      </li>
    </ul>
    <button
      class="event-edition-nav__arrow"
      :class="{'event-edition-nav__arrow-disabled':right}"
      @click="scrollToRight"
      >
      <i v-if="!left || !right"
         class="fa-solid fa-circle-arrow-right"
      >
      </i>
    </button>
  </nav>
  <div id="scroll-sentinel" ref="scroll-sentinel" style="height: 0; width: 100%;"></div>
</template>

<script setup>
import {
  ref, toRefs, watch, computed,
} from 'vue';

import { useScroll, useWindowSize } from '@vueuse/core';

defineProps(
  {
    navItems: {
      type: Array,
      required: true,
    },
  },
);

const scrollDistance = 150;

const { width } = useWindowSize();

const nav = ref();

const { arrivedState } = useScroll(nav, { offset: { right: 30, left: 30 } });

const { left, right } = toRefs(arrivedState);

const domain = computed(() => `${window.location.protocol}//${window.location.hostname}`);

watch(
  [nav, width],
  () => { right.value = nav.value.scrollLeft + nav.value.clientWidth >= nav.value.scrollWidth; },
);

function scrollToLeft() {
  nav.value.scrollLeft -= scrollDistance;
}

function scrollToRight() {
  nav.value.scrollLeft += scrollDistance;
}

</script>
