<template>
  <input type="hidden" name="_token" :value="csrf">
</template>

<script>
export default {
  name: 'CSRF',
  inject: ['csrf'],
};
</script>
