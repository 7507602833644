<template>
  <div class="fe-panel">
    <template v-if="loading">
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>
    <template v-else-if="errored">
      <div style="text-align: center">
        <i class="fa-regular fa-triangle-exclamation info-icon info-icon--alert"></i>
        <h2> {{ errorMessage }} </h2>
      </div>
    </template>
    <template v-else>
      <slot> </slot>
    </template>
  </div>
</template>
<script>

export default {
  name: 'FePanel',
  props: {
    errorMessage: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    alignment: {
      type: String,
      validator: (v) => ['left', 'center'].includes(v),
      default: 'center',
    },
    backLabel: {
      type: String,
      required: false,
    },
    backCallback: {
      type: String,
      required: false,
    },
  },
  computed: {
    errored() {
      return !!this.errorMessage;
    },
  },
};

</script>
