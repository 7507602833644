<template>
  <Popper
    :content="__('buttons.edit')"
    hover
    arrow
  >
    <button class="button button--link" @click="$emit('click')">
      <i  class="far fa-edit"></i>
    </button>
  </Popper>
</template>

<script>

import Popper from 'vue3-popper';

export default {
  name: 'EditIconButton',
  components: { Popper },
  emits: ['click'],
};
</script>
