<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ __('profile.rows.lookingFor.label') }}
      <FeBadge v-if="!lookingFor" alert small />
    </div>
    <div class="profile-row__value" v-if="lookingFor"> {{ lookingFor }} </div>
    <div class="profile-row__value profile-row__value--invalid" v-else>
      {{ __('ui.notAdded') }}
    </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal">
        <i class="fa fa-edit"></i>
        {{ __('buttons.edit') }}
      </button>
    </div>
  </div>
  <ModalForm
    :form="{
      action: '/profile/looking-for',
      method: 'patch',
      name: 'looking_for',
    }"
    :modal="{
      title: __('profile.rows.lookingFor.modalTitle'),
      titleIcon: 'far fa-edit',
    }"
    ref="modal"
    @success="update"
  >
    <FormTextarea
      :label="__('profile.rows.lookingFor.inputLabel')"
      name="looking_for"
      :modelValue="lookingFor"
      :rows="10"
      :max="1000"
      :placeholder="__('profile.rows.lookingFor.inputPlaceholder')"
      />
    <template #footer>
      <div class="button-group">
        <button type="button" class="button button--secondary" @click="closeModal">
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit> {{ __('buttons.save') }}</FormSubmit>
      </div>
    </template>
  </ModalForm>
</template>

<script>

import { user } from '~/Frontend/store';

export default {
  computed: {
    lookingFor: () => user.activeContact.looking_for,
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    update(data) {
      user.activeContact.looking_for = data.looking_for;
      this.closeModal();
    },
  },
};

</script>
