<template>
  <div class="filter-group filter-no-scroll">
    <label class="filter-option">
      <i :class="icon"></i>
      <select
          class="filter-select"
          :name="name"
          :value="modelValue"
          @change="$emit('update:modelValue', $event.target.value)"
        >
        <slot name="default"></slot>
      </select>
    </label>
  </div>
</template>

<script>

export default {
  name: 'FilterSelect',
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'far fa-filter',
    },
  },
  emits: ['update:modelValue'],
};
</script>
