<template>
  <div class="form-input" :class="{'form-input--invalid': invalid}">
    <FormLabel v-bind="labelAttrs"/>
    <MultilevelDropdown v-bind="inputAttrs" v-model="modelValue" />
    <FormError :errors="errors" />
  </div>
</template>

<script>

import { computed } from 'vue';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    valueLimit: {
      type: Number,
      required: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    /**
     * @type Object TreeOption
     * @type String TreeOption.value
     * @type String TreeOption.text
     * @type ?TreeOption[] TreeOption.options
     * @prop TreeOption[] options
     * */
    options: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change'],
  inject: ['feForm'],
  data() {
    return {
      modelValue: this.selected,
    };
  },
  watch: {
    modelValue(newValue) {
      this.$emit('change', [...newValue]);
    },
  },
  computed: {
    id() {
      return `${this.feForm.name}-${this.name}`;
    },
    errors() {
      return this.feForm.error(this.name).value || [];
    },
    invalid() {
      return this.errors.length > 0;
    },
    labelAttrs() {
      const attrs = {
        label: this.label,
      };

      if ('required' in this.$attrs) {
        attrs.required = this.$attrs.required;
      }

      if (this.applyValueLimit) {
        attrs.limit = this.valueLimit;
      }
      attrs.size = this.modelValue.length;

      return attrs;
    },
    applyValueLimit() {
      return !!(this.valueLimit || this.valueLimit === 0);
    },
    inputAttrs() {
      const {
        valueLimit, multiple: isMulti, options, applyValueLimit,
      } = this;

      return {
        applyValueLimit,
        isMulti,
        valueLimit,
        options,
      };
    },

  },
  mounted() {
    this.feForm.register(this.name, computed(() => this.modelValue));
  },
};

</script>
