<template>
  <section class="hub-section">
    <h1>{{ __('hubs.insights.title') }}</h1>
    <p>{{ __('hubs.insights.description') }}</p>
  </section>

  <FeLoading v-if="loading" />

  <template v-else>

    <section class="hub-section">
      <h2>{{ __('hubs.insights.activity') }}</h2>

      <template v-if="popularityStatistics">
        <div class="fe-statistic-card__container">
          <FeStatisticCard
            v-if="profileViews !== null"
            colorVar=""
            icon="far fa-window"
            :header="__('hubs.insights.activity.profileViews')"
            :value="profileViews"
          />
          <FeStatisticCard
            v-if="'favouritedBy' in popularityStatistics"
            colorVar="alert-color"
            icon="far fa-star"
            :header="__('hubs.insights.activity.favourited')"
            :value="popularityStatistics.favouritedBy"
          />
        </div>
        <ProfileViewsPerDayChart
          v-if="'profileViewsPerDay' in popularityStatistics"
          :profileViewsPerDay="popularityStatistics.profileViewsPerDay"
        />
      </template>
    </section>

    <section v-if="meetingStatistics" class="hub-section">
      <h2>{{ __('hubs.insights.meetings') }}</h2>

      <div class="fe-statistic-card__container">
        <FeStatisticCard
          v-if="'confirmedMeetings' in meetingStatistics"
          colorVar="success-color"
          icon="far fa-handshake"
          :header="__('hubs.meetings.confirmedMeetings')"
          :value="meetingStatistics.confirmedMeetings"
        />
        <FeStatisticCard
          v-if="'sentInvites' in meetingStatistics"
          colorVar="success-color"
          icon="far fa-inbox-out"
          :header="__('hubs.meetings.sentInvites')"
          :value="meetingStatistics.sentInvites"
        />
        <FeStatisticCard
          v-if="'receivedInvites' in meetingStatistics"
          colorVar="success-color"
          icon="far fa-inbox-in"
          :header="__('hubs.meetings.receivedInvites')"
          :value="meetingStatistics.receivedInvites"
        />
      </div>
    </section>
  </template>

</template>

<script>
import axios from 'axios';
import { store, user } from '~/Frontend/store';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import FeStatisticCard from '~/Components/Frontend/Core/Misc/FeStatisticCard.vue';
// import NotificationBar from '~/Components/Frontend/NotificationBar.vue';

export default {
  name: 'InsightsPage',
  components: {
    FeLoading,
    FeStatisticCard,
    // NotificationBar,
  },
  data() {
    return {
      loading: false,
      popularityStatistics: null,
      leadStatistics: null,
      meetingStatistics: null,
    };
  },
  computed: {
    activeContact() {
      return user.activeContact;
    },
    eventEdition() {
      return store.eventEdition;
    },
    profileViews() {
      if (!(this.popularityStatistics && 'profileViewsPerDay' in this.popularityStatistics)) {
        return null;
      }
      return this.popularityStatistics.profileViewsPerDay.reduce(
        (total, perDay) => total + perDay.value,
        0,
      );
    },
  },
  methods: {
    loadData() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const url = new URL(window.location);
      const ajaxUrl = `${url.pathname}/insights`;

      axios.get(`${ajaxUrl}?ajax=true`).then((response) => {
        this.popularityStatistics = response.data.popularity_statistics;
        this.meetingStatistics = response.data.meeting_statistics;
      }).then(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.loadData();
  },
};

</script>
