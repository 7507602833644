<template>
  <NotificationBar type="error" v-if="false">
    <!-- move to FAQs -->
    {{ __('profile.pages.manageContent.warning') }}
  </NotificationBar>

  <ProfileGroup>
    <ImageGalleryProfileTable v-if="company" :gallery="company.gallery"/>
  </ProfileGroup>

  <template v-if="company && company.profile_video_limit > 0">
    <ProfileGroup>
      <CompanyVideosProfileTable
        :videos="company.profile_videos"
        :limit="company.profile_video_limit"
        :demographics="demographics"
      />
    </ProfileGroup>
  </template>
</template>

<script>

import axios from 'axios';

export default {
  data() {
    return {
      company: null,
      demographics: null,
    };
  },
  mounted() {
    axios.get('/profile/manage-content')
      .then(({ data }) => {
        this.company = data.company;
        this.demographics = data.demographics;
      });
  },
};
</script>
