import { reactive } from 'vue';

export const contactInviteCredits = reactive({
  invite_credits: 0,
  invite_spent: 0,
  setInviteCredits(credits) {
    this.invite_credits = credits;
  },
  setInviteSpent(count) {
    this.invite_spent = count;
  },
  limitReached() {
    return this.invite_spent >= this.invite_credits;
  },
});

export const actionRequiredCounts = reactive({
  invites_received_pending: 0,
  meetings_to_rate: 0,
  unread_messages: 0,
  setInvitesReceivedPending(count) {
    this.invites_received_pending = count;
  },
  setMeetingsToRate(count) {
    this.meetings_to_rate = count;
  },
  setUnreadMessages(count) {
    this.unread_messages = count;
  },
});

export const store = reactive({
  eventEdition: null,
  platform: null,
});

export const meeting = reactive({
  attendee_status: [],
});

export const user = reactive({
  activeContact: null,
  user: null,
});

export const ui = reactive({
  mode: 'app',
});

// Manager
export default {
  set(data) {
    if (data.invite_spent) {
      contactInviteCredits.setInviteSpent(data.invite_spent);
    }
    if (data.invite_credits) {
      contactInviteCredits.setInviteCredits(data.invite_credits);
    }
    if (data.event_edition) {
      store.eventEdition = data.event_edition;
    }
    if (data.platform) {
      store.platform = data.platform;
    }
    if (data.attendee_status) {
      meeting.attendee_status = data.attendee_status;
    }
    if (data.active_contact) {
      user.activeContact = data.active_contact;
    }
    if (data.user) {
      user.user = data.user;
    }
    if (data.invites_received_pending) {
      actionRequiredCounts.setInvitesReceivedPending(data.invites_received_pending);
    }
    if (data.meetings_to_rate) {
      actionRequiredCounts.setMeetingsToRate(data.meetings_to_rate);
    }
    if (data.unread_messages) {
      actionRequiredCounts.setUnreadMessages(data.unread_messages);
    }
  },
  get(key) {
    if (!key) {
      return contactInviteCredits;
    }
    // rough equiv to laravels array.dot notation getters
    const path = key.split('.');
    if (path[0] === 'user') {
      let obj = user;
      for (let i = 1; i < path.length; i++) {
        if (!obj[path[i]]) {
          return null;
        }
        obj = obj[path[i]];
      }

      return obj;
    }

    return null;
  },
  getMeeting() {
    return meeting;
  },
};
