<template>
  <div class="profile-row">
    <div class="profile-row__label">
      {{ demographic.name }}
      <FeBadge v-if="attentionNeeded" alert small />
    </div>
    <div class="profile-row__value" v-if="modelValue.length > 0" >
      <div class="tag-list">
        <div v-for="value in selectedValues" :key="value.id" class="tag tag__part">
          {{ value.name }}
        </div>
      </div>
    </div>
    <div class="profile-row__value profile-row__value--invalid" v-else>
      {{ __('ui.notAdded') }}
      <template v-if="!demographic.is_editable">
        &nbsp; <b> <i class="fa-solid fa-lock"></i> {{ __('ui.locked') }} </b>
      </template>
    </div>
    <div class="profile-row__action">
      <button class="button button--link" @click="openModal" v-if="demographic.is_editable">
        <i class="fa fa-edit"></i>
        {{ __('buttons.edit') }}
      </button>
    </div>
  </div>
  <ModalForm
    :form="{
      action: `/profile/demographic/${demographic.id}`,
      method: 'patch',
      name: 'demographic',
    }"
    :modal="{
      title: __('ui.edit', { x: '' }),
      titleIcon: 'far fa-edit',
    }"
    ref="modal"
    @success="update"
    >
    <FormTreeSelect
      name="selected"
      :label="demographic.name"
      :options="options"
      :multiple="demographic.is_multi"
      :selected="modelValue"
      :valueLimit="demographic.value_limit"
    />
    <template #footer>
      <div class="button-group">
        <button
          type="button"
          class="button button--secondary"
          @click="closeModal">
          {{ __('buttons.cancel') }}
        </button>
        <FormSubmit>
          {{ __('buttons.save') }}
        </FormSubmit>
      </div>
    </template>
  </ModalForm>
</template>

<script>
import useMyAccountStore from '~/Store/MyAccount';
import { mapActions } from 'pinia';

const toTreeOption = (value) => ({
  value: value.id,
  label: value.name,
  options: value.descendants?.map(toTreeOption),
});

export default {
  props: {
    demographic: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectedValues() {
      return this.demographic.all_values.filter(
        (value) => this.modelValue.includes(value.id),
      );
    },
    options() {
      return this.demographic.values.map(toTreeOption);
    },
    attentionNeeded() {
      return this.modelValue.length === 0;
    },
  },
  methods: {
    ...mapActions(useMyAccountStore, ['loadSectionStatus']),
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    update(data) {
      this.$emit('update:modelValue', data.selected);
      this.closeModal();
    },
  },
  watch: {
    attentionNeeded() {
      this.loadSectionStatus();
    },
  },
};

</script>
