<template>
  <div class="form-input__error" v-if="errors.length > 0">
    <i class="far fa-exclamation-circle"></i> {{ errors[0] }}
  </div>
</template>

<script>

export default {
  props: {
    errors: {
      type: Array,
    },
  },
};

</script>
