<template>
  <BaseModal
    height="tall"
    startOpen
    ref="modal"
    :notifications="notifications"
  >

    <template #header>
      <h3 class="base-modal__title">{{ __('meeting.cancel.title') }}</h3>
    </template>

    <template #subheader v-if="meeting.id">
      <ContactModalHeader
        v-if="counterpartLead"
        :key="counterpartLead.id"
        :contact="counterpartLead"
      />
    </template>

    <FeLoading v-if="!meeting.id" />

    <!-- main slot -->
    <template v-if="meeting.id">
      <div v-if="complete" class="meeting-invite-form__success">
        <i class="fa-regular fa-circle-check info-icon info-icon--success"></i>
        <h1>{{ __('expanded_contact.meeting.cancelledHeader') }}</h1>
        <p v-html="
        __('meeting.cancellation_success.message',
            {
              company_url: counterpartCompanyUrl,
              company_display_name: counterpartLead.company.display_name,
              display_name: counterpartLead.display_name,
              job_title: counterpartLead.job_title
            }
          )">
        </p>
      </div>

      <h1 v-else-if="submitting" class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>

      <form v-else ref="cancelMeetingForm">
        <p v-html="
            __('meeting.cancel.subheader',
                {
                  displayName: counterpartLead.display_name,
                  jobTitle: counterpartLead.job_title,
                  companyDisplayName: counterpartLead.company.display_name,
                  companyUrl: counterpartCompanyUrl,
                }
            )">
        </p>
        <FrontendSelectInput
          :label="__('meeting.cancel.reasonLabel')"
          :placeholder="__('meeting.cancel.reasonPlaceholder')"
          v-model="cancelReason"
          :options="cancelReasons"
          :errorMessage="errorMessage"
          required
        />
        <FrontendTextInput
          id="cancel-message"
          name="message"
          :label="__('meeting.cancel.messageLabel')"
          :placeholder="__('meeting.cancel.messagePlaceholder')"
          v-model="cancelMessage"
          type="textarea"
          :error="errorMessage"
          :max="500"
        />
      </form>

    </template>

    <template #footer>
      <div class="button-group">
        <a
          v-if="complete"
          :href="meetingDetailsUrl"
          class="button button--primary"
        >
          {{ __('meeting.back_to_meeting') }}
        </a>
        <template v-else>
          <a class="button button--secondary" :href="meetingDetailsUrl">
            {{ __('meeting.cancel.keep') }}
          </a>
          <button class="button button--primary" @click="submit" type="button">
          {{ __('meeting.cancel.button') }}
          </button>
        </template>
      </div>
    </template>

  </BaseModal>
</template>

<script>
import axios from 'axios';
import FrontendSelectInput from '../Inputs/FrontendSelectInput.vue';
import FrontendTextInput from '../Inputs/FrontendTextInput.vue';
import { store, user } from '../../../Frontend/store';

export default {
  name: 'CancelMeetingModal',
  components: { FrontendSelectInput, FrontendTextInput },
  inject: ['meetingService', 'eventEditionManager'],
  props: {
    meetingId: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ['meetingCancelled', 'viewMeeting'],
  data() {
    return {
      meeting: {},
      cancelReasons: [],
      cancelReason: '',
      cancelMessage: null,
      nextInviteId: null,
      nextInviteCounterPart: {},
      errorMessage: null,
      errored: false,
      notifications: [],
      submitting: false,
      complete: false,
    };
  },
  mounted() {
    this.loadContent();
  },
  methods: {
    modalClose() {
      this.$refs.modal.close();
      this.emitter.emit('meetings.modal_closed');
    },
    loadContent() {
      this.meeting = {};
      axios.get(`/${this.eventEdition?.slug}/meetings/${this.meetingId}`)
        .then((response) => {
          this.meeting = response.data.meeting;
          this.cancelReasons = response.data.cancellation_reasons;

          if (!this.meeting.can_cancel) {
            window.location.hash = this.meetingDetailsUrl;
          }
        })
        .catch(() => {
          this.errored = true;
          this.notifications.push({
            time: Date.now(),
            type: 'warning',
            // eslint-disable-next-line no-underscore-dangle
            message: this.__('meeting.errors.cannot_load'),
            icon: 'fa-circle-info',
          });
        });
    },
    submit() {
      if (!this.$refs.cancelMeetingForm.checkValidity()) {
        this.$refs.cancelMeetingForm.reportValidity();
        return false;
      }
      this.submitting = true;

      this.meetingService.cancel(this.meeting.id, this.cancelReason, this.cancelMessage)
        .then((response) => {
          this.emitter.emit('meetings.cancelled', {
            meetingId: response.data.meeting.id,
            attendeeContactIds: response.data.attendee_contact_ids,
          });
          this.complete = true;
        })
        .catch((error) => {
          this.errorMessage = error.response?.data?.message;
          this.notifications.push({
            time: Date.now(),
            type: 'warning',
            message: this.errorMessage,
            icon: 'fa-circle-info',
          });
        })
        .then(() => {
          this.submitting = false;
        });
      return true;
    },
  },
  computed: {
    counterpartLead() {
      return this.meeting.attendees.find((attendee) => attendee.is_lead && attendee.company_id !== this.activeContact.company_id);
    },
    activeContact() {
      return user.activeContact;
    },
    eventEdition() {
      return store.eventEdition;
    },
    meetingDetailsUrl() {
      return `#/meetings/${this.meetingId}/details`;
    },
    counterpartCompanyUrl() {
      return `/${this.eventEdition?.slug}/companies/${this.counterpartLead.company_id}`;
    },
  },
};
</script>
