<template>
  <div
    class="inbox-row"
    :class="{'meeting-row--cancelled': isCancelled}"
    @click="openMeeting"
  >
    <div class="inbox-row__header">
      <InboxContactHeader
        :contact="theirLead"
        :relationshipLabel="__('inbox.relationshipLabels.with')"
      ></InboxContactHeader>
      <div class="inbox-row__inbox-status-container">
        <span class="inbox-row__inbox-status"
          :class="{
            'inbox-row__meeting-status--confirmed': !isCancelled,
            'inbox-row__meeting-status--cancelled': isCancelled
          }"
        >
          <i class="far me-1"
            :class="{
              'fa-circle-check': !isCancelled,
              'fa-exclamation-circle': isCancelled,
            }"
          ></i>
          <template v-if="isCancelled">
            {{ __(`inbox.meetings.statuses.cancelled`) }}
          </template>
          <template v-else>
            {{ __(`inbox.meetings.statuses.confirmed`) }}
          </template>
        </span>
      </div>
    </div>
    <div class="inbox-row__divider"></div>
    <div class="inbox-row__body">
      <div class="inbox-row__source" v-if="meeting.source_type !== 'user-invite'">
        <i
          class="fa-sm"
          :class="{
            'fas fa-badge-check': meeting.source_type === 'concierge-booked',
            'fas fa-wand-magic': meeting.source_type === 'auto-match',
            'fas fa-wand-magic': meeting.source_type === 'auto-accepted',
            'fas fa-person-walking': meeting.source_type === 'walk-up',
          }"
        >
        </i>
        {{ __('meeting.source_types.' + meeting.source_type.replace('-', '_')) }}
      </div>

      <NotificationBar
        v-if="meeting.is_confirmed && !meeting.has_finished && meeting.has_started"
        type="dark"
        icon="fas fa-clock"
      >
        {{ __('meeting.in_progress') }}
        <div v-if="meeting.is_virtual">
          <a class="button" :href="meeting.virtual_url">
            <i class="far fa-video"></i> {{ __('meeting.join_now') }}
          </a>
        </div>
      </NotificationBar>
      <NotificationBar
        v-else-if="meeting.show_countdown"
        type="dark"
        icon="fas fa-clock"
      >
        <CountdownTimer
          :deadline="new Date(meeting.countdown.atom_string).getTime()"
          :gracePeriodSeconds="120"
          format="paragraph"
          :countdown-intro-message="__('meeting.meeting_starts_in_countdown')"
        >
          <template #almost-past>
            <div v-if="meeting.is_virtual">
              <a class="button button--primary" :href="meeting.virtual_url">
                <i class="far fa-video"></i> {{ __('meeting.join_now') }}
              </a>
            </div>
          </template>
          <template #past>
            <div v-if="meeting.is_virtual">
              <a class="button button--primary" :href="meeting.virtual_url">
                <i class="far fa-video"></i> {{ __('meeting.join_now') }}
              </a>
            </div>
            <p v-else><i class="fas fa-clock"></i> {{ __('meeting.in_progress') }}</p>
          </template>
        </CountdownTimer>
      </NotificationBar>

      <div class="inbox-row__meeting-logistics">
        <div class="inbox-row__meeting-logistics__tag">
          <i class="fas fa-fw fa-clock me-1"></i>
          {{ meeting.time }}
          <small style="font-size: var(--font-xsmall">
            ({{ meeting.timezone }})
          </small>
        </div>
        <div class="inbox-row__meeting-logistics__tag">
          <i class="fas fa-fw fa-calendar-day me-1"></i>
          {{ meeting.date_medium }}
        </div>
        <div class="inbox-row__meeting-logistics__tag">
          <i class="fa-regular fa-fw fa-location-dot me-1"></i>
          {{ meeting.location.short }}
        </div>
      </div>

      <div class="inbox-row__message" :class="{'inbox-row__message-sent': isSender}">
        <p>
          <PreWrap :text="message" />
          <template v-if="messageExpandable">
            <a
              class="inbox-row__message-expand"
              :href="`#/meetings/${meeting.id}/details`"
              @click.prevent="messageExpanded = !messageExpanded"
              >
              &nbsp;{{
                messageExpanded
                ? __('inbox.invites.buttons.show_less')
                : __('inbox.invites.buttons.read_more')
              }}
            </a>
          </template>
        </p>
      </div>

      <InboxAdditionalMessage
        v-if="meeting.cancelled_at && meeting.cancelled_by === activeContact.id"
        title-icon="far fa-ban"
        :title="__('inbox.meetings.cancelledByActiveUser',
                {
                  cancel_time: meeting.cancel_time,
                })"
        :message="meeting.cancel_reason"
        :additional-message="meeting.cancel_message"
        is-warning
      />

      <InboxAdditionalMessage
        v-if="meeting.cancelled_at && meeting.cancelled_by !== activeContact.id"
        title-icon="far fa-ban"
        :title="__('inbox.meetings.cancelledBy',
                {
                  cancel_time: meeting.cancel_time,
                  cancelled_by: cancelledBy.display_name,
                })"
        :message="meeting.cancel_reason"
        :additional-message="meeting.cancel_message"
        is-warning
      />

      <div
        v-if="meeting.has_unread_chats"
        class="inbox-row__additional-info inbox-row__additional-info-warn"
      >
        <i class="fas fa-message-lines" style="margin-right: var(--sizing-2)"></i>
        <span v-html="__('inbox.messages.info.newUnreadMessage',
        {
          url: `#/meetings/${meeting.id}/details/chat`
        })"></span>
      </div>
      <div
        v-else-if="meeting.chats_count > 0"
        class="inbox-row__additional-info"
      >
        <i class="far fa-info-circle"></i>
        {{ __('inbox.messages.info.moreMessagesInThread') }}
      </div>

      <!-- Participants -->
      <template v-if="meeting.attendees.length > 2">
        <div class="inbox-row__attendees-title">
          <i class="far fa-people-group me-1"></i>
          {{ __('meeting.attendeesTitle') }}
          <span class="inbox-row__attendees-count">
            ({{ __('meeting.attendeesCount',
              {
                count: meeting.attendees.length,
              })
            }})
          </span>
        </div>
        <div class="inbox-row__attendees-container">
          <div
            v-for="contact in meeting.attendees"
            :key="contact.id"
            >
            <ContactAvatar :contact="contact" size="small" @click.prevent.stop="goToProfile(contact.id)">
            </ContactAvatar>
          </div>
        </div>
      </template>

      <div class="button-group">
        <a
          v-if="canJoinNow"
          :href="meeting.virtual_url"
          class="button button--primary"
        >
          <i class="far fa-video"></i> {{ __('meeting.join_now') }}
        </a>
        <a
          v-else-if="meeting.can_rate && meeting.has_finished && !meeting.cancelled_at"
          :href="`#/meetings/${meeting.id}/rate`"
          class="button button--primary"
        >
          {{ __('inbox.meetings.rate') }}
        </a>
        <a
          v-else
          class="button button--primary"
          :href="`#/meetings/${meeting.id}/details`"
        >
          {{ __('inbox.meetings.view') }}
        </a>

        <SubOptions position="bottom-up" v-if="!meeting.cancelled_at">
          <template v-slot:button="{toggle}">
            <button
              @click.stop="toggle"
              type="button"
              class="button button--secondary button--wide"
            >
              {{ __('inbox.meetings.buttons.moreOptions') }}
            </button>
          </template>
          <SubOption
            icon="far fa-calendar-circle-plus"
            :label="__('meeting.addToCalendar')"
            @clicked="addToCalendar"
          />
          <SubOption
            v-if="canCancel"
            icon="far fa-share"
            :label="__('inbox.meetings.buttons.cancel')"
            @clicked="cancelMeeting"
            :disabled="meeting.location.locked"
          />
          <SubOption
            icon="far fa-edit"
            :label="__('inbox.meetings.buttons.rescheduleMeeting')"
            @clicked="openMeeting"
            :disabled="meeting.location.locked"
          />
          <SubOption
            v-if="hasColleaguesToAdd"
            icon="far fa-user-plus"
            :label="__('inbox.meetings.buttons.addColleagues')"
            @clicked="addColleagues"
          />
        </SubOptions>
      </div>
    </div>
  </div>

</template>
<script>
import _ from 'lodash';
import PreWrap from '~/Components/Common/PreWrap.vue';
import SubOptions from '~/Components/Frontend/SubOptions.vue';
import SubOption from '~/Components/Frontend/SubOption.vue';
import { store, user } from '~/Frontend/store';
import NotificationBar from '~/Components/Frontend/NotificationBar.vue';
import InboxAdditionalMessage from './InboxAdditionalMessage.vue';
import InboxContactHeader from './InboxContactHeader.vue';

const messageUnexpandedLength = 175;

export default {
  components: {
    InboxAdditionalMessage,
    InboxContactHeader,
    NotificationBar,
    PreWrap,
    SubOption,
    SubOptions,
  },
  props: {
    meeting: Object,
  },
  data() {
    return {
      messageExpanded: false,
    };
  },
  computed: {
    isSender() {
      return this.activeContact.id === this.meeting.invite.sender_id;
    },
    isLead() {
      return this.us.find((contact) => contact.id === this.activeContact.id).is_lead;
    },
    us() {
      return this.meeting.attendees.filter((attendee) => attendee.company_id === this.activeContact.company_id);
    },
    them() {
      return this.meeting.attendees.filter((attendee) => attendee.company_id !== this.activeContact.company_id);
    },
    theirLead() {
      return this.them.find((attendee) => attendee.is_lead);
    },
    isCancelled() {
      return !!this.meeting.cancelled_at;
    },
    cancelledBy() {
      return this.meeting.attendees.find((attendee) => attendee.id === this.meeting.cancelled_by);
    },
    hasColleaguesToAdd() {
      return this.meeting.can_add_colleagues;
    },
    truncatedMessage() {
      return _.truncate(this.meeting.invite.message, { length: messageUnexpandedLength, separator: ' ' });
    },
    message() {
      return this.messageExpanded ? this.meeting.invite.message : this.truncatedMessage;
    },
    messageExpandable() {
      return this.meeting.invite.message.length > messageUnexpandedLength;
    },
    canJoinNow() {
      return this.meeting.is_virtual
        && !this.meeting.isCancelled
        && this.meeting.has_started
        && !this.meeting.has_finished;
    },
    canCancel() {
      return !this.isCancelled && this.isLead;
    },
    eventEdition() {
      return store.eventEdition;
    },
    activeContact() {
      return user.activeContact;
    },
  },
  methods: {
    goToProfile(id) {
      window.location.href = `#/contacts/${id}/profile`;
    },
    companyModalLink(id) {
      return `#/companies/${id}`;
    },
    addToCalendar() {
      window.location.href = `/${this.eventEdition?.slug}/meetings/${this.meeting.id}/download-ics`;
    },
    openMeeting() {
      window.location.href = `#/meetings/${this.meeting.id}/details`;
    },
    cancelMeeting() {
      window.location.href = `#/meetings/${this.meeting.id}/cancel`;
    },
    addColleagues() {
      window.location.href = `#/meetings/${this.meeting.id}/add-colleague`;
    },
  },
};
</script>
