<template>
  <div>
    <div class="registration-process" v-if="currentRoute">
      <div v-if="isSendLink">
        <RegistrationSendLink />
      </div>
      <div v-else>
        <h2 class="registration--title">{{ registrationStore.getCurrentQuestionTitle }}</h2>
        <p class="registration--description">
          {{ registrationStore.getCurrentQuestion.description }}
        </p>
        <component :is="currentView"></component>
      </div>
    </div>
    <RegistrationFooter>
      <button type="button"
        :disabled="!isPrev"
        class="button button--gray registration-footer__back-button"
        @click="(n) => navigate('prev', 'navigate')">
        <i class="far fa-light fa-arrow-left text-primary"></i>
      </button>
      <button type="button"
        :disabled="isSendLink && !registrationStore.getCurrentQuestion?.action"
        class="button button--primary button--full"
        @click="(n) => navigate('next', 'navigate')">
        {{ isSubmit ? __('registrations.form.actions.submit')
          : __('registrations.form.actions.next') }}
        &nbsp;
        <i v-if="!isSubmit" class="far fa-light fa-arrow-right"></i>
      </button>
    </RegistrationFooter>
    <teleport to=".registration-header">
      <div class="registration-progress">
        <div class="registration-progress__active"
          :style="{'width': `${registrationStore.getProgress}%`}"
        >
      </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapStores, storeToRefs } from 'pinia';
import axios from 'axios';
import { watch } from 'vue';
import useRegistrationStore from '~/Store/Registration';
import RegistrationNameComponent from '~/Components/Frontend/Registration/ComponentTypes/RegistrationNameComponent.vue';
import RegistrationFooter from '~/Components/Frontend/Registration/Screens/RegistrationFooter.vue';
import RegistrationEmailComponent from '~/Components/Frontend/Registration/ComponentTypes/RegistrationEmailComponent.vue';
import RegistrationLongtextComponent from '~/Components/Frontend/Registration/ComponentTypes/RegistrationLongtextComponent.vue';
import RegistrationShortTextComponent from '~/Components/Frontend/Registration/ComponentTypes/RegistrationShortTextComponent.vue';
import RegistrationPhoneComponent from '~/Components/Frontend/Registration/ComponentTypes/RegistrationPhoneComponent.vue';
import RegistrationCountryComponent from '~/Components/Frontend/Registration/ComponentTypes/RegistrationCountryComponent.vue';
import RegistrationLegalComponent from '~/Components/Frontend/Registration/ComponentTypes/RegistrationLegalComponent.vue';
import RegistrationDemographicSingleSelectComponent from '~/Components/Frontend/Registration/ComponentTypes/RegistrationDemographicSingleSelectComponent.vue';
import RegistrationDemographicMultiSelectComponent from '~/Components/Frontend/Registration/ComponentTypes/RegistrationDemographicMultiSelectComponent.vue';
import RegistrationDemographicTreeViewComponent from '~/Components/Frontend/Registration/ComponentTypes/RegistrationDemographicTreeViewComponent.vue';
import RegistrationSendLink from '~/Components/Frontend/Registration/ComponentTypes/Email/RegistrationSendLink.vue';

const routes = {
  name: RegistrationNameComponent,
  email: RegistrationEmailComponent,
  longtext: RegistrationLongtextComponent,
  shorttext: RegistrationShortTextComponent,
  phone: RegistrationPhoneComponent,
  country: RegistrationCountryComponent,
  legal: RegistrationLegalComponent,
  'demographic-single-select': RegistrationDemographicSingleSelectComponent,
  'demographic-multi-select': RegistrationDemographicMultiSelectComponent,
  'demographic-tree-view': RegistrationDemographicTreeViewComponent,
};

function routeToPreg(route) {
  return new RegExp(`^#${route.replaceAll(/{(.*?)}/g, '([^/]*?)')}$`);
}

export default {
  name: 'RegistrationProcess',
  inheritAttrs: false,
  components: [
    RegistrationFooter,
    RegistrationSendLink,
  ],
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
    compiledRoutes() {
      return Object.entries(routes).map(([route, view]) => ({
        route, view, preg: routeToPreg(route),
      }));
    },
    currentRoute() {
      const currentQuestion = this.registrationStore.getCurrentQuestion;
      if (currentQuestion) {
        return this.compiledRoutes.find((route) => currentQuestion.component_type === route.route);
      }
      return null;
    },
    currentView() {
      return this.currentRoute ? this.currentRoute.view : null;
    },
    isNext() {
      return this.navigate('next', 'validating');
    },
    isPrev() {
      return this.navigate('prev', 'validating');
    },
    isSubmit() {
      return this.registrationStore.isLastQuestion
        || this.registrationStore.getCurrentQuestion?.errors?.user_email
        || this.registrationStore.getCurrentQuestion?.errors?.registration_incomplete;
    },
    isSendLink() {
      return this.registrationStore.getCurrentQuestion?.errors?.user_email
        || this.registrationStore.getCurrentQuestion?.errors?.registration_incomplete;
    },
  },
  setup() {
    const store = useRegistrationStore();
    const { currentQuestionId } = storeToRefs(store);
    watch(currentQuestionId, () => {
      store.updateProgress();
      store.validateQuestion();
    });
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    questionId: {
      type: String,
    },
    registrationId: {
      type: String,
    },
    registrationQuestionsRoute: {
      type: String,
      required: true,
    },
    registrationSaveRoute: {
      type: String,
      required: true,
    },
    registrationActionRoute: {
      type: String,
      required: true,
    },
    countries: Array,
  },
  data() {
    return {
      actions: ['send_login_link', 'send_incomplete_registration_link'],
    };
  },
  created() {
    this.loadQuestions();
  },
  methods: {
    submit() {
      return false;
    },
    loadQuestions() {
      if (this.registrationStore.getQuestions.length === 0) {
        this.registrationStore.setLoading('screen-loading');
        axios.post(this.registrationQuestionsRoute, { registrationId: this.registrationId })
          .then((response) => {
            this.registrationStore.updateQuestions(response.data.questions, this.questionId);
            this.registrationStore.updatePersonalization(response.data.personalization);
            this.registrationStore.updateCountries(this.countries);
            this.loadData('screen-loading');
          }).then(() => {
            this.registrationStore.setLoading('');
          })
          .catch(this.handleError);
      }
    },
    handleError(error) {
      this.registrationStore.setErrorMessage(error?.response?.data?.message);
      this.registrationStore.setErrors(error?.response?.data?.errors ?? {});
      this.registrationStore.setLoading('');
    },
    navigate(navigationType, action = 'navigate') {
      if (_.includes(this.actions, this.registrationStore.getCurrentQuestion?.action) && action === 'navigate') {
        this.registrationStore.action(this.registrationActionRoute);
      } else {
        if (navigationType === 'prev' && this.registrationStore.getQuestions[this.registrationStore.getCurrentQuestionIndex - 1] !== undefined && this.registrationId !== undefined) {
          if (action === 'navigate') {
            // eslint-disable-next-line max-len
            this.registrationStore.clearQuestionExtraProperties(this.registrationStore.getCurrentQuestionIndex);
            this.registrationStore.goToQuestion(this.registrationStore.getQuestions[this.registrationStore.getCurrentQuestionIndex - 1].id, 'back');
            window.location.hash = `/process/${this.registrationId}/${this.registrationStore.getCurrentQuestion.id}`;
          }
          return true;
        }
        if (navigationType === 'next' && action === 'navigate') {
          this.registrationStore.save();
        }
      }
      return false;
    },
  },
  mounted() {
    this.registrationStore.updateRegistration({
      id: this.registrationId,
      saveRoute: this.registrationSaveRoute,
    });
  },
};
</script>
