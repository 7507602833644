<template>
    <div
      class="fullpage-gallery"
      :style="{
        '--active-image': selected + 1,
        '--total-images': images.length
        }"
      >
        <button class="fullpage-gallery__arrow" @click="prev">
          <i class="fa-solid fa-circle-arrow-left fa-2xl"></i>
        </button>

        <div class="fullpage-gallery__main-holder">
          <img class="fullpage-gallery__main" :src="activeImage.gallery.url" />
        </div>

        <button class="fullpage-gallery__arrow" @click="next">
          <i class="fa-solid fa-circle-arrow-right fa-2xl"></i>
        </button>

        <ul class="fullpage-gallery__thumbs">
            <li
              class="fullpage-gallery__thumb"
              v-for="(image,index) in images"
              :key="index"
              :class="{'fullpage-gallery__thumb--active': index === selected}"
              >
                <img :src="image.thumbnail.url" @click="selected = index" />
            </li>
        </ul>
    </div>
</template>

<script>

import { modalClose } from '../../modal';

export default {
  props: {
    images: {
      type: Object,
      required: true,
    },
    initial: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.initial,
    };
  },
  computed: {
    activeImage() {
      return this.images[this.selected];
    },
  },
  methods: {
    next() {
      this.selected = (this.selected + 1) % this.images.length;
    },
    prev() {
      this.selected = (this.selected + this.images.length - 1) % this.images.length;
    },
    handleKeydown(e) {
      switch (e.keyCode) {
        case 37:
          this.prev();
          break;
        case 39:
          this.next();
          break;
        default:
          break;
      }
    },
  },

  beforeMount() {
    window.addEventListener('keydown', this.handleKeydown, null);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
  created() {
    window.onpopstate = () => modalClose();
  },
};

</script>
