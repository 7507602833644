<template>
  <BaseModal
    height="tall"
    startOpen
    ref="modal"
    :notifications="notifications"
  >
    <template #header>
      <h3 class="base-modal__title">{{ __('meeting.add_colleagues.header') }}</h3>
    </template>

    <template #subheader v-if="meeting.id">
      <ContactModalHeader
        v-if="counterpartLead"
        :key="counterpartLead.id"
        :contact="counterpartLead"
      />
    </template>

    <FeLoading v-if="!meeting.id" />

    <!-- main slot -->
    <template v-if="meeting.id">
      <div v-if="complete" class="meeting-invite-form__success">
        <i class="fa-regular fa-circle-check info-icon info-icon--success"></i>
        <h1>{{ choice('meeting.add_colleagues.success.title', colleaguesToAdd.length) }}</h1>
        <p>
        {{ choice('meeting.add_colleagues.success.message', colleaguesToAdd.length) }}
        </p>
      </div>

      <h1 v-else-if="submitting" class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>

      <form v-else ref="addColleaguesToMeetingForm">
        <p v-html="
            __('meeting.add_colleagues.subheader',
                {
                  display_name: counterpartLead.full_name,
                  job_title: counterpartLead.job_title,
                  company_display_name: counterpartLead.company.display_name,
                  company_url: counterpartCompanyUrl,
                }
            )">
        </p>
        <div v-if="!submitting" class="meeting-invite-form__recipients">
          <FrontendCheckboxInput
            v-model="colleaguesToAdd"
            :options="colleagues"
            :disabledOptions="colleaguesDisabled"
            name="collagues"
            required
          />
        </div>
      </form>

    </template>

    <template #footer>
      <div class="button-group">
        <template v-if="complete">
          <a
            :href="meetingDetailsUrl"
            class="button button--primary"
          >
            {{ __('meeting.back_to_meeting') }}
          </a>
          <button v-if="canAddMoreColleagues"
            class="button button--secondary"
            @click="resetForm">
            {{ __('meeting.add_colleagues.buttons.invite_more') }}
          </button>
        </template>
        <template v-else>
          <a class="button button--secondary" :href="meetingDetailsUrl">
            {{ __('meeting.add_colleagues.buttons.cancel') }}
          </a>
          <button class="button button--primary" @click="submit" type="button">
          {{ __('meeting.add_colleagues.buttons.add') }}
          </button>
        </template>
      </div>
    </template>

  </BaseModal>
</template>

<script>
import axios from 'axios';
import FrontendCheckboxInput from '../Inputs/FrontendCheckboxInput.vue';
import { store, user } from '../../../Frontend/store';

export default {
  name: 'AddColleaguesToMeetingModal',
  components: { FrontendCheckboxInput },
  inject: ['meetingService', 'eventEditionManager'],
  props: {
    meetingId: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ['viewMeeting'],
  data() {
    return {
      meeting: {},
      canAddMoreColleagues: false,
      colleaguesToAdd: [],
      nonAttendingColleagues: [],
      nextInviteId: null,
      nextInviteCounterPart: {},
      errorMessage: null,
      errored: false,
      notifications: [],
      submitting: false,
      complete: false,
    };
  },
  mounted() {
    this.loadContent();
  },
  methods: {
    modalClose() {
      this.$refs.modal.close();
      this.emitter.emit('meetings.modal_closed');
    },
    loadContent() {
      this.meeting = {};
      axios.get(`/${this.eventEdition?.slug}/meetings/${this.meetingId}/add-colleague`)
        .then((response) => {
          this.meeting = response.data.meeting;
          this.nonAttendingColleagues = response.data?.nonattending_colleagues || [];
          this.busyColleagues = response.data?.busy_colleagues || [];

          if (this.nonAttendingColleagues.length === 0) {
            window.location.hash = this.meetingDetailsUrl;
          }
        })
        .catch(() => {
          this.errored = true;
          this.notifications.push({
            time: Date.now(),
            type: 'warning',
            // eslint-disable-next-line no-underscore-dangle
            message: this.__('meeting.errors.cannot_load'),
            icon: 'fa-circle-info',
          });
        });
    },
    submit() {
      if (!this.$refs.addColleaguesToMeetingForm.checkValidity()) {
        this.$refs.addColleaguesToMeetingForm.reportValidity();
        return false;
      }

      this.submitting = true;

      axios.post(`/${this.eventEdition?.slug}/meetings/${this.meeting.id}/add-colleague`, {
        colleagues: this.colleaguesToAdd,
      })
        .then((response) => {
          this.complete = true;
          this.canAddMoreColleagues = response.data.can_add_colleagues;
        }).catch((error) => {
          this.errorMessage = error.response?.data?.message;
          this.notifications.push({
            time: Date.now(),
            type: 'warning',
            message: this.errorMessage,
            icon: 'fa-circle-info',
          });
        }).then(() => {
          this.submitting = false;
        });
      return true;
    },
    resetForm() {
      this.complete = false;
      this.loadContent();
    },
  },
  computed: {
    counterpartLead() {
      return this.meeting.attendees.find((attendee) => attendee.is_lead && attendee.company_id !== this.activeContact.company_id);
    },
    activeContact() {
      return user.activeContact;
    },
    eventEdition() {
      return store.eventEdition;
    },
    meetingDetailsUrl() {
      return `#/meetings/${this.meetingId}/details`;
    },
    counterpartCompanyUrl() {
      return `/${this.eventEdition?.slug}/companies/${this.counterpartLead.company.id}`;
    },
    colleagues() {
      return this.nonAttendingColleagues?.map((colleague) => ({
        value: colleague.id,
        text: colleague.display_name,
      }));
    },
    colleaguesDisabled() {
      return this.busyColleagues?.map((colleague) => colleague.id);
    },
  },
};
</script>
