<template>
  <div
    @click.prevent="openBooking"
    class="my-schedule__booking"
    :data-bookable-type="type"
    :style="{
      '--schedule-booking-duration': duration,
      '--schedule-booking-start': minutesSince,
    }"
  >
    <div class="my-schedule__booking-summary">
      <div class="my-schedule__booking-type-badge">
        <i :class="icon"></i>
        {{ typeBadge }}
      </div>

      <p class="my-schedule__booking-title">
        <span v-if="type !== 'Unavailability'">
          {{ title }}
        </span>
      </p>

      <div class="my-schedule__booking-time">
        <i class="fas fa-clock"></i>
        {{ startTime }} - {{ endTime }}
      </div>

      <div
        style="display: none;"
        v-if="model.bookable.source_type && model.bookable.source_type !== 'user-invite'"
        class="my-schedule__meeting-source">
      >
        {{ __('my_schedule.bookings.source_types.' + model.bookable.source_type.replace('-', '_')) }}
      </div>
    </div>

    <div class="my-schedule__booking-details">
      <p v-if="location">
        <i class="fas fa-location-dot"></i> {{ location }}
        <strong v-if="model.bookable?.table_number">
          {{ __('my_schedule.bookings.table_number', { number: this.model.bookable.table_number, }) }}
        </strong>
      </p>

      <div
        v-if="type === 'Meeting'"
        class="my-schedule__booking-description my-schedule__booking-description--attendees"
      >
        <div v-for="contact in model.bookable.attendees" :key="contact.id">
          <ContactAvatar :contact="contact" size="small"/>
        </div>
        <div v-if="model.bookable.attendees.length > 4">
          +{{ model.bookable.attendees.length - 4}}
        </div>
      </div>
      <div v-else v-html="description" class="my-schedule__booking-description"></div>

      <div class="my-schedule__booking-button-holder" v-if="type !== 'ExclusiveEvent'">
        <a href="#" @click.prevent="openBooking">
          {{ buttonText }}
          <i class="fa-regular fa-arrow-right"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ContactAvatar from '../CRM/Components/ContactAvatar.vue';

export default {
  components: { ContactAvatar },
  props: {
    title: {
      type: String,
      required: true,
    },
    minutesSince: {
      type: Number,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    typeBadge: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    startTime: {
      type: String,
      required: true,
    },
    endTime: {
      type: String,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  methods: {
    openBooking() {
      if (this.type === 'Meeting') {
        window.location.href = `#/meetings/${this.model.bookable.id}/details`;
        return;
      }
      if (this.type === 'ConferenceSession') {
        window.location.href = `#/sessions/${this.model.bookable.id}/show`;
        return;
      }
      if (this.type === 'Unavailability') {
        window.location.href = '#/update-availability';
        return;
      }
      if (this.type === 'ExclusiveEvent') {
        return;
      }
      window.location.href = `#/other-bookings/${this.model.bookable.id}`;
    },
  },
  computed: {
    location() {
      return this.model.bookable.location_long;
    },
  },
};
</script>
