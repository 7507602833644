<template>
  <label class="form-input" :class="{'form-input--invalid': invalid}">
    <FormLabel v-bind="labelAttrs"/>
    <textarea
      class="form-input__input"
      v-bind="attributes"
      v-model="value"
      ref="textArea"
      @input="feForm.clearErrors(name)"
    />
    <FormError :errors="errors"/>
    <GenerateAiButton
      :generate-ai-url="generateAiUrl"
      @update:text="updateText"
    />
  </label>
</template>

<script>
import { computed } from 'vue';
import { useVModel } from '@vueuse/core';
import GenerateAiButton from '~/Components/Frontend/Core/Buttons/GenerateAiButton.vue';

export default {
  emits: ['update:modelValue'],
  components: {
    GenerateAiButton,
  },
  setup(props, { emit }) {
    return {
      value: useVModel(props, 'modelValue', emit, { passive: true, defaultValue: props.default }),
    };
  },
  props: {
    default: {
      type: [Number, String],
      default: '',
    },
    modelValue: {
      type: [Number, String],
      default: null,
    },
    label: {
      type: String,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
    name: {
      required: true,
      type: String,
    },
    rows: {
      type: Number,
      default: 3,
    },
    autofocus: Boolean,
    generateAiUrl: {
      type: String,
      default: '',
    },
  },
  inject: ['feForm'],
  computed: {
    id() {
      return `${this.feForm.name}-${this.name}`;
    },
    errors() {
      return this.feForm.error(this.name).value || [];
    },
    invalid() {
      return this.errors.length > 0;
    },
    attributes() {
      const { id, name, rows } = this;
      const attrs = { id, name, rows };
      if (this.max) {
        attrs.maxlength = this.max;
      }
      if (this.$attrs.placeholder) {
        attrs.placeholder = this.$attrs.placeholder;
      }
      return attrs;
    },
    labelAttrs() {
      const attrs = {
        label: this.label,
        size: this.value.length,
        limit: this.max,
      };

      if ('required' in this.$attrs) {
        attrs.required = this.$attrs.required;
      }

      return attrs;
    },
  },
  methods: {
    updateText(text) {
      this.value = text;
      if (text.length % 5 === 0) this.scrollToBottom();
    },
    scrollToBottom() {
      this.$refs.textArea.scrollTo({
        top: this.$refs.textArea.scrollHeight,
        behavior: 'smooth',
      });
    },
  },
  mounted() {
    this.feForm.register(this.name, computed(() => this.value));
    if (this.autofocus) {
      this.$refs.textArea.focus();
    }
  },
};
</script>
