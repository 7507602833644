<template>
  <div ref="slider"
    class="gallery-slider"
  >
    <div ref="viewport" class="gallery-slider__viewport" @scroll.passive="getScrollX">
      <template v-for="galleryImage in galleryImages" :key="galleryImage.id">
        <div
          class="gallery-slider__image"
          :id="`slide-${galleryImage.id}`"
          :style="{ 'background-image': `url(${galleryImage.gallery?.url})` }"
        ></div>
      </template>
    </div>
  </div>
  <template v-if="galleryImages?.length > 1">
    <div class="gallery-slider__nav">
      <template v-for="(galleryImage, imageIndex) in galleryImages" :key="galleryImage.id">
        <a
          :id="`slide-${galleryImage.id}-link`"
          class="gallery-slider__link"
          :class="{ 'gallery-slider__link--active': activeSlideIndex === imageIndex }"
          :href="`#slide-${galleryImage.id}`"
          @click.prevent="scrollToSlide(galleryImage)"
        >
          &#9679;
        </a>
      </template>
    </div>
  </template>
</template>

<script>

export default {
  props: {
    galleryImages: {
      type: Object,
      default: () => {},
    },
    height: {
      type: String,
      required: false,
      validator: (value) => ['tall'].includes(value),
    },
  },
  data() {
    return {
      componentKey: 0,
      slideWidth: 0,
      scrollX: 0,
    };
  },
  created() {
    window.addEventListener('resize', this.getSlideWidth);
  },
  mounted() {
    this.getSlideWidth();
  },
  computed: {
    slideCount() {
      return this.galleryImages?.length ? this.galleryImages?.length : 0;
    },
    activeSlideIndex() {
      return Math.round(this.scrollX / this.slideWidth);
    },
  },
  methods: {
    getScrollX(e) {
      this.scrollX = e.target.scrollLeft;
    },
    getSlideWidth() {
      this.slideWidth = this.$refs.viewport?.clientWidth;
    },
    scrollToSlide(galleryImage) {
      const slide = document.getElementById(`slide-${galleryImage.id}`);
      slide.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });

      const slideNav = document.getElementById(`slide-${galleryImage.id}-link`);
      slideNav.blur();
    },
  },
};
</script>
