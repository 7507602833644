<template>
  <InfoPanel>
    <InfoPanelItem v-if="helpVideo">
      <div v-html="helpVideo"></div>
    </InfoPanelItem>
    <InfoPanelItem>
      <NeedHelpItem :customContent="customNeedHelp" />
    </InfoPanelItem>
    <InfoPanelItem v-if="dataPrivacy">
      <div class="need-help-item">
        <h3 class="need-help-item__header">
          <i class="far fa-eye"></i>
          &nbsp;{{ __('needHelp.dataPrivacy.header') }}
        </h3>
        <p class="need-help-item__body">
          {{ __('needHelp.dataPrivacy.body') }}<br>
          <a href="#/privacy-policy">
            {{ __('needHelp.dataPrivacy.link') }}
            &nbsp;<i class="far fa-arrow-right-long"></i>
          </a>
        </p>
      </div>
    </InfoPanelItem>
    <InfoPanelItem v-if="faqs.length > 0">
      <FeAccordion :header="faqHeader">
        <AccordionItem
          v-for="(faq, index) in faqs"
          :key="`faq-${index}`"
          :label="faq.label"
        >
          <div v-html="faq.answer"></div>
        </AccordionItem>
      </FeAccordion>
    </InfoPanelItem>
  </InfoPanel>
</template>

<script>
export default {
  name: 'HelpPanel',
  props: {
    helpVideo: String,
    faqHeader: String,
    faqs: {
      type: Array,
      required: true,
      default: () => [],
    },
    dataPrivacy: Boolean,
    customNeedHelp: String,
  },
};
</script>
