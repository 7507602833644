<template>
  <div class="chart-card">
    <div class="chart-card__statistic">
      <strong>{{ __('hubs.meetings.MeetingsByDayTitle') }}</strong>
    </div>
    <apexchart
      v-if="data.length"
      type="bar"
      height="400"
      :options="options"
      :series="data"
    />
  </div>
</template>

<script>
import Vue3Apexcharts from 'vue3-apexcharts';

export default {
  name: 'HubMeetingsByDayChart',
  components: {
    apexchart: Vue3Apexcharts,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      options: {
        chart: {
          id: 'meetings-by-day',
          background: '#fff',
          offsetX: 0,
          offsetY: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          labels: {
            rotate: -90,
            rotateAlways: true,
            style: {
              colors: 'var(--gray-4)',
            },
          },
        },
        yaxis: {
          show: false,
          max: 1,
          labels: {
            show: false,
            maxWidth: 0,
          },
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
    };
  },
  mounted() {
    const max = Math.max(...this.data[0].data.map(({ y }) => y));
    this.options.yaxis.max = Math.round(max * 1.1, 2);
  },
};
</script>
