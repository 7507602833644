<template>
  <div>
    <h2 class="purchase-upgrade__popup-title">{{ __('purchase_upgrade.checkout_heading') }}</h2>
    <form id="payment-form">
      <div class="purchase-upgrade__popup-checkout-holder">
        <div v-if="errorCode">
          <p class="purchase-upgrade__popup-subtitle">
            <i class="far fa-exclamation-triangle"></i>
            {{ __('purchase_upgrade.error_heading') }}
          </p>
          <p class="purchase-upgrade__popup-subtitle">
            {{ errorMessage }}
          </p>
        </div>
        <div id="payment-element" v-else>
          <!-- Stripe will create form elements here -->
        </div>
      </div>

      <div class="button-group button-group--right" style="margin-top: 2rem;">
        <button
          v-if="errorCode"
          type="button"
          class="button button--secondary"
          @click="reset"
          style="max-width: fit-content"
        >
          <i class="far fa-arrow-left"></i>
          {{ __('purchase_upgrade.buttons.try_again') }}
        </button>
        <button
          v-if="!errorCode"
          type="button"
          class="button button--secondary"
          @click="$emit('cancel')"
          style="max-width: fit-content"
          :disabled="sending"
        >
          {{ __('purchase_upgrade.buttons.cancel') }}
        </button>
        <button
          v-if="!errorCode"
          type="submit"
          class="button button--primary"
          @click="handleSubmit"
          style="max-width: fit-content"
          :disabled="sending"
        >
          {{ __('purchase_upgrade.buttons.pay_now') }} <i class="far fa-arrow-right"></i>
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    initiatePaymentUrl: String,
    successUrl: String,
    failureUrl: String,
    purchaseItem: Object,
    stripePublishableKey: String,
  },
  data() {
    return {
      token: null,
      stripe: null,
      elements: null,
      errorCode: null,
      errorMessage: null,
      sending: false,
    };
  },
  emits: ['cancel', 'paymentComplete'],
  methods: {
    reset() {
      this.errorCode = null;
      this.errorMessage = null;
      this.sending = false;

      this.setupStripe();
    },
    async handleSubmit(e) {
      e.preventDefault();

      this.errorCode = null;
      this.errorMessage = null;
      this.sending = true;

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        redirect: 'if_required',
      });

      if (error === undefined) {
        axios.post(this.successUrl, {
          token: this.token,
        })
          .then((response) => {
            this.$emit('paymentComplete', {
              token: this.token,
              paymentId: response.data.payment_id,
              totalInCents: response.data.total_in_cents,
            });
          });
      } else {
        this.errorCode = error.code;
        this.errorMessage = error.message;
        this.sending = false;

        axios.post(this.failureUrl, {
          token: this.token,
          code: error.code,
          description: error.message,
        });
      }
    },
    setupStripe() {
      axios.post(this.initiatePaymentUrl, {
        purchase_item_id: this.purchaseItem.id,
      })
        .then((response) => {
          this.token = response.data.token; // Use to identify the payment
          /* eslint-disable-next-line */
          this.stripe = Stripe(this.stripePublishableKey);
          const options = {
            clientSecret: response.data.client_secret,
          };
          this.elements = this.stripe.elements(options);
          const paymentElement = this.elements.create('payment');
          paymentElement.mount('#payment-element');
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },

  },
  mounted() {
    this.setupStripe();
  },
};
</script>
