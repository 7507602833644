<template>
  <section class="inbox-row" @click="showInvite">
    <div class="inbox-row__header">
      <InboxContactHeader
        :contact="otherParty"
        :relationshipLabel="viewingAsSender ?
          __('inbox.relationshipLabels.to') :
          __('inbox.relationshipLabels.from')"
      ></InboxContactHeader>
      <div class="inbox-row__inbox-status-container">
        <span class="inbox-row__inbox-status"
          :class="{
            'inbox-row__invite-status--sent': isSentByMeAndPending,
          }">
          <i
            class="far me-1"
            :class="{
              'fa-circle-check': isSentByMeAndPending,
              'fa-exclamation-circle': !isSentByMeAndPending,
            }"
          ></i>
          <template v-if="isSentByMeAndPending">
            {{ __('inbox.invites.statuses.sent') }}
          </template>
          <template v-else>
            {{ __(`inbox.invites.statuses.${invite.status}`) }}
          </template>
        </span>
      </div>
    </div>
    <div class="inbox-row__divider"></div>
    <div class="inbox-row__body">
      <!-- Always show one details line above invite -->
      <div class="inbox-row__details">
        <div class="inbox-row__when">
          <i class="fas fa-fw fa-inbox-in"
            :class="{
              'fa-arrow-turn-down-right': invite.status === 'forwarded',
              'fa-inbox-out': viewingAsSender && invite.status !== 'forwarded',
              'fa-inbox-in': !viewingAsSender && invite.status !== 'forwarded',
            }"
            style="margin-right: var(--sizing-1)"
          ></i>
          <template v-if="viewingAsSender"> {{ __('inbox.invites.whenSent') }} </template>
          <template v-else> {{ __('inbox.invites.whenReceived') }} </template>
          {{ invite.formatted_created_at_time }}
        </div>
        <div
          v-if="invite.status === 'pending' && invite.expires_in"
          class="inbox-row__expires-when"
        >
          <i class="fas fa-fw fa-exclamation-circle me-1 ms-1"></i>
          <template v-if="invite.status === 'expired'">
            {{ __('inbox.invites.expiry.expired') }} {{ invite.expires_in }}
          </template>
          <template v-else>
            {{ __('inbox.invites.expiry.expires') }} {{ invite.expires_in }}
          </template>
        </div>
      </div>

      <!--
      If this invite was forwarded TO the active user,
      show "stacked" fwd & original messages
      -->
      <InboxAdditionalMessage
        v-if="invite.forwarded_from_id && invite.recipient_id === activeContact?.id"
        title-icon="far fa-circle-exclamation"
        :title="__('inbox.invites.forwardedByColleague',
              {
                forwarded_by: invite.forwarded_from.recipient.display_name,
              })"
        :message="invite.forward_message"
      />

      <!--
        If the user is viewing an invite they forwarded on,
        include their outgoing message
      -->
      <InboxAdditionalMessage
        v-if="invite.status === 'forwarded' && invite.recipient_id === activeContact?.id"
        title-icon="far fa-circle-exclamation"
        :title="__('inbox.invites.forwardedByActiveUser',
                {
                  forwardee: invite.forwarded_to.recipient.display_name,
                })"
        :message="invite.forwarded_to_message"
      />

      <div class="inbox-row__message" :class="{'inbox-row__message-sent': viewingAsSender}">
        <p>
          <PreWrap :text="message" />
          <template v-if="messageExpandable">
            <a
              class="inbox-row__message-expand"
              :href="`#/invites/${invite.id}/show`"
              >
              &nbsp;{{ __('inbox.invites.buttons.read_more') }}
            </a>
          </template>
        </p>
      </div>

      <template v-if="viewingAsSender">
        <!--
          If viewing an invite that was forwarded from another
          and i sent the invite
        -->
        <InboxAdditionalMessage
          v-if="invite.forwarded_from_id"
          title-icon="far fa-circle-exclamation"
          :title="__('inbox.invites.forwardedByRecipient',
                  {
                    forwardee: `${otherParty.full_name ?? otherParty.display_name}`,
                    forwarded_by: `${invite.forwarded_from.recipient.full_name ?? invite.forwarded_from.recipient.display_name}`,
                  })"
          :message="invite.forward_message"
        />

        <InboxAdditionalMessage
          v-if="invite.status === 'declined'"
          title-icon="far fa-ban"
          :title="__('inbox.invites.declinedByRecipient',
                  {
                    declined_time: invite.formatted_declined_at_time,
                    declined_by: invite.recipient.display_name
                  })"
          :message="invite.decline_reason"
          :additional-message="invite.decline_message"
          is-warning
        />

        <InboxAdditionalMessage
          v-if="invite.status === 'withdrawn'"
          title-icon="far fa-ban"
          :title="__('inbox.invites.withdrawnByActiveUser',
                  {
                    withdrawn_time: invite.formatted_withdrawn_at_time,
                  })"
          :message="invite.withdraw_reason"
          :additional-message="invite.withdraw_message"
          is-warning
        />

      </template>
      <template v-else>
        <InboxAdditionalMessage
          v-if="invite.status === 'declined'"
          title-icon="far fa-ban"
          :title="__('inbox.invites.declinedByActiveUser',
                  {
                    declined_time: invite.formatted_declined_at_time,
                  })"
          :message="invite.decline_reason"
          :additional-message="invite.decline_message"
          is-warning
        />

        <InboxAdditionalMessage
          v-if="invite.status === 'withdrawn'"
          title-icon="far fa-ban"
          :title="__('inbox.invites.withdrawnBySender',
                  {
                    withdrawn_time: invite.formatted_withdrawn_at_time,
                    withdrawn_by: invite.sender.display_name,
                  })"
          :message="invite.withdraw_reason"
          :additional-message="invite.withdraw_message"
          is-warning
        />
      </template>

      <div
        v-if="invite.has_unread_chats"
        class="inbox-row__additional-info inbox-row__additional-info-warn"
      >
        <i class="fas fa-message-lines" style="margin-right: var(--sizing-2)"></i>
        <span v-html="__('inbox.messages.info.newUnreadMessage', {
          url: `#/invites/${invite.id}/show`,
        })"></span>
      </div>
      <div
        v-else-if="invite.chats_count > 0"
        class="inbox-row__additional-info"
      >
        <i class="far fa-info-circle"></i>
        {{ __('inbox.messages.info.moreMessagesInThread') }}
      </div>

      <div class="button-group">
        <button
          v-if="invite.policy.can_accept"
          class="button button--primary button--wide"
          type="button"
          @click.stop="acceptInvite"
        >
          {{ __('inbox.invites.buttons.accept') }}
          <i class="far fa-arrow-right-long"></i>
        </button>

        <SubOptions position="bottom-up">
          <template v-slot:button="{toggle}">
            <button
              @click.stop="toggle"
              type="button"
              class="button button--secondary button--wide"
            >
              {{ __('inbox.invites.buttons.moreOptions') }}
            </button>
          </template>
          <SubOption
            v-if="invite.policy.can_forward"
            icon="far fa-share"
            :label="__('inbox.invites.buttons.forward')"
            @clicked="forwardInvite"
          />
          <SubOption
            v-if="invite.policy.can_decline"
            icon="far fa-ban"
            :label="__('inbox.invites.buttons.decline')"
            @clicked="declineInvite"
          />
          <SubOption
            v-if="invite.policy.can_withdraw"
            icon="far fa-ban"
            :label="__('inbox.invites.buttons.withdraw')"
            @clicked="withdrawInvite"
          />
          <SubOption
            v-if="invite['messageable']"
            icon="far fa-paper-plane"
            :label="__('inbox.invites.buttons.askQuestion')"
            @clicked="showInvite"
          />
          <SubOption
            icon="far fa-eye"
            :label="__('inbox.invites.buttons.viewProfile')"
            @clicked="openProfileModal"
          />
        </SubOptions>
      </div>
    </div>
  </section>
</template>

<script>
import _ from 'lodash';
import PreWrap from '~/Components/Common/PreWrap.vue';
import SubOptions from '~/Components/Frontend/SubOptions.vue';
import SubOption from '~/Components/Frontend/SubOption.vue';
import { user, store } from '~/Frontend/store';
import InboxAdditionalMessage from './InboxAdditionalMessage.vue';
import InboxContactHeader from './InboxContactHeader.vue';

const messageUnexpandedLength = 175;

export default {
  components: {
    InboxAdditionalMessage,
    InboxContactHeader,
    SubOptions,
    SubOption,
    PreWrap,
  },
  props: {
    invite: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    viewingAsSender() {
      return this.invite.sender_id === this.activeContact.id;
    },
    isSentByMeAndPending() {
      return this.invite.status === 'pending' && this.viewingAsSender;
    },
    truncatedMessage() {
      return _.truncate(this.invite.message, { length: messageUnexpandedLength, separator: ' ' });
    },
    message() {
      return this.truncatedMessage;
    },
    messageExpandable() {
      return this.invite.message.length > messageUnexpandedLength;
    },
    otherParty() {
      return this.viewingAsSender
        ? this.invite.recipient
        : this.invite.sender;
    },
    eventEdition() {
      return store.eventEdition;
    },
    activeContact() {
      return user.activeContact;
    },
  },
  methods: {
    showInvite() {
      window.location.href = `#/invites/${this.invite.id}/show`;
    },
    acceptInvite() {
      window.location.href = `#/invites/${this.invite.id}/accept`;
    },
    forwardInvite() {
      window.location.href = `#/invites/${this.invite.id}/forward`;
    },
    declineInvite() {
      window.location.href = `#/invites/${this.invite.id}/decline`;
    },
    withdrawInvite() {
      window.location.href = `#/invites/${this.invite.id}/withdraw`;
    },
    openProfileModal() {
      if (this.viewingAsSender) {
        window.location.href = `#/contacts/${this.invite.recipient_id}/profile`;
      } else {
        window.location.href = `#/contacts/${this.invite.sender_id}/profile`;
      }
    },
  },
};
</script>
