<template>
  <option
    :value="value"
    :disabled="disabled"
  >
    {{ label }}
    <template v-if="badgeNumber > 0">
      ({{ badgeNumber }})
    </template>
  </option>
</template>

<script>

export default {
  name: 'FilterSelectOption',
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    badgeNumber: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
  },
};
</script>
