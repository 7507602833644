<template>
  <section class="catalogue-entry-card" :class="{'catalogue-entry-card--faded': isHidden}" @click="openModal">
    <div class="catalogue-entry-card__sub-menu" v-if="!model.is_iframed">
      <SubOptions>
        <template v-slot:button="{toggle}">
          <IconButton @click.stop="toggle" small icon="fa-ellipsis" />
        </template>
        <SubOptionFavourite
          :favouritableId="model.id"
          favouritableType="CatalogueEntry"
          v-model:isFavourited="isFavourited"
        />
        <SubOption :link="modalHref" icon="fas fa-eye" :label="__('catalogue_product_preview.view_product')" />
        <SubOptionHide
          :hideableId="model.id"
          hideableType="CatalogueEntry"
          v-model:isHidden="isHidden"
        />
      </SubOptions>
    </div>
    <div class="catalogue-entry-card__main-image-container">
      <img
        class="catalogue-entry-card__main-image"
        :src="mainImage"
        :alt="model.name + ' image'"
        v-if="mainImage"
        />
    </div>

    <div class="catalogue-entry-card__tags-container">
      <div
        v-if="model.visible_demographic_values?.length > 0"
        class="catalogue-entry-card__tag"
      >
        {{ model.visible_demographic_values[0].full_name }}
      </div>
      <div v-if="model.visible_demographic_values?.length > 1"
        class="catalogue-entry-card__tag"
        style="flex-shrink: 0"
      >
        + {{ model.visible_demographic_values.length - 1 }}
      </div>
    </div>

    <a class="catalogue-entry-card__entry-name" :href="modalHref">
      <text-clamp
        :text="model.name"
        :max-lines="2"
        max-height="48px"
        ellipsis="…"
        auto-resize
      >
        <template #before>
          <i
            v-if="isFavourited"
            class="fa-2xs fa fa-star"
            style="margin-right: 4px"
          ></i>
        </template>
      </text-clamp>
    </a>

    <a
      v-if="companyModalHref"
      class="catalogue-entry-card__company-name-link"
      @click.stop
      :href="companyModalHref"
      >
      {{ model.company_display_name }}
    </a>
    <span
      v-else
      class="catalogue-entry-card__company-name"
      >
      {{ model.company_display_name }}
    </span>

    <div class="company-card__actions">
      <a class="button button--secondary" @click.stop="openModal">
        {{ __('catalogue_product_preview.more') }}
      </a>
    </div>

  </section>
</template>

<script>
import TextClamp from 'vue3-text-clamp';
import SubOptionFavourite from '~/Components/Frontend/Profile/Actions/SubOptionFavourite.vue';
import SubOptionHide from '~/Components/Frontend/Profile/Actions/SubOptionHide.vue';
import SubOption from '~/Components/Frontend/SubOption.vue';
import SubOptions from '~/Components/Frontend/SubOptions.vue';
import { store } from '../../../Frontend/store';

export default {
  components: {
    TextClamp, SubOptionFavourite, SubOptionHide, SubOption, SubOptions,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      defaultImage: 'https://s3.eu-west-1.amazonaws.com/storage.v4.eventnetworking.com/assets/default-marketplace-card-banner.png',
      isFavourited: this.model.is_favourited,
      isHidden: this.model.isHidden,
    };
  },
  methods: {
    openModal() {
      window.location.href = this.modalHref;
    },
  },
  computed: {
    mainImage() {
      return this.model.main_image?.banner?.url ?? this.defaultImage;
    },
    eventEdition() {
      return store.eventEdition;
    },
    modalHref() {
      return `#/catalogue-entries/${this.model.id}/preview`;
    },
    companyModalHref() {
      if (this.model.is_iframed) {
        return null;
      }
      return `#/companies/${this.model.company_id}`;
    },
  },
};
</script>
