<template>

  <FeLoading v-if="loading"></FeLoading>

  <template v-else>
    <ProfileGroup v-if="questions">
      <CommunicationPreferencesProfileRow
        :questions="questions"
        :preferences="preferences"
        />
    </ProfileGroup>

    <ProfileGroup
      v-if="events"
      :heading="__('profile.groups.eventOptouts.heading')"
    >
      <EventOptoutProfileRow
        v-for="event in events"
        :key="event.id"
        :event-edition="event"
        :findable="optouts.filter(
          (optout) => optout.type === 'findable' && optout.event_edition_id === event.id
        ).length == 0"
        :meetable="optouts.filter(
          (optout) => optout.type === 'meetable' && optout.event_edition_id === event.id
        ).length == 0"
        />
    </ProfileGroup>
  </template>

</template>

<script>

import axios from 'axios';
import CommunicationPreferencesProfileRow from '~/Components/Frontend/Profile/Rows/CommunicationPreferencesProfileRow.vue';
import EventOptoutProfileRow from '~/Components/Frontend/Profile/Rows/EventOptoutProfileRow.vue';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import ProfileGroup from '~/Components/Frontend/Profile/ProfileGroup.vue';

export default {
  name: 'NotificationSettingsPage',
  components: {
    CommunicationPreferencesProfileRow,
    EventOptoutProfileRow,
    FeLoading,
    ProfileGroup,
  },
  data() {
    return {
      events: null,
      preferences: null,
      questions: null,
      optouts: null,
      loading: true,
    };
  },
  mounted() {
    axios.get('/profile/notification-settings')
      .then(({
        data: {
          questions, events, preferences, optouts,
        },
      }) => {
        this.questions = questions;
        this.events = events;
        this.preferences = preferences;
        this.optouts = optouts;
      }).then(() => {
        this.loading = false;
      });
  },
};
</script>
